import {
  RECEIVE_DELIVERY_ADDRESSES,
  SAVE_ADDRESS_REQUEST,
  SAVE_ADDRESS_SUCCESS,
  SAVE_ADDRESS_FAILURE,
} from '../../constants/account';

import { CALL_API } from '../../constants/api';

/**
 * @param {Array} addresses
 * @returns {{type: string, payload: array}}
 */
export function receiveDeliveryAddresses(addresses) {
  return {
    type: RECEIVE_DELIVERY_ADDRESSES,
    payload: addresses,
  };
}

/**
 * @param {Object} address
 * @returns {{type: string, payload: Object}}
 */
export function saveAddressRequest(address) {
  return {
    type: SAVE_ADDRESS_REQUEST,
    payload: address,
  };
}

/**
 * @param {string} errors
 * @returns {{type: string, payload: string}}
 */
export function saveAddressFailure(errors) {
  return {
    type: SAVE_ADDRESS_FAILURE,
    payload: errors,
    error: true,
  };
}

/**
 * @param {Object} address
 * @returns {{type: string, payload: Object}}
 */
export function saveAddressSuccess(address) {
  return {
    type: SAVE_ADDRESS_SUCCESS,
    payload: address,
  };
}

/**
 * @param {Object} address
 * @returns {{type, payload}|{type: string, payload: Object}}
 */
export function addAddress(address) {
  return saveAddressSuccess(address);
}

/**
 * @param {Object} address
 * @returns {{
 *  actions: {
 *   error: saveAddressFailure,
 *   start: saveAddressRequest,
 *   success: saveAddressSuccess
 *  },
 *  method: string,
 *  params: {address: *},
 *  type
 * }}
 */
export function apiSaveAddress(address) {
  return {
    actions: {
      error: saveAddressFailure,
      start: saveAddressRequest,
      success: saveAddressSuccess,
    },
    method: 'post',
    params: { address },
    type: CALL_API,
    url: 'account/address',
  };
}
