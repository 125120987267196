import { CALL_API } from '../constants/api';

/**
 * @param {Object} data
 *
 * @returns {{type}}
 */
export function apiLogToServer(data) {
  return {
    actions: {
      error: () => {
        return {
          type: 'LOG_TO_SERVER_ERROR',
          payload: {},
        };
      },
      start: () => {
        return {
          type: 'LOG_TO_SERVER_REQUEST',
          payload: {},
        };
      },
      success: () => {
        return {
          type: 'LOG_TO_SERVER_SUCCESS',
          payload: {},
        };
      },
    },
    method: 'post',
    params: {
      data,
    },
    type: CALL_API,
    url: 'log',
  };
}
