// @flow
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import FieldInputSubmit from 'brastrap/common/field-input-submit/FieldInputSubmit.jsx';
import type { EmailSignUp as Props } from './email-signup-form.types.js';

const messages = defineMessages({
  emailPlaceholder: {
    id: 'email-sign-up.placeholder',
    defaultMessage: 'Enter your email...',
  },
  submitLabel: { id: 'email-sign-up.submitLabel', defaultMessage: 'Go' },
  confirmed: {
    id: 'email-sign-up.confirmation',
    defaultMessage:
      'Thank you for signing up to receive emails from us' +
      ' \u2013 check your inbox for your first email from us very soon.' +
      " To make sure you don't miss out on our email updates, simply add {sender} to your email address book.",
  },
});

const EmailSignUpForm = (props: Props) => {
  const {
    confirmed,
    fields: { email },
    handleSubmit,
    invalid,
    isSaving,
    intl: { formatMessage },
    analyticsRef,
  } = props;

  if (confirmed) {
    return (
      <div className="c-email-sign-up">
        <p>{formatMessage(messages.confirmed)}</p>
      </div>
    );
  }

  const inputProps = {
    name: 'email',
    ...email,
    placeholder: formatMessage(messages.emailPlaceholder),
    type: 'email',
    required: true,
    readOnly: isSaving,
    modifiers: ['white'],
    'aria-label': 'Email Input',
  };

  const buttonProps = {
    label: formatMessage(messages.submitLabel),
    submit: true,
    disabled: invalid || isSaving,
  };

  return (
    <div className="c-email-sign-up">
      <FieldInputSubmit
        analyticsRef={analyticsRef}
        onSubmit={handleSubmit}
        input={inputProps}
        button={buttonProps}
      />
    </div>
  );
};

EmailSignUpForm.defaultProps = {
  fields: { email: {} },
  handleSubmit: () => {},
  isSaving: false,
};

export default injectIntl(EmailSignUpForm);
