import { createChildPath } from '../../../utils';
import generateAsyncRouteComponent from '../../../generateAsyncRouteComponent';

export default [
  {
    path: createChildPath('reward-points'),
    component: generateAsyncRouteComponent({
      loader: () => import('./containers/LoyaltyPointsPageAuthenticated'),
    }),
  },
  {
    path: createChildPath('bravellous-points'),
    component: generateAsyncRouteComponent({
      loader: () => import('./containers/LoyaltyPointsPageAuthenticated'),
    }),
  },
];
