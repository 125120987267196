import {
  RECEIVE_DEPARTMENTS,
  RECEIVE_VACANCY,
  RECEIVE_ROLES,
  RECEIVE_ROLE,
} from '../constants/jobs';

export const initialState = {};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function jobs(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload.departments,
        containers: action.payload.containers,
        sidebars: action.payload.sidebars,
      };

    case RECEIVE_VACANCY:
      return {
        ...state,
        vacancy: action.payload,
      };

    case RECEIVE_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case RECEIVE_ROLE:
      return {
        ...state,
        role: action.payload,
      };

    default:
      return state;
  }
}
