// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, type History } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as routingActions from '../actions/app/routing';
import { type State as ReduxRoutingState } from '../reducers/app/routing';

type Props = {
  ...ReduxRoutingState,
  push: string => void,
  replace: string => void,
  routingFunctionExecuted: () => void,
  changeLocation: RouterLocation => void,
  history: History,
  location: RouterLocation,
};

/**
 * Responds to actions dispatched elsewhere, and executes appropriate client-side
 * navigation functions. Also tracks client-side location changes and updates state.app.routing
 */
class RoutingHelper extends React.Component<Props> {
  /**
   * Listening to router history and updating state
   * and making url accessable for GTM when location changes
   *
   * We could make these updates in componentWillReceiveProps
   * when this component executes navigation functions, but if we were
   * to ever execute client-side navigation functions directly (ie. not by
   * dispatching a push or replace action and letting this component handle
   * the navigation) then that location change would not be updated in the store.
   * By using a listener we ensure that client location state is always accurate.
   */
  componentDidMount() {
    if (window) {
      const { hash } = window.location;
      window.scrollTo(0, 0);
      if (document && hash !== '') {
        // Push onto callback queue so it runs after the DOM is updated,
        // this is required when navigating from a different page so that
        // the element is rendered on the page before trying to getElementById.
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) element.scrollIntoView();
        }, 0);
      }
    }
    this.unlisten = this.props.history.listen(location => {
      // Tracking for Google Tag Manager
      if (window && window.setDataLayer) {
        window.setDataLayer({
          event: 'locationChange',
          path: location.pathname,
        });
      }
      // Keeps current Url in redux store
      this.props.changeLocation(location);
    });
    // Set initial location object provided by withRouter into Redux store
    // Only the url is set on the server - not query object, search string, or pathname
    this.props.changeLocation(this.props.location);
  }

  /**
   * Check if the redux store has been updated to indicate that
   * navigation should occur. If it has, execute the needed navigation function.
   * @param {Object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    const { history, routingFunctionExecuted } = this.props;
    if (nextProps.needsLocationUpdate) {
      const navigationFunction = history[nextProps.navigationFunction];
      if (typeof navigationFunction === 'function') {
        navigationFunction(nextProps.nextLocation);
        routingFunctionExecuted();
      }
    }
  }

  /**
   * Clean up listeners
   */
  componentWillUnmount() {
    this.unlisten();
  }

  unlisten = () => {};

  /**
   * This component only exists to listen for navigation actions and responds,
   * it does not render any content
   * @returns {null}
   */
  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  ...state.app.routing,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(routingActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RoutingHelper)
);
