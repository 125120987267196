import {
  PAGE_CONTENT_SUCCESS,
  PAGE_CONTENT_REQUEST,
  PAGE_CONTENT_FAILURE,
} from '../constants/page';

import { CALL_API } from '../constants/api';
import { setContent } from './editorial';
import { setSchema } from './schema';

/**
 * @param {object} contentData
 * @returns {{type, payload: object}}
 */
export const getPageSuccess = contentData => dispatch => {
  const { data, organisationSchema, siteSearchSchema } = contentData;
  dispatch(setContent(data));
  if (organisationSchema) {
    dispatch(setSchema('organisation', organisationSchema));
  }

  if (siteSearchSchema) {
    dispatch(setSchema('siteSearch', siteSearchSchema));
  }
  dispatch({
    type: PAGE_CONTENT_SUCCESS,
    payload: contentData,
  });
};

/**
 * @returns {{type}}
 */
export function getPageRequest() {
  return {
    type: PAGE_CONTENT_REQUEST,
  };
}

/**
 * @returns {{type}}
 */
export function getPageFailure() {
  return {
    type: PAGE_CONTENT_FAILURE,
  };
}

/**
 * Call the api middleware to fetch page content.
 *
 * @param {object} slug the url slug
 *
 * @returns {{
 *   actions: {
 *     error: getPageRequest,
 *     start: getPageFailure,
 *     success: getPageSuccess
 *   },
 *   method: string,
 *   params: *,
 *   type,
 *   url: string
 * }}
 */
export function apiGetPage(slug) {
  let url = 'page';
  if (slug) {
    url = `${url}/${slug}`;
  }
  return {
    actions: {
      start: getPageRequest,
      error: getPageFailure,
      success: getPageSuccess,
    },
    method: 'get',
    type: CALL_API,
    url,
  };
}
