// @flow
import { connect } from 'react-redux';
import RedirectModal from 'brastrap/common/modal/ModalRedirection';

import { REDIRECT_MODAL_ID } from '../constants/app';
import { redirectToExpected, remainOnSelected } from '../actions/app/locale';

const mapStateToProps = ({
  app: {
    locale: { modalContent },
  },
  layer,
}) => ({
  ...modalContent,
  isOpen: layer.target === REDIRECT_MODAL_ID && layer.isVisible,
});

const mapDispatchToProps = dispatch => ({
  onRemainClick: () => dispatch(remainOnSelected()),
  onRedirectClick: () => dispatch(redirectToExpected()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RedirectModal);
