// @flow
import React from 'react';

import { type Facet } from './facet-types';
import FacetInput from './ResultsFacetsInput';

type Props = {
  onChange: (boolean, string, string) => void,
  facet: Facet,
};

type State = {
  selectedId: string,
};

class ResultsFacetsEnhanced extends React.Component<Props, State> {
  state = { selectedId: '' };

  /**
   * Renders the component.
   * @return {React.Component}
   */
  render() {
    const { facet, onChange } = this.props;
    return (
      <div
        className={`c-results-facet__enhanced c-results-facet__enhanced-${
          facet.style || 'disc'
        }`}
        id={facet.id}
      >
        <p className="c-results-facet__enhanced-title">{facet.name}</p>
        <ul>
          {facet.values.map(({ id, name, selected, swatch }) => {
            const props = {
              id: `facet-input-${facet.id}-${id}`,
              key: `${facet.id}-${id}`,
              name,
              onChange: e => onChange(e.target.checked, id, facet.id),
              selected,
              swatch,
              value: id,
              checked: !!selected,
            };

            return <FacetInput {...props} />;
          })}
        </ul>
      </div>
    );
  }
}

export default ResultsFacetsEnhanced;
