// @flow
import React from 'react';
import ReturnNav from 'brastrap/common/return-nav/ReturnNav';
import Action from 'brastrap/common/action/Action';
import { applyModifiers } from '../../utils';
import type { Modal as Props } from './modal.types.js';

const CLASS_NAME = 'c-modal';

const Modal = (props: Props) => (
  <div className="c-modal__container" data-open={props.isOpen}>
    <div
      className={applyModifiers(CLASS_NAME, props.modifiers)}
      data-open={props.isOpen}
      id={props.modalId}
      style={{ background: props.backgroundColour }}
    >
      {(props.title || props.actions) && (
        <header className="c-modal__header">
          {props.title && <h1 className="c-modal__title">{props.title}</h1>}

          {props.actions && (
            <div className="c-modal__actions">
              {props.actions.returnNav && (
                <ReturnNav {...props.actions.returnNav} />
              )}
              {props.actions.dismiss && (
                <Action {...props.actions.dismiss} modifiers={['dismiss']} />
              )}
            </div>
          )}
        </header>
      )}

      <div className="c-modal__main">{props.children}</div>

      {Array.isArray(props.footer) && (
        <div className="c-modal__footer">
          {props.footer.map((element, key) => {
            if (React.isValidElement(element)) {
              return React.cloneElement(element, { key });
            }
            return null;
          })}
        </div>
      )}
    </div>
  </div>
);

Modal.defaultProps = {
  onClick: () => {},
  isOpen: false,
  modalId: null,
  modifiers: [],
  backgroundColour: 'white',
};

export default Modal;
