import { change } from 'redux-form';

import { CALL_API } from '../constants/api';
import {
  CONTACT_REQUEST,
  CONTACT_REQUEST_SUCCEEDED,
  CONTACT_REQUEST_FAILED,
  RECEIVE_ENQUIRY_TEMPLATES,
  SELECT_ENQUIRY_TEMPLATE,
} from '../constants/contact-request';

/**
 * @param {Object} payload - containing the details of the request
 * @returns {Object} An action containing all the necessary fields to make an API call
 */
export function contactRequestSubmitted(payload) {
  return {
    actions: {
      start: contactRequestStart,
      success: contactRequestSuccess,
      error: contactRequestFailure,
    },
    method: 'post',
    params: payload,
    type: CALL_API,
    url: 'contactRequest',
  };
}

/**
 * @returns {{type: String}}
 */
export function contactRequestStart() {
  return {
    type: CONTACT_REQUEST,
  };
}

/**
 * @param {object} details
 * @returns {{type, payload: object}}
 */
export function contactRequestSuccess(details) {
  return {
    type: CONTACT_REQUEST_SUCCEEDED,
    payload: details,
  };
}

/**
 * @param {object} details
 * @returns {{type, error: boolean, payload: object}}
 */
export function contactRequestFailure(details) {
  return {
    type: CONTACT_REQUEST_FAILED,
    error: true,
    payload: details,
  };
}

/**
 * @param {Array} templates
 * @return {{type, payload: *}}
 */
export function receiveEnquiryTemplates(templates) {
  return {
    type: RECEIVE_ENQUIRY_TEMPLATES,
    payload: templates,
  };
}

/**
 * @param {string} subject
 * @return {{type, payload: *}}
 */
export const selectEnquiryTemplate = subject => (dispatch, getState) => {
  dispatch({
    type: SELECT_ENQUIRY_TEMPLATE,
    payload: subject,
  });
  const state = getState();
  const message =
    (state.contactRequest &&
      state.contactRequest.enquiryTemplate &&
      state.contactRequest.enquiryTemplate.body) ||
    '';
  dispatch(change('contact', 'message', message));
};
