/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { renderRoutes } from '@bravissimolabs/react-router-config';
import root from 'shared/components/Root';
import createStore from 'shared/create-store';
import createRoutes from 'shared/routes';
import { calculateResponsiveState } from 'redux-responsive';
import { loadAsyncRouteComponents, match } from 'shared/routes/utils';
import qhistory from 'qhistory';
import { sendErrorMessage } from 'shared/utils/utils';
import createBrowserHistory from 'history/createBrowserHistory';
import { stringify, parse } from 'qs';
import 'brastrapAssets/styles/brastrap.scss';

const initialState = JSON.parse(document.getElementById('__STATE__').innerText);
const path = initialState.app && initialState.app.config && initialState.app.config.basePath;
const routes = createRoutes(path);
const store = createStore(initialState);

const { pathname, search, hash } = window.location;
const url = (initialState.app.routing && initialState.app.routing.url) || `${pathname}${search}${hash}`;

const stringifyQuery = query => stringify(query, { arrayFormat: 'brackets', encode: false });
const history = qhistory(
  createBrowserHistory(),
  stringifyQuery,
  parse
);

// Log Level 1 - send client errors to New Relic
// Log Level 2 - send to New Relic and Rethinkdb
if (window && (process.env.CLIENT_ERROR_LOG_LEVEL === '1' || process.env.CLIENT_ERROR_LOG_LEVEL === '2')) {
  window.onerror = (message, source, lineNum, colNum, error) => {
    // Unfortunately, most browsers won't supply the error object
    // so creating one with the information we do have
    const err = error || new Error(message);
    sendErrorMessage(store.getState(), err, 'WINDOW_EVENT_LISTENER');
  };
}

const render = async () => {
  const branch = match(routes, url);
  await loadAsyncRouteComponents(branch);

  // We use an error route, without modifying the url to display an error page. Need to give the router
  // the "simulated" error location if we want to display the error page on the client.
  if (url.match('error')) {
    history.location = { pathname: url };
  }

  const router = React.createElement(Router, { history }, renderRoutes(routes));
  
  // hydrate for react 16 client side rendering
  ReactDOM.hydrate(
    root({ store, children: router }),
    document.getElementById('app')
  );
  store.dispatch(calculateResponsiveState(window));
};

render();

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('shared/routes', render);
}
