import {
  RECEIVE_NAV_ITEMS,
  SET_ITEM_VISIBILITY,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAILURE,
  PRODUCT_SUGGESTED_SEARCH_REQUEST,
  PRODUCT_SUGGESTED_SEARCH_SUCCESS,
  PRODUCT_SUGGESTED_SEARCH_FAILURE,
  PRODUCT_SEARCH_OPEN,
  PRODUCT_SEARCH_CLOSE,
} from '../../constants/masthead';

export const initialState = {
  active: false,
  focused: false,
  isFetching: false,
  items: [],
  recentSearches: [],
  suggestions: [],
};

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
export function navigation(state = initialState, action = {}) {
  switch (action.type) {
    case PRODUCT_SEARCH_REQUEST:
      return {
        ...state,

        isFetching: true,
      };

    case PRODUCT_SEARCH_SUCCESS:
      return {
        ...state,

        active: true,
        isFetching: false,
        searchPhrase: '',
      };

    case PRODUCT_SEARCH_FAILURE:
      return {
        ...state,

        isFetching: false,
      };

    case RECEIVE_NAV_ITEMS:
      return {
        ...state,

        items: action.payload,
      };

    case SET_ITEM_VISIBILITY:
      return {
        ...state,

        items: state.items.map((item, index) =>
          action.payload !== index || item.isVisible
            ? { ...item, isVisible: false }
            : { ...item, isVisible: true }
        ),
      };

    case PRODUCT_SEARCH_OPEN:
      return {
        ...state,

        focused: true,
      };

    case PRODUCT_SEARCH_CLOSE:
      return {
        ...state,

        active: false,
        focused: false,
      };

    case PRODUCT_SUGGESTED_SEARCH_REQUEST:
      return {
        ...state,

        isFetching: true,
        suggestions: [],
      };

    case PRODUCT_SUGGESTED_SEARCH_SUCCESS:
      return {
        ...state,

        active: true,
        isFetching: false,
        suggestions: action.payload.suggestions,
        recentSearches: action.payload.recentSearches,
      };

    case PRODUCT_SUGGESTED_SEARCH_FAILURE:
      return {
        ...state,

        isFetching: false,
      };

    default:
      return state;
  }
}
