import { CALL_API } from '../constants/api';
import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from '../constants/password-reset-request';

import { addMessage } from './messages';

// Thunk to hook up with messages reducer
const setStatusAndMessage = (
  action,
  messageObject,
  isError = false
) => dispatch => {
  const { message } = messageObject;
  dispatch(action());
  dispatch(addMessage(message, 'resetRequest', isError));
};

/**
 * @param {{email: string}} payload - containing the email address for the account to reset
 * @returns {Object} An action containing all the necessary fields to make an API call
 */
export function passwordResetRequested(payload) {
  return {
    actions: {
      start: passwordResetRequestStart,
      success: response =>
        setStatusAndMessage(passwordResetRequestSuccess, response),
      error: response =>
        setStatusAndMessage(passwordResetRequestFailure, response, true),
    },
    method: 'post',
    params: payload,
    type: CALL_API,
    url: 'account/passwordResetRequest',
  };
}

/**
 * @param {object} email
 * @returns {{type, payload: object}}
 */
export function passwordResetRequestStart(email) {
  return {
    type: RESET_PASSWORD,
    payload: {
      ...email,
    },
  };
}

/**
 * @param {object} details
 * @returns {{type, payload: object}}
 */
export function passwordResetRequestSuccess(details) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: details,
  };
}

/**
 * @param {object} details
 * @returns {{type, error: boolean, payload: object}}
 */
export function passwordResetRequestFailure(details) {
  return {
    type: RESET_PASSWORD_FAILURE,
    error: true,
    payload: details,
  };
}
