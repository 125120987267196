import {
  RECEIVE_TOOLS,
  RECEIVE_HEADER_LINKS,
  OPEN_DRAWER,
  CLOSE_DRAWER,
  OPEN_POPOVER_BAG,
  CLOSE_POPOVER_BAG,
  RECEIVE_TOP_BAR_TEXT,
} from '../../constants/masthead';

import { openLayer, closeLayer } from '../layer';

import { clearAllMessages } from '../messages';

import { startTimer } from '../timers';
import { CLOSE_POPOVER_BAG_TIMER } from '../../constants/timers';

// TODO: This needs cleaning up

/**
 * @param {array} tools
 * @returns {{type, payload: array}}
 */
export function receiveTools(tools) {
  return {
    type: RECEIVE_TOOLS,
    payload: tools,
  };
}

/**
 * @param {array} links
 * @returns {{type, payload: array}}
 */
export function receiveLinks(links) {
  return {
    type: RECEIVE_HEADER_LINKS,
    payload: links,
  };
}

/**
 * @param {array} topBarText
 * @returns {{type, payload: array}}
 */
export function receiveTopBarText(topBarText) {
  return {
    type: RECEIVE_TOP_BAR_TEXT,
    payload: topBarText,
  };
}

/**
 * @param {string} drawerName
 * @return {{type, payload: *}}
 */
export function openDrawer(drawerName) {
  return {
    type: OPEN_DRAWER,
    payload: drawerName,
  };
}

/**
 * @param {string} drawerName
 * @returns {{type}}
 */
export function closeDrawer(drawerName) {
  return {
    type: CLOSE_DRAWER,
    payload: drawerName,
  };
}

/**
 * @return {{type}} an action to open the popover bag
 */
export function openPopoverBag() {
  return {
    type: OPEN_POPOVER_BAG,
  };
}

/**
 * @return {{type}} an action to close the popover bag
 */
function closePopoverBag() {
  return {
    type: CLOSE_POPOVER_BAG,
  };
}

/**
 * @param {string} drawerName
 * @returns {function} dispatch
 */
export const closeDrawerAndLayer = (drawerName = 'navigation') => dispatch => {
  dispatch(closeDrawer(drawerName));
  dispatch(closeLayer());
};

/**
 * Dispatches two events to open the drawerNav and open the backdrop.
 *
 * @param {string} drawerName
 * @returns {Function}
 */
export const openDrawerAndLayer = (drawerName = 'navigation') => dispatch => {
  dispatch(openDrawer(drawerName));
  dispatch(openLayer(drawerName));
};

/**
 * Dismiss all bag messages when the bag is closed.
 *
 * @returns {Function}
 */
export const closeBagAndDeleteMessages = () => (dispatch, getState) => {
  const state = getState();
  if (!state.browser.deviceIs.desktop) {
    dispatch(closeLayer());
  }
  dispatch(clearAllMessages('bag'));
};

/**
 * Open the bag drawer or the popover bag. God bless
 * redux-thunk for supplying getState().
 *
 * @returns {Function}
 */
export const showBag = () => (dispatch, getState) => {
  const state = getState();
  const action = !state.browser.deviceIs.desktop
    ? openDrawerAndLayer('bag')
    : openPopoverBag();
  dispatch(action);
};

/**
 * Closes the bag drawer or the popover bag. Unlike
 * showBag() above, we don't care about the browser
 * dimensions because we are closing, not opening.
 *
 * @returns {Function}
 */
export const hideBag = () => dispatch => {
  dispatch(closeBagAndDeleteMessages());
  dispatch(closePopoverBag());
};

/**
 * @return {Object} an action that starts a countdown, which when triggered will close the bag.
 */
export const startPopoverBagCloseTimer = () => dispatch => {
  const action = startTimer(CLOSE_POPOVER_BAG_TIMER, 5000, () =>
    dispatch(hideBag())
  );

  dispatch(action);
};
