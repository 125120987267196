// @flow
import React from 'react';
import Link from 'brastrap/common/link/Link';
import Button from 'brastrap/common/button/Button';
import EditorialContainer from 'brastrap/editorial/editorial-container/EditorialContainer';
import type { Container } from 'brastrap/editorial/editorial-container/editorial-container.types';
import Modal from './Modal';
import { applyModifiers } from '../../utils';

type RedirectAction = {
  action: string,
  label: string,
  stayOnCurrentSite: boolean,
  modifiers?: string | Modifiers,
  linkType: string,
};

type RedirectActions = Array<RedirectAction>;

export type Props = {
  title?: string,
  actions: RedirectActions,
  containers: Array<Container>,
  onRemainClick: (SyntheticEvent<HTMLButtonElement>) => void,
  onRedirectClick: (SyntheticEvent<HTMLButtonElement>) => void,
  isOpen: boolean,
  modalId: string,
  modifiers?: string | Modifiers,
};

const baseModifiers = ['dialog', 'redirection'];

const RedirectModal = ({
  title,
  actions,
  containers,
  onRemainClick,
  onRedirectClick,
  isOpen,
  modalId,
  modifiers,
}: Props) => {
  const modifiersArray = Array.isArray(modifiers)
    ? [...baseModifiers, ...(modifiers || [])]
    : [...baseModifiers, modifiers || ''];
  return (
    // We need to make sure that the modal is only rendered client side (for SEO reasons)
    // So if it's closed (which is the initial state of the locale reducer) do not render the modal in the DOM.
    isOpen ? (
      <Modal
        title={title}
        isOpen={isOpen}
        modalId={modalId}
        modifiers={modifiersArray}
        footer={actions.map(action => {
          if (action.linkType === 'Link') {
            return (
              <Link
                key={action.label}
                id={
                  action.stayOnCurrentSite
                    ? 'modalDeclineButton'
                    : 'modalAcceptButton'
                }
                className={applyModifiers('c-modal-link', action.modifiers)}
                onClick={
                  action.stayOnCurrentSite ? onRemainClick : onRedirectClick
                }
              >
                {action.label}
              </Link>
            );
          }
          return (
            <Button
              key={action.label}
              id={
                action.stayOnCurrentSite
                  ? 'modalDeclineButton'
                  : 'modalAcceptButton'
              }
              label={action.label}
              modifiers={action.modifiers}
              onClick={
                action.stayOnCurrentSite ? onRemainClick : onRedirectClick
              }
            />
          );
        })}
      >
        <EditorialContainer containers={containers} />
      </Modal>
    ) : null
  );
};

RedirectModal.defaultProps = {
  modifiers: '',
  containers: [],
  actions: [],
};

export default RedirectModal;
