// @flow
import React from 'react';
import Activity from 'brastrap/common/activity/Activity';
import RatioBox from 'brastrap/helpers/ratio-box/ratio-box';
import Ellipsis from 'brastrap/common/ellipsis/Ellipsis';

type Height = number;
type Width = number;

type Props = {
  height?: Height,
  loader: 'activity' | 'ellipsis',
  width?: Width,
};

/**
 * Get the aspect ratio from a width and height.
 *
 * @param {Number} height
 * @param {Number} width
 * @returns {Number|false}
 */
export const getAspectRatio = (height?: Height, width?: Width) =>
  // If a width and height are specified then return the aspect ratio.
  height && width && width / height;

/**
 * @param {Object} props
 * @returns {JSX}
 * @constructor
 */
const Placeholder = ({ loader, height, width }: Props) => {
  const aspectRatio = getAspectRatio(height, width);

  const content = loader === 'activity' ? <Activity /> : <Ellipsis />;

  return (
    <div className="c-image__placeholder">
      {aspectRatio ? (
        <RatioBox aspectRatio={aspectRatio}>{content}</RatioBox>
      ) : (
        content
      )}
    </div>
  );
};

Placeholder.defaultProps = {
  loader: 'activity',
};

export default Placeholder;
