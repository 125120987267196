import PropTypes from 'prop-types';
import React from 'react';
import Link from 'brastrap/containers/link/LinkContainer';
import Icon from 'brastrap/common/icon/Icon';

const STYLE_PREFIX = 'c-action';

const Action = props => {
  const buttonProps = {
    className: STYLE_PREFIX,
  };

  props.modifiers.forEach(modifier => {
    buttonProps.className += ` ${STYLE_PREFIX}--${modifier}`;
  });

  if (props.dataset) {
    Object.keys(props.dataset).forEach(
      // eslint-disable-next-line no-return-assign
      key => (buttonProps[`data-${key}`] = props.dataset[key])
    );
  }

  if (props.icon && props.icon.label) {
    buttonProps['aria-label'] = props.icon.label;
  }

  if (props.onClick) {
    buttonProps.onClick = props.onClick;
  }

  const icon = <Icon {...props.icon} />;

  return props.url ? (
    <Link {...buttonProps} href={props.url}>
      {icon}
    </Link>
  ) : (
    <button {...buttonProps} type="button" onClick={props.onClick}>
      {icon}
    </button>
  );
};

Action.propTypes = {
  dataset: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  id: PropTypes.string,
  icon: PropTypes.shape(Icon.propTypes),
  modifiers: PropTypes.array,
  url: PropTypes.string,
  onClick: PropTypes.func,
};

Action.defaultProps = {
  modifiers: [],
  onClick: () => {},
};

export default Action;
