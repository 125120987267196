// @flow
import { CLOSE_OVERLAY, OPEN_OVERLAY } from '../constants/overlay';

import { type Actions } from '../actions/overlay';

type State = {
  isVisible: boolean,
  message: ?string,
};

export const initialState = {
  isVisible: false,
  message: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {{target, onClose}}
 */
export function overlay(state: State = initialState, action: Actions) {
  switch (action.type) {
    case OPEN_OVERLAY: {
      return {
        ...state,
        isVisible: true,
        ...action.payload,
      };
    }

    case CLOSE_OVERLAY:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
