// @flow
import { type Dispatch } from 'react-redux';
import {
  type SetPoints as SetPointsType,
  SET_POINTS,
  type GetPointsFailure as GetPointsFailureType,
  GET_POINTS_FAILURE,
  type GetPointsSuccess as GetPointsSuccessType,
  GET_POINTS_SUCCESS,
  type GetPointsRequest as GetPointsRequestType,
  GET_POINTS_REQUEST,
} from '../../constants/loyalty';
import { type CallApi, CALL_API } from '../../constants/api';

type LoyaltyApiPayload = {
  display: boolean,
};

type SetPoints = {
  type: SetPointsType,
  payload: LoyaltyApiPayload,
};

/**
 * @param {Object} payload
 * @return {{type: SetPointsType, payload: {display: boolean}}}
 */
export function setPoints(payload: LoyaltyApiPayload): SetPoints {
  return {
    type: SET_POINTS,
    payload,
  };
}

type GetPointsRequest = {
  type: GetPointsRequestType,
};

/**
 * @return {{type: GetPointsRequest}}
 */
function getPointsRequest(): GetPointsRequest {
  return {
    type: GET_POINTS_REQUEST,
  };
}

type GetPointsFailure = {
  type: GetPointsFailureType,
  error: boolean,
};

/**
 * @return {{type: GetPointsFailure, error: boolean}}
 */
function getPointsFailure(): GetPointsFailure {
  return {
    type: GET_POINTS_FAILURE,
    error: true,
  };
}

type GetPointsSuccess = {
  type: GetPointsSuccessType,
};

/**
 * @param {Object} payload
 * @return {{type: GetPointsSuccess, payload: LoyaltyApiPayload}}
 */
const getPointsSuccess = (payload: LoyaltyApiPayload) => (
  dispatch: Dispatch<GetPointsSuccess | SetPoints>
) =>
  dispatch({
    type: GET_POINTS_SUCCESS,
  }).then(dispatch(setPoints(payload)));

export type ApiGetPoints = {
  actions: {
    error: string => GetPointsFailure,
    start: () => GetPointsRequest,
    success: LoyaltyApiPayload => Dispatch<GetPointsSuccess>,
  },
  type: CallApi,
  url: string,
};

/**
 * @return {*}
 */
export function apiGetPoints(): ApiGetPoints {
  return {
    actions: {
      error: getPointsFailure,
      start: getPointsRequest,
      success: getPointsSuccess,
    },
    type: CALL_API,
    url: 'account/loyalty',
  };
}

export type Actions =
  | SetPoints
  | GetPointsFailure
  | GetPointsRequest
  | GetPointsSuccess;
