// @flow
import { SET_SCHEMA } from '../constants/schema';

export type Schema = {}; // Can be any object really.
export type Key = string;

export type SetSchema = {|
  type: typeof SET_SCHEMA,
  payload: {
    key: Key,
    schema: Schema,
  },
|};

/**
 * @param {String} key
 * @param {Object} schema
 * @returns {{type, payload: {key: *, schema: *}}}
 */
export function setSchema(key: Key, schema: Schema): SetSchema {
  return {
    type: SET_SCHEMA,
    payload: {
      key,
      schema,
    },
  };
}
