import unique from 'lodash/uniq';
import flatten from 'lodash/flatten';

/**
 * @param {Object} value
 * @returns {Boolean}
 */
const selected = value => !!value.selected;

/**
 * @param {Object} value
 * @returns {*[]}
 */
const getMatching = value => {
  const { matching = [value.id] } = value;
  return matching;
};

/**
 * @param {Object} facet
 * @returns {Array}
 */
const addSelectedFacetValues = facet => {
  const selectedValues = facet.values.filter(selected);
  return unique(flatten(selectedValues.map(getMatching)));
};

/**
 * @param {Object} facets
 * @param {Object} facet
 * @returns {Object}
 */
const addSelectedValues = (facets, facet) => {
  const list = { ...facets };
  list[facet.id] = addSelectedFacetValues(facet);
  return list;
};

/**
 * Add new values to the existing values. Ensure no duplicates are added.
 *
 * @param {Array} existingValues
 * @param {Array} values
 * @returns {Array}
 */
const addValues = (existingValues = [], values = []) => [
  ...existingValues,
  ...values.filter(value => existingValues.indexOf(value) === -1),
];

/**
 * Find the values that are going to be added to/removed from the facet
 * Includes matching values too.
 *
 * @param {Array} facets
 * @param {string} facetId
 * @param {string} valueId
 * @returns {Array}
 */
const findValues = (facets, facetId, valueId) => {
  const facetValue = (
    (facets.find(facet => facet.id === facetId) || {}).values || []
  ).find(value => value.id === valueId);
  return facetValue.matching && facetValue.matching.length
    ? facetValue.matching
    : [valueId];
};

/**
 * Either add or remove a value to a facet.
 * If a matching value is found, both are added to the facet.
 *
 * Example:
 *  - User selects size 28DD
 *  - If it has a matching facet ie 28D/DD/FF, then that size is also added to the facet.
 *
 * @param {Array} facets
 * @param {Boolean} isSelected
 * @param {String} valueId
 * @param {String} facetId
 * @returns {Object}
 */
export default function update(facets = [], isSelected, valueId, facetId) {
  const newFacets = facets.reduce(addSelectedValues, {});

  // For some reason (I have not investigated into the cause), this function is called without a value or facet.
  if (valueId && facetId) {
    const values = findValues(facets, facetId, valueId);

    if (isSelected) {
      // Add this facet value
      newFacets[facetId] = addValues(newFacets[facetId], values);
    } else {
      // Remove the facet value and all its matching values
      // I have to remove all of them or it will be impossible to remove facets created by dual sizes
      values.forEach(value => {
        newFacets[facetId].splice(newFacets[facetId].indexOf(value), 1);
      });
    }
  }

  return newFacets;
}
