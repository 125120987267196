import {
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  PASSWORD_CHANGE_CLOSE_MESSAGES,
} from 'shared/constants/password';

export const initialState = {
  errorMessage: null,
  confirmationMessage: null,
  isSaving: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function passwordChangeForm(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,

        isSaving: true,
        errorMessage: null,
        confirmationMessage: null,
      };

    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,

        isSaving: false,
        errorMessage: action.message,
        confirmationMessage: null,
      };

    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,

        isSaving: false,
        errorMessage: null,
        confirmationMessage: action.message,
      };

    case PASSWORD_CHANGE_CLOSE_MESSAGES:
      return {
        ...state,

        confirmationMessage: null,
        errorMessage: null,
      };

    default:
      return state;
  }
}
