// @flow
import React from 'react';
import StyledText from 'brastrap/editorial/styled-text/StyledText';
import Link from 'brastrap/containers/link/LinkContainer';
import type { ClickThroughPanel as Props } from './click-through-panel.types.js';

const ClickThroughPanel = (props: Props) => {
  const panelInlineStyle = {};
  if (props.backgroundColour) {
    panelInlineStyle.backgroundColor = props.backgroundColour;
  }
  const panelText = props.styledText.map((item, index) => (
    <StyledText key={index} {...item} />
  ));
  return (
    <div className="c-click-through-panel" style={panelInlineStyle}>
      <Link href={props.link.url || '/'}>{panelText}</Link>
    </div>
  );
};

ClickThroughPanel.defaultProps = {
  styledText: [],
  link: {},
};

export default ClickThroughPanel;
