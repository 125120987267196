import React from 'react';

const SvgIcon = () => (
  <svg
    className="c-icon__glyph"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
  >
    <path
      fillRule="evenodd"
      d="M84 24H22c-8 0-14 6-14 14s6 14 14 14h156c8 0 14-6 14-14s-6-14-14-14h-62V14c0-8-6-14-14-14h-4c-8 0-14 6-14 14v10zM24 64h152v120c0 9-7 16-16 16H40c-9 0-16-7-16-16V64zm28 0h96v104c0 2-2 4-4 4H56c-2 0-4-2-4-4V64zm12 0h16v96H64V64zm28 0h16v96H92V64zm28 0h16v96h-16V64z"
    />
  </svg>
);

export default SvgIcon;
