import PropTypes from 'prop-types';
import React from 'react';
import Field from 'brastrap/common/field/Field.jsx';
import { applyModifiers, modifiersShape } from '../../utils';

const invalidSelectProps = [
  'options',
  'modifiers',
  'hiddenLabel',
  'initialValue',
  'autofill',
  'onUpdate',
  'valid',
  'invalid',
  'dirty',
  'pristine',
  'active',
  'visited',
  'autofilled',
  'note',
  'touched',
  'error',
  'floatLabel',
  'fieldType',
  'validation',
];

export const filterSelectProps = props => {
  const selectProps = { ...props };
  invalidSelectProps.forEach(attr => delete selectProps[attr]);
  return selectProps;
};

const FieldSelect = props => {
  const options = [...props.options];
  if (props.placeholder) {
    options.unshift({
      label: props.required ? `${props.placeholder}*` : props.placeholder,
      value: '',
    });
  }

  const selectProps = {
    ...filterSelectProps(props),
    className: applyModifiers('c-field-select', props.modifiers),
  };

  const select = (
    <select {...selectProps}>
      {options.map(({ disabled, label, value }, key) => {
        const optionProps = { key, value };
        if (disabled) {
          optionProps.disabled = true;
        }
        return <option {...optionProps}>{label}</option>;
      })}
    </select>
  );

  if (props.label) {
    return (
      <Field {...props}>
        {(props.touched || !props.invalid) && props.note && (
          <em className="c-field__note">{props.note}</em>
        )}
        {select}
      </Field>
    );
  }

  return select;
};

const stringOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

FieldSelect.propTypes = {
  disabled: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  label: PropTypes.string,
  modifiers: modifiersShape,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: stringOrNumber,
      value: stringOrNumber,
      disabled: PropTypes.bool,
    })
  ),
  placeholder: PropTypes.string,
  value: stringOrNumber,
  touched: PropTypes.bool,
  invalid: PropTypes.bool,
  note: PropTypes.string,
  required: PropTypes.bool,
  hasStickyValues: PropTypes.bool
};

FieldSelect.defaultProps = {
  name: 'select',
  disabled: false,
  options: [],
  hiddenLabel: false,
};

export default FieldSelect;
