// @flow
// $FlowFixMe
import React, { useState } from 'react';
// Our current flow version does not support type checking for react hooks
// TODO: Update the flow version to latest to add type checking for hooks as well.
import { defineMessages, injectIntl, type IntlShape } from 'react-intl';
import Table from './ShopByTable';
import Accordion from './ShopByAccordion';
import { applyModifiers } from '../../utils';

type Size = {
  label: string,
  href: Url,
  title: string,
};

type Variant = {
  sizes: {
    value: string,
    variants: Array<Size>,
  },
  value: string,
};

export type Sizes = {
  value: string,
  variants: Array<Variant>,
};

type Props = {
  accordion: boolean,
  sizes: Sizes,
  intl: IntlShape,
};

type ShopBySizeTab = {
  id: string,
  current?: boolean,
  type: string,
};

const titles = defineMessages({
  lingerie: { id: 'shop-by.tab.lingerie', defaultMessage: 'Lingerie' },
  swimwear: { id: 'shop-by.tab.swimwear', defaultMessage: 'Swimwear' },
  subTitle: {
    id: 'shop-by.sub-title',
    defaultMessage: 'Select your back & cup size',
  },
});

const SHOP_BY_SIZE_TABS: Array<ShopBySizeTab> = [
  {
    id: 'lingerie',
    current: true,
    type: 'bras',
  },
  {
    id: 'swimwear',
    type: 'swimwear',
  },
];

const SizeTable = ({ accordion, sizes, intl: { formatMessage } }: Props) => {
  // following condition is to fix the flow type error. This will never occur as there is default tab selected.
  const currentTab: ShopBySizeTab =
    SHOP_BY_SIZE_TABS.find(tab => tab.current) || SHOP_BY_SIZE_TABS[0];

  const [shopBySizeTabs, setShopBySizeTabs] = useState(SHOP_BY_SIZE_TABS);
  const [type, setType] = useState(currentTab.type);

  const selectTab = selectedTab => {
    setShopBySizeTabs(
      shopBySizeTabs.map(tab => ({
        ...tab,
        current: tab.id === selectedTab.id,
      }))
    );
    setType(selectedTab.type);
  };

  const STYLE_PREFIX = 'c-shop-by';

  const getTabs = () =>
    shopBySizeTabs.map(tab => {
      const className = applyModifiers(
        `${STYLE_PREFIX}__tab`,
        `${tab.current ? 'active' : ''}`
      );
      return (
        <button
          key={tab.id}
          className={className}
          onClick={() => selectTab(tab)}
        >
          {formatMessage(titles[tab.id])}
        </button>
      );
    });

  return (
    <div>
      {accordion && (
        <h3 className={`${STYLE_PREFIX}__subtitle`}>
          {formatMessage(titles.subTitle)}
        </h3>
      )}
      <div className={`${STYLE_PREFIX}__head`}>{getTabs()}</div>
      {accordion ? (
        <Accordion sizes={sizes} type={type} />
      ) : (
        <Table sizes={sizes} type={type} />
      )}
    </div>
  );
};

export default injectIntl(SizeTable);
