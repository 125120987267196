import { CALL_API } from '../constants/api';
import {
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_FAILURE,
  PASSWORD_CHANGE_CLOSE_MESSAGES,
} from '../constants/password';

/**
 * @param {object} authData authenticated data returned by api/customer.
 * @returns {{type, payload: {user: object}}}
 */
export function updatePasswordSuccess(authData) {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    isIdentified: true,
    token: authData.token,
    user: authData.user,
    message: authData.message,
  };
}

/**
 * @param {object} details user details
 * @returns {{type, payload: {code: string}}}
 */
export function updatePasswordRequest(details) {
  return {
    type: UPDATE_PASSWORD_REQUEST,
    payload: {
      details,
    },
  };
}

/**
 * @param {string} details
 * @returns {{type, payload: {message: string}}}
 */
export function updatePasswordFailure(details) {
  return {
    type: UPDATE_PASSWORD_FAILURE,
    message: details.message,
    payload: {
      details,
    },
  };
}

/**
 * @param {string} details - Customer details object
 * @returns {{
 * actions: {
 *  error: updatePasswordFailure,
 *  start: updatePasswordRequest,
 *  success: updatePasswordSuccess
 * },
 * method: string,
 * type,
 * url: string
 * }}
 */
export function apiUpdatePassword(details) {
  return {
    actions: {
      error: updatePasswordFailure,
      start: updatePasswordRequest,
      success: updatePasswordSuccess,
    },
    method: 'post',
    params: details,
    type: CALL_API,
    url: 'account/passwordChange',
  };
}

/**
 * Close error and confirmation messages
 * @returns {{type, payload: number}}
 */
export function closeMessages() {
  return {
    type: PASSWORD_CHANGE_CLOSE_MESSAGES,
  };
}
