/* global window */
// @flow
import React, { Component } from 'react';
import Measure from 'react-measure';
import Link from 'brastrap/containers/link/LinkContainer';
import LazyLoadImage from 'brastrap/containers/image/ImageContainer';
import type { Image as Props } from './image.types.js';

type State = {
  containerWidth: number,
};

type Dimensions = {
  width: number,
};

// This is desktop first like the data structure defined in Contentful.
class Image extends Component<Props, State> {
  state = {
    containerWidth: -1,
  };

  setContainerWidth = (dimensions: Dimensions) =>
    this.setState({ containerWidth: Math.round(dimensions.width) });

  applyWidthToSrc = (src: Url): Url => {
    const { containerWidth } = this.state;

    // Will be 1 for standard displays, or greater for HiDPI/Retina displays. Can sometimes have a decimal value
    const devicePixelRatio =
      (typeof window !== 'undefined' && window.devicePixelRatio) || 1;

    // The Contentful image api only allows the width parameter to be added to jpegs and pngs.
    // There are some cases in Contentful where the file extension of the image is in caps, so we make the check case
    // insensitive
    const canSupportWidthParameter = /\.(jpe?g|png)/i.test(src);

    return (
      (canSupportWidthParameter &&
        src.indexOf('w=') === -1 &&
        containerWidth > 0 &&
        `${src}${src.indexOf('?') === -1 ? '?' : '&'}w=${Math.round(
          containerWidth * devicePixelRatio
        )}`) ||
      src
    );
  };

  /**
   * @return {*}
   */
  render() {
    const { height, label, link, smallScreen, src, width, fetchPriority } = this.props;

    const img = (
      <Measure onMeasure={this.setContainerWidth}>
        <div>
          <LazyLoadImage
            fetchPriority={fetchPriority}
            mobile={
              smallScreen && smallScreen.src
                ? { ...smallScreen, src: this.applyWidthToSrc(smallScreen.src) }
                : null
            }
            src={this.applyWidthToSrc(src)}
            alt={label}
            {...{ height, width }}
          />
        </div>
      </Measure>
    );

    if (link && link.url) {
      return <Link href={link.url}>{img}</Link>;
    }

    return img;
  }
}

export default Image;
