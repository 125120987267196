// @flow
import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'shared/actions/modal';
import { updateGoogleConsent } from '../../../../shared/utils/google-ads-conversion';
import ConsentModal from '../../global/consent-modal/ConsentModal';
import { doesCookieExist } from '../../../../shared/utils/utils';

type Props = {
  containers: Array<Container>,
  modalId: string,
  collapsedBackgroundColour?: string,
  collapsedTextColour?: string,
  onClose: (
    modalId: string,
    redisplayTime: number,
    action: 'granted' | 'denied'
  ) => void,
  redisplayTime: number,
  consentModalText: string,
  modalHeader: string,
  consentModalAcceptButtonText: string,
  consentModalRejectButtonText: string,
};

const mapStateToProps = ({ modal: { content, queue } }, ownProps) => {
  const modalId = queue[0];
  const modalContent = content[modalId];

  return {
    ...ownProps,
    ...modalContent,
    modalId,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,

  onClose: (id, redisplayTime, consent) => {
    updateGoogleConsent(consent);
    dispatchProps.dispatch(closeModal(id, redisplayTime, consent));
  },
});

const WrappedConsentModal = (props: Props) => {
  const modalCookieName = `${props.modalId.toUpperCase()}_MODAL`;

  const isConsentCookiePresent = doesCookieExist(modalCookieName);
  if (!props.containers.length) {
    return null;
  }
  return (
    <ConsentModal {...props} isConsentCookiePresent={isConsentCookiePresent} />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(WrappedConsentModal);
