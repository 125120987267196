import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { CookiesProvider } from 'react-cookie';

const Root = props => {
  const { store } = props;
  const { selected, translations } = store.getState().app.locale;

  return (
    <Provider store={store}>
      <CookiesProvider>
        <IntlProvider locale={selected} messages={translations}>
          {props.children}
        </IntlProvider>
      </CookiesProvider>
    </Provider>
  );
};

Root.propTypes = {
  children: PropTypes.node.isRequired,
  store: PropTypes.object.isRequired,
};

export default Root;
