// @flow
import React, { type ComponentType } from 'react';

type Props = any;

interface WithPopulateStore {
  populateStore(): Promise<*>;
}

type ComponentWithPopulateStore = ComponentType<*> & WithPopulateStore;

type Loader = () => Promise<ComponentWithPopulateStore>;

type State = {
  Component: ?ComponentWithPopulateStore,
};

/**
 * Returns a new React component, ready to be instantiated.
 * Note the closure here protecting Component, and providing a unique
 * instance of Component to the static implementation of `load`.
 * @returns {React.Component}
 */
export default function generateAsyncRouteComponent({
  loader,
  Placeholder,
}: {
  loader: Loader,
  Placeholder: ?ComponentType<*>,
}): ComponentWithPopulateStore {
  let Component = null;

  return class AsyncRouteComponent extends React.Component<Props, State> {
    /**
     * Static so that you can call load against an uninstantiated version of
     * this component. This should only be called one time on the server.
     * @returns {Promise}
     */
    static load() {
      return loader().then(ResolvedComponent => {
        // $FlowFixMe - default property is not expected
        Component = ResolvedComponent.default || ResolvedComponent;
        // Hoist displayName property for debugging purposes
        this.displayName = Component.displayName;
      });
    }

    /**
     * Make the populateStore method of the Route Component accessible
     * to be called on the server
     * @returns {Promise}
     */
    static async populateStore(...args) {
      if (Component) {
        if (Component.populateStore) {
          return Component.populateStore(...args);
        } else if (
          Component.WrappedComponent &&
          Component.WrappedComponent.populateStore
        ) {
          return Component.WrappedComponent.populateStore(...args);
        }
      }
      return Promise.resolve(null);
    }

    state = { Component };

    /**
     * Load the Component on the client if it has not been loaded
     */
    componentDidMount() {
      // eslint-disable-line require-jsdoc
      if (!Component) {
        AsyncRouteComponent.load().then(this.updateState);
      }
    }

    updateState = () => {
      // Only update state if we don't already have a reference to the
      // component, this prevent unnecessary renders.
      if (this.state.Component !== Component) {
        this.setState({ Component });
      }
    };

    /**
     * Renders
     * @returns {*}
     */
    render() {
      const { Component: ComponentFromState } = this.state;
      if (ComponentFromState) {
        return <ComponentFromState {...this.props} />;
      }
      if (Placeholder) {
        return <Placeholder {...this.props} />;
      }
      return null;
    }
  };
}
