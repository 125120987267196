import {
  RECEIVE_EMAIL,
  RECEIVE_REASONS,
  RECEIVE_SOURCE,
  UNSUBSCRIBE,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_FAILURE,
} from 'shared/constants/unsubscribe';

export const initialState = {
  isSaving: false,
  confirmed: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} state
 */
export function unsubscribe(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_EMAIL:
      return {
        ...state,

        email: action.payload.email,
      };

    case RECEIVE_REASONS:
      return {
        ...state,

        reasons: action.payload.reasons,
      };

    case RECEIVE_SOURCE:
      return {
        ...state,

        sourceId: action.payload.sourceId,
      };

    case UNSUBSCRIBE:
      return {
        ...state,

        isSaving: true,
      };

    case UNSUBSCRIBE_SUCCESS:
      return {
        ...state,

        isSaving: false,
        confirmed: true,
      };

    case UNSUBSCRIBE_FAILURE:
      return {
        ...state,

        isSaving: false,
      };

    default:
      return state;
  }
}
