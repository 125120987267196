import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import noopReducer from './noop';
import * as app from './app';
import * as auth from './auth';
import * as personalDetailsForm from './personal-details-form';
import * as passwordChangeForm from './password-change-form';
import * as communicationPrefsForm from './communication-prefs-form';
import * as paymentCardDetailsForm from './payment-card-details-form';
import * as giftVoucherPaymentForm from './gift-voucher-payment-form';
import * as product from './product';
import * as bag from './bag';
import * as masthead from './masthead';
import * as footer from './footer';
import * as sidebar from './sidebar';
import * as messages from './messages';
import * as account from './account';
import * as checkout from './checkout';
import * as help from './help';
import * as editorial from './editorial';
import * as deliveryAddressesForm from './delivery-addresses-form';
import * as productListing from './product-listing';
import * as review from './review';
import * as productReviews from './product-reviews';
import * as shopReviews from './shop-reviews';
import * as shops from './shops';
import * as shopFinder from './shop-finder';
import * as countries from './countries';
import * as jobs from './jobs';
import * as requestMagazine from './request-magazine';
import * as emailSignup from './email-signup';
import * as unsubscribe from './unsubscribe';
import * as passwordResetRequest from './password-reset-request';
import * as resetPassword from './reset-password';
import * as mastectomyVatExemptionRequest from './mastectomy-vat-exemption-request';
import * as layer from './layer';
import * as contactRequest from './contact-request';
import timers from './timers';
import * as scroll from './scroll';
import * as promotions from './promotions';
import * as savedItems from './saved-items';
import schema from './schema';
import * as overlay from './overlay';
import createResponsiveReducer from './browser';
import * as gdpr from './gdpr';
import * as editorialForm from './editorial-form';
import * as stockFinder from './stock-finder';
import * as modal from './modal';
import * as sizeQuiz from './size-quiz';

export default combineReducers({
  ...app,
  ...auth,
  ...layer,
  ...personalDetailsForm,
  ...passwordChangeForm,
  ...communicationPrefsForm,
  ...paymentCardDetailsForm,
  ...giftVoucherPaymentForm,
  ...product,
  ...bag,
  ...masthead,
  ...footer,
  ...sidebar,
  ...messages,
  ...account,
  ...editorial,
  ...checkout,
  ...help,
  ...deliveryAddressesForm,
  ...productListing,
  ...review,
  ...productReviews,
  ...shopReviews,
  ...shops,
  ...shopFinder,
  ...jobs,
  ...requestMagazine,
  ...countries,
  ...emailSignup,
  ...unsubscribe,
  ...passwordResetRequest,
  ...resetPassword,
  ...mastectomyVatExemptionRequest,
  ...contactRequest,
  ...scroll,
  ...promotions,
  ...savedItems,
  ...overlay,
  ...gdpr,
  ...editorialForm,
  ...stockFinder,
  ...modal,
  ...sizeQuiz,

  map: noopReducer,
  browser: createResponsiveReducer(),
  form: formReducer,
  timers,
  schema,
});
