import { connect } from 'react-redux';
import Messages from 'brastrap/common/message-container/MessageContainer';
import { dismiss } from 'shared/actions/messages';

const mapStateToProps = state => ({
  messages: state.messages,
});

const mapDispatchToProps = dispatch => ({
  onClose: index => dispatch(dismiss(index)),
});

const mergeProps = ({ messages }, dispatch, { target }) => ({
  ...dispatch,
  // If there are specific messages to target, ensure they are filtered. If not display all messages.
  messages: target
    ? messages.filter(message => message.target === target)
    : messages,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Messages);
