import React from 'react';
import Container from 'brastrap/common/container/Container';
import Link from 'brastrap/containers/link/LinkContainer';
import EditorialRow from 'brastrap/editorial/editorial-row/EditorialRow';
import type {
  Row,
  Container as ContainerType,
} from 'brastrap/editorial/editorial-container/editorial-container.types';
import * as elementTypes from 'brastrap/editorial/editorial-container/editorial-container-element-types';
import { createDataRef } from '../../utils.js';

type Props = {
  content: Array<Container>,
};

const EditorialContent = (props: Props) => {
  if (!Array.isArray(props.content)) return null;

  const content = props.content
    /*
     * Check to see if the container has a type that can be rendered. If your content isn't rendering as expected,
     * check that the container type is present in editorial-container-element-types.js
     */
    .filter(
      (container: ContainerType) =>
        container.type === elementTypes[container.type] || 'Block'
    )
    .map((container: ContainerType, containerIndex: number) => {
      const containerProps = {
        ...container,
        id: container.id ? `c_${container.id}` : undefined,
        analyticsRef: createDataRef(container),
      };
      /*
       * The HeroPod component is full-screen, and is therefore not rendered as a child of the
       * Container component (which is subject to a max-width value)
       */
      switch (container.type) {
        case 'HeroPod':
        case 'HeroPodWithOverlay':
          return React.createElement(elementTypes[container.type], {
            ...container.rows[0].items[0],
            hideAtDesktop: container.rows[0].hideAtDesktop,
            hideAtTablet: container.rows[0].hideAtTablet,
            hideAtMobile: container.rows[0].hideAtMobile,
            key: container.id || containerIndex,
            analyticsRef: createDataRef(container.rows[0].items[0]),
          });
        default:
          return (
            <div key={container.id || containerIndex}>
              {container.slug && <Link name={container.slug} />}
              {container.type === 'SizesSelector' ||
              container.type === 'ShopBy' ? (
                React.createElement(elementTypes[container.type], container)
              ) : (
                <Container {...containerProps}>
                  {container.rows &&
                    container.rows.map((row: Row, index: Number) => (
                      <EditorialRow
                        {...{
                          ...row,
                          containerType: container.type,
                          key: row.id || index,
                        }}
                      />
                    ))}
                </Container>
              )}
            </div>
          );
      }
    });

  return <div>{content}</div>;
};

EditorialContent.defaultProps = {
  content: [],
};

export default EditorialContent;
