import PropTypes from 'prop-types';
import React from 'react';
import { applyModifiers } from '../../utils';

const CLASS = 's-prose';

const Prose = ({ children, modifiers }) => (
  <div className={applyModifiers(CLASS, modifiers)}>{children}</div>
);

Prose.propTypes = {
  children: PropTypes.node,
  modifiers: PropTypes.any,
};

export default Prose;
