import { connect } from 'react-redux';
import ShopBy from 'brastrap/promotional/shop-by-container/ShopByContainer';

const mapStateToProps = ({
  app: {
    config: { shopBySize },
  },
}) => ({
  shopBySizeUseBanner: !!+shopBySize.useBanner,
});

export default connect(mapStateToProps)(ShopBy);
