import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
  >
    <path d="M0 0h56v56H0V0zm72 0h56v57H72V0zm72 0h56v57h-56V0zM0 72h56v56H0V72zm72 0h56v57H72V72zm72 0h56v57h-56V72zM0 144h56v56H0v-56zm72 0h56v57H72v-57zm72 0h56v57h-56v-57z" />
  </svg>
);

export default SvgIcon;
