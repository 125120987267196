import { RECEIVE_CONTAINERS } from '../../constants/masthead';

const initialState = [];

/**
 * @param {Array} state
 * @param {Object} action
 * @returns {Array}
 */
export function containers(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_CONTAINERS:
      return action.payload;

    default:
      return state;
  }
}
