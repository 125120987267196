import {
  EDITORIAL_FORM_REQUEST,
  EDITORIAL_FORM_SUCCESS,
  EDITORIAL_FORM_FAILURE,
  VALIDATION,
  CLOSE_MESSAGES,
  SET_FORM_METADATA,
} from 'shared/constants/editorial-form';

export const initialState = {
  confirmed: null,
  error: null,
  isSending: null,
  message: null,
  isValid: false,
  metadata: {},
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} state
 */
export function editorialForm(state = initialState, action = {}) {
  switch (action.type) {
    case EDITORIAL_FORM_REQUEST:
      return {
        ...state,

        isSending: action.payload, // Contains the form key
        message: null,
      };

    case EDITORIAL_FORM_SUCCESS:
      return {
        ...state,

        isSending: null,
        error: null,
        confirmed: action.payload.formKey,
        message: action.payload.message,
      };

    case EDITORIAL_FORM_FAILURE:
      return {
        ...state,

        isSending: null,
        confirmed: null,
        error: action.payload.formKey,
        message: action.payload && action.payload.message,
      };

    case VALIDATION:
      return {
        ...state,

        isValid: action.payload,
      };

    case CLOSE_MESSAGES:
      return {
        ...initialState,
      };

    case SET_FORM_METADATA:
      return {
        ...state,

        metadata: action.payload,
      };

    default:
      return state;
  }
}
