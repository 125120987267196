import { CALL_API } from '../constants/api';
import {
  ADD_GIFT_VOUCHER_PAYMENT_REQUEST,
  ADD_GIFT_VOUCHER_PAYMENT_FAILURE,
  GET_GIFT_VOUCHER_PAYMENT_SUCCESS,
  GET_GIFT_VOUCHER_PAYMENT_REQUEST,
  ADD_GIFT_VOUCHER_PAYMENT_SUCCESS,
  GET_GIFT_VOUCHER_PAYMENT_FAILURE,
  ADD_GIFT_VOUCHER_PAYMENT_CLOSE_MESSAGES,
  ADD_GIFT_VOUCHER_PAYMENT_MODAL_CLOSED,
  ADD_GIFT_VOUCHER_PAYMENT_REFERENCE_COMPLETE,
} from '../constants/add-gift-voucher-payment';

import { closeLayer } from '../actions/layer';
import { receiveBag } from '../actions/bag';

/**
 * @param {object} giftVoucher
 * @returns {{type, message}}
 */
export function getGiftVoucherPaymentSuccess(giftVoucher) {
  return {
    type: GET_GIFT_VOUCHER_PAYMENT_SUCCESS,
    payload: {
      giftVoucher,
    },
  };
}

/**
 * @param {string} details - gift voucher ref
 * @returns {{type, payload: {code: string}}}
 */
export function getGiftVoucherPaymentRequest(details) {
  return {
    type: GET_GIFT_VOUCHER_PAYMENT_REQUEST,
    payload: {
      details,
    },
  };
}

/**
 * @param {object} details
 * @returns {{type, payload: {message: string}}}
 */
export function getGiftVoucherPaymentFailure(details) {
  return {
    type: GET_GIFT_VOUCHER_PAYMENT_FAILURE,
    message: details.message,
    payload: {
      details,
    },
  };
}

/**
 * @param {string} details - gift voucher ref
 * @returns {Object}
 */
export function apiGetGiftVoucherPayment(details) {
  return {
    actions: {
      error: getGiftVoucherPaymentFailure,
      start: getGiftVoucherPaymentRequest,
      success: getGiftVoucherPaymentSuccess,
    },
    method: 'get',
    params: details,
    type: CALL_API,
    url: 'order/getGiftVoucherPayment',
  };
}

// Thunk to close layer after successful add
const addGiftVoucherPaymentSuccessAndCloseLayer = bag => dispatch => {
  dispatch(addGiftVoucherPaymentSuccess());
  dispatch(receiveBag(bag));
  dispatch(closeLayer());
};

/**
 * @param {string} details - gift voucher ref
 * @returns {{type, payload: {code: string}}}
 */
export function addGiftVoucherPaymentRequest(details) {
  return {
    type: ADD_GIFT_VOUCHER_PAYMENT_REQUEST,
    payload: {
      details,
    },
  };
}

/**
 * @returns {{type, payload: {code: string}}}
 */
export function addGiftVoucherPaymentSuccess() {
  return {
    type: ADD_GIFT_VOUCHER_PAYMENT_SUCCESS,
  };
}

/**
 * @param {string} details
 * @returns {{type, payload: {message: string}}}
 */
export function addGiftVoucherPaymentFailure(details) {
  return {
    type: ADD_GIFT_VOUCHER_PAYMENT_FAILURE,
    message: details.message,
    payload: {
      details,
    },
  };
}

/**
 * @param {Object} giftVoucher
 * @returns {Object}
 */
export function apiAddGiftVoucherPayment(giftVoucher) {
  return {
    actions: {
      error: addGiftVoucherPaymentFailure,
      start: addGiftVoucherPaymentRequest,
      success: addGiftVoucherPaymentSuccessAndCloseLayer,
    },
    method: 'post',
    params: giftVoucher,
    type: CALL_API,
    url: 'bag/addGiftVoucherPayment',
  };
}

export const closeGiftVoucherPaymentModal = () => dispatch => {
  dispatch({
    type: ADD_GIFT_VOUCHER_PAYMENT_MODAL_CLOSED,
  });
  dispatch(closeLayer());
};

/**
 * @returns {{type, payload: number}}
 */
export function closeMessages() {
  return {
    type: ADD_GIFT_VOUCHER_PAYMENT_CLOSE_MESSAGES,
  };
}

/**
 * Validate gift reference
 * @param {string} giftVoucherReference
 * @returns {{type, payload: {giftVoucherReference: string}}}
 */
export function checkGiftVoucherPaymentReferenceComplete(giftVoucherReference) {
  return {
    type: ADD_GIFT_VOUCHER_PAYMENT_REFERENCE_COMPLETE,
    payload: {
      giftVoucherReference,
    },
  };
}
