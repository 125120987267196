// @flow

export type ReceiveGiftVoucherPage =
  'bravissimo/product/RECEIVE_GIFT_VOUCHER_PAGE';
export type AvailableSizesRequest =
  'bravissimo/product/AVAILABLE_SIZES_REQUEST';
export type AvailableSizesFailure =
  'bravissimo/product/AVAILABLE_SIZES_FAILURE';
export type ReceiveAvailableSizes =
  'bravissimo/product/RECEIVE_AVAILABLE_SIZES';
export type ReceiveColoursNotInStock =
  'bravissimo/product/RECEIVE_COLOURS_NOT_IN_STOCK';
export type ColoursNotInStockRequest =
  'bravissimo/product/COLOURS_NOT_IN_STOCK_REQUEST';
export type ColoursNotInStockFailure =
  'bravissimo/product/COLOURS_NOT_IN_STOCK_FAILURE';
export type AccessoryColourSelect =
  'bravissimo/product/ACCESSORY_COLOUR_SELECT';
export type AccessorySizeSelect = 'bravissimo/product/ACCESSORY_SIZE_SELECT';
export type ReceiveThumbnailWidth =
  'bravissimo/product/RECEIVE_THUMBNAIL_WIDTH';
export type SetSizeGridContainerWidth =
  'bravissimo/product/SET_SIZE_GRID_CONTAINER_WIDTH';
export type ReceiveImageDimensions =
  'bravissimo/product/RECEIVE_IMAGE_DIMENSIONS';
export type ProductGallerySetZoom = 'bravissimo/product/GALLERY_SET_ZOOM';
export type ProductCloseMessages = 'bravissimo/product/CLOSE_MESSAGES';
export type ProductGallerySetFeatured =
  'bravissimo/product/GALLERY_SET_FEATURED';
export type ProductSelectSku = 'bravissimo/product/SELECT_SKU';
export type ProductSelectTab = 'bravissimo/product/SELECT_TAB';
export type ProductSelectStyleColour = 'bravissimo/product/SELECT_STYLECOLOUR';
export type ProductQuantityChange = 'bravissimo/product/QUANTITY_CHANGE';
export type ProductFailure = 'bravissimo/product/FAILURE';
export type ProductSuccess = 'bravissimo/product/SUCCESS';
export type ProductRequest = 'bravissimo/product/REQUEST';
export type UpdateAccessorySku = 'bravissimo/product/UPDATE_ACCESSORY_SKU';

export const PRODUCT_REQUEST: ProductRequest = 'bravissimo/product/REQUEST';
export const PRODUCT_SUCCESS: ProductSuccess = 'bravissimo/product/SUCCESS';
export const PRODUCT_FAILURE: ProductFailure = 'bravissimo/product/FAILURE';
export const PRODUCT_QUANTITY_CHANGE: ProductQuantityChange =
  'bravissimo/product/QUANTITY_CHANGE';
export const PRODUCT_SELECT_TAB: ProductSelectTab =
  'bravissimo/product/SELECT_TAB';
export const PRODUCT_SELECT_STYLECOLOUR: ProductSelectStyleColour =
  'bravissimo/product/SELECT_STYLECOLOUR';
export const PRODUCT_SELECT_SKU: ProductSelectSku =
  'bravissimo/product/SELECT_SKU';
export const PRODUCT_GALLERY_SET_FEATURED: ProductGallerySetFeatured =
  'bravissimo/product/GALLERY_SET_FEATURED';
export const PRODUCT_CLOSE_MESSAGES: ProductCloseMessages =
  'bravissimo/product/CLOSE_MESSAGES';
export const PRODUCT_GALLERY_SET_ZOOM: ProductGallerySetZoom =
  'bravissimo/product/GALLERY_SET_ZOOM';
export const RECEIVE_IMAGE_DIMENSIONS: ReceiveImageDimensions =
  'bravissimo/product/RECEIVE_IMAGE_DIMENSIONS';
export const SET_SIZE_GRID_CONTAINER_WIDTH: SetSizeGridContainerWidth =
  'bravissimo/product/SET_SIZE_GRID_CONTAINER_WIDTH';
export const RECEIVE_THUMBNAIL_WIDTH: ReceiveThumbnailWidth =
  'bravissimo/product/RECEIVE_THUMBNAIL_WIDTH';

// Accessories
export const ACCESSORY_SIZE_SELECT: AccessorySizeSelect =
  'bravissimo/product/ACCESSORY_SIZE_SELECT';
export const ACCESSORY_COLOUR_SELECT: AccessoryColourSelect =
  'bravissimo/product/ACCESSORY_COLOUR_SELECT';
export const UPDATE_ACCESSORY_SKU: UpdateAccessorySku =
  'bravissimo/product/UPDATE_ACCESSORY_SKU';

// Reviews
export const RECEIVE_AVAILABLE_SIZES: ReceiveAvailableSizes =
  'bravissimo/product/RECEIVE_AVAILABLE_SIZES';
export const AVAILABLE_SIZES_FAILURE: AvailableSizesFailure =
  'bravissimo/product/AVAILABLE_SIZES_FAILURE';
export const AVAILABLE_SIZES_REQUEST: AvailableSizesRequest =
  'bravissimo/product/AVAILABLE_SIZES_REQUEST';

// Vouchers
export const RECEIVE_GIFT_VOUCHER_PAGE: ReceiveGiftVoucherPage =
  'bravissimo/product/RECEIVE_GIFT_VOUCHER_PAGE';

export const RECIEVE_COLOURS_NOT_IN_STOCK: ReceiveColoursNotInStock =
  'bravissimo/product/RECEIVE_COLOURS_NOT_IN_STOCK';
export const COLOURS_NOT_IN_STOCK_REQUEST: ColoursNotInStockRequest =
  'bravissimo/product/COLOURS_NOT_IN_STOCK_REQUEST';
export const COLOURS_NOT_IN_STOCK_FAILURE: ColoursNotInStockFailure =
  'bravissimo/product/COLOURS_NOT_IN_STOCK_FAILURE';
