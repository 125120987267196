import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
    aria-labelledby="title"
  >
    <title>Facebook</title>
    <path d="M10 0h180c5 0 10 5 10 10v180c0 5-5 10-10 10H10c-5 0-10-5-10-10V10C0 5 5 0 10 0zm128 200v-77h26l4-31h-30V73c0-9 2-15 15-15h16V31s-12-1-23-1c-23 0-40 15-40 40v22H80v31h26v77h32z" />
  </svg>
);

export default SvgIcon;
