import {
  multiCategoryMap,
  sizeSelectorFacets,
  sizeFacetData,
} from './sizeSelectorData';
import updateFacet from './product-listing/facet';

/**
 * @param {{ braFacets: Array, clothingFacets: Array }} facets
 * @param {Array} selected
 *
 * @return {Object}
 */
export const markSelected = ({ braFacets, clothingFacets }, selected = []) => {
  const braFacetsCopy = [...braFacets];
  const clothingFacetsCopy = [...clothingFacets];
  Object.keys(selected).forEach(sizeType => {
    selected[sizeType].forEach(size => {
      const facets =
        sizeType === 'backsize' || sizeType === 'cupsize'
          ? braFacetsCopy
          : clothingFacetsCopy;
      facets.forEach(facet => {
        if (facet.id === sizeType) {
          // eslint-disable-next-line no-param-reassign
          facet.values = facet.values.map(value =>
            value.id === size ? { ...value, selected: true } : value
          );
        }
      });
    });
  });
  return {
    braFacets: braFacetsCopy,
    clothingFacets: clothingFacetsCopy,
  };
};

/**
 * @param {Object} facet
 *
 * @return {boolean}
 */
export const isSizeSelector = facet => {
  let keep = true;
  Object.keys(facet).forEach(key => {
    const size = facet[key];
    if (
      (multiCategoryMap[key] && !multiCategoryMap[key][size]) ||
      sizeSelectorFacets.indexOf(key) === -1
    ) {
      keep = false;
    }
  });
  return keep;
};

/**
 * Map selected sizes to multi-category sizes
 *
 * @param {Object} facets
 *
 * @return {Object} mapped facets
 */
export const includeMultiCategoryFacets = facets => {
  const facetsCopy = { ...facets };
  Object.keys(facetsCopy).forEach(f => {
    if (multiCategoryMap[f]) {
      facetsCopy[f].forEach(size => {
        const combinedSizes = multiCategoryMap[f][size];
        if (combinedSizes) {
          const existingSizes = new Set(facetsCopy[f]);
          combinedSizes.forEach(combSize => existingSizes.add(combSize));
          facetsCopy[f] = [...existingSizes];
        }
      });
    }
  });
  return facetsCopy;
};

/**
 * Turns cup-back size combination into a size facet
 * @param {{ backsize: Array, cupsize: Array }} facets
 * @returns {array}
 */
const mapBackSizeAndCupSize = ({ backsize = [], cupsize = [] }) => {
  const mappedSizes = new Set();
  backsize.forEach(back => {
    cupsize.forEach(cup => {
      const currentCombination =
        multiCategoryMap.combined[back + cup.toLowerCase()];
      if (currentCombination) {
        currentCombination.forEach(combination => mappedSizes.add(combination));
      }
    });
  });
  return [...mappedSizes];
};

/**
 * Turns cup / back size or dress / curvy size into a size facet
 * @param {{ backsize: Array, cupsize: Array, dresssize: Array, curvysize: Array }} facets
 * @return {Object}
 */
export const mapToSizeFacet = ({ backsize, cupsize, dresssize, curvysize }) => {
  const sizeFacets = {
    size: [],
  };
  const { braFacets, clothingFacets } = sizeFacetData;
  const allSizeFacets = [...braFacets, ...clothingFacets];
  const sizesMap = sizeSelectorFacets.reduce((sizesObj, selector) => {
    sizesObj[selector] = allSizeFacets // eslint-disable-line no-param-reassign
      .find(f => f.id === selector)
      .values.reduce(
        (valueArray, value) =>
          valueArray.concat([
            value.id,
            ...((multiCategoryMap[selector] &&
              multiCategoryMap[selector][value.id]) ||
              []),
          ]),
        []
      );
    return sizesObj;
  }, {});
  if (backsize || cupsize) {
    const bSizes = backsize || sizesMap.backsize;
    const cSizes = cupsize || sizesMap.cupsize;
    bSizes.forEach(bSize => {
      cSizes.forEach(cSize => {
        sizeFacets.size.push(bSize + cSize);
      });
    });
  }
  if (dresssize || curvysize) {
    const dSizes = dresssize || sizesMap.dresssize;
    const cvSizes = curvysize || sizesMap.curvysize;
    dSizes.forEach(dSize => {
      sizeFacets.size.push(dSize);
      cvSizes.forEach(cvSize => {
        if (sizeFacets.size.indexOf(dSize + cvSize) === -1) {
          sizeFacets.size.push(dSize + cvSize);
        }
      });
    });
    // Include accessories with clothing
    sizeFacets.size.push('one');
  }
  return sizeFacets;
};

/**
 * Group size facets into a single object
 * @param  {Array} sizeFacets
 * @return {Object}
 */
export const getGroupedSizeFacets = sizeFacets =>
  sizeFacets.reduce((facetObj, facet) => {
    const key = Object.keys(facet)[0];
    const sizes = new Set(facetObj[key]);
    sizes.add(facet[key]);
    facetObj[key] = [...sizes]; // eslint-disable-line no-param-reassign
    return facetObj;
  }, {});

/**
 * Get query params from a set of facets without updating selected facets (for sale size selector)
 * Sending in a true flag for includeMultiCategory will add in sizes that span categories (ie. d-e)
 *
 * @param {Object} facets
 * @return {Object} query params
 */
export const generateQueryFromFacets = facets =>
  Object.keys(facets).reduce((selectedFacets, f) => {
    if (facets[f].length) {
      selectedFacets[`f_${f}`] = facets[f]; // eslint-disable-line no-param-reassign
    }
    return selectedFacets;
  }, {});

/**
 * @param {Object} facets
 * @param {boolean} isSelected
 * @param {string} [valueId]
 * @param {string} [facetId]
 * @return {*}
 */
export const updateFacetAndBuildQuery = (
  facets = [],
  isSelected,
  valueId,
  facetId
) => {
  const updatedFacets = updateFacet(facets, isSelected, valueId, facetId);
  return generateQueryFromFacets(updatedFacets);
};

/**
 * @param {Object} queryParams
 * @param {Object} facetData
 * @param {Object} [newFacet]
 * @param {Object} [additionalParams]
 * @return {{}}
 */
export const buildQuery = (
  queryParams,
  facetData = {},
  newFacet,
  additionalParams = {}
) => {
  const {
    facets = [],
    sizeFacets = null,
    joinSizeFacets = true,
    includeMultipleSizes = true,
  } = facetData;
  let facetQueryParams = newFacet
    ? updateFacetAndBuildQuery(
        facets,
        newFacet.isSelected,
        newFacet.valueId,
        newFacet.facetId
      )
    : updateFacetAndBuildQuery(facets, false);
  let combinedSizeFacets = [];

  if (sizeFacets) {
    let sizeFacetQueryParams = { ...sizeFacets };
    if (includeMultipleSizes) {
      sizeFacetQueryParams = includeMultiCategoryFacets(sizeFacetQueryParams);
      combinedSizeFacets = mapBackSizeAndCupSize(sizeFacets);
    }
    if (joinSizeFacets) {
      sizeFacetQueryParams = mapToSizeFacet(sizeFacetQueryParams);
      sizeFacetQueryParams.size = sizeFacetQueryParams.size.concat(
        combinedSizeFacets
      );
    }
    facetQueryParams = {
      ...facetQueryParams,
      ...generateQueryFromFacets(sizeFacetQueryParams),
    };
  }

  return {
    ...queryParams,
    ...facetQueryParams,
    ...additionalParams,
  };
};

/**
 * Strips all the crap from a query param 'f_' and '[]' and returns the facet key.
 * Example:
 *  'f_brand[]' => 'brand'
 *
 * @param {String} key
 * @return {String}
 */
export const paramToKey = key => key.substring(2).replace('[]', '');

/**
 * Get all the facets from query params. All facet related entries are prefixed with 'f_'
 *
 * @param {Object} query
 * @return {Array}
 */
export const getFacetsFromQuery = query => {
  const facetParams = Object.keys(query).filter(
    key => key.substring(0, 2) === 'f_'
  );
  const facets = [];

  facetParams.forEach(param => {
    if (Array.isArray(query[param])) {
      query[param].forEach(filter =>
        facets.push({ [paramToKey(param)]: filter })
      );
    } else {
      facets.push({ [paramToKey(param)]: query[param] });
    }
  });
  return facets;
};
