import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 200 200"
    role="img"
  >
    <path d="M95 50c3-4 8-4 12 0l90 90c3 3 3 8 0 11-3 2-8 3-11 0L100 66 16 151c-3 3-9 2-11 0-3-3-3-8 0-11l90-90z" />
  </svg>
);

export default SvgIcon;
