import PropTypes from 'prop-types';
import React from 'react';

const FacetInput = props => {
  const {
    className,
    elementType,
    id,
    name,
    count,
    onChange,
    checked,
    selected,
    swatch,
    value,
  } = props;

  const inputProps = {
    id,
    onChange,
    value,
    checked: !!(checked || selected),
  };

  inputProps['aria-checked'] = inputProps.checked;

  const containerProps = {};
  if (className) {
    containerProps.className = className;
  }

  const input = (
    <input className="c-results-facet__check" type="checkbox" {...inputProps} />
  );
  const label = (
    <label className="c-results-facet__label" htmlFor={id}>
      {swatch && <span className="c-swatch" style={{ background: swatch }} />}
      {`${name}${!isNaN(count) ? ` (${count})` : ''}`}
    </label>
  );

  return elementType === 'div' ? (
    <div {...containerProps}>
      {input}
      {label}
    </div>
  ) : (
    <li {...containerProps}>
      {input}
      {label}
    </li>
  );
};

FacetInput.propTypes = {
  className: PropTypes.string,
  elementType: PropTypes.oneOf(['li', 'div']).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  count: PropTypes.number,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  selected: PropTypes.bool,
  swatch: PropTypes.string,
  value: PropTypes.string.isRequired,
};

FacetInput.defaultProps = {
  elementType: 'li',
  onChange: () => {},
};

export default FacetInput;
