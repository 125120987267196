import { START_TIMER, CANCEL_TIMER } from '../constants/timers';

/**
 * Creates an action that arranges for a callback to be executed after a delay
 * @param {string} key a name to identify the callback, should it be cancelled
 * @param {number} delay the execution delay, in milliseconds
 * @param {function} callback the callback to execute after the delay
 * @return {{type: *, payload: {key: *, delay: *, callback: *}}}
 */
export function startTimer(key, delay, callback) {
  return {
    type: START_TIMER,
    payload: {
      key,
      delay,
      callback,
    },
  };
}

/**
 * Cancels a deferred action that was installed with startTimer().
 * @param {string} key the name of the callback.
 * @return {{type, payload: {key: *}}}
 */
export function cancelTimer(key) {
  return {
    type: CANCEL_TIMER,
    payload: {
      key,
    },
  };
}
