// @flow
const namespace = 'bravissimo/editorial-form';

export const EDITORIAL_FORM_REQUEST = `${namespace}/EDITORIAL_FORM_REQUEST`;
export const EDITORIAL_FORM_SUCCESS = `${namespace}/EDITORIAL_FORM_SUCCESS`;
export const EDITORIAL_FORM_FAILURE = `${namespace}/EDITORIAL_FORM_FAILURE`;
export const VALIDATION = `${namespace}/VALIDATION`;
export const CLOSE_MESSAGES = `${namespace}/CLOSE_MESSAGES`;
export const SET_FORM_METADATA = `${namespace}/SET_FORM_METADATA`;
export const SEND_TO_ADESTRA = `${namespace}/SEND_TO_ADESTRA`;
