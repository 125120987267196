import {
  RECEIVE_DELIVERY_ADDRESSES,
  SAVE_ADDRESS_SUCCESS,
} from '../../constants/account';

import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../../constants/auth';

export const initialState = [];

/**
 * @param {Array} state
 * @param {Object} action
 * @returns {Array}
 */
export function addresses(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_DELIVERY_ADDRESSES:
      return [...action.payload];

    case SAVE_ADDRESS_SUCCESS: {
      const addressesState = [...state];
      const address = { ...action.payload };
      const existingAddress = addressesState.find(
        addy => addy.id === address.id
      );

      if (existingAddress) {
        const index = addressesState.indexOf(existingAddress);
        addressesState[index] = address;
      } else {
        addressesState.push(address);
      }

      return addressesState;
    }

    // Add addresses when we login or update our details
    case LOGIN_SUCCESS:
      return action.payload.addresses && action.payload.addresses.length
        ? [...action.payload.addresses]
        : state;

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
