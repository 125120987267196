export const backSizesData = [
  { size: '28', selected: false },
  { size: '30', selected: false },
  { size: '32', selected: false },
  { size: '34', selected: false },
  { size: '36', selected: false },
  { size: '38', selected: false },
  { size: '40', selected: false },
  { size: '42', selected: false },
  { size: '44', selected: false },
  { size: '46', selected: false },
  { size: '48', selected: false },
];

export const usCupSizesData = [
  { size: 'AA', selected: false },
  { size: 'A', selected: false },
  { size: 'B', selected: false },
  { size: 'C', selected: false },
  { size: 'D', selected: false },
  { size: 'DD', selected: false },
  { size: 'DDD', selected: false },
  { size: 'DDDD', selected: false },
  { size: 'H', selected: false },
  { size: 'I', selected: false },
  { size: 'J', selected: false },
  { size: 'K', selected: false },
  { size: 'L', selected: false },
  { size: 'M', selected: false },
  { size: 'N', selected: false },
  { size: 'O', selected: false },
  { size: 'P', selected: false },
  { size: 'Q', selected: false },
];

export const ukCupSizesData = [
  { size: 'AA', selected: false },
  { size: 'A', selected: false },
  { size: 'B', selected: false },
  { size: 'C', selected: false },
  { size: 'D', selected: false },
  { size: 'DD', selected: false },
  { size: 'E', selected: false },
  { size: 'F', selected: false },
  { size: 'FF', selected: false },
  { size: 'G', selected: false },
  { size: 'GG', selected: false },
  { size: 'H', selected: false },
  { size: 'HH', selected: false },
  { size: 'J', selected: false },
  { size: 'JJ', selected: false },
  { size: 'K', selected: false },
  { size: 'KK', selected: false },
  { size: 'L', selected: false },
];
