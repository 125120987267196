import { OPEN_POPOVER_BAG, CLOSE_POPOVER_BAG } from '../../constants/masthead';

export const initialState = {
  isVisible: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function popoverBag(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_POPOVER_BAG: {
      return {
        isVisible: true,
      };
    }

    case CLOSE_POPOVER_BAG: {
      return {
        isVisible: false,
      };
    }

    default:
      return state;
  }
}
