import { HELP_REQUEST, HELP_SUCCESS, HELP_FAILURE } from '../constants/help';

import { CALL_API } from '../constants/api';

/**
 * @param {object} faqData
 * @returns {{type, payload: object}}
 */
export function setHelp(faqData) {
  return {
    type: HELP_SUCCESS,
    payload: faqData,
  };
}

/**
 * @returns {{type}}
 */
export function helpRequest() {
  return {
    type: HELP_REQUEST,
  };
}

/**
 * @param {object} faqData
 * @returns {{type, payload}|{type, payload: Object}}
 */
export function helpSuccess(faqData) {
  return setHelp(faqData);
}

/**
 * @returns {{type}}
 */
export function helpFailure() {
  return {
    type: HELP_FAILURE,
  };
}

/**
 * @returns {{
 * actions: {
 *  error: helpFailure,
 *  start: helpRequest,
 *  success: helpSuccess
 * },
 * type,
 * url: string
 * }}
 */
export function apiGetHelp() {
  return {
    actions: {
      error: helpFailure,
      start: helpRequest,
      success: helpSuccess,
    },
    type: CALL_API,
    url: 'help',
  };
}

/**
 * @param {string} topic
 * @returns {{
 * actions: {
 * error: helpFailure,
 * start: helpRequest,
 * success: helpSuccess
 * },
 * type,
 * url: string
 * }}
 */
export function apiGetHelpByTopic(topic) {
  return {
    actions: {
      error: helpFailure,
      start: helpRequest,
      success: helpSuccess,
    },
    type: CALL_API,
    url: `help/${topic}`,
  };
}

/**
 * @param {string} topic
 * @param {string} question
 * @returns {{
 * actions: {
 * error: helpFailure,
 * start: helpRequest,
 * success: helpSuccess
 * },
 * type,
 * url: string
 * }}
 */
export function apiGetHelpByFaq(topic, question) {
  return {
    actions: {
      error: helpFailure,
      start: helpRequest,
      success: helpSuccess,
    },
    type: CALL_API,
    url: `help/${topic}/${question}`,
  };
}
