// @flow

type ReceiveSavedItems = 'bravissimo/saved-items/RECEIVE_SAVED_ITEMS_MAP';
type ReceiveDecoratedSavedItems =
  'bravissimo/saved-items/RECEIVE_DECORATED_SAVED_ITEMS';
type GetSavedItemsFailure = 'bravissimo/saved-items/GET_SAVED_ITEMS_FAILURE';
type GetSavedItemsRequest = 'bravissimo/saved-items/GET_SAVED_ITEMS_REQUEST';
type AddSavedItemFailure = 'bravissimo/saved-items/ADD_SAVED_ITEM_FAILURE';
type AddSavedItemRequest = 'bravissimo/saved-items/ADD_SAVED_ITEM_REQUEST';
type DeleteSavedItemFailure =
  'bravissimo/saved-items/DELETE_SAVED_ITEM_FAILURE';
type DeleteSavedItemRequest =
  'bravissimo/saved-items/DELETE_SAVED_ITEM_REQUEST';
type SavePendingItem = 'bravissimo/saved-items/SAVE_PENDING_ITEM';
type ReceiveSizes = 'bravissimo/saved-items/RECEIVE_SIZES';
type GetSizesFailure = 'bravissimo/saved-items/GET_SIZES_FAILURE';
type GetSizesRequest = 'bravissimo/saved-items/GET_SIZES_REQUEST';

export const RECEIVE_SAVED_ITEMS_MAP: ReceiveSavedItems =
  'bravissimo/saved-items/RECEIVE_SAVED_ITEMS_MAP';
export const RECEIVE_DECORATED_SAVED_ITEMS: ReceiveDecoratedSavedItems =
  'bravissimo/saved-items/RECEIVE_DECORATED_SAVED_ITEMS';
export const GET_SAVED_ITEMS_FAILURE: GetSavedItemsFailure =
  'bravissimo/saved-items/GET_SAVED_ITEMS_FAILURE';
export const GET_SAVED_ITEMS_REQUEST: GetSavedItemsRequest =
  'bravissimo/saved-items/GET_SAVED_ITEMS_REQUEST';
export const ADD_SAVED_ITEM_FAILURE: AddSavedItemFailure =
  'bravissimo/saved-items/ADD_SAVED_ITEM_FAILURE';
export const ADD_SAVED_ITEM_REQUEST: AddSavedItemRequest =
  'bravissimo/saved-items/ADD_SAVED_ITEM_REQUEST';
export const DELETE_SAVED_ITEM_FAILURE: DeleteSavedItemFailure =
  'bravissimo/saved-items/DELETE_SAVED_ITEM_FAILURE';
export const DELETE_SAVED_ITEM_REQUEST: DeleteSavedItemRequest =
  'bravissimo/saved-items/DELETE_SAVED_ITEM_REQUEST';
export const SAVE_PENDING_ITEM: SavePendingItem =
  'bravissimo/saved-items/SAVE_PENDING_ITEM';
export const RECEIVE_SIZES: ReceiveSizes =
  'bravissimo/saved-items/RECEIVE_SIZES';
export const GET_SIZES_FAILURE: GetSizesFailure =
  'bravissimo/saved-items/GET_SIZES_FAILURE';
export const GET_SIZES_REQUEST: GetSizesRequest =
  'bravissimo/saved-items/GET_SIZES_REQUEST';
