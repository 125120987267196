// @flow
import React from 'react';
import Accordion from 'brastrap/common/accordion/Accordion';
import Link from 'brastrap/containers/link/LinkContainer';
import { type Sizes } from './ShopBy';

type Props = {
  sizes: Sizes,
  type: string,
};

const SizeAccordion = ({ sizes: { variants }, type }: Props) => (
  <div className="c-shop-by-accordion">
    <Accordion>
      {variants.map(variant => (
        <Accordion.Item
          key={variant.value}
          id={variant.value}
          label={variant.value}
        >
          {variant.sizes.variants.map(size => (
            <div key={size.label} className="c-shop-by-accordion__item">
              <Link
                className="c-shop-by-accordion__link"
                href={`${size.href}-${type}/`}
                title={size.title}
              >
                {size.label}
              </Link>
            </div>
          ))}
        </Accordion.Item>
      ))}
    </Accordion>
  </div>
);

export default SizeAccordion;
