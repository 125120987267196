import generateAsyncRouteComponent from '../generateAsyncRouteComponent';

export default [
  {
    path: 'products/:slug/write-review',
    component: generateAsyncRouteComponent({
      loader: () => import('./components/Review/containers/FormAuthenticated'),
    }),
  },
  {
    path: 'products/:slug',
    component: generateAsyncRouteComponent({
      loader: () => import('./containers/ProductPageContainer'),
    }),
  },
];
