// @flow
import React from 'react';
import EditorialBlock from 'brastrap/editorial/editorial-block/EditorialBlock';
import RatioBox from 'brastrap/helpers/ratio-box/ratio-box';
import { type Overlay as Props, type OverlayItem } from './overlay.types';
import { applyModifiers } from '../../utils';

type ConnectedProps = {
  ...Props,
  // These properties come from state (ie placeholder).
  onDesktop: boolean,
  hideContent: boolean,
};

type Dimensions = {
  height: number,
  width: number,
};

const POD = 'pod';
const HERO_POD = 'heroPod';
const EDITORIAL_ASSET = 'editorialAsset';
const STYLE_PREFIX = 'c-overlay';

/**
 * Calculate the aspect ratio given the dimensions.
 *
 * @param {Object} dimensions
 * @returns {number}
 */
const getAspectRatioFromDimensions = ({ height, width }: Dimensions): number =>
  width / height;

/**
 * Get the aspect ratio of a Pod or Editorial Asset.
 *
 * @param {Object} overlayItem
 * @param {boolean} onDesktop
 * @returns {number}
 */
const getAspectRatio = (
  overlayItem: OverlayItem,
  onDesktop: boolean
): number => {
  switch (
    overlayItem._type // eslint-disable-line no-underscore-dangle
  ) {
    case EDITORIAL_ASSET: {
      return getAspectRatioFromDimensions(overlayItem);
    }

    case POD: {
      const image = onDesktop
        ? overlayItem.desktopImage
        : overlayItem.mobileImage;
      return getAspectRatioFromDimensions(image);
    }

    case HERO_POD: {
      const image = onDesktop
        ? overlayItem.desktopBackgroundImage
        : overlayItem.mobileBackgroundImage ||
          overlayItem.desktopBackgroundImage;
      return getAspectRatioFromDimensions(image);
    }

    default:
      return 1;
  }
};

/**
 * @param {string} className
 * @returns {string}
 */
const element = (className: string): string => `${STYLE_PREFIX}__${className}`;

/**
 * @param {Object} props
 * @returns {*}
 * @constructor
 */
const Overlay = (props: ConnectedProps) => {
  if (props.hideContent) return null;
  const aspectRatio = getAspectRatio(props.item, props.onDesktop);
  const modifiers = applyModifiers(
    STYLE_PREFIX,
    props.showOnHover ? 'show-on-hover' : null
  );
  return (
    <div className={modifiers}>
      <div className={element('item')}>
        <EditorialBlock content={props.item} />
      </div>
      <div className={element('content')}>
        <RatioBox aspectRatio={aspectRatio}>
          <div
            className={element('content__svg-container')}
            dangerouslySetInnerHTML={{ __html: props.overlay }} // eslint-disable-line react/no-danger
          />
        </RatioBox>
      </div>
    </div>
  );
};

export default Overlay;
