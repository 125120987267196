import { CALL_API } from '../constants/api';
import {
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  MODAL_CLOSED,
  ADDRESS_ADDED,
  EDIT_STARTED,
  DELETE_STARTED,
} from '../constants/delivery-addresses';

/**
 * @param {object} authData authenticated data returned by api/customer.
 * @returns {{type, payload: {user: object}}}
 */
export function updateDeliveryAddressesSuccess(authData) {
  return {
    type: UPDATE_SUCCESS,
    message: authData.message,
    payload: {
      user: authData.user,
    },
  };
}

/**
 * @param {object} details user details
 * @returns {{type, payload: {code: string}}}
 */
export function updateDeliveryAddressesRequest(details) {
  return {
    type: UPDATE_REQUEST,
    payload: {
      details,
    },
  };
}

/**
 * @param {string} details
 * @returns {{type, payload: {message: string}}}
 */
export function updateDeliveryAddressesFailure(details) {
  return {
    type: UPDATE_FAILURE,
    message: details.message,
    payload: {
      details,
    },
  };
}

/**
 * @param {string} details - Customer details object
 * @returns {{
 * actions: {
 *  error: updateDeliveryAddressesFailure,
 *  start: updateDeliveryAddressesRequest,
 *  success: updateDeliveryAddressesSuccess
 * },
 * method: string,
 * type,
 * url: string
 * }}
 */
export function apiUpdateDeliveryAddresses(details) {
  return {
    actions: {
      error: updateDeliveryAddressesFailure,
      start: updateDeliveryAddressesRequest,
      success: updateDeliveryAddressesSuccess,
    },
    method: 'post',
    params: details,
    type: CALL_API,
    url: 'account/personalDetails',
  };
}

/**
 * Close error and confirmation messages
 * @returns {{type}}
 */
export function modalClosed() {
  return {
    type: MODAL_CLOSED,
  };
}

/**
 * Add new address
 * @returns {{
 * type,
 * payload: {
 * isNewAddress: boolean
 * }
 * }}
 */
export function addressAdded() {
  return {
    type: ADDRESS_ADDED,
    payload: {
      isNewAddress: true,
    },
  };
}

/**
 * Opens the edit address modal for the specified address index
 * @param {number} index - the array index of the entry in the form to be edited
 * @returns {{type}}
 */
export function editStarted(index) {
  return {
    type: EDIT_STARTED,
    index,
  };
}

/**
 * Opens the delete address modal for the specified address index
 * @param {number} index - the array index of the entry in the form to be deleted
 * @returns {{type}}
 */
export function deleteStarted(index) {
  return {
    type: DELETE_STARTED,
    index,
  };
}
