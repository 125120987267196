import { combineReducers } from 'redux';
import * as navigation from './masthead/navigation';
import * as header from './masthead/header';
import * as megaMenu from './masthead/mega-menu';
import * as drawer from './masthead/drawer';
import * as popoverBag from './masthead/popover-bag';
import * as containers from './masthead/containers';

export const masthead = combineReducers({
  ...containers,
  ...navigation,
  ...header,
  ...megaMenu,
  ...drawer,
  ...popoverBag,
});

export default {
  masthead,
};
