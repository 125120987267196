// @flow
import React from 'react';
import Link from 'brastrap/containers/link/LinkContainer';
import { applyModifiers } from '../../utils';

export type Props = {
  analyticsRef?: string,
  id?: string,
  isDummyButton: boolean,
  label: string,
  linkType: 'href' | 'to',
  link?: {
    id?: string,
    label?: string,
    url?: Url,
  },
  modifiers?: Modifiers,
  onClick?: (SyntheticEvent<HTMLAnchorElement>) => void,
  rel?: string,
  target?: string,
  url?: Url,
};

const STYLE_PREFIX = 'c-button-link';

const ButtonLink = ({
  modifiers,
  url,
  onClick,
  label,
  link,
  linkType,
  isDummyButton,
  analyticsRef,
  rel,
  target,
}: Props) => {
  const className = applyModifiers(STYLE_PREFIX, modifiers);
  const linkProps = {
    ...link,
    [linkType]: url,
    className,
    isDummyButton,
    onClick,
    analyticsRef,
    rel,
    target,
  };
  return <Link {...linkProps}>{label}</Link>;
};

ButtonLink.defaultProps = {
  label: 'Label',
  link: {},
  url: '',
  isDummyButton: false,
  linkType: 'href',
};

export default ButtonLink;
