import { connect } from 'react-redux';
import Banner from 'brastrap/editorial/banner/Banner';

const mapStateToProps = ({ browser }) => ({
  isDesktop: browser.deviceIs.desktop,
  isTablet: browser.deviceIs.tablet,
  isMobile: browser.deviceIs.mobile,
});

export default connect(mapStateToProps)(Banner);
