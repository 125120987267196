// @flow
/* eslint-env browser */
import React, { type ChildrenArray } from 'react';
import { Overlay } from 'react-overlays';
import { applyModifiers } from '../../utils';

const extraStyle = {
  position: 'absolute',
  zIndex: 10000,
};

const classToPlacement = {
  above: 'top',
  after: 'right',
  below: 'bottom',
  before: 'left',
};

const filterPlacements = placements =>
  placements.filter(p => classToPlacement[p]);
const mapPlacements = placements =>
  filterPlacements(placements).map(p => classToPlacement[p]);

const STYLE_PREFIX = 'c-popover';

export type PopoverPlacements = 'above' | 'after' | 'below' | 'before';

type Props = {
  children: ChildrenArray<*>,
  onRootClick: Function,
  placement: PopoverPlacements | Array<PopoverPlacements>,
  show: boolean,
  style: ?Object,
  target: ?Object,
  skipOverlay: ?boolean,
  className: string,
};

const Popover = (props: Props) => {
  const popoverStyle = Object.assign({}, extraStyle, props.style);

  const placements =
    typeof props.placement === 'string' ? [props.placement] : props.placement;

  if (props.skipOverlay) {
    return (
      <div
        style={popoverStyle}
        className={applyModifiers(STYLE_PREFIX, filterPlacements(placements))}
      >
        {props.children}
      </div>
    );
  }

  const container =
    typeof document === 'undefined' ? null : document.getElementById('app');
  return (
    <Overlay
      container={container}
      onHide={props.onRootClick}
      placement={mapPlacements(placements)[0] || 'bottom'}
      rootClose
      show={props.show}
      target={props.target}
    >
      <div
        style={popoverStyle}
        className={applyModifiers(STYLE_PREFIX, [
          ...filterPlacements(placements),
          props.className,
        ])}
      >
        {props.children}
      </div>
    </Overlay>
  );
};

Popover.defaultProps = {
  style: {},
  skipOverlay: false,
  className: '',
};

export default Popover;
