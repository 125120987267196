import React from 'react';

const SvgIcon = (props: Svg) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={props.size.height}
    width={props.size.width}
    viewBox="0 0 100.23846 100.25703"
  >
    <g transform="translate(-155.7 -668.55)" fill={props.style.fill}>
      <g transform="translate(-192.11 88.816)">
        <path d="m380.8 665.64-24.73-14.149-0.02-11.598-0.02-11.598-4-1.8038-4-1.8038v-1.1244c0-0.61839 2.8581-6.0625 6.3514-12.098l6.3514-10.974 10.399-6.2046c5.7192-3.4125 10.883-6.225 11.474-6.25 1.2975-0.0548 5.4243 4.1829 5.4243 5.57v0.98659l-8.0412 4.8611c-4.4227 2.6736-8.2063 5.1282-8.408 5.4546l-0.36681 0.5935 17.439 9.9944 17.439 9.9943 1.1729-0.31786c2.2041-0.59733 25.766-13.621 25.766-14.242 0-0.34068-1.125-1.132-2.5-1.7585-3.1064-1.4154-3.0804-1.9094 0.30884-5.8665l2.7276-3.1846 7.2318 4.0109 7.2318 4.0109v24.698 24.698l-19.821 11.226-19.821 11.226-1.429-0.10055c-0.78597-0.0553-12.558-6.4678-26.159-14.25zm44.836-6.2645 13.394-7.615v-16.458-16.458l-5.25 2.9436c-2.8875 1.619-9.1875 5.0634-14 7.6544l-8.75 4.7108v16.419 16.419h0.60609c0.33334 0 6.6333-3.4268 14-7.615zm-23.665-5.635-0.0588-12.25-0.99039 1.5c-0.54471 0.825-1.9808 3.1507-3.1912 5.1681l-2.2008 3.6681-12-7.011c-6.6-3.8561-13.462-7.9059-15.25-8.9997l-3.25-1.9886v5.8709 5.8709l17.75 10.182c9.7625 5.6 18.088 10.195 18.5 10.211l0.75 0.029-0.0588-12.25zm-7.3441-18.938 1.5829-3.0609-1.34-1.2132c-0.73698-0.66724-7.7893-4.922-15.672-9.455l-14.332-8.2418-1.3915 1.8293c-1.7098 2.2476-2.7998 5.3078-2.1295 5.9781 0.27395 0.27395 7.1775 4.4453 15.341 9.2697l14.843 8.7716 0.75711-0.40843c0.41641-0.22464 1.4694-1.7858 2.34-3.4693zm3.6314-25.083c-4.5257-4.5493-8.2285-8.5996-8.2285-9.0006v-0.72911h4.13 4.1301l-0.50559-4.4856-0.50559-4.4856-1.6605-2.5343c-0.91329-1.3938-3.5568-3.9332-5.8745-5.6429l-4.2139-3.1087 5.7277 0.55396 5.7277 0.55396 5.1952 2.8246 5.1952 2.8246 2.938 4c1.6159 2.2 3.3821 5.2375 3.9249 6.75l0.98695 2.75h3.9022 3.9022v0.77208c0 0.42464-3.7222 4.4749-8.2715 9.0006l-8.2715 8.2285-8.2285-8.2715z" />
        <path d="m381.53 665.71-25-14.251-0.28124-11.678-0.28124-11.678-1.7188-0.574c-0.94532-0.3157-2.7817-1.3941-4.0809-2.3964l-2.3622-1.8224 6.5542-11.415 6.5542-11.415 6.808-3.9362c3.7444-2.1649 8.6564-4.9784 10.916-6.2522l4.1076-2.3161 2.6424 2.0785 2.6424 2.0785-0.0182 1.1774-0.0182 1.1774-8.4818 4.964c-4.665 2.7302-8.4818 5.2337-8.4818 5.5634 0 0.46853 32.312 19.649 34.923 20.73 0.34185 0.14158 6.8436-3.0692 14.448-7.135l13.827-7.3924-3.0989-1.8306c-1.7044-1.0068-3.0989-2.0994-3.0989-2.4279 0-0.32851 1.3138-1.856 2.9196-3.3945l2.9197-2.7972 0.84859 0.36012c0.46672 0.19806 3.6529 1.9265 7.0803 3.8409l6.2318 3.4808-0.0201 24.519-0.02 24.519-19.545 11c-10.75 6.05-20.078 11.106-20.73 11.236l-1.1847 0.23579-25-14.251zm47.5-8.034 9.5-5.4655 0.27459-16.702 0.27459-16.702-10.775 5.7237c-5.926 3.148-12.237 6.6006-14.025 7.6725l-3.25 1.9488v16.961 16.961l4.25-2.4659c2.3375-1.3562 8.525-4.9254 13.75-7.9314zm-27.022-3.937-0.0221-13.25-3.0961 5.3117-3.0961 5.3116-1.44-0.55257c-0.79199-0.30392-7.3763-3.996-14.632-8.2046-7.2555-4.2086-13.529-7.8392-13.942-8.0681l-0.75-0.41612v5.9792 5.9792l18.25 10.524c10.038 5.7883 18.362 10.549 18.5 10.58 0.1375 0.0306 0.24007-5.9069 0.22794-13.194zm-7.3223-18.738 2.1556-3.9711-15.775-9.0206c-8.6764-4.9613-16.05-9.0206-16.385-9.0206-0.33555 0-1.4692 1.6875-2.5191 3.75l-1.909 3.75 15.639 9.2065c8.6013 5.0636 15.864 9.2224 16.139 9.2418s1.47-1.7518 2.6556-3.9359zm3.345-25.512-8.4564-8.5006h4.2279 4.2279v-4.0497-4.0497l-1.4107-2.7003c-1.532-2.9325-5.9036-7.2785-9.0893-9.0361l-2-1.1034 3.0685-0.0304c1.6877-0.0167 5.3564 0.64904 8.1528 1.4795l5.0844 1.5099 5.0005 4.4959 5.0005 4.4958 1.84 4.4486 1.84 4.4486 3.7897 0.29561 3.7897 0.29561-8.3045 8.2506-8.3045 8.2506-8.4564-8.5006z" />
        <path d="m381.53 665.6-25-14.34-0.28029-11.466-0.28029-11.466-3.9697-2.2311c-2.1833-1.2271-3.9697-2.3738-3.9697-2.5482 0-0.17442 2.8964-5.3929 6.4366-11.597l6.4366-11.28 10.751-6.274 10.751-6.274 1.7892 0.95754c1.7022 0.91101 4.367 4.7938 3.666 5.3418-0.18206 0.14233-4.0435 2.4615-8.581 5.1537s-8.25 5.1746-8.25 5.5165c0 0.34183 7.9714 5.2391 17.714 10.883l17.714 10.261 8.5357-4.5669c4.6946-2.5118 10.973-5.9027 13.953-7.5353l5.4172-2.9684-3.2512-1.9772-3.2512-1.9772 2.914-3.2614 2.914-3.2614 7.177 3.9016 7.177 3.9016-0.25706 24.456-0.25707 24.456-19.5 11.238c-10.725 6.1811-19.95 11.251-20.5 11.268-0.55 0.0161-12.25-6.424-26-14.311zm49-8.6237 8-4.5872 0.27458-16.786 0.27458-16.786-12.775 6.7323c-7.026 3.7028-13.337 7.1727-14.025 7.7109l-1.25 0.97863v16.886 16.886l5.75-3.2234c3.1625-1.7729 9.35-5.2877 13.75-7.8106zm-28.531-3.2331-0.0312-13.25-2.4196 4c-1.3308 2.2-2.8127 4.6503-3.2931 5.4451l-0.87355 1.4451-14.369-8.4451c-7.9031-4.6448-14.755-8.6026-15.226-8.7952l-0.85676-0.35011 0.30031 6.2494 0.30032 6.2494 18 10.306c9.9 5.6685 18.112 10.326 18.25 10.351 0.1375 0.0244 0.23596-5.9181 0.21879-13.206zm-7.2171-18.709 2.2517-3.9434-5.7517-3.3608c-11.582-6.7677-25.895-14.737-26.467-14.737-0.66866 0-5.0213 7.1251-4.5757 7.4903 0.98554 0.80792 31.384 18.49 31.791 18.492 0.275 0.001 1.5133-1.7721 2.7517-3.941zm2.7483-26.041-7.9531-8h4.2265 4.2265v-4.1138-4.1138l-2.0921-3.7225-2.0921-3.7225-4.1579-2.7757-4.1579-2.7757 5.172 0.55566 5.172 0.55566 4.8914 2.4787 4.8914 2.4787 3.5659 3.947 3.5658 3.947 1.1982 3.6307 1.1982 3.6307h4.149 4.149l-7.9531 8c-4.3742 4.4-8.4242 8-9 8-0.57581 0-4.6258-3.6-9-8z" />
        <path d="m381.28 665.36-25.25-14.52v-11.402-11.402l-4.0652-2.0739-4.0652-2.0739 3.1993-5.6964c1.7596-3.133 4.7552-8.3964 6.6568-11.696l3.4576-6 10.658-6.1423 10.658-6.1423 1.7271 0.91524c0.9499 0.50339 2.2038 1.8061 2.7866 2.8949l1.0595 1.9796-4.5827 2.7474c-2.5205 1.5111-6.64 4.0188-9.1544 5.5727l-4.5718 2.8252 16.368 9.5254c9.0023 5.2389 17.327 9.9778 18.498 10.531l2.1304 1.0054 13.616-7.356c7.4887-4.0458 13.618-7.6119 13.62-7.9246 0.002-0.31272-1.3591-1.2141-3.0251-2.0031l-3.029-1.4345 2.821-3.379 2.821-3.379 7.2048 3.8821 7.2048 3.8821-0.24679 24.378-0.24679 24.378-19.852 11.372c-10.919 6.2545-20.144 11.346-20.5 11.315-0.35634-0.0314-12.01-6.5914-25.898-14.578zm44.432-5.4683 14.318-8.1031v-16.397-16.397h-0.90927c-0.50009 0-7.2501 3.416-15 7.591l-14.091 7.591v16.909 16.909h0.68216c0.37518 0 7.1252-3.6464 15-8.1031zm-23.886-12.647-0.29569-6.75-2.2113 4c-1.2162 2.2-2.6204 4.6072-3.1204 5.3494l-0.90912 1.3494-14.676-8.5994c-8.0718-4.7297-14.902-8.5994-15.177-8.5994-0.27576 0-0.36741 2.7998-0.20367 6.2218l0.29772 6.2218 18 10.272 18 10.272 0.29569-6.494c0.16263-3.5717 0.16263-9.5315 0-13.244zm-6.957-12.177 2.207-3.9268-1.2691-0.82315c-0.69799-0.45273-8.0208-4.7003-16.273-9.439l-15.004-8.6158-2.3478 3.9347c-1.2913 2.1641-2.1913 4.0905-2 4.2809 0.77957 0.77597 31.321 18.516 31.878 18.516 0.33108 0 1.5951-1.7671 2.809-3.9268zm2.6613-26.073-7.9531-8h4.1592 4.1592l0.62075-1.9558 0.62076-1.9558-1.2398-4.1379-1.2398-4.1379-2.5636-2.6689c-1.41-1.4679-3.6886-3.4709-5.0636-4.451l-2.5-1.7821 4.3966 0.58004 4.3966 0.58004 4.8911 2.1041 4.8911 2.1041 3.9126 4.4449 3.9126 4.4449 1.1273 3.4157 1.1273 3.4157h4.149 4.149l-7.9531 8c-4.3742 4.4-8.4242 8-9 8-0.57581 0-4.6258-3.6-9-8z" />
        <path d="m381.28 665.27-25.25-14.532v-11.27-11.27l-3.9571-2.3515-3.9571-2.3515 6.0458-10.5c3.3252-5.775 6.52-11.073 7.0995-11.774 0.57952-0.70076 5.4702-3.7747 10.868-6.8311l9.8144-5.557 1.7956 0.5699 1.7956 0.5699 1.2232 1.9587 1.2232 1.9587-8.4477 5.1027c-4.6462 2.8065-8.6476 5.426-8.8918 5.8211l-0.44403 0.71846 16.692 9.6769c9.1808 5.3223 17.452 9.9682 18.379 10.324l1.687 0.64737 13.537-7.2394c7.4451-3.9817 13.537-7.5513 13.537-7.9324 0-0.38115-1.125-1.2056-2.5-1.8321-3.0744-1.4008-3.0907-1.9314-0.17171-5.5792l2.3184-2.8973 7.1767 4.1914 7.1767 4.1914v23.882 23.882l-19.272 11.018c-10.6 6.06-19.937 11.225-20.75 11.478l-1.4778 0.46003-25.25-14.532zm45.5-5.883 13.25-7.5132v-16.44-16.44l-1.25 0.0345c-0.6875 0.019-7.325 3.3578-14.75 7.4197l-13.5 7.3852-0.27411 17.197-0.27412 17.197 1.7741-0.6634c0.97576-0.36487 7.7366-4.0443 15.024-8.1766zm-24.759-6.1425-0.009-13.75-3.1219 5.5c-1.717 3.025-3.2222 5.622-3.3449 5.771-0.12269 0.14905-6.4761-3.3938-14.119-7.8731-7.6426-4.4792-14.458-8.3618-15.146-8.628l-1.25-0.4839 0.0217 6.357 0.0217 6.357 18.202 10.25c10.011 5.6375 18.326 10.25 18.478 10.25 0.15191 0 0.27198-6.1875 0.26682-13.75zm-6.8846-18.458 2.1467-4.2079-0.87638-0.31903c-0.482-0.17547-7.8514-4.3324-16.376-9.2375l-15.5-8.9185-2.2446 3.6954c-1.2345 2.0325-2.1293 4.0485-1.9885 4.48 0.33383 1.0228 29.855 18.493 31.463 18.619l1.2297 0.0965 2.1467-4.2079zm3.4639-25.722-7.8844-7.9309 3.9072-0.31973 3.9072-0.31974-0.18102-4.1663-0.18101-4.1663-1.6049-3.1034-1.6049-3.1035-4.1142-2.9803-4.1142-2.9803h1.5447c0.84958 0 4.4234 0.88915 7.9419 1.9759l6.3972 1.9759 4.0663 3.4806 4.0663 3.4806 2.1437 4.9832 2.1437 4.9832 3.5336 0.31026 3.5336 0.31025-7.8083 7.7507-7.8083 7.7507-7.8844-7.9309z" />
        <path d="m382.49 665.68-24.965-14.254-0.27962-11.474-0.27961-11.474-4.3606-2.495-4.3606-2.495 6.498-11.29 6.498-11.29 10.142-5.9497c5.5782-3.2724 10.626-5.9542 11.218-5.9596 0.59162-0.005 2.0745 1.0533 3.2953 2.3528l2.2196 2.3626-9.0168 5.3874c-4.9593 2.963-9.0296 5.6218-9.0453 5.9083-0.0384 0.70402 35.992 21.531 36.818 21.283 0.35928-0.10799 6.8853-3.5438 14.502-7.635l13.849-7.4387-3.0989-1.8306c-1.7044-1.0068-3.0828-2.0711-3.063-2.365 0.0198-0.29391 1.0668-1.794 2.3268-3.3336l2.2908-2.7992 7.1732 4.1357 7.1732 4.1356v23.793 23.793l-17.25 9.8139c-9.4875 5.3976-18.616 10.614-20.285 11.593l-3.0354 1.7789-24.965-14.254zm43.223-5.5468 13.812-7.8561 0.27403-17.24 0.27402-17.24-11.774 6.2212c-6.4757 3.4216-13.237 7.1015-15.024 8.1775l-3.25 1.9564v16.918 16.918h0.93777c0.51578 0 7.1533-3.5352 14.75-7.8561zm-23.718-6.8939-0.0303-13.75-3.2882 5.731-3.2882 5.731-15.115-8.8809-15.115-8.8809-0.56664 0.56663c-0.31165 0.31165-0.56663 3.1655-0.56663 6.3419v5.7753l18.25 10.481c10.038 5.7647 18.588 10.516 19 10.558l0.75 0.0768-0.0303-13.75zm-7.0685-17.807c1.1543-1.9564 2.0949-3.8689 2.0902-4.25-0.007-0.58718-10.584-6.8402-28.642-16.933l-4.2504-2.3756-2.1308 4.1767-2.1308 4.1767 0.83102 0.7889c1.6269 1.5444 29.852 17.871 30.982 17.922l1.1512 0.0516 2.0988-3.5572zm3.6688-26.372-7.8844-7.9309 3.9072-0.31973 3.9072-0.31974 0.31291-2.7136 0.3129-2.7136-1.9761-4.4672-1.9761-4.4673-4.3368-3.1471-4.3368-3.1471 3 0.63436c1.65 0.34891 4.8 1.0865 7 1.639l4 1.0046 4.6433 3.3712 4.6433 3.3712 2.6971 5.5127 2.6971 5.5127 3.445 0.30498 3.445 0.30497-7.8083 7.7507-7.8083 7.7507-7.8844-7.9309z" />
        <path d="m382.53 665.57-25-14.312-0.28043-11.482-0.28042-11.482-4.3436-2.4014-4.3436-2.4014 6.624-11.362 6.624-11.362 10-5.8419c5.5-3.2131 10.484-5.8628 11.076-5.8882 0.59162-0.0255 2.0749 1.0173 3.2961 2.3172l2.2204 2.3635-9.0461 5.3554c-4.9754 2.9455-9.0461 5.6336-9.0461 5.9735 0 0.79765 35.177 21.26 36.5 21.232 0.55-0.0118 7.2428-3.4098 14.873-7.5511l13.873-7.5297-2.873-1.801c-1.5801-0.99052-3.0414-1.9181-3.2474-2.0614-0.20594-0.14323 0.73667-1.5809 2.0947-3.1948l2.4691-2.9344 7.1553 3.9685 7.1553 3.9685v23.716 23.716l-18.25 10.386c-10.038 5.7125-19.15 10.956-20.25 11.653l-2 1.2664-25-14.312zm48-8.0982 9-5.0869 0.27361-17.196c0.15049-9.458 0.12344-17.196-0.0601-17.196-0.18355 0-6.8317 3.4911-14.774 7.758l-14.44 7.758-0.27343 17.829-0.27343 17.829 5.7734-3.3035c3.1754-1.8169 9.8234-5.5926 14.773-8.3904zm-28.5-4.1463c0-7.5167-0.22125-13.445-0.49168-13.175-0.27042 0.27042-1.7486 2.7901-3.2847 5.5992l-2.7931 5.1075-15.269-8.9325c-8.3982-4.9129-15.491-8.9325-15.761-8.9325-0.27036 0-0.3585 2.9867-0.19587 6.6372l0.29569 6.6372 18 10.3c9.9 5.6648 18.338 10.328 18.75 10.363l0.75 0.0631v-13.667zm-5.0312-21.836 0.0312-1.0029-15.701-8.9971c-8.6356-4.9484-16.085-8.9971-16.553-8.9971-0.46877 0-1.8173 1.6885-2.9967 3.7522l-2.1444 3.7522 0.94781 0.9345c0.5213 0.51397 7.8988 4.9879 16.394 9.942l15.447 9.0075 2.2721-3.6942c1.2497-2.0318 2.2861-4.1455 2.3033-4.6971zm1.5319-22.496-7.9537-8.0007h4.2265 4.2265v-3.0321-3.0321l-1.5381-3.6813c-1.85-4.4277-3.9656-6.9271-7.9317-9.3705l-2.9698-1.8296 3.9698 0.56025 3.9698 0.56024 6.1866 2.944 6.1866 2.944 3.1238 4.2185 3.1239 4.2185 0.55413 2.6981 0.55414 2.6981 3.9198 0.30189 3.9198 0.3019-7.8072 7.7507-7.8072 7.7507-7.9538-8.0007z" />
      </g>
    </g>
  </svg>
);

export default SvgIcon;
