// @flow
import React from 'react';
import FieldInput from 'brastrap/common/field-input/FieldInput';
import { applyModifiers } from '../../utils';

type Props = {
  name: string,
  modifiers?: Modifiers,
};

const FieldSearch = (props: Props) => {
  const className = applyModifiers('c-field-search', props.modifiers);

  return (
    <div className={className}>
      <FieldInput {...props} />
    </div>
  );
};

export default FieldSearch;
