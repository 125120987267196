/**
 * Middleware that performs a full client-side redirect.
 *
 * Will ignore any action dispatched that doesn't contain a type equal to REDIRECT_CLIENT.
 *
 */
import { REDIRECT_CLIENT } from '../constants/redirect';
import { dataLayerSliCollectionsSearch } from '../utils/data-layer';

/**
 * @param {Object} window
 * @returns {Function}
 */
export default function redirectMiddleware(window = global.window) {
  return store => next => action => {
    if (action.type !== REDIRECT_CLIENT) {
      return next(action);
    }

    if (
      window &&
      window.setDataLayer &&
      action &&
      action.type === REDIRECT_CLIENT
    ) {
      if (action && action.payload) {
        dataLayerSliCollectionsSearch(action.payload);
      }
    }

    const url = action.payload;
    const {
      serverAndPort,
      config: { basePath },
    } = store.getState().app;
    const redirectUrl =
      url.indexOf('://') >= 0
        ? url
        : `${serverAndPort}${basePath}${url.replace(/^\/?(.*)/i, '$1')}`;

    if (window && window.location) {
      // eslint-disable-next-line no-param-reassign, no-return-assign
      return (window.location.href = redirectUrl);
    }
    return next(action);
  };
}
