// @flow
import {
  GET_POINTS_REQUEST,
  GET_POINTS_SUCCESS,
  GET_POINTS_FAILURE,
  SET_POINTS,
} from '../../constants/loyalty';
import { type Actions } from '../../actions/account/loyalty';

type State = {
  display?: boolean,
  expiryDate?: string, // Redux stores all dates as strings.
  isFetching: boolean,
  points?: number,
};

const initialState = {
  isFetching: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function loyalty(state: State = initialState, action: Actions) {
  switch (action.type) {
    case SET_POINTS:
      return {
        ...state,
        ...action.payload,
      };

    case GET_POINTS_SUCCESS:
    case GET_POINTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case GET_POINTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    default:
      return state;
  }
}
