import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Container from 'brastrap/common/container/Container';
import Grid from 'brastrap/helpers/l-grid/l-grid';
import StyledTitleBlock from 'brastrap/editorial/styled-title-block/StyledTitleBlock';
import FacetsContainer from 'brastrap/promotional/results-facets/ResultsFacetsContainer';

class SizesSelector extends Component {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      braFacets: props.braFacets,
      clothingFacets: props.clothingFacets,
    };
    this.updateBraFacets = this.updateFacetState.bind(this, 'braFacets');
    this.updateClothingFacets = this.updateFacetState.bind(
      this,
      'clothingFacets'
    );
    this.submitFacets = this.submitFacets.bind(this);
  }

  /**
   * @param {string} stateProp
   * @param {boolean} selected
   * @param {string} value
   * @param {string} facetId
   */
  updateFacetState(stateProp, selected, value, facetId) {
    const oldState = this.state[stateProp];
    const newState = oldState.map(facet => {
      if (facet.id === facetId) {
        const newFacet = { ...facet };
        newFacet.values = facet.values.map(facetValue =>
          facetValue.id === value ? { ...facetValue, selected } : facetValue
        );
        return newFacet;
      }
      return facet;
    });
    this.setState({ [stateProp]: newState });
  }

  /**
   * Sends selected facet to parent for querying SLI
   */
  submitFacets() {
    const { braFacets, clothingFacets } = this.state;
    const [selectedBraFacets, selectedClothingFacets] = [
      braFacets,
      clothingFacets,
    ].map(facets =>
      facets.map(({ id, values }) => {
        const selectedValues = values
          .filter(facet => facet.selected)
          .map(facet => facet.id);
        return selectedValues.length ? { [id]: selectedValues } : {};
      })
    );
    this.props.submitSelectedFacets(
      Object.assign(...selectedBraFacets, ...selectedClothingFacets)
    );
  }

  /**
   * @returns {XML}
   */
  render() {
    const newContainerProps = {
      ...this.props.containerProps,
      actions: [
        {
          ...this.props.containerProps.actions[0],
          onClick: this.submitFacets,
        },
      ],
    };
    return (
      <Container {...newContainerProps}>
        <Grid>
          <Grid.Unit modifiers={['offset']}>
            <StyledTitleBlock title={this.props.braSizesTitle}>
              <FacetsContainer
                facets={this.state.braFacets}
                onChange={this.updateBraFacets}
              />
            </StyledTitleBlock>
          </Grid.Unit>
          <Grid.Unit modifiers={['offset']}>
            <StyledTitleBlock title={this.props.clothingSizesTitle}>
              <FacetsContainer
                facets={this.state.clothingFacets}
                onChange={this.updateClothingFacets}
              />
            </StyledTitleBlock>
          </Grid.Unit>
        </Grid>
      </Container>
    );
  }
}

SizesSelector.propTypes = {
  containerProps: PropTypes.object,
  braSizesTitle: PropTypes.array,
  clothingSizesTitle: PropTypes.array,
  braFacets: PropTypes.array,
  clothingFacets: PropTypes.array,
  submitSelectedFacets: PropTypes.func,
};

export default SizesSelector;
