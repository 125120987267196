import { REDIRECT_MODAL_ID } from '../constants/app';

/**
 * Find out if a modal is collapsed initially
 *
 *  @param {Object} state
 *  @param {String} modalId
 *
 *  @returns {Boolean}
 */
export const isModalCollapsedInitially = (state, modalId) => {
  const {
    browser: { deviceIs },
    modal: { content },
  } = state;

  const userDevice =
    Object.keys(deviceIs).find(device => deviceIs[device]) || 'desktop';

  const { collapsedText, collapsedDevices } = content[modalId];

  return !!collapsedText && (collapsedDevices || []).includes(userDevice);
};

/**
 * Format the payload that goes into the openLayer dispatch used by the modal
 *
 *  @param {String} modalId
 *  @param {Object} modalContent
 *  @param {Function} onClose
 *
 *  @returns {Object}
 */
export const formatOpenLayerPayload = (modalId, modalContent, onClose) => {
  const closeOnOutsideClick =
    modalId === REDIRECT_MODAL_ID ? false : modalContent.closeOnOutsideClick;

  const openLayerPayload = {
    target: modalId,
    closeOnOutsideClick,
  };

  if (closeOnOutsideClick) {
    openLayerPayload.onClose = onClose;
  }

  return openLayerPayload;
};
