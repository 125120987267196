import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import EmailSignupForm from 'brastrap/editorial/email-signup-form/EmailSignupForm';

import { emailOptIn } from 'shared/actions/email-signup';
import { validate } from 'shared/utils/validation';

const validationRules = {
  email: ['required', 'email'],
};

const formConfig = {
  form: 'emailSignupForm',
  fields: ['email'],
  validate: (fields, { intl: { formatMessage } }) =>
    validate(fields, validationRules, formatMessage),
};

const mapStateToProps = (state, ownProps) => ({
  // Set confirmed = true if this component was the originator of the request
  confirmed:
    state.emailSignup.confirmed &&
    state.emailSignup.initiatedBy === ownProps.formKey,
  isSaving: state.emailSignup.isSaving,
});

/* 1. Apply reduxForm to the form component and assign it */
const FormComponent = reduxForm(formConfig, mapStateToProps)(EmailSignupForm);

/* 2. Wrap the result in another component so that we can programmatically set some form props */
const EmailSignup = props => {
  // eslint-disable-next-line no-underscore-dangle
  const formKey = props.formKey || (props._link && props._link.id) || 'default';
  return (
    <FormComponent
      formKey={formKey}
      // Supply the form key in the action payload so that the state can track which
      // (instance of the) component sent the action.
      onSubmit={(values, dispatch) =>
        dispatch(emailOptIn({ formKey, ...values }))
      }
      {...props}
    />
  );
};

export default injectIntl(EmailSignup);

EmailSignup.propTypes = {
  formKey: PropTypes.string,
  _link: PropTypes.shape({
    // eslint-disable-line no-underscore-dangle
    id: PropTypes.string,
  }),
};
