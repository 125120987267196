/* eslint-disable no-underscore-dangle */
import uuid from 'uuid';
import axios from 'axios';
import {
  VIEW_CONTENT,
  ADD_TO_CART,
  INITIATE_CHECKOUT,
  PURCHASE,
  COMPLETE_PAYMENT,
  PAGE_VISIT,
  CHECKOUT,
} from 'shared/constants/social-media';

import { hashData } from './utils';

/**
 * Shapes product data for social media server events
 * @param {string} action
 * @param {string} eventId
 * @return {object}
 */
const productDataForServer = (
  action,
  eventId,
  { product, currency, bag, customerDetails = {}, fbp, fbc }
) => {
  let shapedData = {};

  const customerInformationParameters = {
    fn: [null],
    ln: [null],
    em: [null],
    client_user_agent: navigator.userAgent,
    ...(fbp && { fbp: fbp }),
    ...(fbc && { fbc: fbc }),
  };

  if (customerDetails !== null) {
    if (customerDetails.firstName) {
      customerInformationParameters.fn = hashData(customerDetails.firstName);
    }
    if (customerDetails.lastName) {
      customerInformationParameters.ln = hashData(customerDetails.lastName);
    }
    if (customerDetails.email) {
      customerInformationParameters.em = hashData(customerDetails.email);
    }
  }

  const serverEventParameters = {
    // Born Social use PURCHASE for FB events. If we start using TikTok/other Social Media
    // server events this will need a bit of re-working.
    event_name: action === COMPLETE_PAYMENT ? PURCHASE : action,
    event_id: eventId,
    action_source: 'website',
    event_source_url: window.location?.href,
    event_time: Math.floor(new Date().getTime() / 1000),
  };

  const customData = {
    content_type: 'product',
    currency: currency,
  };

  if (action === ADD_TO_CART || action === VIEW_CONTENT) {
    shapedData = {
      ...serverEventParameters,
      user_data: customerInformationParameters,
      custom_data: {
        ...customData,
        value: product.prices.price,
        content_ids: [product.styleColourCode],
        content_name: product.styleColourName,
      },
    };
  }
  if (action === INITIATE_CHECKOUT || action === COMPLETE_PAYMENT) {
    shapedData = {
      ...serverEventParameters,
      user_data: customerInformationParameters,
      custom_data: {
        ...customData,
        contents: bag.items?.map(item => ({
          id: item.id,
          quantity: item.quantity,
          price: item.price,
        })),
        value: bag.subTotal,
      },
    };
  }

  return shapedData;
};

/**
 * Maps the correct code to the given payment method
 * @param {string} paymentMethod
 * @returns {string}
 */
const mapCodeToPaymentMethod = paymentMethod => {
  if (paymentMethod === 'adyen') return 'CARD';
  if (paymentMethod === 'paypal') return 'PAYPAL';
  return null;
};

/**
 * Shapes product data for social media browser events
 * @param {string} channel
 * @param {string} action
 * @return {object}
 */
const productDataForBrowser = (
  channel,
  action,
  { product, quantity, currency, bag, customerDetails, orderId }
) => {
  let shapedData;
  if (action === VIEW_CONTENT || action === ADD_TO_CART) {
    const variedProperties = {
      ...(channel === 'fb' && {
        content_ids: [product.styleColourCode],
        value: product.prices.price * quantity,
        content_category: product.category,
        content_type: 'product',
        content_name: product.styleColourName,
      }),
      ...(channel === 'tt' && {
        content_id: product.styleColourCode,
        price: product.prices.price,
        ...(action === ADD_TO_CART && {
          quantity: quantity,
          value: product.prices.price * quantity,
        }),
        content_category: product.category,
        content_type: 'product',
        content_name: product.styleColourName,
      }),
      ...(channel === 'pinterest' && {
        product_id: product.styleColourCode,
        value: product.prices.price * quantity,
        product_category: product.category,
        product_name: product.styleColourName,
      }),
    };

    shapedData = {
      ...variedProperties,
      currency: currency,
    };
  }

  if (action === INITIATE_CHECKOUT) {
    shapedData = {
      currency: currency,
      value: bag.subTotal,
      contents: bag.items.map(item => {
        return {
          ...(channel === 'fb' && { id: item.skuCode }),
          ...(channel === 'tt' && { content_id: item.skuCode }),
          quantity: item.quantity,
        };
      }),
      ...(channel === 'fb' && { num_items: bag.itemCount }),
      ...(channel === 'tt' && { quantity: bag.itemCount }),
    };
  }

  if (action === COMPLETE_PAYMENT || action === PURCHASE) {
    const variedProperties = {
      ...(channel === 'fb' && {
        contents: bag.items.map(item => ({
          id: item.skuCode,
          quantity: item.quantity,
          item_price: item.price,
        })),
        quantity: bag.itemCount,
      }),
      ...(channel === 'tt' && {
        contents: bag.items.map(item => ({
          content_id: item.styleColourCode,
          quantity: item.quantity,
        })),
        quantity: bag.itemCount,
      }),
      ...(channel === 'pinterest' && {
        line_items: bag.items.map(item => ({
          product_id: item.styleColourCode,
          product_quantity: item.quantity,
          product_category: item.category,
        })),
      }),
      ...(channel === 'wk' && {
        order_id: orderId,
        email: customerDetails.email,
        phone: bag.customer.phoneDaytime,
        goal: 'purchase',
        transaction_origin: 'online',
        coupon: null,
        total_discount: null,
        tax: null,
        shipping: bag.total - bag.subTotal,
        amount: Number(bag.total).toFixed(2).toString(),
        pay_method: mapCodeToPaymentMethod(bag.customer.paymentMethod),
        item: bag.items.map(item => ({
          product_id: item.styleColourCode,
          quantity: Number(item.quantity).toString(),
          price: Number(item.price).toFixed(2).toString(),
          sku: item.skuCode,
        })),
      }),
      ...(channel === 'upf' && {
        order_id: orderId,
        order_name: 'website_order',
        amount: Number(bag.total).toFixed(2).toString(),
        currency: currency,
        items: bag.items.map(item => ({
          name: item.styleColourCode,
          amount: Number(item.price).toFixed(2).toString(),
          currency: currency,
        })),
      }),
    };

    shapedData = {
      ...variedProperties,
      currency: currency,
      ...(channel !== 'wk' && { value: bag.subTotal }),
      ...(channel === 'pinterest' && { order_quantity: bag.itemCount }),
    };
  }
  return shapedData;
};

/**
 * Maps standard actions to pinterest ones
 * @param {string} action
 * @returns {string}
 */
function mapToPinterestAction(action) {
  if (action === VIEW_CONTENT) {
    return PAGE_VISIT;
  }

  if (action === COMPLETE_PAYMENT) {
    return CHECKOUT;
  }

  return action;
}

/**
 * Sends Social Media Data through Browser Events
 * @param {string} channel
 * @param {string} pixelId
 * @param {string} action
 * @param {object} socialMediaData
 * @param {string} eventId
 */
const sendSocialMediaDataThroughBrowser = (
  channel,
  pixelId,
  action,
  socialMediaData,
  eventId
) => {
  if (channel === 'fb' && window.fbq) {
    let ac = action;
    if (ac === COMPLETE_PAYMENT) {
      ac = PURCHASE;
    }
    window.fbq(
      'track',
      ac,
      productDataForBrowser(channel, ac, socialMediaData),
      {
        eventID: eventId,
      }
    );
    return;
  }
  if (channel === 'tt' && window.ttq) {
    if (socialMediaData.customerDetails) {
      window.ttq.identify({
        email: socialMediaData.customerDetails.email,
      });
    }
    window.ttq.track(
      action,
      productDataForBrowser(channel, action, socialMediaData)
    );
  }
  if (
    channel === 'pinterest' &&
    window.pintrk &&
    (action === ADD_TO_CART ||
      action === VIEW_CONTENT ||
      action === COMPLETE_PAYMENT)
  ) {
    window.pintrk(
      'track',
      mapToPinterestAction(action),
      productDataForBrowser(channel, action, socialMediaData)
    );
  }
  if (channel === 'wk' && window.top.bouncex && action === COMPLETE_PAYMENT) {
    window.top.bouncex = window.top.bouncex || [];
    window.top.bouncex.push([
      'conversion',
      productDataForBrowser(channel, action, socialMediaData),
    ]);
  }
  if (channel === 'upf' && window._upf && action === COMPLETE_PAYMENT) {
    window._upf.push([
      'order',
      productDataForBrowser(channel, action, socialMediaData),
    ]);
  }
};

/**
 * Send Social Media Data through server events
 * @param {string} action
 * @param {Object} data
 * @param {string} eventId
 */
const sendSocialMediaDataThroughServer = async (action, data, eventId) => {
  const shapedData = productDataForServer(action, eventId, data);
  await axios.post(`/api/socialmedia/facebook`, {
    data: shapedData,
    // Born Social use the PURCHASE rather than COMPLETE_PAYMENT so change when required
    event_name: action === COMPLETE_PAYMENT ? PURCHASE : action,
  });
};

/**
 * Send Social Media Data through server events
 * @param {string} action
 * @param {Object} channels
 * @param {Object } data
 */
export async function sendSocialMediaData(action, channels, data) {
  const eventId = uuid.v4().toString().toUpperCase();

  Object.keys(channels).forEach(function eachKey(ch) {
    const pixelId = channels[ch];
    sendSocialMediaDataThroughBrowser(ch, pixelId, action, data, eventId);
  });

  sendSocialMediaDataThroughServer(action, data, eventId);
}
