// @flow
import React from 'react';
import Link from 'brastrap/containers/link/LinkContainer';
import StyledText from 'brastrap/editorial/styled-text/StyledText';

type Props = {
  content: PromotionMessageType,
};
const PromotionMessage = ({ content }: Props) => {
  switch (
    content._type // eslint-disable-line no-underscore-dangle
  ) {
    case 'link':
      return (
        <Link href={content.url} className="c-promotions-message__link">
          {content.label}
        </Link>
      );
    case 'styledText':
      return <StyledText {...content} />;
    default:
      return null;
  }
};

export default PromotionMessage;
