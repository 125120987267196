// @flow
import React from 'react';

type Props = {
  style: {
    fill: string,
  },
  size: {
    width: number,
    height: number,
  },
};

const SvgIcon = ({ style, size }: Props) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 240 200"
    {...size}
  >
    <path d="M205 115c-7-1-17-10-19-17-2-8 4-16 13-16 7-0 13 2 19 7 4 4 5 1 6-2 2-5-1-12-7-15C206 65 194 64 183 70c-17 9-34 19-50 28-11 6-21 12-32 18-19 11-41 15-63 16-10-8-20-4-29-10-10-7-12-23-4-32 9-11 27-13 36-4 3 3 5 6 3 10-2 3-5 8-7 9-3-4-9-2-11-5-3-6-5-4-8-1-7 7-5 15 4 17 4 1 9 1 13 1 24-0 45-10 64-23C108 88 117 81 127 76 148 64 171 56 195 51c16-4 27 3 36 15 3 4 5 8 7 13 4 11-5 22-8 30-4 4-22 6-26 6zM74 136c15 0 26-8 38-14 12-6 23-13 35-19 6-2 13-2 19-2 2 0 6 9 1 12-3 2-8 3-12 4-12 3-22 9-31 16-9 7-19 11-30 13-6 1-13 2-18 3-5 0-13-14-2-14z" />
  </svg>
);

export default SvgIcon;
