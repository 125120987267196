import { CALL_API } from '../constants/api';
import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  NOTIFY_EXPIRED_TOKEN,
} from '../constants/reset-password';
import { loginSuccess } from './auth';

/**
 * @param {{token: string, password: string, confirmPassword: string}} payload
 *  Email address for the account to reset
 * @returns {Object} An action containing all the necessary fields to make an API call
 */
export function resetPassword(payload) {
  return {
    actions: {
      start: resetPasswordStart,
      success: resetPasswordSuccess,
      error: resetPasswordFailure,
    },
    method: 'post',
    params: payload,
    type: CALL_API,
    url: 'account/resetPassword',
  };
}

/**
 * @param {object} email
 * @returns {{type, payload: object}}
 */
export function resetPasswordStart(email) {
  return {
    type: RESET_PASSWORD,
    payload: {
      ...email,
    },
  };
}

/**
 * @param {object} details
 * @returns {Function}
 */
export function resetPasswordSuccess(details) {
  const successAction = {
    type: RESET_PASSWORD_SUCCESS,
    payload: details,
  };

  return dispatch =>
    dispatch(successAction).then(() => dispatch(loginSuccess(details)));
}

/**
 * @param {object} details
 * @returns {{type, error: boolean, payload: object}}
 */
export function resetPasswordFailure(details) {
  return {
    type: RESET_PASSWORD_FAILURE,
    error: true,
    payload: details,
  };
}

/**
 * @param {object} details
 * @returns {{type, error: boolean, payload: object}}
 */
export function notifyExpiredToken(details) {
  return {
    type: NOTIFY_EXPIRED_TOKEN,
    payload: details,
  };
}
