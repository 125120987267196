// @flow
import type { GetState } from 'redux';
import {
  RECEIVE_SHOP_REVIEWS,
  SHOP_REVIEWS_REQUEST,
  SHOP_REVIEWS_FAILURE,
  RECEIVE_SHOP_RATING,
  type ReceiveShopReviews,
  type ShopReviewsRequest,
  type ShopReviewsFailure,
  type ReceiveShopRating,
} from '../constants/shop-reviews'
import { CALL_API, type CallApi } from '../constants/api';

type ShopReviewsRequestAction = {|
  type: ShopReviewsRequest,
|};

type ShopReviewsFailureAction = {
  type: ShopReviewsFailure,
  error: boolean,
  payload: {
    message: Error,
  },
};

export type ReceiveShopReviewsAction = {
  type: ReceiveShopReviews,
  payload: {
    addToExisting: boolean,
    reviews: Review[],
  },
};

export type ReceiveShopRatingAction = {
  type: ReceiveShopRating,
  payload: {
    rating: Rating,
  },
};

export type ApiFetchReviewsAction = {
  actions: {
    success: (reviews: Review[]) => ReceiveShopReviewsAction,
    error: (err: Error) => ShopReviewsFailureAction,
    start: () => ShopReviewsRequestAction,
  },
  method: string,
  type: CallApi,
  url: string,
};

// Thunk types
export type Action =
  | ReceiveShopReviewsAction
  | ShopReviewsFailureAction
  | ShopReviewsRequestAction
  | ReceiveShopRatingAction
  | ApiFetchReviewsAction;
type Dispatch = (action: Action | ThunkAction) => any; // eslint-disable-line no-use-before-define
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

/**
 * @param {Boolean} addToExisting
 * @param {Array} reviews
 * @param {Object} rating
 * @returns {{type, payload: *}}
 */
export function receiveReviews(
  addToExisting: boolean,
  reviews: Review[]
): ReceiveShopReviewsAction {
  return {
    type: RECEIVE_SHOP_REVIEWS,
    payload: {
      reviews,
      addToExisting,
    },
  };
}

/**
 * @param {Object} rating
 * @returns {{type, payload: *}}
 */
export function receiveRating(rating: Rating): ReceiveShopRatingAction {
  return {
    type: RECEIVE_SHOP_RATING,
    payload: {
      rating,
    },
  };
}

/**
 * @returns {{type}}
 */
export function reviewsRequest(): ShopReviewsRequestAction {
  return {
    type: SHOP_REVIEWS_REQUEST,
  };
}

/**
 * @param {Object} err
 * @returns {{type}}
 */
export function reviewsFailure(err: Error): ShopReviewsFailureAction {
  return {
    type: SHOP_REVIEWS_FAILURE,
    error: true,
    payload: {
      message: err,
    },
  };
}

/**
 * @param {number} limit
 * @param {boolean} addToExisting
 * @param {number} currentOffset
 * @returns {{type, payload: *}}
 */
function apiFetchReviews(
  limit: number,
  addToExisting: boolean,
  currentOffset: number = 0
): ApiFetchReviewsAction {
  const params: { limit: number, currentOffset: number } = {
    limit,
    currentOffset,
  };

  return {
    actions: {
      success: receiveReviews.bind(null, addToExisting),
      error: reviewsFailure,
      start: reviewsRequest,
    },
    method: 'get',
    type: CALL_API,
    params,
    url: 'shop-reviews',
  };
}

export const getShopReviews = () => (
  dispatch: Dispatch,
  getState: GetState
): ThunkAction => {
  const { shopReviews: { reviews = [], limit } = {} } = getState();
  const currentOffset = reviews.length;
  return dispatch(apiFetchReviews(limit, true, currentOffset));
};
