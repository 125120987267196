import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
  >
    <path
      fillRule="evenodd"
      d="M40 80H24c-4 0-8 4-8 8v104c0 4 4 8 8 8h152c4 0 8-4 8-8V88c0-4-4-8-8-8h-16V60c0-32-28-60-60-60S40 28 40 60v20zm32 0h56V60c0-16-12-28-28-28S72 45 72 60v20z"
    />
  </svg>
);

export default SvgIcon;
