// @flow
import React from 'react';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import ReadMore from 'brastrap/editorial/read-more/ReadMore';
import StyledText, {
  getWrappingTag,
} from 'brastrap/editorial/styled-text/StyledText';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import type { Voice as Props } from './voice.types.js';

type Figure = {
  className: string,
  style?: {},
};

const Voice = (props: Props) => {
  const { backgroundColour } = props;
  const figureProps: Figure = { className: 'c-voice' };
  if (backgroundColour) {
    figureProps.style = { backgroundColor: backgroundColour };
  }
  const actions = [];
  if (props.actions) {
    actions.push(
      ...props.actions.map((action, index) => (
        <ButtonLink key={index} {...action} />
      ))
    );
  }
  let headingText;
  if (props.title) {
    headingText = props.title.map((item, index) => (
      <StyledText key={index} {...item} />
    ));
  }

  const TagName = getWrappingTag(props.title || [], 'h2');

  return (
    <figure {...figureProps}>
      <div className="c-voice__body">
        <TagName className="c-voice__title">
          <span className="c-voice__label">{props.label}</span>
          <span className="u-hidden">:</span>
          {headingText}
        </TagName>
        <blockquote className="c-voice__quote">
          {props.readMoreQuote ? (
            <ReadMore
              {...props.readMoreQuote}
              parentBackgroundColour={backgroundColour}
            />
          ) : (
            <MarkDown content={props.quote} />
          )}
        </blockquote>
        <figcaption className="c-voice__caption">
          <p>{props.customer}</p>
        </figcaption>
        {!!actions.length && <div className="c-voice__actions">{actions}</div>}
      </div>
    </figure>
  );
};

Voice.defaultProps = {
  actions: [],
  title: [],
};

export default Voice;
