import {
  EDITORIAL_CONTENT_SUCCESS,
  EDITORIAL_CONTENT_REQUEST,
  EDITORIAL_CONTENT_FAILURE,
  RECEIVE_PAGE_CONTAINERS,
} from '../constants/editorial';

import { CALL_API } from '../constants/api';

/**
 * @param {object} contentData
 * @param {boolean} shouldStartChat
 * @returns {{type, payload: object}}
 */
export function setContent(contentData, shouldStartChat = false) {
  return {
    type: EDITORIAL_CONTENT_SUCCESS,
    payload: { contentData, shouldStartChat },
  };
}

/**
 * @param {object} containers
 * @returns {{type, payload: object}}
 */
export function receivePageContainers(containers) {
  return {
    type: RECEIVE_PAGE_CONTAINERS,
    payload: { ...containers },
  };
}

/**
 * @returns {{type}}
 */
export function getEditorialContentRequest() {
  return {
    type: EDITORIAL_CONTENT_REQUEST,
  };
}

/**
 * @returns {{type}}
 */
export function getEditorialContentFailure() {
  return {
    type: EDITORIAL_CONTENT_FAILURE,
  };
}
/**
 * @param {Array} slugs
 * @returns {Object}
 */
export function getEditorialContent(slugs) {
  return {
    actions: {
      start: getEditorialContentRequest,
      error: getEditorialContentFailure,
      success: setContent,
    },
    type: CALL_API,
    method: 'get',
    url: `content/${slugs.join(',')}`,
  };
}

/**
 * getWrapperContainers
 *
 * @param {string} id
 * @returns {Object}
 */
export function getWrapperContainers(id) {
  return {
    actions: {
      start: getEditorialContentRequest,
      error: getEditorialContentFailure,
      success: receivePageContainers,
    },
    type: CALL_API,
    method: 'get',
    url: `content/wrappers/${id}`,
  };
}
