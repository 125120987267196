import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
    aria-labelledby="title"
  >
    <title>Instagram</title>
    <path
      fillRule="evenodd"
      d="M16 16C0 32 0 44 0 100s0 68 16 84 36 16 84 16 68 0 84-16 16-28 16-84 0-68-16-84-36-16-84-16-68 0-84 16zm2 84c0 53 1 60 8 68l3 3C38 181 47 182 100 182c53 0 62-1 71-11 1-1 2-2 3-3 7-8 8-15 8-68 0-57-1-61-11-71C162 19 153 18 100 18c-53 0-62 1-71 11C19 39 18 43 18 100zm147-53c0 7-5 12-12 12s-12-5-12-12 5-12 12-12 12 5 12 12zm-65 2c-28 0-51 23-51 51s23 51 51 51 51-23 51-51-23-51-51-51zm0 84c-18 0-33-15-33-33s15-33 33-33 33 15 33 33-15 33-33 33z"
    />
  </svg>
);

export default SvgIcon;
