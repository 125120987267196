import { upsertObjectToArray } from '../utils/utils';

import {
  SET_ESTIMATED_DELIVERY_DATE,
  GET_DELIVERY_METHODS,
  TOGGLE_DELIVERY_METHODS,
  CONFIRM_ORDER_FAILURE,
  SET_CONFIRMED_ORDER,
  SAVE_ORDER_NUMBER,
  ADD_CUSTOMER_DETAILS,
  SET_AUTH_PAYMENT_CARD_MODAL_HTML,
  IGNORE_LOGIN_PROMPT,
  TOGGLE_ADD_ADDRESS_FORM,
  SET_GUEST_EMAIL,
  CUSTOMER_CHECKOUT_DATA_REQUEST,
  CUSTOMER_CHECKOUT_DATA_FAILURE,
  NO_DELIVERY_METHODS,
  ORDER_SUBMITTED,
  SET_ADYEN,
  PROCESS_3D_AUTH,
  AUTH_3DS_FAILED,
  SHOW_US_DEFAULT_ADDRESS_ON_UK_SITE_ERROR,
  US_TAX_DATA_RECEIVED,
  US_TAX_DATA_FAILURE,
  US_TAX_DATA_FAILURE_ADDRESS,
  US_TAX_DATA_REQUEST,
  US_TAX_DATA_FAILURE_GENERAL_ERROR,
  US_TAX_UNHANDLED_ERROR,
  DISPLAY_UK_PAYMENT,
} from '../constants/checkout';

import {
  RECEIVE_DELIVERY_ADDRESSES,
  RECEIVE_PAYMENT_METHODS,
} from '../constants/account';

import { ADD_PAYMENT_CARD_SUCCESS } from '../constants/payment-card-details';

import { LOGIN_SUCCESS } from '../constants/auth';

// TODO: refactor this nested state into individual reducers and use combine reducers here
export const initialState = {
  customer: null,
  isFetching: {
    customerDetails: false,
    deliveryAddresses: false,
    deliveryMethods: false,
    paymentMethods: false,
  },
  delivery: {
    choice: 'delivery',
    clickAndCollectAddress: null,
    outletId: null,
    date: null,
    displayAddAddressForm: false,
    displayDeliveryAddressOptions: false,
    isPremiumAvailable: false,
    premiumUpgradeId: null,
    methods: [],
    number: null,
  },
  ignoreLoginPrompt: false,
  guestEmail: null,
  payment: {
    cards: [],
  },
  confirmed: {
    orderId: null,
    bag: {},
    success: false,
  },
  authPaymentCardHTML: '',
  orderSubmitted: false,
  adyen: {},
  processing3dsAuth: { status: false, type: null },
  auth3dsFailed: false,
  orderValidationFailure: false,
  deliveryAddressisUSonUKsite: false,
  usTaxInfo: { taxValue: 0 },
  isValidUSOrUkAddress: false,
  usTaxError: false,
  showPaymentOptions: false,
  usTaxLoading: false,
};

/**
 * @param {Object} state
 * @param {Array} deliveryMethods
 * @returns {{delivery: {methods: *}}}
 */
function addDeliveryMethods(state, deliveryMethods) {
  const premiumDeliveryMethod =
    deliveryMethods && deliveryMethods.find(method => method.premium);

  return {
    delivery: {
      ...state.delivery,
      isPremiumAvailable: false, // Disabled temporarily for #812  // !!premiumDeliveryMethod,
      premiumDeliveryMethod,
      methods: deliveryMethods,
    },
  };
}

/**
 * @param {Object} state
 * @param {Array} paymentMethods
 * @returns {{payment: {cards: *}}}
 */
function addPaymentMethods(state, paymentMethods) {
  return {
    payment: {
      ...state.payment,
      cards: paymentMethods,
    },
  };
}

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function checkout(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ESTIMATED_DELIVERY_DATE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          date: action.payload,
        },
      };

    case SET_AUTH_PAYMENT_CARD_MODAL_HTML: {
      return {
        ...state,
        authPaymentCardHTML: action.payload,
      };
    }

    case CONFIRM_ORDER_FAILURE:
      return {
        ...state,
        orderId: null,
        orderValidationFailure: action.payload || false,
      };

    case GET_DELIVERY_METHODS:
      return {
        ...state,
        ...addDeliveryMethods(state, action.payload),
        isFetching: {
          ...state.isFetching,
          deliveryMethods: false,
        },
      };

    case RECEIVE_PAYMENT_METHODS:
      return {
        ...state,
        ...addPaymentMethods(state, action.payload),
        isFetching: {
          ...state.isFetching,
          paymentMethods: false,
        },
      };

    case ADD_PAYMENT_CARD_SUCCESS: {
      const cards = upsertObjectToArray(
        state.payment.cards,
        action.payload.card,
        'tokenRef'
      );
      return {
        ...state,
        payment: {
          ...state.payment,
          cards,
        },
      };
    }

    case TOGGLE_DELIVERY_METHODS:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          displayDeliveryAddressOptions: !state.delivery
            .displayDeliveryAddressOptions,
        },
      };

    case SET_CONFIRMED_ORDER:
      return {
        ...state,
        confirmed: {
          orderId: action.payload.orderId,
          bag: action.payload.bag,
          success: action.payload.success,
        },
      };

    case SAVE_ORDER_NUMBER:
      return {
        ...state,
        orderNo: action.payload.orderNo,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        customer: action.payload.user,
        ...addDeliveryMethods(state, action.payload.deliveryMethods),
        ...addPaymentMethods(state, action.payload.paymentMethods),
      };

    case CUSTOMER_CHECKOUT_DATA_REQUEST:
      return {
        ...state,
        isFetching: {
          customerDetails: true,
          deliveryAddresses: true,
          deliveryMethods: true,
          paymentMethods: true,
        },
      };

    case CUSTOMER_CHECKOUT_DATA_FAILURE:
      return {
        ...state,
        isFetching: {
          customerDetails: false,
          deliveryAddresses: false,
          deliveryMethods: false,
          paymentMethods: false,
        },
      };

    case ADD_CUSTOMER_DETAILS:
      return {
        ...state,
        customer: action.payload,
        isFetching: {
          ...state.isFetching,
          customerDetails: false,
        },
      };

    case RECEIVE_DELIVERY_ADDRESSES:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          deliveryAddresses: false,
        },
      };

    case NO_DELIVERY_METHODS:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          deliveryMethods: false,
        },
      };

    case IGNORE_LOGIN_PROMPT:
      return {
        ...state,
        ignoreLoginPrompt: true,
      };

    case TOGGLE_ADD_ADDRESS_FORM:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          displayAddAddressForm: action.payload,
        },
      };

    case SET_GUEST_EMAIL:
      return {
        ...state,
        guestEmail: action.payload,
      };

    case ORDER_SUBMITTED:
      return {
        ...state,
        orderSubmitted: action.payload,
      };

    case SET_ADYEN:
      return {
        ...state,
        adyen: {
          ...state.adyen,
          ...action.payload,
        },
      };

    case PROCESS_3D_AUTH:
      return {
        ...state,
        processing3dsAuth: { ...action.payload },
      };

    case AUTH_3DS_FAILED:
      return {
        ...state,
        auth3dsFailed: action.payload,
      };

    case US_TAX_DATA_REQUEST:
      return {
        ...state,
        showPaymentOptions: false,
        isValidUSOrUkAddress: false,
        usTaxError: false,
        usTaxLoading: true,
      };

    case US_TAX_DATA_RECEIVED:
      return {
        ...state,
        usTaxInfo: action.payload,
        usTaxError: false,
        isValidUSOrUkAddress: true,
        showPaymentOptions: true,
        usTaxLoading: false,
      };

    case US_TAX_DATA_FAILURE:
      return {
        ...state,
        usTaxError: true,
        usTaxLoading: false,
      };

    case US_TAX_DATA_FAILURE_ADDRESS:
      return {
        ...state,
        isValidUSOrUkAddress: false,
        usTaxError: true,
        usTaxLoading: false,
      };

    case US_TAX_DATA_FAILURE_GENERAL_ERROR:
      return {
        ...state,
        usTaxError: true,
        isValidUSOrUkAddress: false,
      };

    case US_TAX_UNHANDLED_ERROR:
      return {
        ...state,
        usTaxError: false,
        isValidUSOrUkAddress: true,
        showPaymentOptions: true,
        usTaxLoading: false,
      };

    case DISPLAY_UK_PAYMENT:
      return {
        ...state,
        showPaymentOptions: true,
        isValidUSOrUkAddress: true,
        usTaxLoading: false,
      };

    case SHOW_US_DEFAULT_ADDRESS_ON_UK_SITE_ERROR:
      return {
        ...state,
        deliveryAddressisUSonUKsite: action.payload,
      };

    default:
      return state;
  }
}
