import PropTypes from 'prop-types';
import React from 'react';
import StyledText, {
  getWrappingTag,
} from 'brastrap/editorial/styled-text/StyledText';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-styled-title-block';

const StyledTitleBlock = props => {
  const className = applyModifiers(STYLE_PREFIX, props.modifiers);

  let headerText;
  if (props.title) {
    headerText = props.title.map((item, index) => (
      <StyledText key={index} {...item} />
    ));
  }

  const TagName = getWrappingTag(props.title || [], 'h1');

  return (
    <section className={className}>
      <header className="c-styled-title-block__header">
        <TagName className="c-styled-title-block__title">{headerText}</TagName>
      </header>

      <div className="c-styled-title-block__main">{props.children}</div>
    </section>
  );
};

StyledTitleBlock.propTypes = {
  children: PropTypes.node,
  modifiers: PropTypes.array,
  title: PropTypes.array,
};

StyledTitleBlock.defaultProps = {
  modifiers: [],
  title: [],
};

export default StyledTitleBlock;
