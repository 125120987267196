// @flow

type AuthenticatedTokenStorageKey = 'authenticated';
type IdentifiedTokenStorageKey = 'identified';
type BrowserIdStorageKey = 'BROWSER_ID';
type IsEmailRegisteredFailure = 'bravissimo/auth/IS_EMAIL_REGISTERED_FAILURE';
type IsEmailRegisteredSuccess = 'bravissimo/auth/IS_EMAIL_REGISTERED_SUCCESS';
type IsEmailRegisteredRequest = 'bravissimo/auth/IS_EMAIL_REGISTERED_REQUEST';
type LogoutFailure = 'bravissimo/auth/LOGOUT_FAILURE';
type LogoutSuccess = 'bravissimo/auth/LOGOUT_SUCCESS';
type LogoutRequest = 'bravissimo/auth/LOGOUT_REQUEST';
type NotAuthorised = 'bravissimo/auth/NOT_AUTHORISED';
type LoginFailure = 'bravissimo/auth/LOGIN_FAILURE';
type LoginSuccess = 'bravissimo/auth/LOGIN_SUCCESS';
type LoginRequest = 'bravissimo/auth/LOGIN_REQUEST';

export const LOGIN_REQUEST: LoginRequest = 'bravissimo/auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS: LoginSuccess = 'bravissimo/auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE: LoginFailure = 'bravissimo/auth/LOGIN_FAILURE';

export const NOT_AUTHORISED: NotAuthorised = 'bravissimo/auth/NOT_AUTHORISED';

export const LOGOUT_REQUEST: LogoutRequest = 'bravissimo/auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS: LogoutSuccess = 'bravissimo/auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE: LogoutFailure = 'bravissimo/auth/LOGOUT_FAILURE';

export const IS_EMAIL_REGISTERED_REQUEST: IsEmailRegisteredRequest =
  'bravissimo/auth/IS_EMAIL_REGISTERED_REQUEST';
export const IS_EMAIL_REGISTERED_SUCCESS: IsEmailRegisteredSuccess =
  'bravissimo/auth/IS_EMAIL_REGISTERED_SUCCESS';
export const IS_EMAIL_REGISTERED_FAILURE: IsEmailRegisteredFailure =
  'bravissimo/auth/IS_EMAIL_REGISTERED_FAILURE';

export const BROWSER_ID_STORAGE_KEY: BrowserIdStorageKey = 'BROWSER_ID';
export const IDENTIFIED_TOKEN_STORAGE_KEY: IdentifiedTokenStorageKey =
  'identified';
export const AUTHENTICATED_TOKEN_STORAGE_KEY: AuthenticatedTokenStorageKey =
  'authenticated';
