import {
  EMAIL_OPT_IN,
  EMAIL_OPT_IN_SUCCESS,
  EMAIL_OPT_IN_FAILURE,
} from 'shared/constants/email-signup';

export const initialState = {
  isSaving: false,
  confirmed: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} state
 */
export function emailSignup(state = initialState, action = {}) {
  switch (action.type) {
    case EMAIL_OPT_IN:
      return {
        ...state,

        initiatedBy: action.payload.formKey,
        isSaving: true,
      };

    case EMAIL_OPT_IN_SUCCESS:
      return {
        ...state,

        isSaving: false,
        confirmed: true,
      };

    case EMAIL_OPT_IN_FAILURE:
      return {
        ...state,

        isSaving: false,
      };

    default:
      return state;
  }
}
