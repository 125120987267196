import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="24"
    viewBox="0 0 120 240"
  >
    <path d="M116 112c4 4 4 12 0 16L16 228c-4 4-9 3-12 0s-4-8 0-12l96-96L4 24c-4-4-3-9 0-12s8-4 12 0l100 100z" />
  </svg>
);

export default SvgIcon;
