import { change } from 'redux-form';

import {
  FIND_SHOPS_FORM_ID,
  FIND_SHOPS_FAILURE,
  FIND_SHOPS_REQUEST,
  FIND_SHOPS_SUCCESS,
  GET_ALL_FAILURE,
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
} from '../constants/shops';

import { CALL_API } from '../constants/api';

/**
 * @param {string} postCode
 * @returns {Promise}
 */
export const usePostCodeSuggestion = postCode => dispatch =>
  Promise.all([
    dispatch(change(FIND_SHOPS_FORM_ID, 'location', postCode)),
    dispatch(apiFindShops(postCode)),
  ]);

/**
 * @return {{type}}
 */
export function getShops() {
  return {
    type: GET_ALL_REQUEST,
  };
}

/**
 * @param {Array} shops
 * @return {{type, payload: Array}}
 */
export function getShopsSuccess(shops = []) {
  return {
    type: GET_ALL_SUCCESS,
    payload: shops,
  };
}

/**
 * @param {String} message
 * @return {{type, payload: string, error: boolean}}
 */
export function getShopsFailure(message) {
  return {
    type: GET_ALL_FAILURE,
    payload: message,
    error: true,
  };
}

/**
 * @return {{actions: {start: getShops, success: getShopsSuccess, error: getShopsFailure}, type, url: string}}
 */
export function apiGetShops() {
  return {
    actions: {
      start: getShops,
      success: getShopsSuccess,
      error: getShopsFailure,
    },
    type: CALL_API,
    url: 'shop-finder',
  };
}

/**
 * @param {string} location
 * @return {{type, payload: {location: string}}}
 */
export function findShops(location) {
  return {
    type: FIND_SHOPS_REQUEST,
    payload: {
      location,
    },
  };
}

/**
 * @param {Object} shopsAndPostcodes
 * @return {{type, payload: Object}}
 */
export function findShopsSuccess(shopsAndPostcodes) {
  const noResults =
    !shopsAndPostcodes.shops.length && !shopsAndPostcodes.postCodes.length;
  return {
    type: FIND_SHOPS_SUCCESS,
    payload: {
      ...shopsAndPostcodes,
      noResults,
    },
  };
}

/**
 * @param {string} message
 * @return {{type, payload: string, error: boolean}}
 */
export function findShopsFailure(message) {
  return {
    type: FIND_SHOPS_FAILURE,
    payload: message,
    error: true,
  };
}

/**
 * @param {string} location
 * @param {number} clickAndCollectShopsOnly
 * @return {Object}
 */
export function apiFindShops(location, clickAndCollectShopsOnly) {
  return {
    actions: {
      start: findShops,
      success: findShopsSuccess,
      error: findShopsFailure,
    },
    params: { clickAndCollectShopsOnly },
    type: CALL_API,
    url: `shop-finder/${location}`,
  };
}
