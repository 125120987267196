import {
  GET_ALL_SHOPS_FAILURE,
  GET_ALL_SHOPS_REQUEST,
  RECEIVE_ALL_SHOPS,
  RECEIVE_SHOP,
  UPDATE_ACCORDION,
} from '../constants/shops';

import { CALL_API } from '../constants/api';

/**
 * @param {Array} error
 * @return {{type, payload: *}}
 */
function getAllShopsFailure(error) {
  return {
    type: GET_ALL_SHOPS_FAILURE,
    error: true,
    payload: {
      message: error,
    },
  };
}

/**
 * @return {{type}}
 */
function getAllShopsRequest() {
  return {
    type: GET_ALL_SHOPS_REQUEST,
  };
}

/**
 * @returns {Object}
 */
export function apiGetAllShops() {
  return {
    actions: {
      success: receiveAllShops,
      error: getAllShopsFailure,
      start: getAllShopsRequest,
    },
    type: CALL_API,
    url: 'shops',
  };
}

export const getAllShops = () => dispatch => {
  dispatch(apiGetAllShops());
};

/**
 * @param {array} shops
 * @returns {{type, payload: object}}
 */
export function receiveAllShops(shops) {
  return {
    type: RECEIVE_ALL_SHOPS,
    payload: shops,
  };
}

/**
 * @param {array} shop
 * @returns {{type, payload: object}}
 */
export function receiveShop(shop) {
  return {
    type: RECEIVE_SHOP,
    payload: shop,
  };
}

/**
 * @param {string} country
 * @returns {{type, payload: string}}
 */
export function updateAccordion(country) {
  return {
    type: UPDATE_ACCORDION,
    payload: country,
  };
}
