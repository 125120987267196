import React from 'react';

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="1024"
    height="271"
    viewBox="-1.35569997 -1.35569997 180.87454994 47.90139894"
  >
    <path
      d="M 32.60643,3.4075 C 30.51393,1.0225 26.73143,0 21.89268,0 L 7.84893,0 C 6.85893,0 6.01768,0.72 5.86268,1.69625 L 0.01518001,38.7825 c -0.11625,0.73125 0.45,1.39375 1.19124999,1.39375 l 8.67,0 2.1775,-13.81125 -0.0675,0.4325 c 0.155,-0.97625 0.99,-1.69625 1.97875,-1.69625 l 4.12,0 c 8.09375,0 14.43125,-3.2875 16.2825,-12.7975 0.055,-0.28125 0.1025,-0.555 0.14375,-0.8225 -0.23375,-0.12375 -0.23375,-0.12375 0,0 0.55125,-3.515 -0.004,-5.9075 -1.905,-8.07375"
      fill="#003087"
      fillOpacity="1"
      fillRule="nonzero"
      stroke="none"
    />
    <path
      d="m 122.18243,20.94413 -4.66625,0 c -0.44625,0 -0.86375,0.22125 -1.11375,0.59125 l -6.4375,9.48 -2.7275,-9.11 c -0.17125,-0.57 -0.69625,-0.96125 -1.29125,-0.96125 l -4.58625,0 c -0.55375,0 -0.94375,0.545 -0.765,1.06875 l 5.1375,15.0825 -4.8325,6.81875 c -0.37875,0.535 0.004,1.275 0.66,1.275 l 4.66125,0 c 0.44125,0 0.855,-0.21625 1.1075,-0.57875 l 15.5175,-22.3975 c 0.37125,-0.53625 -0.0113,-1.26875 -0.66375,-1.26875 m -31.19587,9.03612 c -0.44875,2.6525 -2.55375,4.43375 -5.24,4.43375 -1.34625,0 -2.425,-0.43375 -3.1175,-1.25375 -0.68625,-0.8125 -0.945,-1.97125 -0.7275,-3.26 0.4175,-2.63 2.5575,-4.4675 5.2025,-4.4675 1.31875,0 2.38875,0.4375 3.095,1.265 0.71125,0.83375 0.99125,1.99875 0.7875,3.2825 m 6.47,-9.03625 -4.6425,0 c -0.3975,0 -0.73625,0.28875 -0.79875,0.6825 l -0.20375,1.2975 -0.32375,-0.47 c -1.00625,-1.45875 -3.2475,-1.9475 -5.485,-1.9475 -5.12875,0 -9.51,3.8875 -10.3625,9.33875 -0.44375,2.72 0.18625,5.31875 1.72875,7.1325 1.41625,1.66625 3.4375,2.36 5.84625,2.36 4.135,0 6.4275,-2.655 6.4275,-2.655 l -0.2075,1.29 c -0.0775,0.49 0.30125,0.93375 0.79875,0.93375 l 4.18,0 c 0.66375,0 1.2275,-0.48125 1.33125,-1.13625 l 2.51,-15.8925 c 0.0775,-0.49 -0.3025,-0.93375 -0.79875,-0.93375 m -27.85913,0.1115 c -0.53,3.48 -3.1875,3.48 -5.75875,3.48 l -1.4625,0 1.02625,-6.4975 c 0.0612,-0.3925 0.4,-0.68125 0.7975,-0.68125 l 0.67125,0 c 1.75,0 3.4025,0 4.255,0.99625 0.51,0.59625 0.66375,1.48125 0.47125,2.7025 m -1.11875,-9.08 -9.695,0 c -0.66375,0 -1.2275,0.4825 -1.33125,1.1375 l -3.92,24.86 c -0.0775,0.49 0.30125,0.93375 0.7975,0.93375 l 4.63,0 c 0.6625,0 1.22625,-0.4825 1.33,-1.13625 l 1.05875,-6.7075 c 0.1025,-0.655 0.6675,-1.1375 1.33,-1.1375 l 3.0675,0 c 6.38625,0 10.0725,-3.09 11.035,-9.21625 0.43375,-2.6775 0.0175,-4.7825 -1.23625,-6.25625 -1.37875,-1.62 -3.8225,-2.4775 -7.06625,-2.4775"
      fill="#002f86"
      fillOpacity="1"
      fillRule="nonzero"
      stroke="none"
    />
    <path
      d="m 172.07806,12.65863 -3.97875,25.315 c -0.0775,0.49 0.30125,0.93375 0.7975,0.93375 l 4.0025,0 c 0.6625,0 1.2275,-0.4825 1.33,-1.1375 l 3.92375,-24.86 c 0.0775,-0.49 -0.30125,-0.93375 -0.79875,-0.93375 l -4.4775,0 c -0.39875,0 -0.7375,0.28875 -0.79875,0.6825 m -11.94175,17.32162 c -0.44875,2.6525 -2.55375,4.43375 -5.24,4.43375 -1.34625,0 -2.425,-0.43375 -3.1175,-1.25375 -0.6875,-0.8125 -0.945,-1.97125 -0.7275,-3.26 0.4175,-2.63 2.5575,-4.4675 5.2025,-4.4675 1.31875,0 2.38875,0.4375 3.095,1.265 0.71125,0.83375 0.99125,1.99875 0.7875,3.2825 m 6.47,-9.03625 -4.6425,0 c -0.3975,0 -0.73625,0.28875 -0.79875,0.6825 l -0.20375,1.2975 -0.325,-0.47 c -1.005,-1.45875 -3.24625,-1.9475 -5.48375,-1.9475 -5.12875,0 -9.51,3.8875 -10.3625,9.33875 -0.44375,2.72 0.18625,5.31875 1.72875,7.1325 1.41625,1.66625 3.4375,2.36 5.84625,2.36 4.135,0 6.4275,-2.655 6.4275,-2.655 l -0.2075,1.29 c -0.0775,0.49 0.30125,0.93375 0.79875,0.93375 l 4.18,0 c 0.66375,0 1.2275,-0.48125 1.33125,-1.13625 l 2.51,-15.8925 c 0.0775,-0.49 -0.3025,-0.93375 -0.79875,-0.93375 m -27.85925,0.1115 c -0.53,3.48 -3.1875,3.48 -5.75875,3.48 l -1.4625,0 1.02625,-6.4975 c 0.0613,-0.3925 0.4,-0.68125 0.7975,-0.68125 l 0.67125,0 c 1.75,0 3.4025,0 4.255,0.99625 0.51,0.59625 0.66375,1.48125 0.47125,2.7025 m -1.11875,-9.08 -9.695,0 c -0.66375,0 -1.2275,0.4825 -1.33125,1.1375 l -3.92,24.86 c -0.0775,0.49 0.3025,0.93375 0.7975,0.93375 l 4.975,0 c 0.46375,0 0.85875,-0.3375 0.93125,-0.795 l 1.1125,-7.04875 c 0.1025,-0.655 0.6675,-1.1375 1.33,-1.1375 l 3.0675,0 c 6.38625,0 10.0725,-3.09 11.035,-9.21625 0.43375,-2.6775 0.0175,-4.7825 -1.23625,-6.25625 -1.37875,-1.62 -3.8225,-2.4775 -7.06625,-2.4775"
      fill="#009cde"
      fillOpacity="1"
      fillRule="nonzero"
      stroke="none"
    />
    <path
      d="M 32.60643,3.4075 C 30.51393,1.0225 26.73143,0 21.89268,0 L 7.84893,0 C 6.85893,0 6.01768,0.72 5.86268,1.69625 L 0.01518001,38.7825 c -0.11625,0.73125 0.45,1.39375 1.19124999,1.39375 l 8.67,0 2.1775,-13.81125 -0.0675,0.4325 c 0.155,-0.97625 0.99,-1.69625 1.97875,-1.69625 l 4.12,0 c 8.09375,0 14.43125,-3.2875 16.2825,-12.7975 0.055,-0.28125 0.1025,-0.555 0.14375,-0.8225 -0.23375,-0.12375 -0.23375,-0.12375 0,0 0.55125,-3.515 -0.004,-5.9075 -1.905,-8.07375"
      fill="#003087"
      fillOpacity="1"
      fillRule="nonzero"
      stroke="none"
    />
    <path
      d="m 14.39418,11.52788 c 0.0925,-0.5875 0.47,-1.06875 0.9775,-1.3125 0.23125,-0.11 0.48875,-0.17125 0.75875,-0.17125 l 11.01,0 c 1.30375,0 2.52,0.085 3.63125,0.26375 0.31875,0.0512 0.6275,0.11 0.9275,0.1775 0.3,0.0662 0.59125,0.14125 0.87375,0.22375 0.14125,0.0412 0.28,0.0838 0.41625,0.12875 0.54625,0.1825 1.055,0.395 1.5225,0.64375 0.55125,-3.51625 -0.004,-5.9075 -1.905,-8.07375 -2.09375,-2.385 -5.875,-3.4075 -10.71375,-3.4075 l -14.045,0 c -0.98875,0 -1.83,0.72 -1.985,1.69625 l -5.84749999,37.085 c -0.11625,0.7325 0.45,1.39375 1.18999999,1.39375 l 8.67125,0 2.1775,-13.81125 2.34,-14.83625 z"
      fill="#002f86"
      fillOpacity="1"
      fillRule="nonzero"
      stroke="none"
    />
    <path
      d="m 34.51168,11.48125 0,0 c -0.0425,0.26875 -0.0888,0.54125 -0.14375,0.8225 -1.85125,9.50875 -8.18875,12.7975 -16.2825,12.7975 l -4.12125,0 c -0.98875,0 -1.82375,0.72 -1.9775,1.69625 l -2.11,13.3775 -0.59875,3.795 c -0.10125,0.64 0.39375,1.22 1.04125,1.22 l 7.30875,0 c 0.865,0 1.60125,-0.63 1.73625,-1.48375 l 0.0713,-0.3725 1.3775,-8.73 0.0888,-0.4825 c 0.135,-0.85375 0.87125,-1.48375 1.73625,-1.48375 l 1.09375,0 c 7.08,0 12.62375,-2.87625 14.24375,-11.195 0.67625,-3.47625 0.32625,-6.37875 -1.4625,-8.4175 -0.5425,-0.6175 -1.21625,-1.1275 -2.00125,-1.54375"
      fill="#009cde"
      fillOpacity="1"
      fillRule="nonzero"
      stroke="none"
    />
    <path
      d="m 32.57331,10.70863 c -0.2825,-0.0825 -0.57375,-0.1575 -0.87375,-0.22375 -0.3,-0.0663 -0.61,-0.125 -0.9275,-0.17625 -1.1125,-0.18 -2.3275,-0.265 -3.6325,-0.265 l -11.00875,0 c -0.27125,0 -0.52875,0.0613 -0.75875,0.1725 -0.50875,0.24375 -0.885,0.72375 -0.9775,1.3125 l -2.34,14.83625 -0.0675,0.4325 c 0.15375,-0.97625 0.98875,-1.69625 1.9775,-1.69625 l 4.12125,0 c 8.09375,0 14.43125,-3.2875 16.2825,-12.7975 0.055,-0.28125 0.10125,-0.55375 0.14375,-0.8225 -0.46875,-0.2475 -0.97625,-0.46125 -1.5225,-0.6425 -0.13625,-0.045 -0.275,-0.0888 -0.41625,-0.13"
      fill="#012069"
      fillOpacity="1"
      fillRule="nonzero"
      stroke="none"
    />
  </svg>
);

export default Icon;
