import { RECEIVE_SETTINGS_GROUP } from 'shared/constants/footer';

/**
 * @param {Object} data
 * @returns {{type, payload: *}}
 */
function receiveSettingGroupData(data) {
  return {
    type: RECEIVE_SETTINGS_GROUP,
    payload: data,
  };
}

export default receiveSettingGroupData;
