// @flow
import type { GetState } from 'redux';
import { replace, type Replace } from './app/routing';

import {
  FILTER_PRODUCT_REVIEWS,
  RECEIVE_REVIEWS,
  REVIEWS_REQUEST,
  REVIEWS_FAILURE,
  type FilterProductReviews,
  type ReceiveReviews,
  type ReviewsRequest,
  type ReviewsFailure,
} from '../constants/product-reviews';

import { CALL_API, type CallApi } from '../constants/api';

type PageFilter = { page: number, limit: number };
type LimitFilter = { limit: number };
type SortByFilter = { sortBy: string, limit: number };

type Filters = PageFilter | LimitFilter | SortByFilter;

type Dispatch = (action: Action | ThunkAction) => any; // eslint-disable-line no-use-before-define
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type FilterAction = {|
  type: FilterProductReviews,
  payload: Filters,
|};

export type ReviewsRequestAction = {|
  type: ReviewsRequest,
|};

export type ReviewsFailureAction = {
  type: ReviewsFailure,
  error: boolean,
  payload: {
    message: Error,
  },
};

export type ReceiveReviewsAction = {
  type: ReceiveReviews,
  payload: {
    reviews: Review[],
  },
};

export type ApiFetchReviewsAction = {
  actions: {
    success: (reviews: Review[]) => ReceiveReviewsAction,
    error: (err: Error) => ReviewsFailureAction,
    start: () => ReviewsRequestAction,
  },
  method: string,
  type: CallApi,
  url: string,
};

export type Action =
  | FilterAction
  | ReceiveReviewsAction
  | ReviewsFailureAction
  | ReviewsRequestAction
  | Replace;

/**
 * @param {Object} payload
 * @param {boolean} changeRoute
 * @returns {{type, payload: *}}
 */
export function filter(
  payload: Filters,
  changeRoute: boolean = true
): ThunkAction {
  return (dispatch, getState) =>
    dispatch({
      type: FILTER_PRODUCT_REVIEWS,
      payload,
    }).then(() => {
      if (changeRoute) {
        const {
          productReviews: { page, sortBy, starRating },
          app: {
            routing: { hash, pathname },
          },
        } = getState();
        const { limit } = payload;
        const query = `?page=${page}&limit=${limit}&sortBy=${sortBy}&starRating=${starRating}`;
        const url = `${pathname}${query}${hash}`;
        dispatch(replace(url));
      }
    });
}

/**
 * @param {Array} reviews
 * @returns {{type, payload: *}}
 */
export function receiveReviews(reviews: Review[]): ReceiveReviewsAction {
  return {
    type: RECEIVE_REVIEWS,
    payload: {
      reviews,
    },
  };
}

/**
 * @returns {{type}}
 */
export function reviewsRequest(): ReviewsRequestAction {
  return {
    type: REVIEWS_REQUEST,
  };
}

/**
 * @param {Object} err
 * @returns {{type}}
 */
export function reviewsFailure(err: Error): ReviewsFailureAction {
  return {
    type: REVIEWS_FAILURE,
    error: true,
    payload: {
      message: err,
    },
  };
}

/**
 * @param {string} styleColourOrCode
 * @param {Object} filters
 * @returns {{type, payload: *}}
 */
export function apiFetchReviews(
  styleColourOrCode: string,
  filters: {
    limit: number,
    page?: number,
    sortBy?: string,
    starRating?: string,
  }
): ApiFetchReviewsAction {
  return {
    actions: {
      success: receiveReviews,
      error: reviewsFailure,
      start: reviewsRequest,
    },
    method: 'get',
    params: filters,
    type: CALL_API,
    url: `products/${styleColourOrCode}/reviews`,
  };
}

/**
 * @param {Object} filters
 * @returns {{type, payload: *}}
 */
export function apiFetchAllReviews(filters: {
  limit: number,
  page?: number,
  sortBy?: string,
}): ApiFetchReviewsAction {
  return {
    actions: {
      success: receiveReviews,
      error: reviewsFailure,
      start: reviewsRequest,
    },
    method: 'get',
    params: filters,
    type: CALL_API,
    url: `products/reviews/all`,
  };
}
