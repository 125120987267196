import { push } from './app/routing';

export const updateQueryParams = query => (dispatch, getState) => {
  const { pathname } = getState().app.routing;

  const location =
    typeof query === 'string'
      ? pathname + query
      : {
          pathname,
          query,
        };

  return dispatch(push(location));
};

export default {
  updateQueryParams,
};
