// @flow
// TODO: Upgrade Flow to a version that supports React hooks
// $FlowFixMe
import React, { useEffect } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import Form from 'brastrap/common/form/Form';
import FieldSet from 'brastrap/common/fieldset/Fieldset';
import Field from 'brastrap/common/field/Field';
import Button from 'brastrap/common/button/Button';
import Message from 'brastrap/common/message/Message';
import EditorialContainer from 'brastrap/editorial/editorial-container/EditorialContainer';
import Link from 'brastrap/containers/link/LinkContainer';
import * as fieldTypes from './editorial-form-field-options';

import type { EditorialForm as Props } from './editorial-form.types.js';

const messages = defineMessages({
  formNote: {
    id: 'editorial-form.form-note',
    defaultMessage: '*Required Field',
  },
});

const EditorialForm = (props: Props) => {
  const {
    formConfig: { form },
    formKey,
    intl: { formatMessage },
    interaction,
    setFormMetadata,
  } = props;
  const buttonProps = {
    label: props.buttonLabel,
    submit: true,
    disabled: props.isSending,
  };

  const messageModifiers = ['banner', props.confirmed ? 'confirm' : 'warn'];

  const button = <Button {...buttonProps}>{buttonProps.label}</Button>;

  useEffect(() => {
    if (formKey) {
      // We need some of the data that flows down from Contentful to be available in
      // redux state.
      setFormMetadata({ formKey, formName: form, interaction });
    }
  });

  const formProps = {};

  if (props.analyticsReference) {
    formProps['data-ref'] = props.analyticsReference;
  }

  return (
    <Form {...formProps} onSubmit={props.handleSubmit} method="post">
      {props.message && (
        <Message modifiers={messageModifiers} onClose={props.onCloseMessages}>
          <EditorialContainer containers={props.message} />
        </Message>
      )}
      <FieldSet {...props} note={formatMessage(messages.formNote)}>
        {props.formFields.map(field => {
          if (props.fields && props.fields[field.name]) {
            return field.fieldType !== 'selectField' ? (
              React.createElement(fieldTypes[field.fieldType], {
                ...field,
                ...props.fields[field.name],
                key: field.id,
                note: props.fields[field.name].error,
              })
            ) : (
              <Field key={field.id}>
                {React.createElement(fieldTypes[field.fieldType], {
                  ...field,
                  ...props.fields[field.name],
                })}
              </Field>
            );
          }
          return null;
        })}
      </FieldSet>
      {props.action ? <Link action={props.action}>{button}</Link> : button}
    </Form>
  );
};

EditorialForm.defaultProps = {
  interaction: '',
  formFields: [],
  handleSubmit: () => {},
  onCloseMessages: () => {},
  onSubmit: () => {},
  setFormMetadata: () => {},
};

export default injectIntl(EditorialForm);
