import PropTypes from 'prop-types';
import React from 'react';
import Field from 'brastrap/common/field/Field';

const STYLE_PREFIX = 'c-field-textarea';

const invalidTextAreaProps = [
  'initialValue',
  'autofill',
  'onUpdate',
  'valid',
  'invalid',
  'dirty',
  'pristine',
  'error',
  'active',
  'visited',
  'autofilled',
  'modifiers',
  'floatLabel',
  'note',
  'touched',
  'fieldType',
  'validation',
];

/**
 * Ensure only valid textarea properties are used.
 *
 * @param {object} props
 * @return {{}}
 */
export const validateTextareaProps = props => {
  const textAreaProps = { ...props };
  invalidTextAreaProps.forEach(attr => delete textAreaProps[attr]);
  return textAreaProps;
};

/**
 * @param {Array} modifiers
 * @returns {String}
 */
const getClassName = modifiers =>
  `${STYLE_PREFIX}${modifiers.map(m => ` ${STYLE_PREFIX}--${m}`).join('')}`;

/**
 * @param {Object} props
 * @return {XML}
 * @constructor
 */
const FieldTextarea = props => {
  const textAreaProps = {
    ...validateTextareaProps(props),
    className: getClassName(props.modifiers),
  };

  const textArea = <textarea {...textAreaProps} />;

  if (props.label) {
    return (
      <Field {...props}>
        {(props.touched || !props.invalid) && props.note && (
          <em className="c-field__note">{props.note}</em>
        )}
        {textArea}
      </Field>
    );
  }

  return textArea;
};

FieldTextarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  modifiers: PropTypes.array,
  touched: PropTypes.bool,
  invalid: PropTypes.bool,
  note: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

FieldTextarea.defaultProps = {
  name: 'textarea',
  modifiers: [],
  disabled: false,
};

export default FieldTextarea;
