// @flow
import React from 'react';
import type {
  Nav as Props,
  Label as LabelProps,
  Arrow as ArrowProps,
} from 'brastrap/common/carousel/carousel.type';
import Icon from 'brastrap/common/icon/Icon';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-carousel-nav';

const arrowMap = {
  next: 'right',
  prev: 'left',
};

/**
 * @param {Object} props
 * @constructor
 */
const Label = ({ heading, subheading }: LabelProps) =>
  !heading && !subheading ? null : (
    <div className={`${STYLE_PREFIX}__label`}>
      {heading && <strong>{heading}</strong>}
      {subheading && <em>{subheading}</em>}
    </div>
  );

/**
 * @param {Object} props
 * @constructor
 */
const Arrow = ({ advance }: ArrowProps) => (
  <div className={`${STYLE_PREFIX}__arrow`}>
    <Icon icon={`${arrowMap[advance]}--large`} />
  </div>
);

/**
 * @param {Object} props
 * @returns {XML}
 * @constructor
 */
const CarouselNav = ({
  advance,
  disabled,
  label,
  onClick,
  verticalScroll,
}: Props) => {
  const modifiers = [advance];

  if (disabled) {
    modifiers.push('disabled');
  }

  if (verticalScroll) {
    modifiers.push('vertical');
  }

  return (
    <button
      className={applyModifiers(STYLE_PREFIX, modifiers)}
      onClick={onClick}
    >
      <Arrow {...{ advance }} />
      <Label {...{ ...label }} />
    </button>
  );
};

CarouselNav.defaultProps = {
  advance: 'next',
  onClick: () => {},
};

export default CarouselNav;
