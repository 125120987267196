import {
  EDITORIAL_CONTENT_SUCCESS,
  EDITORIAL_CONTENT_REQUEST,
  EDITORIAL_CONTENT_FAILURE,
  RECEIVE_PAGE_CONTAINERS,
} from '../constants/editorial';

export const initialState = {
  isFetching: false,
  data: [],
  headerContainers: [],
  footerContainers: [],
  shouldStartChat: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function content(state = initialState, action = {}) {
  switch (action.type) {
    case EDITORIAL_CONTENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.contentData,
        shouldStartChat: action.payload.shouldStartChat,
      };
    case EDITORIAL_CONTENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case EDITORIAL_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case RECEIVE_PAGE_CONTAINERS:
      return {
        ...state,
        headerContainers: action.payload.headerContainers,
        footerContainers: action.payload.footerContainers,
      };
    default:
      return state;
  }
}
