// @flow
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

type Props = {
  selectedSku: {
    label: string,
    stockStatus: string,
    id: number,
  },
  sizes: {
    value: string,
    variants: Array<{
      value: string,
    }>,
  },
  onChange: Node => any,
  selectedSkuRef: object,
};

type State = {
  grid: {},
};

const sizeMap = {
  XS: 0,
  'XS/S': 1,
  S: 2,
  'S/M': 3,
  M: 4,
  'M/L': 5,
  L: 6,
  'L/XL': 7,
  XL: 8,
  'XL/2XL': 9,
  '2XL': 10,
  '2XL/3XL': 11,
  'S-FC': 12,
  'M-FC': 13,
  'L-FC': 14,
  'XL-FC': 15,
};

const createTable = sizes => ({
  cols: sizes.variants
    .map(variant => variant.label)
    .sort((a, b) =>
      sizeMap[a] && sizeMap[b]
        ? sizeMap[a] - sizeMap[b]
        : parseInt(a, 10) - parseInt(b, 10)
    ),
  rows: ['items'],
  items: sizes.variants,
});

class SingleDimensionSizeGrid extends Component<Props, State> {
  state = {
    grid: createTable(this.props.sizes),
  };

  /**
   * Sets the size to the single dimension grid if changing colours by referencing saved sku details
   */
  componentDidMount() {
    if (
      typeof window !== 'undefined' &&
      !this.state.selectedSize &&
      this.props.selectedSkuRef &&
      this.props.selectedSkuRef !== undefined
    ) {
      const stringifiedObj = sessionStorage.getItem('selectedSkuDetails');
      const selectedSkuDetails = JSON.parse(stringifiedObj);

      const matchingSize =
        selectedSkuDetails &&
        this.state.grid.items.find(
          item => item.value === selectedSkuDetails.cupSize
        );

      if (
        selectedSkuDetails?.styleCode === this.props?.styleCode &&
        matchingSize &&
        matchingSize !== undefined
      ) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ selectedSize: selectedSkuDetails.cupSize });
        this.props.onChange(
          this.state.grid.items.find(
            item => item.value === selectedSkuDetails.cupSize
          )
        );
      } else {
        this.props.selectedSkuRef.current = null;
      }
    }
  }

  /** Gets all relevant classes for selector
   * @param {object} item
   * @returns {string}
   */
  getSizeSelectorClasses(item) {
    let classes = 'size-selector-widget__selector ';
    const isSelected =
      this.props.selectedSku && this.props.selectedSku.id === item.id;
    const isAvailable = item.available;

    if (isSelected && isAvailable) {
      classes += isAvailable
        ? 'size-selector-widget__selector--selected '
        : 'size-selector-widget__selector--selectedAndUnavailable ';
    }

    if (!isAvailable) {
      classes += 'size-selector-widget__selector--unavailable ';
    }

    return classes;
  }

  /**
   * Mathematical!
   * @returns {HTML}
   */
  render() {
    return (
      <section className="size-selector-widget__selectors c-size-grid-single-dimension__container">
        <div className="size-selector-widget__container">
          <h1 className="size-selector-widget__bandHeader-singleDimension">
            SIZE &nbsp; {this.state.selectedSize && '|'}
            &nbsp; {this.state.selectedSize}
          </h1>
          <div className="size-selector-widget__bandContainer-singleDimension">
            {this.state.grid.cols.map(col => {
              const item = this.state.grid.items.find(i => {
                // Remove any non alphanumeric characters
                return i.value === col.replace(/[^0-9a-z]/gi, '');
              });
              return (
                <div
                  id={`single-dimension-selector-${item.code}`}
                  className={this.getSizeSelectorClasses(item)}
                  onClick={() => {
                    this.props.onChange(item);
                    this.setState({ selectedSize: item.code });
                  }}
                >
                  {item.code}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default injectIntl(SingleDimensionSizeGrid);
