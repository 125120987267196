const namespace = 'bravissimo/shops';

export const GET_ALL_SHOPS_FAILURE = `${namespace}/GET_ALL_SHOPS_FAILURE`;
export const GET_ALL_SHOPS_REQUEST = `${namespace}/GET_ALL_SHOPS_REQUEST`;
export const RECEIVE_ALL_SHOPS = `${namespace}/RECEIVE_ALL_SHOPS`;
export const RECEIVE_SHOP = `${namespace}/RECEIVE_SHOP`;

export const FIND_SHOPS_REQUEST = `${namespace}/FIND_REQUEST`;
export const FIND_SHOPS_SUCCESS = `${namespace}/FIND_SUCCESS`;
export const FIND_SHOPS_FAILURE = `${namespace}/FIND_FAILURE`;

export const GET_ALL_REQUEST = `${namespace}/GET_ALL_REQUEST`;
export const GET_ALL_SUCCESS = `${namespace}/GET_ALL_SUCCESS`;
export const GET_ALL_FAILURE = `${namespace}/GET_ALL_FAILURE`;

export const UPDATE_ACCORDION = `${namespace}/UPDATE_ACCORDION`;

export const FIND_SHOPS_FORM_ID = 'shopFinder';
