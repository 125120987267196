// @flow
import React from 'react';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-ellipsis';

type Props = {
  modifiers?: Modifiers,
};

const Dot = (
  <svg viewBox="0 0 100 100">
    <circle cx="50" cy="50" r="30" />
  </svg>
);

const Ellipsis = ({ modifiers }: Props) => (
  <div className={`${applyModifiers(STYLE_PREFIX, modifiers)} u-center`}>
    <div>{Dot}</div>
    <div>{Dot}</div>
    <div>{Dot}</div>
  </div>
);

Ellipsis.defaultProps = {
  modifiers: [],
};

export default Ellipsis;
