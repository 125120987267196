import generateAsyncRouteComponent from '../generateAsyncRouteComponent';

export default [
  {
    path: 'reset-password/:token?',
    component: generateAsyncRouteComponent({
      loader: () => import('./containers/PasswordResetContainer'),
    }),
  },
];
