import {
  RECEIVE_MEGA_MENU_ITEMS,
  TOGGLE_MEGA_MENU_NAVIGATION,
  CLOSE_MEGA_MENU_NAVIGATION,
} from '../../constants/masthead';

import { setItemVisibility } from './navigation';

import { openLayer, closeLayer } from '../layer';

/**
 * @param {array} items - containing mega menu content.
 * @returns {{type, payload: *}}
 */
export function receiveItems(items) {
  return {
    type: RECEIVE_MEGA_MENU_ITEMS,
    payload: items,
  };
}

/**
 * Opens the mega-menu if it isn't already open, and displays the content
 * at the supplied index.
 * @param {number} index of the nav items we want to display content for.
 * @param {boolean} isDesktop flag for if device is desktop.
 * @returns {{type: string, payload: number}}
 */
export function toggleMenu(index, isDesktop) {
  return {
    type: TOGGLE_MEGA_MENU_NAVIGATION,
    payload: {index, isDesktop},
  };
}

/**
 * Closes the mega-menu.
 * @returns {{type}}
 */
export function closeMenu() {
  return {
    type: CLOSE_MEGA_MENU_NAVIGATION,
  };
}

/**
 * Thunk to close the mega-menu and layer
 * @returns {function}
 */
export const closeMenuAndLayer = () => dispatch => {
  dispatch(closeLayer());
  dispatch(closeMenu());
  dispatch(setItemVisibility());
};

/**
 * Thunk to toggle menu and layer
 * @returns {function}
 * @param {Number} index
 * @param {Boolean} isDesktop
 */
export const toggleMenuAndLayer = (index, isDesktop) => (dispatch, getState) => {
  const menu = getState().masthead.megaMenu[index];
  if (menu.isVisible) {
    dispatch(closeMenuAndLayer());
  } else {
    dispatch(toggleMenu(index, isDesktop));
    dispatch(
      openLayer({
        target: menu.id,
        onClose: () => dispatch(closeMenuAndLayer()),
        modifiers: ['mega-menu'],
      })
    );
  }
};
