import { CALL_API } from '../constants/api';
import {
  EMAIL_OPT_IN,
  EMAIL_OPT_IN_SUCCESS,
  EMAIL_OPT_IN_FAILURE,
} from '../constants/email-signup';
import { dataLayerSendEmail } from '../utils/data-layer';

/**
 * @param {object} details
 * @returns {{type, payload: object}}
 */
export const emailOptInSuccess = details => dispatch => {
  const { email } = details.body;
  const action = {
    type: EMAIL_OPT_IN_SUCCESS,
    payload: details,
  };
  dispatch(action);
  dataLayerSendEmail(email);
};

/**
 * @param {object} payload - Object containing the email address to opt in
 * @returns {Object} An action containing all the necessary field to make an API call
 */
export function emailOptIn(payload) {
  return {
    actions: {
      start: emailOptInStart,
      success: emailOptInSuccess,
      error: emailOptInFailure,
    },
    method: 'post',
    params: payload,
    type: CALL_API,
    url: 'account/emailOptIn',
  };
}

/**
 * @param {object} payload
 * @returns {{type, payload: object}}
 */
export function emailOptInStart(payload) {
  return {
    type: EMAIL_OPT_IN,
    payload,
  };
}

/**
 * @param {object} details
 * @returns {{type, error: boolean, payload: object}}
 */
export function emailOptInFailure(details) {
  return {
    type: EMAIL_OPT_IN_FAILURE,
    error: true,
    payload: details,
  };
}
