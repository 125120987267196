import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15.5 13.6"
    width="20"
    height="20"
  >
    <path d="M13.9,1c0,0-2.5-2.6-6.2,1C4.1-1.6,1.6,1,1.6,1c-2.7,2.7-0.8,5.5-0.8,5.5c1.2,2.8,7,7,7,7s5.8-4.2,7-7C14.7,6.5,16.6,3.7,13.9,1z" />
  </svg>
);

export default SvgIcon;
