import {
  OPEN_STOCK_FINDER,
  CLOSE_STOCK_FINDER,
  MODAL_ID,
  FIND_STOCK_REQUEST,
  FIND_STOCK_SUCCESS,
  FIND_STOCK_FAILURE,
} from '../constants/stock-finder';
import { closeLayer, openLayer } from '../actions/layer';
import { selectSku } from '../actions/product';
import { findShopsSuccess } from '../actions/shop-finder';
import { CALL_API } from '../constants/api';

/**
 * Close the stock finder and close the layer
 *
 * @returns {function(*): *}
 */
export const close = () => dispatch => {
  dispatch({
    type: CLOSE_STOCK_FINDER,
  });
  return dispatch(closeLayer());
};

/**
 * Open the stock finder and close the layer.
 * Attach action to close the stock finder when clicking on the layer.
 *
 * @returns {function(*): *}
 */
export const open = () => dispatch => {
  dispatch({
    type: OPEN_STOCK_FINDER,
  });
  return dispatch(
    openLayer({ target: 'stock-finder', onClose: () => dispatch(close()) })
  );
};

/**
 * Open the size select modal.
 *
 * @returns {{type: string, payload: *}}
 */
export const openSizeSelect = () => openLayer(MODAL_ID);

/**
 * Close the size select.
 * Note: Because the layer reducer has been designed so that only one modal/drawer has focus at one time, we have to
 * close the layer (the open layer will be the size picker) and open the Stock Finder again.
 * Because we animations are all CSS the user will not notice any additional opening or closing on the frontend.
 *
 * @returns {function(*): *}
 */
export const closeSizeSelect = () => dispatch => {
  dispatch(closeLayer());
  return dispatch(open());
};

/**
 * @param {String} sku - Sku Code: AU01BL28DD for example.
 * @returns {function(*): *}
 */
export const selectSkuAndCloseSizeSelect = sku => dispatch => {
  dispatch(selectSku(sku));
  return dispatch(closeSizeSelect());
};

/**
 * @returns {{type: string}}
 */
const findStock = () => ({
  type: FIND_STOCK_REQUEST,
});

/**
 * The API endpoint for found stock may return locations rather than shops.
 * In this scenario we need to dispatch the poorly named findShopsSuccess action, which actually handles shops and
 * location suggestions and stores them in the shop finder.
 * If we only have shops then we store the data in the stock finder reducer.
 *
 * @param {Object} payload
 * @returns {Function}
 */
const foundStock = payload => dispatch => {
  const containsLocationSuggestions = payload.every(
    location => !!location.postCode
  );

  if (containsLocationSuggestions) {
    dispatch(findShopsSuccess({ postCodes: payload, shops: [] }));
  } else {
    dispatch({
      type: FIND_STOCK_SUCCESS,
      payload,
    });
  }
};

/**
 * @param {boolean} error
 * @returns {{type: string, payload: *, error: boolean}}
 */
const findStockError = error => ({
  type: FIND_STOCK_FAILURE,
  payload: error,
  error: true,
});

/**
 * API action to find stock for a given skuId and optional location
 * @param {String} skuId
 * @param {String} [location]
 * @returns {{
 *   params,
 *   type: CallApi,
 *   actions: {
 *     success: (function(Object): Function),
 *     start: (function(): {type: string}),
 *     error: (function(boolean): {payload: boolean, type: string, error: boolean})
 *   },
 *   url: string
 * }}
 */
export const apiFindStock = (skuId, location) => {
  const params = {};

  if (location) {
    params.location = location;
  }

  return {
    actions: {
      start: findStock,
      success: foundStock,
      error: findStockError,
    },
    params,
    type: CALL_API,
    url: `stock/${skuId}`,
  };
};
