import {
  RECEIVE_PRODUCT_LISTING,
  RECEIVE_PRODUCT_LISTING_REQUEST,
  SELECT_STYLE_COLOUR,
  CLEAR_SCROLL,
} from '../constants/product-listing';

export const initialState = {
  collection: null,
  containers: [],
  filter: {},
  isFetching: false,
  items: [],
  pagination: {},
  poster: null,
  resultsPath: null,
  searchPhrase: null,
  isShopByCollection: false,
  shopByCollectionData: null,
  total: 0,
};

/**
 * @param {Object}state
 * @param {Object} action
 * @returns {*}
 */
export function productListing(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_PRODUCT_LISTING_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_PRODUCT_LISTING:
      return {
        ...initialState,
        ...action.payload,
      };

    case CLEAR_SCROLL: {
      const newState = { ...state };
      delete newState.scrollTo;
      return newState;
    }

    case SELECT_STYLE_COLOUR: {
      const styleColourCode = action.payload;
      const items = [...state.items];

      const itemToUpdate = items.find(item =>
        styleColourCode.match(item.styleCode)
      );
      itemToUpdate.colours = itemToUpdate.colours.map(colour => ({
        ...colour,
        selected: colour.styleColourCode === styleColourCode,
      }));

      return {
        ...state,
        items,
      };
    }

    default:
      return state;
  }
}
