import {
  ADD_GIFT_VOUCHER_PAYMENT_REQUEST,
  ADD_GIFT_VOUCHER_PAYMENT_FAILURE,
  ADD_GIFT_VOUCHER_PAYMENT_SUCCESS,
  GET_GIFT_VOUCHER_PAYMENT_REQUEST,
  GET_GIFT_VOUCHER_PAYMENT_FAILURE,
  GET_GIFT_VOUCHER_PAYMENT_SUCCESS,
  ADD_GIFT_VOUCHER_PAYMENT_CLOSE_MESSAGES,
  ADD_GIFT_VOUCHER_PAYMENT_MODAL_OPEN,
  ADD_GIFT_VOUCHER_PAYMENT_MODAL_CLOSED,
  ADD_GIFT_VOUCHER_PAYMENT_REFERENCE_COMPLETE,
} from 'shared/constants/add-gift-voucher-payment';

export const initialState = {
  errorMessage: null,
  confirmationMessage: null,
  modalOpen: false,
  isCompleteGiftVoucherReference: false,
  isAdding: false,
  isValidating: false,
  isValidGiftVoucher: false,
  giftVoucherPayment: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function addGiftVoucherPaymentForm(state = initialState, action = {}) {
  switch (action.type) {
    case GET_GIFT_VOUCHER_PAYMENT_REQUEST:
      return {
        ...state,

        isAdding: false,
        isValidating: true,
        isValidGiftVoucher: false,
        giftVoucherPayment: null,
      };

    case ADD_GIFT_VOUCHER_PAYMENT_REQUEST:
      return {
        ...state,

        isAdding: true,
        isValidating: false,
      };

    case GET_GIFT_VOUCHER_PAYMENT_FAILURE:
    case ADD_GIFT_VOUCHER_PAYMENT_FAILURE:
      return {
        ...state,

        isAdding: false,
        isValidating: false,
        errorMessage: action.message,
        confirmationMessage: null,
      };

    case GET_GIFT_VOUCHER_PAYMENT_SUCCESS: {
      return {
        ...state,

        errorMessage: null,
        confirmationMessage: 'The gift voucher has a redeemable value of: ',
        isValidating: false,
        isValidGiftVoucher: true,
        giftVoucherPayment: action.payload,
      };
    }

    case ADD_GIFT_VOUCHER_PAYMENT_SUCCESS:
      return {
        ...state,

        errorMessage: null,
        confirmationMessage: null,
        modalOpen: false,
        isCompleteGiftVoucherReference: false,
        isAdding: false,
        isValidating: false,
        isValidGiftVoucher: false,
      };

    case ADD_GIFT_VOUCHER_PAYMENT_CLOSE_MESSAGES:
      return {
        ...state,

        confirmationMessage: null,
        errorMessage: null,
      };

    case ADD_GIFT_VOUCHER_PAYMENT_MODAL_OPEN:
      return {
        ...state,

        modalOpen: true,
        isCompleteGiftVoucherReference: false,
        isAdding: false,
        isValidating: false,
        isValidGiftVoucher: false,
        giftVoucherPayment: null,
      };

    case ADD_GIFT_VOUCHER_PAYMENT_MODAL_CLOSED:
      return {
        ...state,

        modalOpen: false,
        isCompleteGiftVoucherReference: false,
        isAdding: false,
        isValidating: false,
        isValidGiftVoucher: false,
        giftVoucherPayment: null,
      };

    case ADD_GIFT_VOUCHER_PAYMENT_REFERENCE_COMPLETE: {
      const giftVoucherReference = action.payload.giftVoucherReference;
      const giftVoucherReferenceIncomplete =
        giftVoucherReference === '' || giftVoucherReference.includes('_');
      return {
        ...state,

        isCompleteGiftVoucherReference: !giftVoucherReferenceIncomplete,
      };
    }

    default:
      return state;
  }
}
