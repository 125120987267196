// @flow
import React from 'react';
import Button from 'brastrap/common/button/Button';
import Link from 'brastrap/containers/link/LinkContainer';
import type { ImageButton as Props } from './image-button.types.js';

const STYLE_PREFIX = 'c-image-button';

const ImageButton = (props: Props) => {
  let className = STYLE_PREFIX;
  const { id, selected, action, onSelect, image } = props;
  const outerProps = {};
  const onClick = onSelect
    ? (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onSelect(id, !selected);
      }
    : undefined;
  if (onClick) {
    outerProps.onClick = onClick;
  }
  if (selected) {
    className += ` ${STYLE_PREFIX}--selected`;
    outerProps['aria-selected'] = 'true';
  }
  const buttonProps = {
    id: `ib-${id}`,
    label: `${action.label} >`,
    modifiers: action.modifiers,
    submit: false,
  };

  if (id) {
    outerProps['aria-label'] = action.label;
  }

  const button = (
    <div className={className} {...outerProps}>
      {image && image.src && (
        <img
          className={`${STYLE_PREFIX}__image`}
          src={image.src}
          alt={image.alt}
        />
      )}
      <div className={`${STYLE_PREFIX}__actions`}>
        <Button {...buttonProps} />
      </div>
    </div>
  );

  return onClick ? button : <Link href={action.url}>{button}</Link>;
};

export default ImageButton;
