// @flow
import React from 'react';
import { connect } from 'react-redux';
import EditorialModal from 'brastrap/editorial/editorial-modal/EditorialModal';
import { openLayer } from 'shared/actions/layer';
import { closeModal } from 'shared/actions/modal';
import { dataLayerEditorialModal } from 'shared/utils/data-layer';

type Props = {
  containers: Array<Container>,
  modalId: string,
  isOpen: boolean,
};

const mapStateToProps = ({ modal: { content, queue }, layer }, ownProps) => {
  const modalId = queue[0];
  const modalContent = content[modalId];

  const isOpen = layer.target === modalId && layer.isVisible;

  return {
    ...ownProps,
    ...modalContent,
    isOpen,
    modalId,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,

  onOpen: id => {
    dataLayerEditorialModal('open', stateProps.name);

    dispatchProps.dispatch(
      openLayer({
        closeOnOutsideClick: stateProps.closeOnOutsideClick,
        onClose: () => dispatchProps.onClose(id, stateProps.redisplayTime),
        target: id,
      })
    );
  },

  onClose: (id, redisplayTime) => {
    dataLayerEditorialModal('close', stateProps.name);

    dispatchProps.dispatch(closeModal(id, redisplayTime));
  },
});

const WrappedEditorialModal = (props: Props) => {
  if (!props.containers.length) {
    return null;
  }

  return <EditorialModal {...props} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(WrappedEditorialModal);
