const namespace = 'bravissimo/add-gift-voucher-payment/';

export const ADD_GIFT_VOUCHER_PAYMENT_REQUEST = `${namespace}ADD_GIFT_VOUCHER_PAYMENT_REQUEST`;
export const ADD_GIFT_VOUCHER_PAYMENT_FAILURE = `${namespace}ADD_GIFT_VOUCHER_PAYMENT_FAILURE`;
export const ADD_GIFT_VOUCHER_PAYMENT_SUCCESS = `${namespace}ADD_GIFT_VOUCHER_PAYMENT_SUCCESS`;
export const GET_GIFT_VOUCHER_PAYMENT_REQUEST = `${namespace}GET_GIFT_VOUCHER_PAYMENT_REQUEST`;
export const GET_GIFT_VOUCHER_PAYMENT_FAILURE = `${namespace}GET_GIFT_VOUCHER_PAYMENT_FAILURE`;
export const GET_GIFT_VOUCHER_PAYMENT_SUCCESS = `${namespace}GET_GIFT_VOUCHER_PAYMENT_SUCCESS`;
export const ADD_GIFT_VOUCHER_PAYMENT_CLOSE_MESSAGES = `${namespace}ADD_GIFT_VOUCHER_PAYMENT_CLOSE_MESSAGES`;
export const ADD_GIFT_VOUCHER_PAYMENT_MODAL_OPEN = `${namespace}ADD_GIFT_VOUCHER_PAYMENT_MODAL_OPEN`;
export const ADD_GIFT_VOUCHER_PAYMENT_MODAL_CLOSED = `${namespace}ADD_GIFT_VOUCHER_PAYMENT_MODAL_CLOSED`;
export const ADD_GIFT_VOUCHER_PAYMENT_REFERENCE_COMPLETE = `${namespace}ADD_GIFT_VOUCHER_PAYMENT_REFERENCE_COMPLETE`;
