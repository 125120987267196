// @flow
import React from 'react';
import { connect } from 'react-redux';
import Ellipsis from 'brastrap/common/ellipsis/Ellipsis';
import { CSSTransitionGroup } from 'react-transition-group';

import { applyModifiers } from './BackdropContainer';
import BodyClass from '../components/BodyClass';

type Props = {
  isVisible: boolean,
  message: string,
};

const Overlay = ({ message, isVisible }: Props) => {
  const content = isVisible ? (
    // Key is used by react transition group
    <BodyClass key="overlay" className="is-layer">
      <div className={applyModifiers('u-backdrop', ['dark'])}>
        <div className="u-overlay__content">
          <div className="u-overlay__message u-center">{message}</div>
          <Ellipsis modifiers={['large', 'white']} />
        </div>
      </div>
    </BodyClass>
  ) : null;
  // Need to always render the transition group, and add or remove the content
  // for transitions to work
  return (
    <CSSTransitionGroup
      transitionName="u-overlay"
      transitionAppear
      transitionAppearTimeout={800}
      transitionEnterTimeout={800}
      transitionLeaveTimeout={300}
    >
      {content}
    </CSSTransitionGroup>
  );
};

const mapStateToProps = ({ overlay }) => ({
  ...overlay,
});

export default connect(mapStateToProps)(Overlay);
