import React from 'react';

const SvgIcon = () => (
  <svg
    className="c-icon__glyph"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 240 240"
    role="img"
  >
    <g transform="rotate(45, 120, 120)">
      <rect x="-20" y="110" width="280" height="24" rx="12" />
      <rect x="110" y="-20" width="24" height="280" rx="12" />
    </g>
  </svg>
);

export default SvgIcon;
