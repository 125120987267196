// @flow
import React from 'react';
import Link from 'brastrap/containers/link/LinkContainer';
import DropdownMenu from 'brastrap/common/dropdown-menu/DropdownMenu';
import Icon from 'brastrap/common/icon/Icon';
import Popover from 'brastrap/common/popover/Popover';

type Item = {
  id: string,
  label: string,
  url: Url,
  onClick?: (SyntheticEvent<HTMLAnchorElement>) => void,
};

type ItemProps = {
  className: string,
  href?: Url,
  onClick?: (SyntheticEvent<HTMLAnchorElement>) => void,
};

export type ToolShape = {
  count: number,
  countContext: {
    one: string,
    other: string,
  },
  hide: string,
  icon: string,
  label: string,
  labelled: Labelled,
  menu?: string,
  onClick?: (SyntheticEvent<HTMLAnchorElement>) => void,
  url: Url,
  dropdown: Array<Item>,
  isVisible: boolean,
  component: () => void,
  onHide: (SyntheticEvent<HTMLElement>) => void,
  onShow: (SyntheticEvent<HTMLAnchorElement>) => void,
};

type Props = {
  tool: ToolShape,
  locale: string,
};

type State = {
  dropdownVisible: boolean,
};

class Tool extends React.Component<Props, State> {
  iconElement: ?HTMLSpanElement;

  /**
   * @param {Object} props Received properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { dropdownVisible: false };
  }

  toggleDropdown = () => {
    this.setState({
      dropdownVisible: !this.state.dropdownVisible,
    });
  };

  openDropdown = () => {
    this.setState({
      dropdownVisible: true,
    });
  };

  closeDropdown = () => {
    this.setState({
      dropdownVisible: false,
    });
  };

  /**
   *
   * @param {Object} itemList
   * @return {XML}
   */
  buildDropdownList(itemList: Array<Item>) {
    return (
      <div className="c-menu">
        <ul className="c-menu__items">
          {itemList.map(item => {
            const itemProps: ItemProps = {
              className: 'c-menu__label',
            };

            itemProps.onClick = e => {
              if (item.onClick) {
                item.onClick(e);
              }
              this.toggleDropdown();
            };

            if (item.url) {
              itemProps.href = item.url;
            }

            return (
              <li key={item.id} className="c-menu__item">
                <Link {...itemProps}>{item.label}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  /**
   *
   * @return {XML}
   */
  render() {
    const tool = this.props.tool;

    let liClass = `c-toolbar__item ${
      this.props.locale.selected === 'en-US' ? 'c-toolbar__item--usOnly' : ''
    }`;

    const aProps = {};

    const iconProps: {
      count?: number,
      countContext?: { one: string, other: string },
      icon: string,
      label: string,
      labelled: Labelled,
      refCallback?: () => void,
    } = {
      icon: tool.icon,
      label: tool.label,
      labelled: tool.labelled,
    };

    if (tool.hide) {
      liClass += ` u-hidden@${tool.hide}`;
    }

    if (Number.isInteger(tool.count)) {
      aProps.className = 'c-counter';
      iconProps.count = tool.count;
      iconProps.countContext = tool.countContext;
    }

    if (tool.menu) {
      aProps['data-menu'] = tool.menu;
    }

    let dropdown = null;
    if (tool.dropdown && tool.dropdown && tool.dropdown.length > 0) {
      aProps.role = 'button';
      aProps.onClick = this.toggleDropdown;

      // We need to get a DOM element rendered by <Icon>. We could store a ref to the <a>
      // element, but any <Overlay> is rendered in an incorrect position. A ref to <Icon>
      // makes no sense because it does not, in itself, render a node to the DOM.
      iconProps.refCallback = el => {
        this.iconElement = el;
      };

      dropdown = (
        <Popover
          onRootClick={this.toggleDropdown}
          placement="below"
          show={this.state.dropdownVisible}
          style={{ marginTop: 10 }}
          target={this.iconElement}
        >
          {this.buildDropdownList(tool.dropdown)}
        </Popover>
      );
    } else if (tool.component) {
      aProps.role = 'button';
      aProps.onClick = tool.isVisible ? tool.onHide : tool.onShow;

      dropdown = (
        <DropdownMenu show={tool.isVisible} onRootClose={tool.onHide}>
          {tool.component()}
        </DropdownMenu>
      );
    } else {
      if (tool.url) {
        aProps.href = tool.url;
      }

      if (tool.onClick) {
        aProps.onClick = tool.onClick;
      }
    }

    return (
      <li className={liClass}>
        <Link {...aProps}>
          <Icon {...iconProps} />
        </Link>
        {dropdown}
      </li>
    );
  }
}

export default Tool;
