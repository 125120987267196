import { combineReducers } from 'redux';
import * as addresses from './addresses';
import * as loyalty from './loyalty';
import * as payment from './payment';
import * as signup from './sign-up';
import * as orders from './orders';

export const account = combineReducers({
  ...addresses,
  ...loyalty,
  ...payment,
  ...signup,
  ...orders,
});

export default account;
