// @flow
import {
  PUSH,
  REPLACE,
  ROUTING_FUNCTION_EXECUTED,
  CHANGE_LOCATION,
} from '../../constants/app';
import { type Actions } from '../../actions/app/routing';

export type State = {|
  needsLocationUpdate: boolean,
  nextLocation: ?Url,
  navigationFunction: 'push' | 'replace', // | 'goBack' | 'goForward' - These may be used in the future
  ...RouterLocation,
  url: ?Url, // Includes search string
|};

const navigationInitialState = {
  needsLocationUpdate: false,
  nextLocation: null,
  navigationFunction: 'push',
};

const initialState: State = {
  // Not spreading in navigationInitialState because it angers Flow
  needsLocationUpdate: false,
  nextLocation: null,
  navigationFunction: 'push',
  url: null,
  hash: '',
  pathname: '',
  query: {},
  search: '',
  state: null,
};

/**
 * Used to indicate when a client side navigation function needs to be called.
 * This state will be responded to in the NavigationHelper component.
 * @param {Object} state
 * @param {{ payload: any, type: String }} action
 * @returns {*}
 */
export function routing(state: State = initialState, action: Actions) {
  switch (action.type) {
    case PUSH:
      return {
        ...state,
        needsLocationUpdate: true,
        nextLocation: action.payload.url,
        navigationFunction: 'push',
      };

    case REPLACE:
      return {
        ...state,
        needsLocationUpdate: true,
        nextLocation: action.payload.url,
        navigationFunction: 'replace',
      };

    case ROUTING_FUNCTION_EXECUTED:
      return {
        ...state,
        ...navigationInitialState,
      };

    case CHANGE_LOCATION:
      return {
        ...state,
        ...action.payload.location,
        url: action.payload.location.pathname + action.payload.location.search,
      };

    default:
      return {
        ...initialState,
        ...state,
      };
  }
}
