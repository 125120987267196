import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

import Button from 'brastrap/common/button/Button';
import { applyModifiers } from '../../utils';

const messages = defineMessages({
  addToBag: { id: 'actions.add-to-bag', defaultMessage: 'Add to bag' },
  adding: { id: 'actions.adding-to-bag', defaultMessage: 'Adding...' },
  addedToBag: { id: 'actions.added-to-bag', defaultMessage: 'Added' },
  outOfStock: { id: 'actions.out-of-stock', defaultMessage: 'Out of Stock' },
});

const withSpinner = label => (
  <span>
    <progress className={applyModifiers('c-activity', 'white small')} /> {label}
  </span>
);

/**
 * A stateful button component for adding items to the bag. It changes its label
 * from 'Add to bag' through 'Adding' to 'Added', depending on supplied props,
 * but then resets its label back to 'Add to bag' after a couple of seconds.
 */
class AddToBagButton extends React.Component {
  /**
   * Set the initial state.
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      showAddedLabel: false,
    };

    this.getAddToBagLabel = this.getAddToBagLabel.bind(this);
  }

  /**
   * Observes when a change in props indicates that an item has been added, and
   * sets a timeout to reset the button label.
   * @param {object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (this.props.isAdded === false && nextProps.isAdded === true) {
      this.setState({ showAddedLabel: true });
      setTimeout(() => this.setState({ showAddedLabel: false }), 2000);
    }
  }

  /**
   * Works out what label to use for the button.
   * @returns {string|React.Element}
   */
  getAddToBagLabel() {
    const {
      isFetching,
      isAdded,
      intl: { formatMessage },
      isOutOfStock,
    } = this.props;

    if (isOutOfStock) {
      return formatMessage(messages.outOfStock);
    }

    if (isFetching) {
      return withSpinner(formatMessage(messages.adding));
    }

    if (isAdded && this.state.showAddedLabel) {
      return formatMessage(messages.addedToBag);
    }

    return formatMessage(messages.addToBag);
  }

  /**
   * Renders the component.
   * @return {Object}
   */
  render() {
    return (
      <Button
        label={this.getAddToBagLabel()}
        onClick={this.props.onClick}
        modifiers={this.props.modifiers}
        disabled={this.props.useDisabledAttribute}
      />
    );
  }
}

AddToBagButton.propTypes = {
  isAdded: PropTypes.bool,
  isFetching: PropTypes.bool,
  isOutOfStock: PropTypes.bool,
  modifiers: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onClick: PropTypes.func.isRequired,
  intl: intlShape,
  useDisabledAttribute: PropTypes.bool,
};

AddToBagButton.defaultProps = {
  isAdded: false,
  isFetching: false,
  isOutOfStock: false,
  modifiers: [],
};

export default injectIntl(AddToBagButton);
