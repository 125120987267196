// @flow
// TODO flow type this file correctly.
import { CALL_API } from '../constants/api';
import {
  EDITORIAL_FORM_REQUEST,
  EDITORIAL_FORM_SUCCESS,
  EDITORIAL_FORM_FAILURE,
  VALIDATION,
  CLOSE_MESSAGES,
  SET_FORM_METADATA,
} from '../constants/editorial-form';

/**
 * @param {Object} payload - containing the details of the request
 * @returns {Object} An action containing all the necessary fields to make an API call
 */
export function apiSubmitEditorialForm(payload: Object) {
  return {
    actions: {
      start: submitEditorialFormStart,
      success: submitEditorialFormSuccess,
      error: submitEditorialFormFailure,
    },
    method: 'post',
    params: payload,
    type: CALL_API,
    url: 'editorialForm',
  };
}

/**
 * @param {Object} payload
 * @returns {{type: String}}
 */
export function submitEditorialFormStart(payload: Object) {
  return {
    payload: payload && payload.formKey,
    type: EDITORIAL_FORM_REQUEST,
  };
}

/**
 * @param {object} details
 * @returns {{type, payload: object}}
 */
export function submitEditorialFormSuccess(details: Object) {
  return {
    type: EDITORIAL_FORM_SUCCESS,
    payload: details,
  };
}

/**
 * @param {object} details
 * @returns {{type, error: boolean, payload: object}}
 */
export function submitEditorialFormFailure(details: Object) {
  return {
    type: EDITORIAL_FORM_FAILURE,
    error: true,
    payload: details,
  };
}

/**
 * @returns {{type}}
 */
export function closeMessages() {
  return {
    type: CLOSE_MESSAGES,
  };
}

/**
 * @param {boolean} isValid
 *
 * @returns {{type, payload: boolean}}
 */
export function formValidation(isValid: boolean) {
  return {
    type: VALIDATION,
    payload: isValid,
  };
}

/**
 * @param {object} payload
 *
 * @returns {{type, payload: string}}
 */
export function setFormMetadata(payload: Object) {
  return {
    type: SET_FORM_METADATA,
    payload,
  };
}
