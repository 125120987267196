import { HELP_REQUEST, HELP_SUCCESS, HELP_FAILURE } from '../constants/help';

export const initialState = {
  isFetching: false,
  data: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function help(state = initialState, action = {}) {
  switch (action.type) {
    case HELP_REQUEST:
      return {
        ...state,

        isFetching: true,
      };

    case HELP_SUCCESS:
      return {
        ...state,

        isFetching: false,
        data: action.payload,
      };

    case HELP_FAILURE:
      return {
        ...state,

        isFetching: false,
        message: action.payload,
      };

    default:
      return state;
  }
}
