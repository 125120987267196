// @flow
import { RECEIVE_PROMOTIONS } from '../constants/promotions';

type PromotionPayload = DatabasePromotion[];

type ReceivePromotionsAction = {
  type: typeof RECEIVE_PROMOTIONS,
  payload: PromotionPayload,
};

/**
 * @param {Object} promotions
 * @return {Object}
 */
export function receivePromotions(
  promotions: PromotionPayload
): ReceivePromotionsAction {
  return {
    type: RECEIVE_PROMOTIONS,
    payload: promotions,
  };
}

export type PromotionActions = ReceivePromotionsAction;
