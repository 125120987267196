// @flow
import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  type IntlShape,
} from 'react-intl';
import type { InputProps } from 'redux-form';
import Button from 'brastrap/common/button/Button';
import Link from 'brastrap/common/link/Link';
import { filterInputProps } from 'brastrap/common/field-input/FieldInput';

export type SearchProps = {
  active: boolean,
  focused: boolean,
  intl: IntlShape,
  onClearSearchPress: (SyntheticEvent<HTMLButtonElement>) => void,
  handleSubmit: (SyntheticEvent<HTMLFormElement>) => void,
  fields: {
    searchPhrase: InputProps,
  },
  onSearchBlur: (SyntheticEvent<HTMLInputElement>) => void,
  onSearchFocus: (SyntheticEvent<HTMLInputElement>) => void,
  onSearchKeyUp: (SyntheticEvent<HTMLInputElement>) => void,
  recentSearches: Array<{
    id: string,
    label: string,
    url: string,
  }>,
  suggestions: Array<{
    id: string,
    label: string,
    url: string,
  }>,
  isForMobile: boolean,
  onCloseDrawer: Function,
};

const messages = defineMessages({
  searchTitle: {
    id: 'header.search.main.title',
    defaultMessage: 'Product search',
  },
  placeholder: {
    id: 'header.search.input.placeholder',
    defaultMessage: 'Search by name, code or style',
  },
  searchAction: { id: 'header.search.action', defaultMessage: 'Go' },
  searchSuggestions: {
    id: 'header.search.suggestions.title',
    defaultMessage: 'Suggestions',
  },
  recentSearches: {
    id: 'header.search.recent.title',
    defaultMessage: 'Recent searches',
  },
  recentSearchesClear: {
    id: 'header.search.recent.clear.title',
    defaultMessage: 'Clear this list',
  },
});

const magGlassColour = (isMobile, searchPhrase) => {
  if (!isMobile && searchPhrase !== '') {
    return '#F4436C';
  } else if (isMobile && searchPhrase !== '') {
    return 'white';
  }
  return '#662653';
};

const Search = (props: SearchProps) => {
  const displaySuggestions = props.suggestions.length > 0;
  const displayRecentSearches = props.recentSearches.length > 0;
  let suggestionsTabIndex = -1;

  const magGlassColour = (isMobile, searchPhrase) => {
    if (!isMobile && searchPhrase !== '') {
      return '#F4436C';
    } else if (isMobile && searchPhrase !== '') {
      return 'white';
    }
    return '#662653';
  };

  if (props.active) {
    suggestionsTabIndex = 0;
  }

  return (
    <form
      onSubmit={props.handleSubmit}
      className={`c-header__search ${
        !props.isForMobile && props.intl.locale === 'en-US'
          ? 'c-header__search--usOnly'
          : ''
      }`}
      id="product-search"
      role="search"
    >
      <fieldset className="c-header__search__main">
        <legend className="u-hidden" htmlFor="product-search">
          <FormattedMessage {...messages.searchTitle} />
        </legend>

        <div
          className={`${
            props.intl.locale === 'en-US'
              ? 'c-header__search__container--usOnly'
              : ''
          } ${
            props.isForMobile
              ? 'c-header__search__container--mobile-usOnly'
              : ''
          }`}
        >
          <input
            {...filterInputProps(props.fields.searchPhrase)}
            className={`c-header__search__input ${
              props.intl.locale === 'en-US'
                ? 'c-header__search__input--usOnly'
                : ''
            } ${
              props.isForMobile ? 'c-header__search__input--mobile-usOnly' : ''
            }`}
            onBlur={props.onSearchBlur}
            onFocus={props.onSearchFocus}
            onKeyUp={props.onSearchKeyUp}
            placeholder={props.intl.formatMessage(messages.placeholder)}
            autoComplete="on"
            autoCorrect="on"
            type="search"
            aria-label="Product Search"
          />
          {props.intl.locale === 'en-US' ? (
            <button
              onClick={() => {
                props.onCloseDrawer();
              }}
              className={`${
                props.isForMobile ? 'c-header__search-button--usOnly' : ''
              } ${
                props.isForMobile && props.fields.searchPhrase.value
                  ? 'c-header__search-button--mobile-usOnly'
                  : ''
              }`}
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 240 240"
              >
                <path
                  fillRule="evenodd"
                  fill={magGlassColour(
                    props.isForMobile,
                    props.fields.searchPhrase.value
                  )}
                  d="M186 173l51 51c4 4 4 10 0 14-4 4-10 4-14 0L173 187c-18 15-42 25-67 25C47 211 0 163 0 106 0 48 48 0 106 0c58 0 106 48 106 106 0 25-9 49-25 67zM19 106c0-48 38-86 86-86 48 0 86 39 86 87S153 192 106 192c-48 0-86-39-86-87z"
                />
              </svg>
            </button>
          ) : null}
        </div>

        <button className="c-header__search__action" type="submit">
          <FormattedMessage {...messages.searchAction} />
        </button>

        <div
          className="c-popover c-header__search__suggestions"
          tabIndex={suggestionsTabIndex}
        >
          {displaySuggestions && (
            <section className="c-menu">
              <h1 className="c-menu__title">
                <FormattedMessage {...messages.searchSuggestions} />
              </h1>

              <ul className="c-menu__items">
                {props.suggestions.map(suggestion => (
                  <li key={suggestion.id} className="c-menu__item">
                    <Link
                      className="c-menu__label"
                      title={suggestion.label}
                      href="#suggestion"
                      rel="nofollow"
                    >
                      {suggestion.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
          )}

          {displayRecentSearches && (
            <section className="c-menu">
              <h1 className="c-menu__title">
                <FormattedMessage {...messages.recentSearches} />
                <Button modifiers="small" onClick={props.onClearSearchPress}>
                  <FormattedMessage {...messages.recentSearchesClear} />
                </Button>
              </h1>

              <ul className="c-menu__items">
                {props.recentSearches.map(item => (
                  <li key={item.id} className="c-menu__item">
                    <Link
                      title={item.label}
                      className="c-menu__label"
                      href="#recentSearch"
                      rel="nofollow"
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
          )}
        </div>
      </fieldset>
    </form>
  );
};

Search.defaultProps = {
  active: false,
  focused: false,
  onNavigationRequest: () => {},
  fields: { searchPhrase: {} },
  handleSubmit: () => {},
  onSearchBlur: () => {},
  onSearchFocus: () => {},
  onSearchKeyUp: () => {},
  recentSearches: [],
  suggestions: [],
};

export default injectIntl(Search);
