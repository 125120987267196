import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Drawer from 'brastrap/global/drawer/Drawer.jsx';
import Tag from 'brastrap/common/tag/Tag';
import Link from 'brastrap/containers/link/LinkContainer';
import Search from '../header/Search';
import { ADVICE, MY_ACCOUNT, SEARCH, SHOP } from '../../constants';

const DrawerNavigation = props => {
  const drawerProps = {
    id: props.currentTab ? props.currentTab : 'navigation',
    isVisible: props.isVisible,
    modifiers: ['left'],
    onClose: props.onClose,
    header: props.toolbar,
    locale: props.locale,
    search: props.search,
    currentTab: props.currentTab,
  };

  const buildAnchorProps = (item, key) => {
    const aProps = {
      className: 'c-drawer-nav__label',
      href: item.url,
      title: item.label,
    };

    if (item.isMegaMenu && props.onNavigationRequest) {
      aProps.onClick = e => {
        e.preventDefault();
        props.onNavigationRequest(item, key, props.isDesktop);
      };
    }

    return aProps;
  };

  const onTabSwitch = tabName => {
    let i = 0;
    const tabSections = document.getElementsByClassName(
      'c-drawer-nav__tab-section--usOnly'
    );
    const tabButtons = document.getElementsByClassName(
      'c-drawer-nav__tab--usOnly'
    );
    for (i = 0; i < tabSections.length; i++) {
      tabSections[i].style.display = 'none';
    }

    for (i = 0; i < tabButtons.length; i++) {
      tabButtons[i].classList.remove('c-drawer-nav__tab--selected-usOnly');
    }

    document.getElementById(`${tabName}Section`).style.display = 'block';
    document
      .getElementById(`${tabName}Tab`)
      .classList.add('c-drawer-nav__tab--selected-usOnly');
  };

  useEffect(() => {
    if (
      props.currentTab === MY_ACCOUNT ||
      props.currentTab === SEARCH ||
      props.currentTab === SHOP
    ) {
      onTabSwitch(props.currentTab);
    }
  }, [props.currentTab]);

  return (
    <Drawer {...drawerProps}>
      <nav
        className="c-drawer-nav"
        role="navigation"
        aria-labelledby="shop-menu"
      >
        <h1 className="u-hidden" id="shop-menu">
          <FormattedMessage
            id="drawer.nav.shop.title"
            defaultMessage="Shop online"
          />
        </h1>

        {props.locale.selected === 'en-US' && (
          <div
            className={`${
              props.locale.selected === 'en-US'
                ? 'c-drawer-nav__tabContainer--usOnly'
                : ''
            }`}
          >
            <button
              id={`${SHOP}Tab`}
              className="c-drawer-nav__tab--usOnly"
              onClick={() => {
                onTabSwitch(SHOP);
              }}
            >
              Shop
            </button>
            <button
              id={`${SEARCH}Tab`}
              className="c-drawer-nav__tab--usOnly"
              onClick={() => {
                onTabSwitch(SEARCH);
              }}
            >
              Search
            </button>
            <button
              id={`${ADVICE}Tab`}
              className="c-drawer-nav__tab--usOnly"
              onClick={() => {
                onTabSwitch(ADVICE);
              }}
            >
              Advice
            </button>
            <button
              id={`${MY_ACCOUNT}Tab`}
              className="c-drawer-nav__tab--usOnly"
              onClick={() => {
                onTabSwitch(MY_ACCOUNT);
              }}
            >
              My Account
            </button>
          </div>
        )}

        <div
          id={`${SHOP}Section`}
          className="c-drawer-nav__tab-section--usOnly"
        >
          <ul className="c-drawer-nav__items">
            {props.items &&
              props.items.map((item, key) => (
                <li
                  key={item.label.replace(/\s/g, '-')}
                  className="c-drawer-nav__item"
                >
                  <Link {...buildAnchorProps(item, key)}>
                    {item.showTag ? <Tag label={item.label} /> : item.label}
                    {item.columns && item.columns.length > 0 ? (
                      <span
                        className={`${
                          props.locale.selected === 'en-US'
                            ? 'c-drawer-nav__item-chevron--usOnly'
                            : ''
                        }`}
                      />
                    ) : null}
                  </Link>
                </li>
              ))}
          </ul>
        </div>

        <div
          id={`${SEARCH}Section`}
          className="c-drawer-nav__tab-section--usOnly"
          style={{ display: 'none' }}
        >
          {!props.isDesktop && (
            <Search
              isForMobile
              onCloseDrawer={() => {
                props.onClose();
              }}
              {...props.search}
            />
          )}
        </div>

        <div
          id={`${ADVICE}Section`}
          className="c-drawer-nav__tab-section--usOnly"
          style={{ display: 'none' }}
        >
          <ul className="c-drawer-nav__items">
            {props.headerLinks &&
              props.headerLinks.map((item, key) => (
                <li
                  key={item.label.replace(/\s/g, '-')}
                  className="c-drawer-nav__item"
                >
                  <Link {...buildAnchorProps(item, key)}>{item.label}</Link>
                </li>
              ))}
          </ul>
        </div>

        <div
          id={`${MY_ACCOUNT}Section`}
          className="c-drawer-nav__tab-section--usOnly"
          style={{ display: 'none' }}
        >
          <ul className="c-drawer-nav__items">
            {props.myAccountTabLinks &&
              props.myAccountTabLinks.map((item, key) => (
                <li
                  key={item.label.replace(/\s/g, '-')}
                  className="c-drawer-nav__item"
                >
                  <Link {...buildAnchorProps(item, key)}>{item.label}</Link>
                </li>
              ))}
          </ul>
        </div>
      </nav>

      {props.locale.selected === 'en-GB' && (
        <nav
          className="c-drawer-nav"
          role="navigation"
          aria-labelledby="shortcuts"
        >
          <h1 className="u-hidden" id="shortcuts">
            <FormattedMessage
              id="drawer.nav.title"
              defaultMessage="Shortcuts"
            />
          </h1>

          <ul className="c-drawer-nav__items">
            {props.links.map(link => {
              const aProps = {
                href: link.url ? link.url : '#',
                title: link.label,
              };

              if (link.onClick) {
                aProps.onClick = link.onClick;
              }

              return (
                <li
                  key={link.label.replace(/\s/g, '-')}
                  className="c-drawer-nav__item"
                >
                  <Link className="c-drawer-nav__label" {...aProps}>
                    {link.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      )}

      {props.children && props.locale.selected === 'en-GB' && (
        <nav
          className="c-drawer-nav"
          role="navigation"
          aria-labelledby="locale-switcher"
        >
          <h1 className="u-hidden" id="locale-switcher">
            <FormattedMessage
              id="drawer.nav.local.title"
              defaultMessage="Locale Switcher"
            />
          </h1>
          <div className="c-drawer-nav__items">{props.children}</div>
        </nav>
      )}
    </Drawer>
  );
};

DrawerNavigation.propTypes = {
  // Drawer basics
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,

  // Navigation requirements
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      showTag: PropTypes.bool,
      url: PropTypes.string.isRequired,
    })
  ),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      url: PropTypes.string,
    })
  ),
  onNavigationRequest: PropTypes.func,
  toolbar: PropTypes.element,
  children: PropTypes.arrayOf(PropTypes.element),
  locale: PropTypes.object,
  search: PropTypes.object,
  currentTab: PropTypes.string,
  isDesktop: PropTypes.bool,
  headerLinks: PropTypes.object,
  myAccountTabLinks: PropTypes.array,
};

DrawerNavigation.defaultProps = {
  isVisible: false,
  onClose: () => {},
  onNavigationRequest: () => {},
  items: [],
  links: [],
};

export default DrawerNavigation;
