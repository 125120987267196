// @flow
import pick from 'lodash/pick';
import { RECEIVE_PROMOTIONS } from '../constants/promotions';
import type { PromotionActions } from '../actions/promotions';

type State = {
  [promotionId: string]: Promotion,
};

const allowedFields = [
  'endDate',
  'id',
  'messages',
  'name',
  'promotionType',
  'promptAtPercentage',
  'publicName',
  'qualificationQuantity',
  'qualificationValue',
];

export const initialState = {};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function promotions(
  state: State = initialState,
  action: PromotionActions
) {
  switch (action.type) {
    case RECEIVE_PROMOTIONS: {
      const promotionsPayload = action.payload
        .map(promotion => pick(promotion, allowedFields))
        .reduce((promotionsObject, promotion) => {
          const newPromotions = {
            ...promotionsObject,
            [promotion.id]: promotion,
          };
          return newPromotions;
        }, {});
      return {
        ...state,
        ...promotionsPayload,
      };
    }
    default:
      return state;
  }
}
