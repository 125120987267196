const PREFIX = 'bravissimo/checkout/';

export const GET_DELIVERY_METHODS = `${PREFIX}GET_DELIVERY_METHODS`;
export const SET_ESTIMATED_DELIVERY_DATE = `${PREFIX}SET_ESTIMATED_DELIVERY_DATE`;
export const CONFIRM_ORDER_FAILURE = `${PREFIX}CONFIRM_ORDER_FAILURE`;
export const TOGGLE_DELIVERY_METHODS = `${PREFIX}TOGGLE_DELIVERY_METHODS`;
export const ADD_DELIVERY_ADDRESS = `${PREFIX}ADD_DELIVERY_ADDRESS`;
export const SET_CONFIRMED_ORDER = `${PREFIX}SET_CONFIRMED_ORDER`;
export const SAVE_ORDER_NUMBER = `${PREFIX}SAVE_ORDER_NUMBER`;
export const MISSING_ORDER_NUMBER = `${PREFIX}MISSING_ORDER_NUMBER`;
export const SAVE_GUEST_DETAILS = `${PREFIX}SAVE_GUEST_DETAILS`;
export const SET_AUTH_PAYMENT_CARD_MODAL_HTML = `${PREFIX}SET_AUTH_PAYMENT_CARD_MODAL_HTML`;
export const ADD_CUSTOMER_DETAILS = `${PREFIX}ADD_CUSTOMER_DETAILS`;
export const IGNORE_LOGIN_PROMPT = `${PREFIX}IGNORE_LOGIN_PROMPT`;
export const SET_DELIVERY_CHOICE = `${PREFIX}SET_DELIVERY_CHOICE`;
export const SET_GUEST_EMAIL = `${PREFIX}SET_GUEST_EMAIL`;
export const ADD_CLICK_AND_COLLECT_ADDRESS = `${PREFIX}ADD_CLICK_AND_COLLECT_ADDRESS`;
export const TOGGLE_ADD_ADDRESS_FORM = `${PREFIX}TOGGLE_ADD_ADDRESS_FORM`;
export const CUSTOMER_CHECKOUT_DATA_REQUEST = `${PREFIX}CUSTOMER_CHECKOUT_DATA_REQUEST`;
export const NO_DELIVERY_METHODS = `${PREFIX}NO_DELIVERY_METHODS`;
export const CUSTOMER_CHECKOUT_DATA_FAILURE = `${PREFIX}CUSTOMER_CHECKOUT_DATA_FAILURE`;
export const CHECKOUT_DATA_REQUEST = `${PREFIX}CHECKOUT_DATA_REQUEST`;
export const CHECKOUT_DATA_FAILURE = `${PREFIX}CHECKOUT_DATA_FAILURE`;
export const ORDER_SUBMITTED = `${PREFIX}ORDER_SUBMITTED`;
export const SET_ADYEN = `${PREFIX}SET_ADYEN`;
export const AUTH_3DS_FAILED = `${PREFIX}AUTH_3DS_FAILED`;
export const PROCESS_3D_AUTH = `${PREFIX}PROCESS_3D_AUTH`;
export const SHOW_US_DEFAULT_ADDRESS_ON_UK_SITE_ERROR = `${PREFIX}SHOW_US_DEFAULT_ADDRESS_ON_UK_SITE_ERROR`;
export const US_TAX_DATA_RECEIVED = `${PREFIX}US_TAX_DATA_RECEIVED`;
export const US_TAX_DATA_FAILURE = `${PREFIX}US_TAX_DATA_FAILURE`;
export const US_TAX_DATA_FAILURE_ADDRESS = `${PREFIX}US_TAX_DATA_FAILURE_ADDRESS`;
export const US_TAX_DATA_REQUEST = `${PREFIX}US_TAX_DATA_REQUEST`;
export const US_TAX_DATA_FAILURE_GENERAL_ERROR = `${PREFIX}US_TAX_DATA_FAILURE_GENERAL_ERROR`;
export const US_TAX_UNHANDLED_ERROR = `${PREFIX}US_TAX_UNHANDLED_ERROR`;
export const DISPLAY_UK_PAYMENT = `${PREFIX}DISPLAY_UK_PAYMENT`;

export const GUEST_CHECKOUT_STORAGE_KEY = 'guestCheckoutDetails';
