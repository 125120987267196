import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 200 200"
    role="img"
  >
    <path d="M95 151c3 4 8 4 12 0l90-90c3-3 3-9 0-11s-8-3-11 0L100 134 16 50c-3-3-9-3-11 0s-3 8 0 11l90 90z" />
  </svg>
);

export default SvgIcon;
