import {
  MASTECTOMY_VAT_EXEMPTION_REQUEST,
  MASTECTOMY_VAT_EXEMPTION_REQUEST_SUCCEEDED,
  MASTECTOMY_VAT_EXEMPTION_REQUEST_FAILED,
  CLOSE_MESSAGES,
} from '../constants/mastectomy-vat-exemption-request';

export const initialState = {
  isSending: false,
  isSent: false,
  failed: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} state
 */
export function mastectomyVatExemptionRequest(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case MASTECTOMY_VAT_EXEMPTION_REQUEST:
      return {
        ...state,

        isSending: true,
        failed: false,
      };

    case MASTECTOMY_VAT_EXEMPTION_REQUEST_SUCCEEDED:
      return {
        ...state,

        isSending: false,
        isSent: true,
        failed: false,
      };

    case MASTECTOMY_VAT_EXEMPTION_REQUEST_FAILED:
      return {
        ...state,

        isSending: false,
        failed: true,
      };

    case CLOSE_MESSAGES:
      return {
        ...state,

        isSending: false,
        isSent: false,
        failed: false,
      };

    default:
      return state;
  }
}
