import {
  REQUEST_FAILED,
  MAGAZINE_REQUESTED,
  REQUEST_SUCCEEDED,
  CLOSE_MESSAGES,
} from 'shared/constants/request-magazine';

export const initialState = {
  confirmationMessage: null,
  isSaving: false,
  errorMessage: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} state
 */
export function requestMagazineForm(state = initialState, action = {}) {
  switch (action.type) {
    case MAGAZINE_REQUESTED:
      return {
        ...state,

        isSaving: true,
        errorMessage: null,
        confirmationMessage: null,
      };

    case REQUEST_FAILED:
      return {
        ...state,

        isSaving: false,
        errorMessage: action.payload.message,
        confirmationMessage: null,
      };

    case REQUEST_SUCCEEDED:
      return {
        ...state,

        isSaving: false,
        errorMessage: null,
        confirmationMessage: action.payload.message,
      };

    case CLOSE_MESSAGES:
      return {
        ...state,

        confirmationMessage: null,
        errorMessage: null,
      };

    default:
      return state;
  }
}
