// @flow
// TODO: Upgrade Flow to a version that supports React hooks
// $FlowFixMeimport
import React, { useEffect, useState } from 'react';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Link from 'brastrap/containers/link/LinkContainer';
import type { Channel as Props } from './channel.types.js';

const Channel = (props: Props) => {
  const [useUSOnlyStyles, setUseUSOnlyStyles] = useState(false);

  const mainAction = props.actions && props.actions.length && props.actions[0];
  const hasDummyButton = !!mainAction;

  useEffect(() => {
    if (window.location.href.indexOf('/us/') > -1) {
      setUseUSOnlyStyles(true);
    } else {
      setUseUSOnlyStyles(false);
    }
  }, []);

  const channel = (
    <section
      className={`c-channel ${useUSOnlyStyles ? 'c-channel--usOnly' : ''}`}
    >
      <header
        className={`c-channel__header ${
          useUSOnlyStyles ? 'c-channel__header--usOnly' : ''
        }`}
      >
        {props.img && props.img.src && (
          <img
            className={`c-channel__img ${
              useUSOnlyStyles ? 'c-channel__img--usOnly' : ''
            }`}
            src={props.img.src}
            alt={props.img.alt}
            height="48"
          />
        )}
        {props.title && (
          <h2
            className={`c-channel__title ${
              useUSOnlyStyles ? 'c-channel__title--usOnly' : ''
            }`}
          >
            {props.title}
          </h2>
        )}
        <div
          className={`c-channel__desc ${
            useUSOnlyStyles ? 'c-channel__desc--usOnly' : ''
          }`}
        >
          {props.introduction && <MarkDown content={props.introduction} />}
        </div>
      </header>
      {props.actions.length && (
        <div
          className={`c-channel__actions ${
            useUSOnlyStyles ? 'c-channel__actions--usOnly' : ''
          }`}
        >
          {props.actions.map((action, key) => (
            <ButtonLink
              isDummyButton={hasDummyButton}
              modifiers={['filled', 'small']}
              key={key}
              {...action}
            />
          ))}
        </div>
      )}
    </section>
  );

  return mainAction && mainAction.link ? (
    <Link {...mainAction.link} href={mainAction.link.url}>
      {channel}
    </Link>
  ) : (
    channel
  );
};

Channel.defaultProps = {
  actions: [],
};

export default Channel;
