import {
  RESET_ALL_CHANGES,
  RESET_BACK_AND_CUP,
  RESET_CUP,
  RESET_SIZE_AND_CACHES,
  SET_BACK_CHANGE,
  SET_BACK_SIZE,
  SET_BACK_SIZES,
  SET_CACHED_BACK_SIZE,
  SET_CACHED_CUP_SIZE,
  SET_COUNTRY,
  SET_CUP_CHANGE,
  SET_CUP_SIZE,
  SET_CUP_SIZES,
  SET_GO_TO_STAGE_EIGHT,
  SET_NEXT_STAGE,
  SET_PART_2_SELECTED,
  SET_PREV_STAGE,
  SET_SELECTED,
  SET_TEMP_BACK_CACHE,
  SET_TEMP_CUP_CACHE,
  TOGGLE_PART_TWO,
  TOGGLE_QUIZ,
} from '../constants/size-quiz.js';

const initialState = {
  backChange: 0,
  backSize: null,
  backSizes: [],
  brand: false,
  buttonSelected: 0,
  cachedBackSize: null,
  cachedCupSize: null,
  country: null,
  cupChange: 0,
  cupSize: null,
  cupSizes: [],
  displayQuiz: false,
  goToStageEight: false,
  partTwoButtonSelected: 0,
  showPartTwo: false,
  tooltip: null,
  stageNumber: 0,
  tempCupCache: { cupSize: null, cupChange: 0 },
  tempBackCache: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export function sizeQuiz(state = initialState, { payload, type }) {
  switch (type) {
    case RESET_ALL_CHANGES:
      return {
        ...initialState,
        country: state.country,
      };
    case RESET_BACK_AND_CUP:
      return {
        ...state,
        backSize: payload.tempBackCache,
        cupSize: payload.tempCupCache,
        backChange: 0,
      };
    case RESET_CUP:
      return {
        ...state,
        cupChange: 0,
        cupSize: payload,
      };
    case RESET_SIZE_AND_CACHES:
      return {
        ...state,
        backSize: null,
        backChange: 0,
        cupChange: 0,
        cupSize: null,
        cachedBackSize: null,
        cachedCupSize: null,
        tempBackCache: null,
        tempCupCache: { cupSize: null, cupChange: 0 },
      };
    case SET_BACK_CHANGE:
      return {
        ...state,
        backChange: payload,
      };
    case SET_BACK_SIZE:
      return {
        ...state,
        backSize: payload,
      };
    case SET_BACK_SIZES:
      return {
        ...state,
        backSizes: payload,
      };
    case SET_CACHED_BACK_SIZE:
      return {
        ...state,
        cachedBackSize: payload,
      };
    case SET_CACHED_CUP_SIZE:
      return {
        ...state,
        cachedCupSize: payload,
      };
    case SET_COUNTRY:
      return {
        ...state,
        country: payload,
      };
    case SET_CUP_CHANGE:
      return {
        ...state,
        cupChange: payload,
      };
    case SET_CUP_SIZE:
      return {
        ...state,
        cupSize: payload,
      };
    case SET_CUP_SIZES:
      return {
        ...state,
        cupSizes: payload,
      };
    case SET_GO_TO_STAGE_EIGHT:
      return {
        ...state,
        goToStageEight: payload,
      };
    case SET_NEXT_STAGE:
      return {
        ...state,
        stageNumber: state.stageNumber + payload,
      };
    case SET_PART_2_SELECTED:
      return {
        ...state,
        partTwoButtonSelected: payload,
      };
    case SET_PREV_STAGE:
      return {
        ...state,
        stageNumber: state.stageNumber - payload,
      };
    case SET_SELECTED:
      return {
        ...state,
        buttonSelected: payload,
      };
    case SET_TEMP_BACK_CACHE:
      return {
        ...state,
        tempBackCache: payload,
      };
    case SET_TEMP_CUP_CACHE:
      return {
        ...state,
        tempCupCache: payload,
      };
    case TOGGLE_PART_TWO:
      return {
        ...state,
        showPartTwo: payload,
      };
    case TOGGLE_QUIZ:
      return {
        ...state,
        displayQuiz: payload,
      };
    default:
      return state;
  }
}
