import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 17.4 17.8"
  >
    <g>
      <circle cx="8.3" cy="5.4" r="4.5" />
      <path d="M16.5,17.3c0-4.1-3.6-7.4-8-7.4s-8,3.3-8,7.4" />
    </g>
  </svg>
);

export default SvgIcon;
