const namespace = 'bravissimo/modal';

export const EDITORIAL_MODAL_SUCCESS = `${namespace}/EDITORIAL_MODAL_SUCCESS`;
export const EDITORIAL_MODAL_UPDATE = `${namespace}/EDITORIAL_MODAL_UPDATE`;
export const ADD_TO_QUEUE = `${namespace}/ADD_TO_QUEUE`;
export const REMOVE_FROM_QUEUE = `${namespace}/REMOVE_FROM_QUEUE`;
export const CLEAR_QUEUE = `${namespace}/CLEAR_QUEUE`;
export const SET_REDISPLAY_TIME_REQUEST = `${namespace}/SET_REDISPLAY_TIME_REQUEST`;
export const SET_REDISPLAY_TIME_FAILURE = `${namespace}/SET_REDISPLAY_TIME_FAILURE`;
export const SET_REDISPLAY_TIME_SUCCESS = `${namespace}/SET_REDISPLAY_TIME_SUCCESS`;
