import React from 'react';

const SvgIcon = () => (
  <svg
    className="c-icon__glyph"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 240 240"
    role="img"
  >
    <path d="M10 46c0-9 7-16 16-16h188c9 0 16 7 16 16v138c0 9-7 16-16 16H26c-9 0-16-7-16-16V46zm130 134h66c2 0 4-2 4-4V54c0-2-2-4-4-4H34c-2 0-4 2-4 4v122c0 2 2 4 4 4h66l20 20 20-20zm-45 20h50l-25 25-25-25z" />
  </svg>
);

export default SvgIcon;
