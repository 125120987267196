// @flow
/* global window */
import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import Icon from 'brastrap/common/icon/Icon';
import { hexToRgb } from '../../utils';
import type { Props } from './read-more.types';

const DEFAULT_BACKGROUND_COLOUR = '#FFFFFF';

const messages = defineMessages({
  expand: { id: 'read-more.expand', defaultMessage: 'Read more' },
  collapse: { id: 'read-more.collapse', defaultMessage: 'Show less' },
});

type State = {
  expanded: boolean,
  fullHeight: number,
  needsHeightUpdate: boolean,
};

const getRgbaString = ({ r, g, b, a }) => `rgba(${r}, ${g}, ${b}, ${a})`;

class ReadMore extends Component<Props, State> {
  defaultProps = {
    backgroundColour: DEFAULT_BACKGROUND_COLOUR,
  };
  state = {
    expanded: false,
    fullHeight: 2000,
    needsHeightUpdate: true,
  };

  /**
   */
  componentDidMount() {
    if (window) {
      window.addEventListener('resize', () => {
        if (this.readMoreContent) {
          this.setState({ fullHeight: this.readMoreContent.scrollHeight });
        }
      });
    }
    setTimeout(() => {
      if (this.readMoreContent) {
        this.setState({ fullHeight: this.readMoreContent.scrollHeight });
      }
    }, 0);
  }

  readMoreContent = null;

  toggleExpand = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  /**
   * @return {*}
   */
  render() {
    const {
      backgroundColour = DEFAULT_BACKGROUND_COLOUR,
      collapseButtonText,
      collapsedHeight,
      collapseTextColour,
      content,
      expandButtonText,
      expandTextColour,
      intl: { formatMessage },
      parentBackgroundColour,
    } = this.props;

    const { expanded, fullHeight } = this.state;

    const rgbBackgroundColour = hexToRgb(
      parentBackgroundColour || backgroundColour
    );
    const topFadeRgba = { ...rgbBackgroundColour, a: 0.25 };
    const bottomFadeRgba = { ...rgbBackgroundColour, a: 0.9 };
    const gradient = `linear-gradient(to bottom, ${getRgbaString(
      topFadeRgba
    )}, ${getRgbaString(bottomFadeRgba)})`;
    const containerStyle: { backgroundColor?: string } = {};
    if (
      !parentBackgroundColour &&
      backgroundColour !== DEFAULT_BACKGROUND_COLOUR
    ) {
      containerStyle.backgroundColor = backgroundColour;
    }
    return (
      <div style={containerStyle} className="c-read-more__container">
        <div
          className="c-read-more__content"
          style={{ maxHeight: expanded ? fullHeight : collapsedHeight }}
          ref={el => {
            this.readMoreContent = el;
          }}
        >
          <MarkDown content={content} />
          {!expanded && (
            <div
              className="c-read-more__fade-overlay"
              style={{ backgroundImage: gradient }}
            />
          )}
        </div>
        <div
          className="c-read-more__button-text"
          onClick={this.toggleExpand}
          style={{
            color: expanded ? collapseTextColour : expandTextColour,
          }}
        >
          {expanded
            ? collapseButtonText || formatMessage(messages.collapse)
            : expandButtonText || formatMessage(messages.expand)}
          <br />
          <Icon
            icon="ornament-underline"
            className={
              expanded ? 'c-read-more__less-icon' : 'c-read-more__more-icon'
            }
            fillColour={expanded ? collapseTextColour : expandTextColour}
            size={{ width: 35, height: 29 }}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(ReadMore);
