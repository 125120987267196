// @flow

type CloseMessages = 'bravissimo/account/CLOSE_MESSAGES';
type SignUpFailed = 'bravissimo/account/SIGN_UP_FAILED';
type AccountSignup = 'bravissimo/account/ACCOUNT_SIGNUP';
type SaveAddressFailure = 'bravissimo/account/SAVE_ADDRESS_FAILURE';
type SaveAddressSuccess = 'bravissimo/account/SAVE_ADDRESS_SUCCESS';
type SaveAddressRequest = 'bravissimo/account/SAVE_ADDRESS_REQUEST';
type ReceivePersonalDetails = 'bravissimo/account/RECEIVE_PERSONAL_DETAILS';
type ReceiveDeliveryAddresses = 'bravissimo/account/RECEIVE_DELIVERY_ADDRESSES';
type DeletePaymentMethodFailure =
  'bravissimo/account/DELETE_PAYMENT_METHOD_FAILURE';
type DeletePaymentMethodRequest =
  'bravissimo/account/DELETE_PAYMENT_METHOD_REQUEST';
type PaymentMethodsFailure = 'bravissimo/account/PAYMENT_METHODS_FAILURE';
type PaymentMethodsRequest = 'bravissimo/account/PAYMENT_METHODS_REQUEST';
type ReceivePaymentMethods = 'bravissimo/account/RECEIVE_PAYMENT_METHODS';

export const RECEIVE_PAYMENT_METHODS: ReceivePaymentMethods =
  'bravissimo/account/RECEIVE_PAYMENT_METHODS';
export const PAYMENT_METHODS_REQUEST: PaymentMethodsRequest =
  'bravissimo/account/PAYMENT_METHODS_REQUEST';
export const PAYMENT_METHODS_FAILURE: PaymentMethodsFailure =
  'bravissimo/account/PAYMENT_METHODS_FAILURE';

export const DELETE_PAYMENT_METHOD_REQUEST: DeletePaymentMethodRequest =
  'bravissimo/account/DELETE_PAYMENT_METHOD_REQUEST';
export const DELETE_PAYMENT_METHOD_FAILURE: DeletePaymentMethodFailure =
  'bravissimo/account/DELETE_PAYMENT_METHOD_FAILURE';

export const RECEIVE_DELIVERY_ADDRESSES: ReceiveDeliveryAddresses =
  'bravissimo/account/RECEIVE_DELIVERY_ADDRESSES';
export const RECEIVE_PERSONAL_DETAILS: ReceivePersonalDetails =
  'bravissimo/account/RECEIVE_PERSONAL_DETAILS';

export const SAVE_ADDRESS_REQUEST: SaveAddressRequest =
  'bravissimo/account/SAVE_ADDRESS_REQUEST';
export const SAVE_ADDRESS_SUCCESS: SaveAddressSuccess =
  'bravissimo/account/SAVE_ADDRESS_SUCCESS';
export const SAVE_ADDRESS_FAILURE: SaveAddressFailure =
  'bravissimo/account/SAVE_ADDRESS_FAILURE';

export const ACCOUNT_SIGNUP: AccountSignup =
  'bravissimo/account/ACCOUNT_SIGNUP';
export const SIGN_UP_FAILED: SignUpFailed = 'bravissimo/account/SIGN_UP_FAILED';

export const CLOSE_MESSAGES: CloseMessages =
  'bravissimo/account/CLOSE_MESSAGES';
