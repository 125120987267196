// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Block from 'brastrap/editorial/block/Block';
import Link from 'brastrap/containers/link/LinkContainer';
import type { HelpBlock as Props } from './help-block.types.js';

const HelpBlock = (props: Props) => {
  let linkList;
  if (props.helpTopic && props.helpTopic.faqs) {
    const limit = 3;
    linkList = props.helpTopic.faqs
      .slice(0, props.helpTopic.featuredFaqs || limit)
      .map((faq, index) => (
        <li key={index}>
          <Link href={faq.faqLink} alt={faq.question}>
            {faq.question}
          </Link>
        </li>
      ));
  } else {
    linkList = props.faqLinks.map((faq, index) => (
      <li key={index}>
        <Link href={faq.faqLink} alt={faq.question}>
          {faq.question}
        </Link>
      </li>
    ));
  }
  return (
    <Block
      {...props}
      title={
        props.helpTopic && props.helpTopic.name
          ? props.helpTopic.name
          : props.name
      }
      modifiers={props.modifiers}
    >
      <ul>{linkList}</ul>
      {props.helpTopic && props.helpTopic.allFaqs && (
        <Link href={props.helpTopic.topicLink}>
          <strong>
            <FormattedMessage
              id="faqs.see-all-FAQS"
              defaultMessage="See all FAQs"
            />
          </strong>
        </Link>
      )}
    </Block>
  );
};

HelpBlock.defaultProps = {
  faqLinks: [],
  modifiers: [],
};

export default HelpBlock;
