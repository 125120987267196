// @flow
import { connect } from 'react-redux';
import ImageCard from 'brastrap/editorial/image-card/ImageCard';

import { openLayer, closeLayer } from 'shared/actions/layer';

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.layer.isVisible && state.layer.target === ownProps.id,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => dispatch(openLayer(ownProps.id)),
  onModalClose: () => dispatch(closeLayer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageCard);
