import PropTypes from 'prop-types';
import React from 'react';

const STYLE_PREFIX = 'c-fieldset';

const FieldSet = props => {
  let className = STYLE_PREFIX;
  props.modifiers.forEach(modifier => {
    className += ` ${STYLE_PREFIX}--${modifier}`;
  });

  let title = null;
  if (props.title) {
    let note = null;
    if (props.note) {
      note = <div className={`${STYLE_PREFIX}__note`}>{props.note}</div>;
    }

    title = (
      <legend>
        <div className={`${STYLE_PREFIX}__header`}>
          <h1
            className={`${STYLE_PREFIX}__title${
              props.strongTitle ? ` ${STYLE_PREFIX}__title--strong` : ''
            }`}
          >
            {props.title}
          </h1>
          {note}
        </div>
      </legend>
    );
  }

  return (
    <fieldset className={className} disabled={props.disabled}>
      {title}
      <div className={`${STYLE_PREFIX}__main`}>{props.children}</div>
    </fieldset>
  );
};

FieldSet.propTypes = {
  title: PropTypes.string,
  strongTitle: PropTypes.bool,
  note: PropTypes.string,
  disabled: PropTypes.bool,
  modifiers: PropTypes.array,
  children: PropTypes.node,
};

FieldSet.defaultProps = {
  title: null,
  note: null,
  disabled: false,
  strongTitle: true,
  modifiers: [],
};

export default FieldSet;
