// @flow
import React from 'react';
import Carousel from 'brastrap/common/carousel/Carousel';
import EditorialBlock from 'brastrap/editorial/editorial-block/EditorialBlock';
import type { Item } from 'brastrap/editorial/editorial-container/editorial-container.types.js';
import type { EditorialCarousel as Props } from './editorial-carousel.types.js';

const EditorialCarousel = (props: Props) => (
  <div className="c-editorial-carousel">
    <Carousel slidesToShow={props.slidesToShow}>
      {props.items.map((item: Item) => (
        <div key={item.id}>
          <EditorialBlock content={item} />
        </div>
      ))}
    </Carousel>
  </div>
);

EditorialCarousel.defaultProps = {
  items: [],
  slidesToShow: 1,
};

export default EditorialCarousel;
