import { OPEN_LAYER, CLOSE_LAYER } from '../constants/layer';

/**
 * Target is either a string containing the id of the layer to open.
 * Or an object containing the target and an onClose action.
 *
 * @param {string|{target: string, onClose: Function, modifiers: Array}} target
 * @returns {{type: string, payload: *}}
 */
export function openLayer(target) {
  return {
    type: OPEN_LAYER,
    payload: target,
  };
}

/**
 * @returns {{type: string}}
 */
export function closeLayer() {
  return {
    type: CLOSE_LAYER,
  };
}
