import { SET_SERVER_AND_PORT } from '../constants/app';

/**
 * @param {string} serverAndPort
 * @returns {{payload: {string}, type: {string}}}
 */
export function setServerAndPort(serverAndPort) {
  return {
    payload: serverAndPort,
    type: SET_SERVER_AND_PORT,
  };
}
