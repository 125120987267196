// @flow
import React from 'react';
import omit from 'lodash/omit';
import UtilityNavCollapsible from './UtilityNavCollapsible';
import Item from './UtilityNavItem';
import { applyModifiers } from '../../utils';
import type { NavOrLinks, UtilityNav as Props } from './utility-nav.types.js';

const UtilityNav = (props: Props) => {
  if (props.collapsible) {
    // I think this is going to be real marmite...
    // If it's collapsible wrap a stateful wrapper around the utility name to manage if it's open or not.
    // Obviously we have to omit the collapsible prop or this might happen:
    //   https://media.giphy.com/media/MrZTFRJ8F4n6/giphy.gif
    return <UtilityNavCollapsible {...omit(props, ['collapsible'])} />;
  }

  const {
    heading,
    hideHeading,
    id,
    links,
    modifiers,
    onHeadingClick,
    currentPage,
  } = props;

  const className = applyModifiers('c-utility-nav', modifiers);
  const headingClass = `c-utility-nav__title${hideHeading ? ' u-hidden' : ''}`;

  return (
    <nav className={className} aria-labelledby={id}>
      <a className={headingClass} id={id} onClick={onHeadingClick}>
        {heading}
      </a>
      <ul className="c-utility-nav__items">
        {links.map((linkOrUtilityNav: NavOrLinks) => {
          if (linkOrUtilityNav && linkOrUtilityNav.links) {
            // We have child links. Must be a nested utility nav
            // https://media.giphy.com/media/xlTwaFb20TVjW/giphy.gif
            return (
              <UtilityNav
                key={linkOrUtilityNav.id}
                {...linkOrUtilityNav}
                currentPage={props.currentPage}
                openNav={props.openNav}
              />
            );
          }

          const itemProps = { ...linkOrUtilityNav };
          const isCurrent = itemProps.id === currentPage;
          itemProps.modifiers = [];

          if (isCurrent) {
            itemProps.modifiers.push('is-current');
          }

          return <Item key={itemProps.id} {...itemProps} />;
        })}
      </ul>
    </nav>
  );
};

UtilityNav.defaultProps = {
  heading: '',
  id: 'utility-nav',
  links: [],
  modifiers: [],
  collapsible: false,
  onHeadingClick: () => {},
};

export default UtilityNav;
