import PropTypes from 'prop-types';
import React from 'react';
import isObject from 'lodash/isObject';
import Markdown from 'brastrap/editorial/markdown/Markdown';
import { applyModifiers, modifiersShape } from '../../utils';

const STYLE_PREFIX = 'c-field-choice';
const INVALID_CLASS = 'is-invalid';

const FieldChoice = props => {
  let className = applyModifiers(STYLE_PREFIX, props.choice.modifiers);

  let label = props.choice.label;

  // If label is defined and it has a markdown property then change to markdown
  if (label && label.markdown) label = <Markdown content={label.markdown} />;

  const inputProps = {
    className: 'c-field-choice__toggle',
    // If label is a react element then add the label property, else assume string.
    'data-label':
      isObject(props.choice.label) && props.choice.label.props
        ? props.choice.label.props.label
        : props.choice.label,
    id: `${props.name}-${props.choice.name}`,
    name: props.name,
    onChange: props.onChange,
    type: props.type,
    value: props.choice.name,
  };

  if (props.checked !== undefined) inputProps.checked = props.checked;
  if (props.disabled) inputProps.disabled = props.disabled;

  if (props.touched && props.invalid) {
    className += ` ${INVALID_CLASS}`;
  }

  return (
    <div className={className}>
      <input {...inputProps} />
      <label
        htmlFor={`${props.name}-${props.choice.name}`}
        className="c-field-choice__label"
      >
        <span className="c-field-choice__text">{label}</span>
        {props.choice.note && (
          <small className="c-field__note">{props.choice.note}</small>
        )}
        {props.required && <em className="c-field__required" />}
      </label>
    </div>
  );
};

const stringOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

FieldChoice.propTypes = {
  checked: PropTypes.bool,
  name: stringOrNumber,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
  choice: PropTypes.shape({
    name: stringOrNumber,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.object,
    ]),
    note: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.element,
    ]),
    modifiers: modifiersShape,
  }),
  onChange: PropTypes.func,
};

FieldChoice.defaultProps = {
  checked: false,
  name: 'radio',
  type: 'radio',
  choice: {},
  onChange: () => {},
};

export default FieldChoice;
