// @flow
import React, { cloneElement, type ComponentType } from 'react';
import isFunction from 'lodash/isFunction';
import AccordionItem from './AccordionItem';
import type { Accordion as AccordionItemProps } from './accordion.type';

type ActiveChildId = ?string;

type Props = {
  activeChild?: ActiveChildId,
  children: AccordionItemProps | Array<AccordionItemProps>,
  onToggle?: ActiveChildId => void,
  footerAccordion?: boolean,
};

type State = {
  activeChild: ActiveChildId,
};

class Accordion extends React.Component<Props, State> {
  static defaultProps: {
    onToggle: () => void,
  };

  static Item: ComponentType<AccordionItemProps>;

  /**
   * Set the initial state of the currently active child.
   * @param {Object} props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      activeChild: props.activeChild,
    };
  }

  /**
   * Update the state of the active child if the component has received a new property value.
   *
   * @param {Object} props
   */
  componentWillReceiveProps({ activeChild }: Props) {
    if (this.state.activeChild !== activeChild) {
      this.setState({ activeChild });
    }
  }

  /**
   * Toggles the active class on the child elements.
   * If the currently active child is the same one as the one that has just been clicked then assume we need to remove
   * the active class.
   *
   * @param {String} id
   */
  toggleActive(id: ActiveChildId) {
    const { activeChild } = this.state;
    const removeActiveClass = activeChild === id;
    const newActiveChildId = removeActiveClass ? null : id;
    this.setState({ activeChild: newActiveChildId });

    const { onToggle } = this.props;
    if (onToggle && isFunction(onToggle)) {
      onToggle(newActiveChildId);
    }
  }
  /**
   * @returns {XML}
   */
  render() {
    const { children, footerAccordion } = this.props;
    const { activeChild } = this.state;

    return (
      <div
        className={
          footerAccordion ? 'c-accordion--footer' : 'c-accordion__container'
        }
      >
        {React.Children.map(children, child =>
          cloneElement(child, {
            ...child.props,
            isActive: activeChild && child.props.id === activeChild,
            onClick: e => {
              if (!child.props.href) {
                e.preventDefault();
                this.toggleActive(child.props.id);
                child.props.onClick();
              }
            },
          })
        )}
      </div>
    );
  }
}

Accordion.Item = AccordionItem;

export default Accordion;
