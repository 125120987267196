import { createResponsiveStateReducer } from 'redux-responsive';
import { APP_BREAKPOINTS } from '../constants/responsive';
import extraFields from './browser/extra-fields';

/**
 * @param {string} [initialMediaType]
 * @returns {*}
 */
export default function createResponsiveReducer(initialMediaType) {
  return createResponsiveStateReducer(APP_BREAKPOINTS, {
    extraFields,
    initialMediaType,
  });
}
