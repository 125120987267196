// @flow
import React from 'react';
import pick from 'lodash/pick';
import { forceCheck } from 'react-lazyload';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import Image from 'brastrap/editorial/image/Image';
import StyledText, {
  getWrappingTag,
} from 'brastrap/editorial/styled-text/StyledText';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Link from 'brastrap/containers/link/LinkContainer';
import VideoContainer from 'brastrap/editorial/video-container/VideoContainer';
import RatioBox from 'brastrap/helpers/ratio-box/ratio-box';
import type { Pod as Props } from './pod.types.js';
import { applyModifiers, hexToRgb } from '../../utils';

const POD_STYLE_PREFIX = 'c-pod';
const BODY_STYLE_PREFIX = 'c-pod__body';
const MAIN_STYLE_PREFIX = 'c-pod__main';

const mapSizingToClass = sizing => {
  switch (sizing) {
    case 'Large':
      return 'large';

    case 'Medium':
      return 'medium';

    default:
      return null;
  }
};

class Pod extends React.Component<Props> {
  static defaultProps = {
    layout: [],
    actions: [],
  };
  /**
   * Manually trigger checking for elements in viewport
   */
  componentDidUpdate() {
    forceCheck();
  }

  /**
   * @returns {JSX}
   */
  render() {
    let podClassName = applyModifiers(
      POD_STYLE_PREFIX,
      mapSizingToClass(this.props.sizing)
    );
    const bodyProps = {
      className: applyModifiers(BODY_STYLE_PREFIX, this.props.layout),
    };
    const mainClassName = applyModifiers(
      MAIN_STYLE_PREFIX,
      this.props.showBackground ? 'background-color' : null
    );

    const textBackgroundStyle = {};
    if (this.props.textBackgroundColour) {
      const opacity = this.props.textBackgroundOpacity || 1;
      const rgb = hexToRgb(this.props.textBackgroundColour);
      textBackgroundStyle.backgroundColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
    }

    const isClickablePod =
      this.props.makeClickable && !!this.props.actions[0].link;

    let headerText;
    if (this.props.title) {
      headerText = this.props.title.map((item, index) => (
        <StyledText key={index} {...item} />
      ));
    }

    const TagName = getWrappingTag(this.props.title || [], 'h1');

    if (this.props.backgroundVideo) {
      podClassName += ` ${POD_STYLE_PREFIX}--video`;
    }

    let actions;
    if (this.props.actions && !this.props.buttonUnderPod) {
      actions = this.props.actions.map((action, index) => (
        <ButtonLink
          key={index}
          isDummyButton={isClickablePod}
          analyticsRef={
            this.props.actions.length > 1
              ? `${String(this.props.analyticsRef)}-${index + 1}`
              : this.props.analyticsRef
          }
          {...action}
        />
      ));
    }

    let cutout;
    if (this.props.cutout) {
      cutout = (
        <img
          className="c-pod__cutout"
          src={this.props.cutout.src}
          alt={this.props.cutout.alt}
        />
      );
    }

    const podContent = (
      <div>
        <section className={podClassName} style={{ ...this.props.styles }}>
          {(this.props.mobileImage || this.props.desktopImage) && (
            <Image
              {...this.props.desktopImage}
              smallScreen={pick(
                this.props.mobileImage,
                'height',
                'src',
                'width'
              )}
            />
          )}
          {this.props.backgroundVideo && (
            <RatioBox
              aspectRatio={
                this.props.backgroundVideo.width /
                this.props.backgroundVideo.height
              }
            >
              <VideoContainer
                modifiers={['pod']}
                play
                displayOverlay={false}
                video={{ ...this.props.backgroundVideo }}
              />
            </RatioBox>
          )}
          <div {...bodyProps}>
            <div className={mainClassName} style={textBackgroundStyle}>
              <header className="c-pod__header">
                <TagName className="c-pod__title">
                  <span className="c-pod__label">{this.props.label}</span>
                  <span className="u-hidden">:</span>
                  {headerText}
                </TagName>
                <div className="s-prose">
                  {this.props.introduction && (
                    <MarkDown content={this.props.introduction} />
                  )}
                </div>
              </header>
              {cutout}
              <div className="c-pod__actions">{actions}</div>
            </div>
          </div>
        </section>
        {this.props.buttonUnderPod
          ? (actions = this.props.actions.map((action, index) => (
              <ButtonLink
                key={index}
                isDummyButton={isClickablePod}
                analyticsRef={
                  this.props.actions.length > 1
                    ? `${String(this.props.analyticsRef)}-${index + 1}`
                    : this.props.analyticsRef
                }
                {...action}
              />
            )))
          : null}
      </div>
    );
    const action =
      isClickablePod && this.props.actions.length && this.props.actions[0];
    return action ? (
      <Link
        analyticsRef={this.props.analyticsRef}
        href={action.link && action.link.url}
      >
        {podContent}
      </Link>
    ) : (
      podContent
    );
  }
}

export default Pod;
