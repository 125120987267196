import PropTypes from 'prop-types';
/* eslint-env browser */
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';

const DropdownMenu = ({ children, onRootClose, show }) => (
  <div className="dropdown-menu">
    <CSSTransitionGroup
      transitionName="dropdown-menu"
      transitionEnterTimeout={200}
      transitionLeaveTimeout={200}
      transitionAppear
      transitionAppearTimeout={200}
    >
      {show && (
        <RootCloseWrapper key="wrapper" onRootClose={onRootClose}>
          {children}
        </RootCloseWrapper>
      )}
    </CSSTransitionGroup>
  </div>
);

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  onRootClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default DropdownMenu;
