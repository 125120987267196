// @flow
import { SET_SCHEMA } from '../constants/schema';
import type { Key, Schema } from '../actions/schema';

type State = { [Key]: Schema };
const initialState = {};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export default function schema(
  state: State = initialState,
  action: Object = {}
) {
  switch (action.type) {
    case SET_SCHEMA:
      return {
        ...state,
        [action.payload.key]: action.payload.schema,
      };

    default:
      return state;
  }
}
