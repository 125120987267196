/**
 * Middleware that makes calls to the api.
 *
 * Will ignore any action dispatched that doesn't contain a type equal to CALL_API.
 *
 * Requires actions for each request:
 * - start: for when the request is made. (optional)
 * - success: for when an api request is successful.
 * - error: obviously for when the api request is unsuccessful.
 *
 * Will send the JWT token if one is found in the store.
 */
import _ from 'lodash';
import axios from 'axios';
import { CALL_API, NOOP } from '../constants/api';
import { notAuthorised } from '../actions/auth';

const methods = ['get', 'post', 'put', 'delete'];
export const API_BASE = '/api/';

const noop = () => ({
  type: NOOP,
});

/**
 * @param {Array} actions
 * @param {String} action
 * @returns {Function} - Should return the action or a noop if no function exists.
 */
const getHandler = (actions, action) => (actions && actions[action]) || noop;

/**
 * Transforms a object into a URL-encoded string that can be sent in a GET URL.
 * Array values are encoded by appending each element under the same key.
 * @param {Object} params the parameters to encode
 * @return {string} the encoded string
 */
export function encodeParameters(params) {
  const encodeParam = key =>
    Array.isArray(params[key])
      ? params[key]
          .map(i => `${encodeURIComponent(key)}=${encodeURIComponent(i)}`)
          .join('&')
      : `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;

  return Object.keys(params).map(encodeParam).join('&');
}

/**
 * @param {Object} opts
 * @returns {Function}
 */
export default function apiMiddleware(opts) {
  const options = {
    apiBase: API_BASE,
    axiosAdapter: null,
    ...opts,
  };

  if (options.axiosAdapter) {
    axios.defaults.adapter = options.axiosAdapter;
  }

  return store => next => async action => {
    if (_.isUndefined(action.type) || action.type !== CALL_API) {
      return next(action);
    }

    const { basePath } = store.getState().app.config;
    const token = store.getState().auth && store.getState().auth.token;
    const { actions } = action;
    const onSuccess = getHandler(actions, 'success');
    const onError = getHandler(actions, 'error');
    const onStart = getHandler(actions, 'start');
    const method =
      methods.indexOf(action.method) >= 0 ? action.method : methods[0];

    store.dispatch(onStart(action.params));

    if (token) {
      // Ensure axios sends the request using the token
      axios.interceptors.request.use(config =>
        _.merge(config, {
          headers: {
            authorization: token,
          },
        })
      );
    }

    let url = (basePath + options.apiBase + action.url).replace(/\/\//g, '/');

    // For GET requests, append parameters as query string components, since
    // Axios doesn't do it automatically for us.
    if (
      method === 'get' &&
      action.params &&
      Object.keys(action.params).length > 0
    ) {
      url += `?${encodeParameters(action.params)}`;
    }

    try {
      const response = await axios[method](url, action.params);
      store.dispatch(onSuccess(response.data));
    } catch (error) {
      const { dispatch } = store;

      switch (true) {
        case error.response && error.response.status === 403:
          return dispatch(notAuthorised());

        case error.toJSON && error.toJSON().name === 'NetworkError':
          // TODO: i18n client-side network errors
          return dispatch(
            onError(
              'There was a problem contacting the server. Please try again later.'
            )
          );

        case !error.response:
          // TODO: i18n client-side unexplained server errors
          return dispatch(
            onError('Something unexpected happened. Please try again later.')
          );

        default:
          return dispatch(onError(error.response.data));
      }
    }

    return next(action);
  };
}
