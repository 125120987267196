/* eslint-env jest */
import { shouldDisplayRedirectionModal } from '../locale';

const LOCALE = {
  INTERNATIONAL: 'fr-FR',
  GB: 'en-GB',
  US: 'en-US',
};

const available = [
  {
    locale: LOCALE.GB,
  },
  {
    locale: LOCALE.US,
  },
];

const scenarios = {
  display: [
    {
      expected: LOCALE.GB,
      selected: LOCALE.US,
    },
    {
      explicit: LOCALE.GB,
      expected: LOCALE.GB,
      selected: LOCALE.US,
    },
    {
      expected: LOCALE.INTERNATIONAL,
      selected: LOCALE.US,
    },
    {
      explicit: LOCALE.GB,
      expected: LOCALE.INTERNATIONAL,
      selected: LOCALE.US,
    },
    {
      expected: LOCALE.US,
      selected: LOCALE.GB,
    },
    {
      explicit: LOCALE.US,
      expected: LOCALE.US,
      selected: LOCALE.GB,
    },
  ],
  hidden: [
    {
      expected: LOCALE.US,
      selected: LOCALE.US,
    },
    {
      explicit: LOCALE.GB,
      expected: LOCALE.US,
      selected: LOCALE.US,
    },
    {
      explicit: LOCALE.US,
      expected: LOCALE.US,
      selected: LOCALE.US,
    },
    {
      explicit: LOCALE.US,
      expected: LOCALE.GB,
      selected: LOCALE.US,
    },
    {
      explicit: LOCALE.US,
      expected: LOCALE.INTERNATIONAL,
      selected: LOCALE.US,
    },
    {
      explicit: LOCALE.GB,
      expected: LOCALE.US,
      selected: LOCALE.GB,
    },
    {
      expected: LOCALE.GB,
      selected: LOCALE.GB,
    },
    {
      explicit: LOCALE.US,
      expected: LOCALE.GB,
      selected: LOCALE.GB,
    },
    {
      explicit: LOCALE.GB,
      expected: LOCALE.GB,
      selected: LOCALE.GB,
    },
    {
      expected: LOCALE.INTERNATIONAL,
      selected: LOCALE.GB,
    },
    {
      explicit: LOCALE.US,
      expected: LOCALE.INTERNATIONAL,
      selected: LOCALE.GB,
    },
    {
      explicit: LOCALE.GB,
      expected: LOCALE.INTERNATIONAL,
      selected: LOCALE.GB,
    },
  ],
};

describe('shouldDisplayRedirectionModal', () => {
  it('Should not display if no expected locale', () => {
    const state = {
      available,
      selected: LOCALE.GB,
    };
    const actual = shouldDisplayRedirectionModal(state);
    expect(actual).toBe(false);
  });

  it('Should not display if no other locales are available', () => {
    const state = {
      available: [{ locale: LOCALE.GB }],
      expected: LOCALE.US,
      selected: LOCALE.GB,
    };
    const actual = shouldDisplayRedirectionModal(state);
    expect(actual).toBe(false);
  });

  scenarios.display.forEach(state => {
    it(`Should display for ${state.expected} customers ${
      state.explicit ? `who have explicit ${state.explicit} ` : ''
    }on
      the ${state.selected} website`, () => {
      const actual = shouldDisplayRedirectionModal({ available, ...state });
      expect(actual).toBe(true);
    });
  });

  scenarios.hidden.forEach(state => {
    it(`Should not display for ${state.expected} customers ${
      state.explicit ? `who have explicit ${state.explicit} ` : ''
    }on the ${state.selected} website`, () => {
      const actual = shouldDisplayRedirectionModal({ available, ...state });
      expect(actual).toBe(false);
    });
  });
});
