import {
  RECEIVE_SIDEBAR_CONTENT,
  GET_SIDEBAR_CONTENT_SUCCESS,
  GET_SIDEBAR_CONTENT_FAILURE,
  GET_SIDEBAR_CONTENT_REQUEST,
} from '../constants/sidebar';

export const initialState = {
  isFetching: false,
  content: [],
  settingId: null,
  pageId: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function sidebar(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SIDEBAR_CONTENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SIDEBAR_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case RECEIVE_SIDEBAR_CONTENT:
    case GET_SIDEBAR_CONTENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    default:
      return state;
  }
}
