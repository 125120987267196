import PropTypes from 'prop-types';
import React from 'react';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-bag__item';
const defaultModifiers = ['message'];

const BagItemMessage = ({ children, modifiers }) => (
  <tr
    className={applyModifiers(STYLE_PREFIX, [
      ...defaultModifiers,
      ...modifiers,
    ])}
  >
    <td colSpan={5}>{children}</td>
  </tr>
);

BagItemMessage.propTypes = {
  children: PropTypes.node,
  modifiers: PropTypes.array,
};

BagItemMessage.defaultProps = {
  modifiers: [],
};

export default BagItemMessage;
