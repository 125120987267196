import { connect } from 'react-redux';
import Grid from 'brastrap/helpers/l-grid/l-grid';
import { shouldHideEditorialContent } from 'shared/utils/utils';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  hideContent: shouldHideEditorialContent(state.browser.deviceIs, ownProps),
});

export default connect(mapStateToProps)(Grid);
