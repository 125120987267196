import { connect } from 'react-redux';
import Overlay from 'brastrap/editorial/overlay/Overlay';
import { shouldHideEditorialContent } from 'shared/utils/utils';

const mapStateToProps = ({ browser: { deviceIs } }, ownProps) => ({
  onDesktop: deviceIs.desktop,
  hideContent: shouldHideEditorialContent(deviceIs, ownProps),
});

export default connect(mapStateToProps)(Overlay);
