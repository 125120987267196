// @flow
import React from 'react';
import Collection, {
  type CollectionType,
} from 'brastrap/global/collection/Collection';

type CollectionsType = {
  id: string,
  label: string,
  collections: Array<{ ...CollectionType, id: string }>,
  hideLabel: boolean,
};

const Collections = ({
  id,
  label,
  collections,
  hideLabel,
}: CollectionsType) => (
  <nav className="c-collections" role="navigation" aria-labelledby={id}>
    <h1
      className={`c-collections__title${hideLabel ? ' u-hidden' : ''}`}
      id={id}
    >
      {label}
    </h1>
    {collections.map(collection => (
      <Collection {...collection} key={collection.id} />
    ))}
  </nav>
);

Collections.defaultProps = {
  id: 'collection',
  label: 'Collection',
  collections: [],
  hideLabel: false,
};

export default Collections;
