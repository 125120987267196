// @flow
import React from 'react';
import { connect } from 'react-redux';
import ZMag from 'brastrap/editorial/zmag/Zmag';
import { Zmag as ZmagType } from 'brastrap/editorial/zmag/zmag.types';

import Helmet from 'react-helmet';

type zmagConfigType = {
  viewerUrl: string,
};

type Props = {
  zmag: zmagConfigType,
  ...ZmagType,
};

const mapStateToProps = (
  {
    app: {
      config: { zmag },
    },
  },
  ownProps
) => ({
  zmag,
  ...ownProps,
});

const dataLayerZmags = event => {
  event.target.getPublicationID(getPublicationIDData => {
    event.target.getCurrentPages(getCurrentPagesData => {
      const zmags = {
        publicationId: getPublicationIDData.publicationID,
        firstPage: getCurrentPagesData.firstPage,
        lastPage: getCurrentPagesData.lastPage,
        productId: event.product && event.product.product_id,
        eventType: event.type,
      };

      if (typeof window !== 'undefined') {
        window.setDataLayer({
          event: 'zmagsInteraction',
          zmags,
        });
      }
    });
  });

  if (event.product) {
    event.target.registerProductDetailsViewed(event.product);
  }
};

const wrappedZMag = (props: Props) => {
  const hasZMagLoaded = () => !!(window && window.com && window.com.zmags);

  const loadZMagViewer = (newState, zmagconfig) => {
    if (!hasZMagLoaded()) {
      const zmagScript = (newState.scriptTags || []).find(
        script =>
          script && script.src && script.src.indexOf(zmagconfig.viewerUrl) > -1
      );
      if (zmagScript) {
        const scriptTag = window.document.querySelector(
          `script[src="${zmagconfig.viewerUrl}"]`
        );
        const originalOnload = scriptTag.onload ? scriptTag.onload : () => {};
        scriptTag.onload = () => {
          originalOnload();
        };
      }
    } else {
      const viewer = new window.com.zmags.api.Viewer();
      viewer.setPublicationID(props.publicationId);
      viewer.setParentElementID(props.publicationId);

      viewer.oncurrentpageschange = event => dataLayerZmags(event);
      viewer.onproductlinkactivate = event => dataLayerZmags(event);
      viewer.onproductwidgetactivate = event => dataLayerZmags(event);

      viewer.show();
    }
  };

  const zmagProps = {
    id: props.publicationId,
    ...props,
  };

  return (
    <div>
      <Helmet
        script={[{ src: props.zmag.viewerUrl, type: 'text/javascript' }]}
        onChangeClientState={newState => loadZMagViewer(newState, props.zmag)}
      />
      <ZMag {...zmagProps} />
    </div>
  );
};

export default connect(mapStateToProps)(wrappedZMag);
