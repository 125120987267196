// @flow
import React from 'react';
import Page from 'brastrap/global/page/Page';
import config from 'config';
import { renderRoutes } from '@bravissimolabs/react-router-config';
import Masthead from './MastheadContainer';
import Footer from './FooterContainer';
import Bag from './BagContainer';
import Backdrop from './BackdropContainer';
import Overlay from './OverlayContainer';
import RoutingHelper from '../components/RoutingHelperContainer';
import { setServerAndPort } from '../actions/app';
import { receivePaymentSessionScriptUrl } from '../actions/add-payment-option';

type Props = {
  route: MatchedRoute,
};

const App = (props: Props) => (
  <div>
    <RoutingHelper />
    <Masthead />
    <Page>{renderRoutes(props.route.routes)}</Page>
    <Footer />
    <Backdrop />
    <Overlay />
  </div>
);

App.populateStore = async (store, props, req) => {
  await Masthead.populateStore(store, props, req);
  await Footer.populateStore(store, props, req);
  await Bag.populateStore(store, props, req);
  store.dispatch(setServerAndPort(`${req.protocol}://${req.headers.host}`));
  // Dispatching this here because checkout page is not server side rendered
  // and the TNS script itself needs time to load
  store.dispatch(
    receivePaymentSessionScriptUrl(config.payment.paymentSessionScriptUrl)
  );
};

export default App;
