import React from 'react';

const SvgIcon = () => (
  <svg
    className="c-icon__glyph"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 200 200"
    role="img"
  >
    <rect x="0" y="16" width="200" height="16" rx="8" />
    <rect x="0" y="92" width="200" height="16" rx="8" />
    <rect x="0" y="168" width="200" height="16" rx="8" />
  </svg>
);

export default SvgIcon;
