import React from 'react';

const SvgIcon = () => (
  <svg
    className="c-rating__stars"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 200"
  >
    <path
      fillRule="evenodd"
      d="M0 0h1000v200H0V0zm100 155l-60 35 15-65L5 80l70-5L100 10l25 65L195 80l-50 45 15 65-60-35zm200 0l-60 35 15-65L205 80l70-5L300 10l25 65L395 80l-50 45 15 65-60-35zm200 0l-60 35 15-65L405 80l70-5L500 10l25 65L595 80l-50 45 15 65-60-35zm200 0l-60 35 15-65L605 80l70-5L700 10l25 65L795 80l-50 45 15 65-60-35zm200 0l-60 35 15-65L805 80l70-5L900 10l25 65L995 80l-50 45 15 65-60-35z"
    />
  </svg>
);

export default SvgIcon;
