import { switchCountry } from '../../bra-fit-finder/utils/helpers';
import {
  RESET_ALL_CHANGES,
  RESET_BACK_AND_CUP,
  RESET_CUP,
  RESET_SIZE_AND_CACHES,
  SET_BACK_CHANGE,
  SET_BACK_SIZE,
  SET_BACK_SIZES,
  SET_CACHED_BACK_SIZE,
  SET_CACHED_CUP_SIZE,
  SET_COUNTRY,
  SET_CUP_SIZE,
  SET_CUP_SIZES,
  SET_CUP_CHANGE,
  SET_GO_TO_STAGE_EIGHT,
  SET_NEXT_STAGE,
  SET_PART_2_SELECTED,
  SET_PREV_STAGE,
  SET_SELECTED,
  SET_TEMP_BACK_CACHE,
  SET_TEMP_CUP_CACHE,
  TOGGLE_PART_TWO,
  TOGGLE_QUIZ,
} from '../constants/size-quiz';

/**
 * @returns {function(*): *}
 */
export const resetAllChanges = () => ({
  type: RESET_ALL_CHANGES,
});

/**
 * @param {string} tempBackCache
 * @param {string} tempCupCache
 * @returns {function(*): *}
 */
export const resetBackAndCup = (tempBackCache, tempCupCache) => {
  return {
    type: RESET_BACK_AND_CUP,
    payload: { tempBackCache, tempCupCache },
  };
};

/**
 * @param {string} cupSize
 * @returns {function(*): *}
 */
export const resetCup = cupSize => ({
  type: RESET_CUP,
  payload: cupSize,
});

/**
 * @returns {function(*): *}
 */
export const resetSizeAndCaches = () => ({
  type: RESET_SIZE_AND_CACHES,
});

/**
 * @param {number} numberOfSizes
 * @returns {function(*): *}
 */
export const setBackChange = numberOfSizes => ({
  type: SET_BACK_CHANGE,
  payload: numberOfSizes,
});

/**
 * @param {number} size
 * @returns {function(*): *}
 */
export const setBackSize = size => ({
  type: SET_BACK_SIZE,
  payload: size,
});

/**
 * @param {array} sizes
 * @returns {function(*): *}
 */
export const setBackSizes = sizes => ({
  type: SET_BACK_SIZES,
  payload: sizes,
});

/**
 * @param {number} size
 * @returns {function(*): *}
 */
export const setCachedBackSize = size => ({
  type: SET_CACHED_BACK_SIZE,
  payload: size,
});

/**
 * @param {string} size
 * @returns {function(*): *}
 */
export const setCachedCupSize = size => ({
  type: SET_CACHED_CUP_SIZE,
  payload: size,
});

/**
 * @param {string} country
 * @returns {function(*): *}
 */
export const setCountry = country => ({
  type: SET_COUNTRY,
  payload: country,
});

/**
 * @param {number} numberOfSizes
 * @returns {function(*): *}
 */
export const setCupChange = numberOfSizes => ({
  type: SET_CUP_CHANGE,
  payload: numberOfSizes,
});

/**
 * @param {string} size
 * @returns {function(*): *}
 */
export const setCupSize = size => ({
  type: SET_CUP_SIZE,
  payload: size,
});

/**
 * @param {striarrayng} size
 * @returns {function(*): *}
 */
export const setCupSizes = size => ({
  type: SET_CUP_SIZES,
  payload: size,
});

/**
 * @param {boolean} payload
 * @returns {function(*): *}
 */
export const setGoToStageEight = payload => ({
  type: SET_GO_TO_STAGE_EIGHT,
  payload: payload,
});

/**
 * @param {number} numberOfSteps
 * @returns {function(*): *}
 */
export const setNextStage = numberOfSteps => ({
  type: SET_NEXT_STAGE,
  payload: numberOfSteps,
});

/**
 * @param {number} id
 * @returns {function(*): *}
 */
export const setPartTwoSelected = id => ({
  type: SET_PART_2_SELECTED,
  payload: id,
});

/**
 * @param {number} numberOfSteps
 * @returns {function(*): *}
 */
export const setPrevStage = numberOfSteps => ({
  type: SET_PREV_STAGE,
  payload: numberOfSteps,
});

/**
 * @param {number} id
 * @returns {function(*): *}
 */
export const setSelected = id => ({
  type: SET_SELECTED,
  payload: id,
});

/**
 * @param {object} payload
 * @returns {function(*): *}
 */
export const setTempBackCache = payload => ({
  type: SET_TEMP_BACK_CACHE,
  payload: payload,
});

/**
 * @param {object} payload
 * @returns {function(*): *}
 */
export const setTempCupCache = payload => ({
  type: SET_TEMP_CUP_CACHE,
  payload: payload,
});

/**
 * Show Second Part of Question
 *
 * @param {string} country
 * @returns {function(*): *}
 */
export const toggleCountry = country => {
  const newCountry = switchCountry(country);
  return {
    type: SET_COUNTRY,
    payload: newCountry,
  };
};
/**
 * Show Second Part of Question
 *
 * @param {boolean} payload
 * @returns {function(*): *}
 */
export const togglePartTwo = payload => ({
  type: TOGGLE_PART_TWO,
  payload: payload,
});

/**
 * @param {boolean} payload
 * @returns {function(*): *}
 */
export const toggleQuiz = payload => ({
  type: TOGGLE_QUIZ,
  payload: payload,
});
