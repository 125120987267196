// @flow
import Remarkable from 'remarkable';
import RemarkableReactRenderer from 'remarkable-react';
import Link from 'brastrap/containers/link/LinkContainer';

// Most of this work has been "borrowed" from https://github.com/HHogg/remarkable-react/issues/6
// Allows us the ability to render our own markdown and link them to custom components on the fly.

type Markdown = string;

type Tag = string;
type Tags = Array<Tag>;
type TagOrTags = Tag | Tags;

type CustomComponents = {};
type Options = ?{};

const defaultOptions = {
  breaks: true,
};

const defaultCustomComponents = {
  a: Link,
};

/**
 * Registers a custom token to be parsed by RemarkableType,
 * then handed off to RemarkableType-React for rendering:
 * https://github.com/HHogg/remarkable-react/issues/6
 *
 * @param {Object} remarkable instance
 * @param {String} tag
 */
const addCustomMarkdownToken = (remarkable, tag: Tag) => {
  if (!remarkable || !tag || typeof tag !== 'string') return;

  remarkable.inline.ruler.push(tag, (state, silent) => {
    if (state.src.substr(state.pos, tag.length) !== tag) return false;
    if (!silent) {
      state.push({ type: tag, text: state.src, level: state.level });
    }
    state.pos += tag.length; // eslint-disable-line no-param-reassign
    return true;
  });
};

/**
 * @param {Object} remarkable
 * @param {Array|String} tags
 */
const addCustomMarkdownTokens = (remarkable, tags: TagOrTags) => {
  if (!remarkable || !tags || !tags.length) return;

  const tagsToAdd = Array.isArray(tags) ? tags : [tags];

  tagsToAdd.forEach(tag => {
    addCustomMarkdownToken(remarkable, tag);
  });
};

/**
 * Parses a customComponents object that contains tokens as keys, and custom React components as values.
 * For example:
 * {
 *   '%example1%': () => (<MyComponent>Example 1</MyComponent>),
 *   '%example2%': () => (<MyOtherComponent>Example 2</MyOtherComponent>)
 * }
 * Automatically registers the tokens with RemarkableType so that they can be parsed and rendered into their associated
 * components.
 *
 * @param {Object} remarkable
 * @param {Object} customComponents
 * @returns {Object} - custom markdown tokens
 */
const createTokensFromCustomComponents = (
  remarkable,
  customComponents: CustomComponents
) => {
  if (!remarkable || !customComponents) return {};

  const tokens = Object.keys(customComponents);

  addCustomMarkdownTokens(remarkable, tokens);

  return tokens.reduce((previous, tag) => {
    const tags = { ...previous };
    tags[tag] = tag;
    return tags;
  }, {});
};

/**
 * @param {String} markdown
 * @param {Object} customComponents
 * @param {Object} markdownOptions
 * @return {String}
 */
export default (
  markdown: Markdown,
  customComponents: CustomComponents = {},
  markdownOptions: Options = {}
) => {
  const options = {
    ...defaultOptions,
    ...markdownOptions,
  };

  const remarkable = new Remarkable(options);

  const customTokens = createTokensFromCustomComponents(
    remarkable,
    customComponents
  );

  const components = { ...defaultCustomComponents, ...customComponents };
  const tokens = { ...customTokens };

  remarkable.renderer = new RemarkableReactRenderer({
    components,
    tokens,
  });
  return remarkable.render(markdown);
};
