// @flow
import { cloneDeep } from 'lodash';
import {
  RECEIVE_SAVED_ITEMS_MAP,
  RECEIVE_DECORATED_SAVED_ITEMS,
  GET_SAVED_ITEMS_FAILURE,
  GET_SAVED_ITEMS_REQUEST,
  ADD_SAVED_ITEM_REQUEST,
  ADD_SAVED_ITEM_FAILURE,
  DELETE_SAVED_ITEM_REQUEST,
  DELETE_SAVED_ITEM_FAILURE,
  SAVE_PENDING_ITEM,
  RECEIVE_SIZES,
} from '../constants/saved-items';
import { LOGOUT_SUCCESS } from '../constants/auth';
import { type Actions } from '../actions/saved-items';

type State = {
  editedItem: ?string,
  itemMap: { [string]: boolean },
  decoratedItems: DecoratedSavedItem[],
  itemCount: number,
  loading: boolean,
  pendingItem: {},
};

const initialState = {
  editedItem: null,
  itemMap: {},
  itemCount: 0,
  pendingItem: {},
  decoratedItems: [],
  // Setting this as true as a way to detect if server side data populating has
  // taken place, but this will likely change as there is definitely a clearer
  // way to indicate this with state.
  loading: true,
};

const getEditedItem = (currentItemMap, nextItemMap) => {
  const currentItems = Object.keys(currentItemMap);
  const nextItems = Object.keys(nextItemMap);
  // If we do not have the same number of items, this cannot be an edit operation
  if (currentItems.length !== nextItems.length) {
    return null;
  }
  const changedItemArray = nextItems.filter(
    newItem => !currentItemMap[newItem]
  );
  return changedItemArray[0];
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function savedItems(state: State = initialState, action: Actions) {
  switch (action.type) {
    case RECEIVE_SAVED_ITEMS_MAP:
      return {
        ...state,
        itemMap: action.payload.itemMap,
        itemCount: action.payload.itemCount,
        editedItem: getEditedItem(state.itemMap, action.payload.itemMap),
      };

    case RECEIVE_DECORATED_SAVED_ITEMS:
      return {
        ...state,
        decoratedItems: action.payload.items,
        loading: false,
      };

    case RECEIVE_SIZES: {
      const decoratedItems = cloneDeep(state.decoratedItems);
      const { styleColourCode, itemIndex, sizes } = action.payload;
      const styleColour = decoratedItems[itemIndex].colours.find(
        item => item.styleColourCode === styleColourCode
      );
      styleColour.sizes = sizes;
      return {
        ...state,
        decoratedItems,
      };
    }

    case GET_SAVED_ITEMS_REQUEST:
    case ADD_SAVED_ITEM_REQUEST:
    case DELETE_SAVED_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_SAVED_ITEMS_FAILURE:
    case ADD_SAVED_ITEM_FAILURE:
    case DELETE_SAVED_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SAVE_PENDING_ITEM:
      return {
        ...state,
        pendingItem: action.payload,
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
