// @flow
import React from 'react';
import type { Item } from 'brastrap/editorial/editorial-container/editorial-container.types.js';
import * as elementTypes from './editorial-block-element-types';
import type { EditorialBlock as Props } from './editorial-block.types.js';
import { createDataRef } from '../../utils.js';

/*
 If your content isn't rendering, check that your content type is present
 in editorial-block-element-types.js
 */
const itemContentType = (item: Item) =>
  // This craziness below... We have typed some Contentful properties, including `_link`
  // however, it is typed as a `maybe`. Flow will not let us get the `type` property back
  // unless it can be proven that the `_link` object is legit.
  // eslint-disable-next-line no-underscore-dangle
  item._type || (item._link && item._link.type && item._link.type);

const EditorialBlock = (props: Props) => {
  const itemProps = {
    ...props.content,
    // We can't guarantee that the item being rendered is inside an editorial container,
    // so we'll make sure we set an analytics ref here
    analyticsRef: createDataRef({
      ...props.content,
      _type: itemContentType(props.content),
    }),
  };
  const contentType = props.contentType || itemContentType(props.content);
  const elementType = elementTypes[contentType];
  return props.content && elementType
    ? React.createElement(elementType, itemProps)
    : null;
};

EditorialBlock.defaultProps = {
  content: {},
};

export default EditorialBlock;
