import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import StyleSize from 'brastrap/promotional/style-size/StyleSize';
import Link from 'brastrap/containers/link/LinkContainer';
import Image from 'brastrap/containers/image/ImageContainer';

const DEFAULT_IMAGE_URL = 'https://placeholder.com/90x90?text=Awaiting+photo';

const Sku = props => {
  const { item, showBrandName } = props;

  const isGiftCard = item.styleCode === 'GV01' || item.skuId === 999999;
  const showQuantity = props.showQuantity && !isGiftCard;
  const showStyleSize = !isGiftCard;
  const showUpsell = props.upsell && !isGiftCard;

  // Handle old style names, and Gift_Voucher
  const name = _.replace(item.styleName, /_/g, ' ');

  const voucherFormat = gvFormat => {
    switch (gvFormat) {
      case 'Paper':
        return (
          <FormattedMessage
            id="voucher.paper-voucher"
            defaultMessage="Paper Voucher"
          />
        );
      case 'Email':
        return (
          <FormattedMessage id="voucher.e-voucher" defaultMessage="E-Voucher" />
        );
      default:
        return null;
    }
  };

  const imageUrl = item.imgUrl || DEFAULT_IMAGE_URL;

  const code =
    item.styleColourCode && item.size && item.size.size
      ? item.styleColourCode + item.size.size
      : item.styleCode;

  const dataRef = { 'data-sku': code };

  return (
    <div className="c-sku" {...dataRef}>
      <div className="c-sku__media">
        <Image
          className="c-sku__img"
          src={imageUrl}
          alt={item.styleCode}
          width={90}
        />
      </div>
      <div className="c-sku__main">
        <div className="c-sku__main-inner">
          <h3 className="c-sku__title">
            <small className="c-sku__code">{item.styleCode}</small>
            {showBrandName && (
              <span className="c-sku__brand">{item.brandName}</span>
            )}
            <span className="c-sku__name">{name}</span>
            {showQuantity && (
              <span className="c-sku__quantity">
                <FormattedMessage
                  id="bag.quantity"
                  defaultMessage="Quantity:"
                />
                {item.quantity}
              </span>
            )}
          </h3>

          {showStyleSize && (
            <p className="c-sku__style-size">
              <StyleSize
                colour={item.colourSwatch}
                style={item.colour}
                size={item.size && item.size.size}
              />
            </p>
          )}

          {item.gvFormat && (
            <div className="c-sku__voucher-format">
              {voucherFormat(item.gvFormat)}
            </div>
          )}

          {showUpsell && (
            <p className="c-sku__upsell">
              <Link href="#todo">
                <FormattedMessage
                  id="bag.upsell"
                  defaultMessage="View matching accessories"
                />
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export const skuShape = PropTypes.shape({
  brandName: PropTypes.string,
  imgUrl: PropTypes.string,
  styleName: PropTypes.string,
  size: PropTypes.shape({
    back: PropTypes.string,
    cup: PropTypes.string,
    curvy: PropTypes.string,
    dress: PropTypes.string,
    size: PropTypes.string,
  }),
  styleCode: PropTypes.string,
  colour: PropTypes.string,
  colourSwatch: PropTypes.string,
  skuId: PropTypes.number,
  gvFormat: PropTypes.string,
  quantity: PropTypes.number,
});

Sku.propTypes = {
  item: skuShape,
  upsell: PropTypes.bool,
  showBrandName: PropTypes.bool,
  showQuantity: PropTypes.bool,
  isBagPage: PropTypes.bool,
};

Sku.defaultProps = {
  showBrandName: true,
  showQuantity: false,
  isBagPage: false,
};

export default Sku;
