import {
  RECEIVE_DEPARTMENTS,
  RECEIVE_VACANCY,
  RECEIVE_ROLES,
  RECEIVE_ROLE,
} from '../constants/jobs';

/**
 * @param {array} departments
 * @returns {{type, payload: object}}
 */
export function receiveDepartments(departments) {
  return {
    type: RECEIVE_DEPARTMENTS,
    payload: departments,
  };
}

/**
 * @param {object} vacancy
 * @returns {{type, payload: object}}
 */
export function receiveVacancy(vacancy) {
  return {
    type: RECEIVE_VACANCY,
    payload: vacancy,
  };
}

/**
 * @param {object} roles
 * @returns {{type, payload: object}}
 */
export function receiveRoles(roles) {
  return {
    type: RECEIVE_ROLES,
    payload: roles,
  };
}

/**
 * @param {object} role
 * @returns {{type, payload: object}}
 */
export function receiveRole(role) {
  return {
    type: RECEIVE_ROLE,
    payload: role,
  };
}
