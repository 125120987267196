// @flow

type AllOrdersRetry = 'bravissimo/orders/ALL_ORDERS_RETRY';
type CancelOrderFailure = 'bravissimo/orders/CANCEL_ORDER_FAILURE';
type CancelOrderSuccess = 'bravissimo/orders/CANCEL_ORDER_SUCCESS';
type CheckOrderStatusFailure = 'bravissimo/orders/CHECK_ORDER_STATUS_FAILURE';
type CheckOrderStatusSuccess = 'bravissimo/orders/CHECK_ORDER_STATUS_SUCCESS';
type CheckOrderStatusRequest = 'bravissimo/orders/CHECK_ORDER_STATUS_REQUEST';
type AllOrdersUpdateFailure = 'bravissimo/orders/ALL_ORDERS_UPDATE_FAILURE';
type AllOrdersUpdateSuccess = 'bravissimo/orders/ALL_ORDERS_UPDATE_SUCCESS';
type AllOrdersUpdateRequest = 'bravissimo/orders/ALL_ORDERS_UPDATE_REQUEST';
type CancelOrderUpdateSuccess = 'bravissimo/orders/CANCEL_ORDER_UPDATE_SUCCESS';
type CancelOrderUpdateFailure = 'bravissimo/orders/CANCEL_ORDER_UPDATE_FAILURE';
type CancelOrderUpdateRequest = 'bravissimo/orders/CANCEL_ORDER_UPDATE_REQUEST';
type DismissMessages = 'bravissimo/orders/DISMISS_CANCEL_MESSAGES';

export const ALL_ORDERS_UPDATE_REQUEST: AllOrdersUpdateRequest =
  'bravissimo/orders/ALL_ORDERS_UPDATE_REQUEST';
export const ALL_ORDERS_UPDATE_SUCCESS: AllOrdersUpdateSuccess =
  'bravissimo/orders/ALL_ORDERS_UPDATE_SUCCESS';
export const ALL_ORDERS_UPDATE_FAILURE: AllOrdersUpdateFailure =
  'bravissimo/orders/ALL_ORDERS_UPDATE_FAILURE';
export const CHECK_ORDER_STATUS_REQUEST: CheckOrderStatusRequest =
  'bravissimo/orders/CHECK_ORDER_STATUS_REQUEST';
export const CHECK_ORDER_STATUS_SUCCESS: CheckOrderStatusSuccess =
  'bravissimo/orders/CHECK_ORDER_STATUS_SUCCESS';
export const CHECK_ORDER_STATUS_FAILURE: CheckOrderStatusFailure =
  'bravissimo/orders/CHECK_ORDER_STATUS_FAILURE';
export const CANCEL_ORDER_SUCCESS: CancelOrderSuccess =
  'bravissimo/orders/CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE: CancelOrderFailure =
  'bravissimo/orders/CANCEL_ORDER_FAILURE';
export const CANCEL_ORDER_UPDATE_SUCCESS: CancelOrderUpdateSuccess =
  'bravissimo/orders/CANCEL_ORDER_UPDATE_SUCCESS';
export const CANCEL_ORDER_UPDATE_FAILURE: CancelOrderUpdateFailure =
  'bravissimo/orders/CANCEL_ORDER_UPDATE_FAILURE';
export const CANCEL_ORDER_UPDATE_REQUEST: CancelOrderUpdateRequest =
  'bravissimo/orders/CANCEL_ORDER_UPDATE_REQUEST';
export const DISMISS_CANCEL_MESSAGES: DismissMessages =
  'bravissimo/orders/DISMISS_CANCEL_MESSAGES';

export const ALL_ORDERS_RETRY: AllOrdersRetry =
  'bravissimo/orders/ALL_ORDERS_RETRY';
