import { CALL_API } from '../constants/api';
import {
  UPDATE_PERSONAL_DETAILS_SUCCESS,
  UPDATE_PERSONAL_DETAILS_REQUEST,
  UPDATE_PERSONAL_DETAILS_FAILURE,
  PERSONAL_DETAILS_CLOSE_MESSAGES,
} from '../constants/personal-details';

import { closeLayer } from '../actions/layer';
import { dataLayerSendEmail } from '../utils/data-layer';

/**
 * @param {object} authData authenticated data returned by api/customer.
 * @returns {{type, payload: {user: object}}}
 */
export const updatePersonalDetailsSuccess = authData => dispatch => {
  const { email } = authData.user;
  const action = {
    type: UPDATE_PERSONAL_DETAILS_SUCCESS,
    payload: {
      isFetching: false,
      isAuthenticated: true,
      isIdentified: true,
      token: authData.token,
      user: authData.user,
      message: authData.message,
    },
  };
  dispatch(action);
  dataLayerSendEmail(email);
};

// Thunk to close layer on update success (if open)
const updatePersonalDetailsSuccessAndCloseLayer = authData => (
  dispatch,
  getState
) => {
  if (getState().layer.isVisible) {
    dispatch(closeLayer());
  }
  dispatch(updatePersonalDetailsSuccess(authData));
};

/**
 * @param {object} details user details
 * @returns {{type, payload: {code: string}}}
 */
export function updatePersonalDetailsRequest(details) {
  return {
    type: UPDATE_PERSONAL_DETAILS_REQUEST,
    payload: {
      details,
    },
  };
}

/**
 * @param {Object} details
 * @returns {{type, message, payload: {details: string}}}
 */
export function updatePersonalDetailsFailure(details) {
  return {
    type: UPDATE_PERSONAL_DETAILS_FAILURE,
    message: details.message,
    payload: {
      details,
    },
  };
}

/**
 * @param {Object} details - Customer details object
 * @returns {{
 * actions: {
 *  error: updatePersonalDetailsFailure,
 *  start: updatePersonalDetailsRequest,
 *  success: updatePersonalDetailsSuccess
 * },
 * method: string,
 * type,
 * url: string
 * }}
 */
export function apiUpdatePersonalDetails(details) {
  return {
    actions: {
      error: updatePersonalDetailsFailure,
      start: updatePersonalDetailsRequest,
      success: updatePersonalDetailsSuccessAndCloseLayer,
    },
    method: 'post',
    params: details,
    type: CALL_API,
    url: 'account/personalDetails',
  };
}

/**
 * Close error and confirmation messages
 * @returns {{type, payload: number}}
 */
export function closeMessages() {
  return {
    type: PERSONAL_DETAILS_CLOSE_MESSAGES,
  };
}
