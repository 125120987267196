/**
 * @param {object} dataObj
 */
export function sendConversionDataToGoogleAds(dataObj) {
  if (typeof window !== 'undefined') {
    window.gtag('event', 'conversion', dataObj);
  }
}

/**
 * @param {string} consent
 */
export function updateGoogleConsent(consent) {
  if (typeof window !== 'undefined' && window.gtag) {
    const consentMode = {
      ad_user_data: consent,
      ad_personalization: consent,
      ad_storage: consent,
      analytics_storage: consent,
    };
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
    window.gtag('consent', 'update', consentMode);
  }
}
