// @flow
// $FlowFixMe
import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import renderMarkdown from './render';

export type Props = {
  charactersToShow?: number,
  className?: string,
  components?: {},
  content: string,
  options?: {},
  isMobile?: boolean,
};

const messages = defineMessages({
  expand: { id: 'read-more.expand', defaultMessage: 'Read more' },
  collapse: { id: 'read-more.collapse', defaultMessage: 'Show less' },
});

const STYLE_PREFIX = 'c-markdown';

const MarkDown = (props: Props) => {
  const [expanded, setExpanded] = useState(false);

  const toggle = () => setExpanded(!expanded);

  const handleReadMoreClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggle();
  };

  const localisedMessage = expanded ? messages.collapse : messages.expand;

  const divProps = {
    className: 'c-markdown',
  };
  if (props.className) {
    divProps.className += ` ${props.className}`;
  }
  if (props.isMobile) {
    divProps.className += ` ${STYLE_PREFIX}${
      expanded ? '--expand' : '--collapse'
    }`;
  }

  return (
    <div {...divProps}>
      {renderMarkdown(props.content, props.components)}
      {props.isMobile && (
        <button className="c-markdown__read-more" onClick={handleReadMoreClick}>
          <FormattedMessage {...localisedMessage} />
        </button>
      )}
    </div>
  );
};

export default MarkDown;
