import React from 'react';

const IconPlaceholder = () => (
  <svg
    className="c-icon__glyph"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
  >
    <path
      fillRule="evenodd"
      fill="red"
      d="M100 200c55.228 0 100-44.772 100-100S155.228 0 100 0 0 44.772 0 100s44.772 100 100 100zm-63.568-66.736h13.152V80.656h16.704L69.552 70H18v10.656h18.432v52.608zm36.608 0h21.6c14.976 0 22.464-9.408 22.464-19.008 0-7.104-4.224-13.248-11.808-15.264V98.8c4.896-2.496 7.488-7.584 7.488-12.768 0-8.064-6.144-16.032-19.872-16.032H73.04v63.264zm13.152-10.752v-15.936h7.968c6.048 0 9.696 2.784 9.696 7.68 0 5.76-3.648 8.256-10.944 8.256h-6.72zm0-26.688V80.752h4.704c6.144 0 9.024 3.36 9.024 7.68 0 4.128-2.976 7.392-8.736 7.392h-4.992zm38.336 37.44h20.064c21.696 0 36.192-12.576 36.192-32.16C180.784 82.192 168.112 70 145.84 70h-21.312v63.264zm13.152-10.656V80.656h6.912c14.592 0 22.176 8.064 22.176 20.832 0 11.136-6.24 21.12-21.888 21.12h-7.2z"
    />
  </svg>
);

export default IconPlaceholder;
