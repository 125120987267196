import { CALL_API } from '../constants/api';
import {
  SUBMIT_CONTACT_TO_ADESTRA_REQUEST,
  SUBMIT_CONTACT_TO_ADESTRA_SUCCESS,
  SUBMIT_CONTACT_TO_ADESTRA_FAILURE,
} from '../constants/adestra';

/**
 * @returns {{type: String}}
 */
export function submitContactToAdestraStart() {
  return {
    type: SUBMIT_CONTACT_TO_ADESTRA_REQUEST,
  };
}

/**
 * @param {object} details
 * @returns {{type, payload: object}}
 */
export function submitContactToAdestraSuccess(details) {
  return {
    type: SUBMIT_CONTACT_TO_ADESTRA_SUCCESS,
    payload: details,
  };
}

/**
 * @param {object} details
 * @returns {{type, error: boolean, payload: object}}
 */
export function submitContactToAdestraFailure(details) {
  return {
    type: SUBMIT_CONTACT_TO_ADESTRA_FAILURE,
    error: true,
    payload: details,
  };
}

/**
 * @param {Object} payload - containing the details of the request
 * @returns {Object} An action containing all the necessary fields to make an API call
 */
export const apiSubmitContactToAdestra = () => (dispatch, getState) => {
  // The data we need comes from different parts of state. 'editorialForm' is a property
  // that we have created and contains information about whether the form is valid and a
  // few things from Contentful. 'form' is part of Redux Form and contains the actual data
  // entered by the customer in the fields.
  const {
    editorialForm: {
      isValid,
      metadata: { formKey, formName, interaction },
    },
    form,
  } = getState();

  const {
    email: { value },
  } = (form[formName] && form[formName][formKey]) || { email: {} };

  // Only send the data to Adestra if the form is valid and can be submitted
  if (isValid) {
    return dispatch({
      actions: {
        start: submitContactToAdestraStart,
        success: submitContactToAdestraSuccess,
        error: submitContactToAdestraFailure,
      },
      method: 'post',
      params: { email: value, interaction },
      type: CALL_API,
      url: 'adestra',
    });
  }

  return null;
};
