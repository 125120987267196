// @flow
import React from 'react';

const InstagramFeed = () => (
  <script
    async
    src="//foursixty.com/media/scripts/fs.slider.v2.5.js"
    data-feed-id="bravissimo"
    data-theme="slider_v2_5"
    data-cell-size="400"
    data-connector-filter="28902"
  />
);
export default InstagramFeed;
