import React from 'react';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Image from 'brastrap/containers/image/ImageContainer';
import type { Image as Props } from './image-card.types.js';

const STYLE_PREFIX = 'c-image-card';

const ImageCardContent = (props: Props) => {
  const { image, text, action } = props;

  return (
    <div className={`${STYLE_PREFIX}__container`}>
      <div className="c-product-summary__media">
        <div className="c-product-summary__img-container">
          <Image
            className={`${STYLE_PREFIX}__img`}
            src={image.src}
            alt={image.alt}
            width={image.width}
            height={image.height}
          />
        </div>
      </div>

      <div className={`${STYLE_PREFIX}__main`}>
        {text && <div className={`${STYLE_PREFIX}__text`}>{text}</div>}
        {action && (
          <h2 className={`${STYLE_PREFIX}__actions`}>
            <ButtonLink {...action} isDummyButton />
          </h2>
        )}
      </div>
    </div>
  );
};

export default ImageCardContent;
