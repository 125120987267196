import { combineReducers } from 'redux';

import noop from './noop';
import * as locale from './app/locale';
import * as serverAndPort from './app/server-and-port';
import * as routing from './app/routing';

/**
 * @return {Reducer<S>}
 */
export const app = combineReducers({
  config: noop,
  ...locale,
  meta: noop,
  ...serverAndPort,
  ...routing,
});
