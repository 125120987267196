// @flow
import * as React from 'react';
import LazyLoad from 'react-lazyload';
import { startCase } from 'lodash';
import { FormattedMessage } from 'react-intl';
import EditorialContainer from 'brastrap/editorial/editorial-container/EditorialContainer';
import UtilityNav from 'brastrap/common/utility-nav/UtilityNav';
import Container from 'brastrap/common/container/Container';
import Icon from 'brastrap/common/icon/Icon';
import Image from 'brastrap/common/image/Image';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import EmailSignUp from 'brastrap/containers/email-signup/EmailSignupContainer';
import TouchPoint from 'brastrap/global/touchpoint/Touchpoint';
import Link from 'brastrap/containers/link/LinkContainer';
import type { Container as ContainerProps } from 'brastrap/editorial/editorial-container/editorial-container.types.js';
import type { UtilityNavLinks } from 'brastrap/common/utility-nav/utility-nav.types.js';
import type { Props as Action } from 'brastrap/common/button-link/ButtonLink';
import Accordion from '../../common/accordion/Accordion';
import SectionNav from '../../common/section-nav/SectionNav';

type SignUp = {
  actions: Action[],
  title: string,
  slug: Url,
};

type Social = { [social_channel: string]: Url };

type Props = {
  containers: ContainerProps[],
  navs: UtilityNavLinks[],
  signUp: SignUp[],
  social: Social,
  children: React.Node,
  securePayments: SettingGroup,
  awards: SettingGroup,
  isMobile: Boolean,
};
const SOCIAL_ICONS = [
  'facebook',
  'instagram',
  'pinterest',
  'tiktok',
  'youtube',
];

const preferredSocialOrder = [
  'instagram',
  'tiktok',
  'facebook',
  'pinterest',
  'youtube',
];

const isValidSocialIcon = name => SOCIAL_ICONS.indexOf(name) > -1;

const sortBySocialOrder = (a, b) => {
  const aIndex = preferredSocialOrder.findIndex(social => social === a);
  const bIndex = preferredSocialOrder.findIndex(social => social === b);
  return aIndex - bIndex;
};

const renderImages = (settingGroup, className) =>
  Object.keys(settingGroup).map(setting => {
    const { description = '', file: { url = '' } = {} } = settingGroup[setting];
    return (
      !!url && (
        <Image
          src={url}
          className={className}
          key={setting}
          alt={description}
        />
      )
    );
  });

const getFiles = settingGroup =>
  Object.keys(settingGroup)
    .map(setting => settingGroup[setting] && settingGroup[setting].file)
    .filter(file => !!file);

const hasFiles = settingGroup => !!getFiles(settingGroup).length;

const Footer = (props: Props) => {
  const icons = Object.keys(props.social)
    .filter(isValidSocialIcon)
    .sort(sortBySocialOrder);

  return (
    <footer className="c-footer">
      <div className="c-footer__channels">
        <LazyLoad>
          <EditorialContainer containers={props.containers} />
        </LazyLoad>
      </div>
      <LazyLoad>
        <Container>
          <div className="c-footer__social">
            <h2 className="c-footer__social__header">
              <FormattedMessage
                id="footer.social"
                defaultMessage="Let's get social!"
              />
            </h2>
            <div className="c-footer__social__logo">
              {icons.map(icon => (
                <Link
                  key={`link-to-${props.social[icon]}`}
                  className="c-footer__social__logo__link"
                  href={props.social[icon]}
                  rel="me noopener noreferrer"
                  target="_blank"
                >
                  <Icon icon={icon} />
                </Link>
              ))}
            </div>
          </div>
        </Container>
        <Container modifiers={['offset']}>
          {props.isMobile && (
            <div>
              <div className="c-footer__accordion">
                <Accordion footerAccordion>
                  {props.navs.map(nav => (
                    <Accordion.Item
                      id={nav.id}
                      key={nav.id}
                      label={nav.heading}
                    >
                      <div key={nav.id} className="l-grid__unit">
                        <SectionNav
                          id="shops-section__nav"
                          title={nav.heading}
                          items={nav.links}
                          footerSectionNav
                        />
                      </div>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          )}
          <div className="l-grid l-grid--wraps l-grid--4up">
            {!props.isMobile &&
              props.navs.map(nav => (
                <div key={nav.id} className="l-grid__unit">
                  <UtilityNav {...nav} />
                </div>
              ))}

            <div className="l-grid__unit l-grid__unit--double">
              {props.signUp.map(signUp => (
                <TouchPoint
                  key={`touchpoint-${signUp.title}`}
                  title={signUp.title}
                >
                  <div className="c-touchpoint--email__sign-up">
                    <EmailSignUp
                      analyticsRef={'Email Signup: Footer Email Signup Form'}
                      formKey="footerEmailSignUp"
                    />
                  </div>
                  <div className="c-touchpoint--magazine__link">
                    <ButtonLink
                      label={signUp.actions[0].label}
                      url={signUp.actions[0].url}
                      modifiers={['filled', 'small']}
                      analyticsRef={`Footer: ${startCase(
                        signUp.actions[0].label
                      )}`}
                    />
                  </div>
                  {hasFiles(props.securePayments) && (
                    <div className="c-touchpoint__payment">
                      {renderImages(
                        props.securePayments,
                        'c-touchpoint__payment__img'
                      )}
                    </div>
                  )}
                </TouchPoint>
              ))}
            </div>
          </div>

          {hasFiles(props.awards) && (
            <div className="c-footer__awards">
              <h4 className="c-footer__awards__header">
                <FormattedMessage
                  id="footer.awards"
                  defaultMessage="Our Awards"
                />
              </h4>
              <div className="c-footer__awards__logo">
                {renderImages(props.awards, 'c-footer__awards__logo__img')}
              </div>
            </div>
          )}
        </Container>
      </LazyLoad>
      <div>{props.children}</div>
    </footer>
  );
};

export default Footer;
