import React from 'react';

const SvgIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#616365"
      stroke="none"
    >
      <path
        d="M4110 4674 c-285 -76 -480 -328 -480 -619 0 -115 20 -198 70 -295
    123 -235 385 -376 639 -341 156 21 284 85 386 193 73 77 109 134 142 225 88
    244 16 525 -179 698 -62 54 -187 118 -273 140 -75 19 -232 18 -305 -1z"
      />
      <path
        d="M368 4258 c-21 -5 -58 -28 -82 -50 -113 -98 -89 -275 45 -342 l53
    -26 1314 0 c1011 0 1324 3 1356 12 57 17 119 82 135 141 27 95 -13 195 -97
    244 l-47 28 -1320 2 c-850 1 -1333 -2 -1357 -9z"
      />
      <path
        d="M1570 3185 c-177 -40 -344 -169 -428 -332 l-43 -83 -360 0 c-302 0
    -366 -2 -397 -15 -169 -71 -169 -319 0 -390 31 -13 95 -15 397 -15 l360 0 43
    -82 c116 -224 352 -359 603 -345 264 16 489 189 572 442 22 68 26 98 26 195 0
    98 -4 127 -27 197 -63 193 -210 341 -408 410 -92 32 -241 40 -338 18z"
      />
      <path
        d="M2900 2754 c-173 -75 -161 -342 18 -394 26 -8 318 -10 944 -8 l906 3
    44 30 c138 97 118 306 -34 370 -33 13 -147 15 -940 15 -784 -1 -908 -3 -938
    -16z"
      />
      <path
        d="M3325 1700 c-151 -24 -251 -75 -360 -185 -122 -121 -179 -245 -191
    -410 -12 -175 54 -353 179 -479 126 -127 252 -185 423 -193 125 -7 222 13 327
    64 101 50 229 180 277 280 l35 72 346 1 c404 0 424 3 487 76 96 109 68 264
    -59 328 l-53 26 -362 0 -362 0 -12 36 c-37 111 -177 260 -304 323 -110 54
    -261 79 -371 61z"
      />
      <path
        d="M331 1254 c-90 -45 -134 -140 -110 -238 16 -67 85 -138 151 -154 34
    -9 282 -12 911 -12 981 0 936 -3 1005 76 96 109 68 264 -59 328 l-53 26 -896
    0 -896 0 -53 -26z"
      />
    </g>
  </svg>
);

export default SvgIcon;
