import { connect } from 'react-redux';
import FieldUpload from 'brastrap/common/field-upload/FieldUpload';

const mapStateToProps = (
  {
    app: {
      config: { filestack },
    },
  },
  ownProps
) => ({
  ...ownProps,
  filestackConfig: filestack,
});

export default connect(mapStateToProps)(FieldUpload);
