import { connect } from 'react-redux';
import Rail from 'brastrap/promotional/rail/Rail';

const mapStateToProps = (
  {
    app: {
      config: { basePath, currency },
    },
  },
  ownProps
) => ({
  ...ownProps,
  currency,
  basePath: basePath && basePath.replace(/\/$/, ''), // Removes trailing slash.
});

export default connect(mapStateToProps)(Rail);
