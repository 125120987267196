export const OPEN_STOCK_FINDER = 'bravissimo/stock-checker/OPEN_STOCK_CHECKER';
export const CLOSE_STOCK_FINDER =
  'bravissimo/stock-checker/CLOSE_STOCK_CHECKER';

export const MODAL_ID = 'stock-finder.field-brasize.modal';
export const FORM_ID = 'stock-finder';

export const FIND_STOCK_REQUEST = 'bravissimo/stock-checker/FIND_STOCK_REQUEST';
export const FIND_STOCK_SUCCESS = 'bravissimo/stock-checker/FIND_STOCK_SUCCESS';
export const FIND_STOCK_FAILURE = 'bravissimo/stock-checker/FIND_STOCK_FAILURE';
