import { START_TIMER, CANCEL_TIMER } from '../constants/timers';

const initialState = {};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default function timers(state = initialState, action = {}) {
  switch (action.type) {
    case START_TIMER: {
      const { key, delay, callback } = action.payload;
      if (state[key]) {
        clearTimeout(state[key]);
      }
      return {
        ...state,
        [key]: setTimeout(callback, delay),
      };
    }

    case CANCEL_TIMER: {
      const { key } = action.payload;
      if (state[key]) {
        clearTimeout(state[key]);
      }
      const newState = { ...state };
      delete newState[key];
      return newState;
    }

    default:
      return state;
  }
}
