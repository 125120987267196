// @flow

export type ReviewsFailure = 'bravissimo/product-reviews/REVIEWS_FAILURE';
export type ReviewsRequest = 'bravissimo/product-reviews/REVIEWS_REQUEST';
export type ReceiveReviews = 'bravissimo/product-reviews/RECEIVE_REVIEWS';
export type FilterProductReviews =
  'bravissimo/product-reviews/FILTER_PRODUCT_REVIEWS';

export const FILTER_PRODUCT_REVIEWS: FilterProductReviews =
  'bravissimo/product-reviews/FILTER_PRODUCT_REVIEWS';
export const RECEIVE_REVIEWS: ReceiveReviews =
  'bravissimo/product-reviews/RECEIVE_REVIEWS';
export const REVIEWS_REQUEST: ReviewsRequest =
  'bravissimo/product-reviews/REVIEWS_REQUEST';
export const REVIEWS_FAILURE: ReviewsFailure =
  'bravissimo/product-reviews/REVIEWS_FAILURE';
