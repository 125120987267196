// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { applyModifiers } from '../../utils';

type Modifier = 'small' | 'white';

type Props = {
  modifiers?: Array<Modifier>,
};

const BASE_CLASS = 'c-activity';

export const Loading = () => (
  <p>
    <FormattedMessage id="actions.loading" defaultMessage="Loading…" />
    &hellip;
  </p>
);

const Activity = ({ modifiers }: Props) => (
  <progress className={`${applyModifiers(BASE_CLASS, modifiers)}`}>
    <Loading />
  </progress>
);

Activity.defaultProps = {
  modifiers: [],
};

export default Activity;
