// @flow
import React, { type Node } from 'react';
import { FormattedMessage } from 'react-intl';
import Field from 'brastrap/common/field/Field';
import { applyModifiers } from '../../utils';

const invalidInputProps = [
  'initialValue',
  'autofill',
  'onUpdate',
  'valid',
  'invalid',
  'dirty',
  'pristine',
  'error',
  'active',
  'visited',
  'autofilled',
  'modifiers',
  'floatLabel',
  'note',
  'touched',
  'showCharacterCount',
  'intl',
  'description',
  'fieldType',
  'validation',
];

type InputTypes =
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'url';

export type Props = {
  disabled?: boolean,
  floatLabel?: boolean,
  label?: string,
  maxLength?: number,
  modifiers?: Modifiers,
  name: string,
  note?: Node,
  placeholder?: string,
  touched?: boolean,
  showCharacterCount?: boolean,
  required?: boolean,
  type: InputTypes,
  value?: string,
  'aria-label'?: string,
};

/**
 * Ensure only valid input properties are used.
 *
 * @param {object} props
 * @return {object}
 */
export const filterInputProps = (props: {}) => {
  const inputProps = { ...props };
  invalidInputProps.forEach(attr => delete inputProps[attr]);
  if (inputProps.value === null) {
    delete inputProps.value;
  }
  return inputProps;
};

/**
 * @param {Object} props
 * @constructor
 */
const CharsLeft = ({ number }: { number: number }) => (
  <em className="c-field__note c-field__note--character-count">
    <strong>{number}</strong>
    &nbsp;
    <FormattedMessage
      id="field-input.character-count"
      defaultMessage="characters remaining"
    />
  </em>
);

/**
 * @param {object} props
 * @return {XML}
 * @constructor
 */
const FieldInput = (props: Props) => {
  const className = applyModifiers('c-field-input', props.modifiers);
  const inputProps = filterInputProps(props);

  const inputField = <input {...inputProps} className={className} />;
  if (props.label) {
    return (
      <Field {...props}>
        {props.note && props.touched && (
          <em className="c-field__note">{props.note}</em>
        )}
        {inputField}
        {props.maxLength && props.showCharacterCount && (
          <CharsLeft number={props.maxLength - (props.value || '').length} />
        )}
      </Field>
    );
  }
  return inputField;
};

FieldInput.defaultProps = {
  type: 'text',
};

export default FieldInput;
