export const VIEW_CONTENT = 'ViewContent';
export const ADD_TO_CART = 'AddToCart';
export const INITIATE_CHECKOUT = 'InitiateCheckout';
export const PURCHASE = 'Purchase';
export const COMPLETE_PAYMENT = 'CompletePayment';
export const PAGE_VISIT = 'PageVisit';
export const CHECKOUT = 'Checkout';
export const TB_ADD_TO_CART = 'add_to_cart';
export const TB_INITIATE_CHECKOUT = 'start_checkout';
export const TB_MAKE_PURCHASE = 'make_purchase';
