import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
  >
    <rect width="200" height="32" y="88" rx="16" />
  </svg>
);

export default SvgIcon;
