import React from 'react';

const SvgIcon = () => (
  <svg
    className="c-icon__glyph"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 160 160"
    role="img"
  >
    <path
      fillRule="evenodd"
      d="M124 113l32 32c3 3 3 8 0 11-4 3-9 3-12 0L113 124c-12 10-27 15-43 15C31 140 0 108 0 70S32 0 70 0s70 32 70 70c0 16-6 31-15 43zM16 70c0-30 24-54 54-54s54 24 54 54-24.2 54-54 54c-30 0-54-24-54-54z"
    />
  </svg>
);

export default SvgIcon;
