import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 240 240"
  >
    <path
      fillRule="evenodd"
      fill="#662653"
      d="M186 173l51 51c4 4 4 10 0 14-4 4-10 4-14 0L173 187c-18 15-42 25-67 25C47 211 0 163 0 106 0 48 48 0 106 0c58 0 106 48 106 106 0 25-9 49-25 67zM19 106c0-48 38-86 86-86 48 0 86 39 86 87S153 192 106 192c-48 0-86-39-86-87z"
    />
  </svg>
);

export default SvgIcon;
