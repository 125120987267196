import { REDIRECT_CLIENT } from '../constants/redirect';

/**
 * @param {string} url
 * @returns {{payload: {string}, type: {string}}}
 */
export function redirect(url) {
  return {
    type: REDIRECT_CLIENT,
    payload: url,
  };
}
