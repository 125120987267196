import uniqueId from 'lodash/uniqueId';
import {
  ADD_ITEM_REQUEST,
  ADD_ITEM_FAILURE,
  REMOVE_ITEM_REQUEST,
  REMOVE_ITEM_FAILURE,
  UPDATE_QUANTITY_REQUEST,
  UPDATE_QUANTITY_FAILURE,
  RECEIVE_BAG,
  ADD_PAYMENT_REQUEST,
  ADD_PAYMENT_FAILURE,
  ADD_DELIVERY_METHOD_REQUEST,
  ADD_DELIVERY_METHOD_FAILURE,
  UPDATE_DELIVERY_METHOD_FIELD,
  VALIDATE_BAG_FAILURE,
  VALIDATE_BAG_REQUEST,
  VALIDATE_BAG_SUCCESS,
  UPDATE_TOTAL_WITH_US_TAX,
  HAS_US_DEFAULT_ADDRESS_FOR_UK_SITE,
} from '../constants/bag';

import { BROWSER_ID_STORAGE_KEY } from '../constants/auth';

import { CALL_API } from '../constants/api';

import { setDynamicRemarketing } from '../actions/dynamic-remarketing';

import { addMessage, clearAllMessages } from './messages';
import { startPopoverBagCloseTimer } from './masthead/header';
import {
  formatProductForDataLayer,
  dataLayerBagChange,
  dataLayerGA4AddToCart,
} from '../utils/data-layer';

// Used in payment-card-details-form reducer
export type ReceiveBag = {
  type: typeof RECEIVE_BAG,
  payload: CustomerBag,
};

/**
 * @param {Object} bag
 * @returns {{type, payload: {Object}}}
 */
export function receiveBag(bag) {
  return {
    type: RECEIVE_BAG,
    payload: bag,
  };
}

/**
 * @param {Object} bag
 * @param {String} code
 * @param {Number} quantity
 * @returns {{type, payload: {Object}}}
 */
const addToBagSuccess = (bag, code, quantity) => (dispatch, getState) => {
  dispatch(receiveBag(bag));
  dispatch(addMessage(bag.message, 'bag'));
  dispatch(startPopoverBagCloseTimer());

  const {
    app: {
      config: { currency },
    },
    bag: { items },
  } = getState();
  const product = items.find(item => item.code === code);
  const formattedProduct = formatProductForDataLayer(product, code, quantity);
  dataLayerBagChange('add', formattedProduct, currency, bag);
  dataLayerGA4AddToCart(currency, formattedProduct);
  dispatch(setDynamicRemarketing('bag'));
};

/**
 * @param {string} code product code
 * @returns {{type, payload: {String}}}
 */
const addToBagRequest = code => dispatch => {
  const action = {
    type: ADD_ITEM_REQUEST,
    payload: code,
  };

  dispatch(clearAllMessages('bag'));
  dispatch(action);
};

/**
 * @param {string} message
 * @returns {{type, payload: {String}}}
 */
const addToBagFailure = message => dispatch => {
  const action = {
    type: ADD_ITEM_FAILURE,
    payload: message,
    error: true,
  };

  dispatch(action);
  dispatch(addMessage(message, 'bag', true));
};

/**
 * Trigger the remove from bag action and add a success message
 *
 * @param {Object} payload
 * @param {String} code
 * @returns {{type, payload: {Object}}}
 */
const removeFromBagSuccess = (payload, code) => (dispatch, getState) => {
  const { bag, message } = payload;
  const {
    app: {
      config: { currency },
    },
    bag: { items },
  } = getState();
  const product = items.find(item => item.code === code);
  dispatch(receiveBag(bag));
  dataLayerBagChange(
    'remove',
    formatProductForDataLayer(product, code),
    currency,
    bag
  );
  dispatch(addMessage(message, 'bag'));
};

/**
 * Clear all bag messages then trigger the request
 *
 * @param {string} code
 * @returns {{type, payload: {String}}}
 */
const removeFromBagRequest = code => dispatch => {
  const action = {
    type: REMOVE_ITEM_REQUEST,
    payload: code,
  };

  dispatch(clearAllMessages('bag'));
  dispatch(action);
};

/**
 * @param {string} message
 * @returns {{type, payload: {String}}}
 */
const removeFromBagFailure = message => dispatch => {
  const action = {
    type: REMOVE_ITEM_FAILURE,
    payload: message,
    error: true,
  };

  dispatch(action);
  dispatch(addMessage(message, 'bag', true));
};

/**
 * @param {object} payload
 * @returns {{type, payload: {code: {string}, quantity: {int}}}}
 */
const updateQuantityRequest = payload => dispatch => {
  const action = {
    type: UPDATE_QUANTITY_REQUEST,
    payload,
  };

  dispatch(clearAllMessages('bag'));
  dispatch(action);
};

/**
 * @param {Object} message
 * @returns {{type, payload: {String}}}
 */
const updateQuantityFailure = message => dispatch => {
  const action = {
    type: UPDATE_QUANTITY_FAILURE,
    payload: message,
    error: true,
  };

  dispatch(action);
  dispatch(addMessage(message, 'bag', true));
};

/**
 * @param {Object} bag
 * @param {String} code
 * @param {Number} quantity
 * @returns {{type, payload: {String}}}
 */
const updateQuantitySuccess = (bag, code, quantity) => (dispatch, getState) => {
  const {
    app: {
      config: { currency },
    },
    bag: { items },
  } = getState();
  const product = items.find(item => item.code === code);
  const type = quantity > product.quantity ? 'add' : 'remove';
  const quantityAddedOrRemoved = Math.abs(quantity - product.quantity);
  dispatch(receiveBag(bag));
  dataLayerBagChange(
    type,
    formatProductForDataLayer(product, code, quantityAddedOrRemoved),
    currency,
    bag
  );
};

/**
 * @returns {{type: string}}
 */
export function addPaymentRequest() {
  return {
    type: ADD_PAYMENT_REQUEST,
  };
}

/**
 * @param {string} message
 * @returns {{type: string, payload: string, error: boolean}}
 */
export function addPaymentFailure(message) {
  return {
    type: ADD_PAYMENT_FAILURE,
    payload: { message },
    error: true,
  };
}

/**
 * Dispatches all changes to bag delivery state
 *
 * @returns {{type: string, payload: *}}
 */
export function setDeliveryMethodRequest() {
  return {
    type: ADD_DELIVERY_METHOD_REQUEST,
  };
}

/**
 * Dispatches all changes to bag delivery state
 *
 * @returns {{type: string, payload: *}}
 */
export function setDeliveryMethodFailure() {
  return {
    type: ADD_DELIVERY_METHOD_FAILURE,
  };
}

/**
 * @param {string} code - Product SKU
 * @param {number} quantity
 * @returns {{
 * actions: {
 *  error: addToBagFailure,
 *  start: addToBagRequest,
 *  success: addToBagSuccess
 * },
 * method: string,
 * params: { code, quantity },
 * type,
 * url: string
 * }}
 */
export function apiAddToBag(code, quantity = 1) {
  return {
    actions: {
      error: addToBagFailure,
      start: addToBagRequest,
      success: payload => addToBagSuccess(payload, code, quantity),
    },
    method: 'post',
    params: { code, quantity },
    type: CALL_API,
    url: 'bag/item',
  };
}

/**
 * @param {number} value - voucher value
 * @param {string} voucherFormat - format of voucher 'Paper' or 'Email'
 * @returns {{
 * actions: {
 *  error: addToBagFailure,
 *  start: addToBagRequest,
 *  success: addToBagSuccess
 * },
 * method: string,
 * params: { code },
 * type,
 * url: string
 * }}
 */
export function apiAddVoucherToBag(value, voucherFormat) {
  const code = `GV01:${value}:${voucherFormat}:${uniqueId()}`;
  return {
    actions: {
      start: addToBagRequest,
      success: payload => addToBagSuccess(payload, code),
      error: addToBagFailure,
    },
    method: 'post',
    params: { code },
    type: CALL_API,
    url: 'bag/item',
  };
}

/**
 * @param {object} code
 * @returns {{
 * actions: {
 *  error: removeFromBagFailure,
 *  start: removeFromBagRequest,
 *  success: removeFromBagSuccess
 * },
 * method: string,
 * params: {code},
 * type,
 * url: string
 * }}
 */
export function apiRemoveFromBag(code) {
  return {
    actions: {
      error: removeFromBagFailure,
      start: removeFromBagRequest,
      success: payload => removeFromBagSuccess(payload, code),
    },
    method: 'delete',
    params: { code },
    type: CALL_API,
    url: `bag/item/${encodeURIComponent(code)}`,
  };
}

/**
 * @param {string} code
 * @param {number} quantity
 * @returns {{
 * actions: {
 *  error: updateQuantityFailure,
 *  start: updateQuantityRequest,
 *  success: receiveBag
 * },
 * method: string,
 * params: {code, quantity: number},
 * type,
 * url: string
 * }}
 */
export function apiUpdateQuantity(code, quantity) {
  const qty = parseInt(quantity, 10);

  return {
    actions: {
      error: updateQuantityFailure,
      start: updateQuantityRequest,
      success: bag => updateQuantitySuccess(bag, code, quantity),
    },
    method: 'put',
    params: {
      code,
      quantity: qty,
    },
    type: CALL_API,
    url: `bag/item/${code}`,
  };
}

/**
 * @param {object} token
 * @param {string} methodName
 * @returns {{
 *   actions: {
 *     error: addPaymentFailure,
 *     start: addPaymentRequest,
 *     success: receiveBag},
 *   method: string,
 *   params: {token: *},
 *   type: string,
 *   url: string
 * }}
 */
export function apiAddPayment(token, methodName) {
  return {
    actions: {
      error: addPaymentFailure,
      start: addPaymentRequest,
      success: receiveBag,
    },
    method: 'post',
    params: {
      token,
      methodName,
    },
    type: CALL_API,
    url: 'bag/payment',
  };
}

/**
 * @param {Object} option
 * @returns {{
 *   actions: {
 *     error: setDeliveryMethodFailure,
 *     start: setDeliveryMethodRequest,
 *     success: receiveBag
 *   },
 *   method: string,
 *   params: {option: *},
 *   type: string,
 *   url: string
 * }}
 */
export function apiAddDeliveryOptionToBag(option) {
  return {
    actions: {
      error: setDeliveryMethodFailure,
      start: setDeliveryMethodRequest,
      success: receiveBag,
    },
    method: 'post',
    params: {
      option,
    },
    type: CALL_API,
    url: 'bag/delivery',
  };
}

/**
 * @return {{type: ValidateBagRequest}}
 */
function validateBagRequest() {
  return {
    type: VALIDATE_BAG_REQUEST,
  };
}

/**
 * @return {{type: ValidateBagFailure}}
 */
function validateBagFailure() {
  return {
    type: VALIDATE_BAG_FAILURE,
  };
}

/**
 * @return {{type: ValidateBagSuccess}}
 */
function validateBagSuccess() {
  return {
    type: VALIDATE_BAG_SUCCESS,
  };
}

/**
 * Sends a request to the server to validate the customers bag.
 * Upon completion it will dispatch any success action
 *
 * @param {Function } [onSuccessAction]
 * @return {Function}
 */
export const apiValidateBag = onSuccessAction => dispatch => {
  /**
   * The onSuccess action consists of receiving the bag, dispatching any onSuccess callbacks that are passed through on
   * the fly, and finally firing the validateBagSuccess action.
   *
   * @param {Object} decoratedBag
   * @return {Promise<{type: ValidateBagSuccess}>}
   */
  const onSuccess = decoratedBag => async () => {
    await dispatch(receiveBag(decoratedBag));
    if (onSuccessAction && typeof onSuccessAction === 'function') {
      await dispatch(onSuccessAction(decoratedBag));
    }
    return validateBagSuccess();
  };

  return dispatch({
    actions: {
      error: validateBagFailure,
      start: validateBagRequest,
      success: onSuccess,
    },
    type: CALL_API,
    url: 'bag/validate',
  });
};

/**
 * Retrieve the bag identifiers from the request.
 *
 * @param {Object} req
 * @returns {{browserId: (*|null), customerId: null}}
 */
export function getBagIdentifier(req) {
  return {
    browserId:
      req.cookies[BROWSER_ID_STORAGE_KEY] ||
      req.body.browserId ||
      req.params.browserId,
    customerId:
      req.body.customerId || req.params.customerId || req.query.customerId,
  };
}

/**
 * @param {String} giftVoucherReference
 * @returns {Object}
 */
export function apiRemoveGiftVoucherPayment(giftVoucherReference) {
  return {
    actions: {
      success: receiveBag,
    },
    method: 'post',
    params: { giftVoucherReference },
    type: CALL_API,
    url: 'bag/removeGiftVoucherPayment',
  };
}

/**
 * @param {Object} field
 * @param {string} event
 * @return {{type, payload: {Object}}}
 */
export function updateDeliveryMethodField(field, event) {
  const value = event.currentTarget.value.slice(0, field.maxLength);
  return {
    type: UPDATE_DELIVERY_METHOD_FIELD,
    payload: {
      field,
      value,
    },
  };
}

/**
 * @param {Object} taxInfo
 * @return {{type, payload: {Object}}}
 */
export function updateTotalWithUSTax(taxInfo) {
  return {
    type: UPDATE_TOTAL_WITH_US_TAX,
    payload: {
      taxValue: taxInfo.taxValue,
    },
  };
}

/**
 * @param {Boolean} doesUKSiteHaveUsAddress
 * @return {{type, payload: {Object}}}
 */
export function updateHasDefaultUSAddress(doesUKSiteHaveUsAddress) {
  return {
    type: HAS_US_DEFAULT_ADDRESS_FOR_UK_SITE,
    payload: {
      doesUKSiteHaveUsAddress,
    },
  };
}
