// @flow
import React from 'react';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Carousel from 'brastrap/common/carousel/Carousel';
import EditorialBlock from 'brastrap/editorial/editorial-block/EditorialBlock';
import Markdown from 'brastrap/editorial/markdown/Markdown';
import Prose from 'brastrap/helpers/s-prose/s-prose';
import StyledText, {
  getWrappingTag,
} from 'brastrap/editorial/styled-text/StyledText';
import type { Props as Action } from 'brastrap/common/button-link/ButtonLink';
import { applyModifiers } from '../../utils';

type Props = {
  actions: Array<Action>,
  introduction: string,
  items: Array<*>,
  title: Array<StyledTextType>,
  basePath: String,
  backgroundColour: String,
  currency: String,
  slidesToShow: number,
  googleAnalyticsTracking: Boolean,
  isForHomepageOnly: Boolean,
};

type State = {
  items: Array<*>,
  showRail: boolean,
};

const PRODUCT_SUMMARY = 'productSummary';
const STYLE_PREFIX = 'c-rail';

class Rail extends React.Component<Props, State> {
  static defaultProps = {
    actions: [],
    items: [],
    title: [],
    backgroundColour: '#FFFFFF',
    slidesToShow: 3,
  };

  state = {
    items: this.props.items,
    showRail: false,
  };

  /**
   * @param {String} styleColourCode
   */
  onStyleColourChange = (styleColourCode: string) => {
    this.setState(state => ({
      items: state.items.map(item => {
        const styleColour = (item.colours || []).find(
          colour => colour.styleColourCode === styleColourCode
        );

        if (styleColour) {
          return {
            ...item,
            colours: item.colours.map(colour => ({
              ...colour,
              selected: colour.styleColourCode === styleColourCode,
            })),
          };
        }

        return item;
      }),
    }));
  };

  /**
   * Update state on carousel init
   */
  onCarouselInitialised = () => {
    this.setState({ showRail: true });
  };

  /**
   * @returns {*}
   */
  render() {
    const {
      actions,
      introduction,
      title,
      backgroundColour,
      currency,
      slidesToShow,
      googleAnalyticsTracking,
      isForHomepageOnly,
    } = this.props;
    const { items, showRail } = this.state;

    const filteredItems = items.filter(item => Object.keys(item).length);

    const onStyleColourChange = this.onStyleColourChange;

    const responsiveCarouselConfig = [];
    // Following configuration is updated to fix the issue of react-slick for responsive configuration
    /* const responsiveCarouselConfig = [
      {
        breakpoint: 560,
        settings: {
        slidesToShow: 1
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2
        }
      }
    ]; */

    const modifiers = [];

    if (showRail) {
      modifiers.push('initialised');
    }

    const className = applyModifiers(STYLE_PREFIX, modifiers);

    filteredItems.forEach(item => {
      // eslint-disable-next-line no-underscore-dangle
      if (item._type === PRODUCT_SUMMARY) {
        item.displaySaveItemButton = false; // eslint-disable-line no-param-reassign
      }
    });

    const TagName = getWrappingTag(title || [], 'h1');

    const railProps = {};
    railProps.isForHomepageOnly = isForHomepageOnly;

    if (googleAnalyticsTracking) {
      railProps.dataRef = googleAnalyticsTracking;
    }

    return (
      <div className={className}>
        <div className="c-rail__body">
          {(!!title.length || introduction || !!actions.length) && (
            <div
              className="c-rail__main"
              style={{ backgroundColor: backgroundColour }}
            >
              <header className="c-rail__header">
                {!!title.length && (
                  <TagName>
                    {title.map(item => (
                      <StyledText key={item.id} {...item} />
                    ))}
                  </TagName>
                )}
              </header>

              {introduction && (
                <Prose modifiers={['small']}>
                  <Markdown content={introduction} />
                </Prose>
              )}

              {!!actions.length &&
                actions.map(action => (
                  <ButtonLink key={action.id} {...action} />
                ))}
            </div>
          )}

          <div className="c-rail__items">
            <Carousel
              dots={false}
              slidesToShow={slidesToShow}
              responsive={responsiveCarouselConfig}
              onInitialise={this.onCarouselInitialised}
              lazyLoad
            >
              {filteredItems.map(item => (
                <div key={item.id}>
                  <div className="c-rail__item">
                    <EditorialBlock
                      content={{
                        ...item,
                        ...railProps,
                        currency,
                        onStyleColourChange,
                      }}
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

export default Rail;
