// @flow
import { START_CHAT } from '../constants/live-help';

type StartChat = {
  type: typeof START_CHAT,
  payload: boolean,
};

/**
 * @param {Object} event
 * @return {Object}
 */
export const startChat = (
  event: SyntheticEvent<HTMLAnchorElement>
): StartChat => {
  event.preventDefault();

  const result =
    window && window.brandembassy && window.brandembassy('openChatWindow');

  return {
    type: START_CHAT,
    payload: result, // See comment above about the result.
  };
};
