import transform from 'lodash/transform';

/**
 * greaterThanOrEqual is built by transforming greaterThan and combining the value with the `is` field.
 *
 * @param {Function} lessThanOrGreaterThan
 * @param {Function} is
 * @returns {Function}
 */
const orEqualTo = (lessThanOrGreaterThan, is) =>
  transform(
    lessThanOrGreaterThan,
    (result, value, mediaType) => {
      result[mediaType] = value || is[mediaType]; // eslint-disable-line no-param-reassign
    },
    {}
  );

/**
 * Helpers to calculate the device type.
 *
 * @param {Object} browser
 * @returns {Object}
 */
const deviceIs = browser => ({
  desktop: browser.greaterThan.xlarge,
  mobile: browser.lessThanOrEqualTo.medium,
  tablet: browser.lessThanOrEqualTo.xlarge && browser.greaterThan.medium,
});

/**
 * Function that takes the browser state and wraps with helper functions for your convince!
 *
 * @param {Object} state
 * @returns {{greaterThanOrEqualTo: Function, lessThanOrEqualTo: Function}}
 */
export default state => {
  const browser = { ...state };
  const { greaterThan, is, lessThan } = browser;

  browser.greaterThanOrEqualTo = orEqualTo(greaterThan, is);
  browser.lessThanOrEqualTo = orEqualTo(lessThan, is);

  browser.deviceIs = deviceIs(browser);

  return browser;
};
