import { cloneDeep } from 'lodash';

import {
  RECEIVE_TOOLS,
  RECEIVE_HEADER_LINKS,
  RECEIVE_TOP_BAR_TEXT,
} from '../../constants/masthead';

import { RECEIVE_BAG } from '../../constants/bag';

import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../../constants/auth';
import { RECEIVE_SAVED_ITEMS_MAP } from '../../constants/saved-items';

export const initialState = {
  toolbar: {
    title: 'Main toolbar',
    name: 'toolbar',
    tools: [],
  },
  links: [],
};

/**
 * @param {Object} state
 * @param {Object} action
 * @return {*}
 */
export function header(state = initialState, action = {}) {
  /**
   * @param {Object} items
   * @param {string} toolId
   * @returns {{toolbar: {tools: [Object]}}}
   */
  const updateItemCount = (items, toolId) => {
    const tools = cloneDeep(state.toolbar.tools);
    const tool = tools.find(i => i.id === toolId);

    // This will mutate tools array, which is why it is being cloned from state
    if (tool) {
      tool.count = items && items.itemCount ? +items.itemCount : 0;
    }
    return {
      ...state,
      toolbar: {
        ...state.toolbar,
        tools,
      },
    };
  };

  const resetItemCounts = (...ids) => {
    const tools = cloneDeep(state.toolbar.tools);
    ids.forEach(id => {
      const tool = tools.find(t => t.id === id);
      if (tool) {
        tool.count = 0;
      }
    });
    return {
      ...state,
      toolbar: {
        ...state.toolbar,
        tools,
      },
    };
  };

  switch (action.type) {
    case RECEIVE_TOOLS: {
      const toolbar = {
        tools: action.payload,
      };

      return {
        ...state,
        toolbar,
      };
    }

    case RECEIVE_HEADER_LINKS:
      return {
        ...state,
        links: action.payload,
      };

    case RECEIVE_TOP_BAR_TEXT:
      return {
        ...state,
        topBarText: action.payload,
      };

    // Update bag counter:
    // - Auth payload contains the bag and the user.
    // - Receive bag payload just contains the bag.
    case LOGIN_SUCCESS:
      return updateItemCount(action.payload.bag, 'Bag');

    case RECEIVE_BAG:
      return updateItemCount(action.payload, 'Bag');

    case RECEIVE_SAVED_ITEMS_MAP:
      return updateItemCount(action.payload, 'saved-items');

    case LOGOUT_SUCCESS:
      // Reset the bag and saved items counters.
      return resetItemCounts('Bag', 'saved-items');

    default:
      return state;
  }
}
