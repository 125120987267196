// @flow
import {
  ALL_ORDERS_UPDATE_REQUEST,
  ALL_ORDERS_UPDATE_SUCCESS,
  ALL_ORDERS_UPDATE_FAILURE,
  CHECK_ORDER_STATUS_REQUEST,
  CHECK_ORDER_STATUS_SUCCESS,
  CHECK_ORDER_STATUS_FAILURE,
  CANCEL_ORDER_UPDATE_SUCCESS,
  CANCEL_ORDER_UPDATE_FAILURE,
  CANCEL_ORDER_UPDATE_REQUEST,
  ALL_ORDERS_RETRY,
  DISMISS_CANCEL_MESSAGES,
} from '../../constants/orders';

import { LOGOUT_SUCCESS } from '../../constants/auth';

import { type Actions, type Orders } from '../../actions/orders';

type State = {
  orders: Orders,
  isFetching: boolean,
  isFetchingStatus: boolean,
  errorMessage: ?string,
  canCancelOrder: boolean,
};

export const initialState = {
  orders: {
    records: [],
    _metadata: {
      endDate: '',
      page: 0,
      perPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  canCancelOrder: false,
  cancelSuccessful: false,
  isFetching: false,
  isFetchingStatus: false,
  errorMessage: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function orders(state: State = initialState, action: Actions) {
  switch (action.type) {
    case ALL_ORDERS_UPDATE_REQUEST:
      return {
        ...state,

        isFetching: true,
        errorMessage: null,
      };

    case ALL_ORDERS_UPDATE_FAILURE:
      return {
        ...state,

        isFetching: false,
        errorMessage: action.payload,
      };

    case ALL_ORDERS_UPDATE_SUCCESS:
      return {
        ...state,

        isFetching: false,
        errorMessage: null,
        orders: action.orders,
      };

    case ALL_ORDERS_RETRY:
      return {
        ...state,

        // Just remove the error message
        // This will trigger the OrderAndReturnsPage to retry the request.
        errorMessage: null,
      };

    case CHECK_ORDER_STATUS_REQUEST:
      return {
        ...state,
        canCancelOrder: false,
        isFetchingStatus: true,
        cancelSuccessful: false,
        errorMessage: null,
      };

    case CHECK_ORDER_STATUS_FAILURE:
      return {
        ...state,
        isFetchingStatus: false,
        errorMessage: action.payload,
      };

    case CHECK_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        isFetchingStatus: false,
        ...action.payload,
      };

    case CANCEL_ORDER_UPDATE_REQUEST:
      return {
        ...state,
        isCancellingOrder: true,
      };

    case CANCEL_ORDER_UPDATE_FAILURE:
      return {
        ...state,
        isCancellingOrder: false,
        cancelSuccessful: false,
        failedToConfirmCancel: true,
      };

    case CANCEL_ORDER_UPDATE_SUCCESS: {
      let orderIndex = null;
      const { records } = state.orders;
      const order = action.payload;
      for (let i = 0; i < records.length; i++) {
        if (records[i].orderId === order.orderId) {
          orderIndex = i;
        }
      }
      const updatedOrders =
        orderIndex === null
          ? records
          : [
              ...records.slice(0, orderIndex),
              order,
              ...records.slice(orderIndex + 1),
            ];
      return {
        ...state,
        isCancellingOrder: false,
        cancelSuccessful: true,
        orders: {
          ...state.orders,
          records: updatedOrders,
        },
      };
    }

    case DISMISS_CANCEL_MESSAGES:
      return {
        ...state,
        cancelSuccessful: false,
        failedToConfirmCancel: false,
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
