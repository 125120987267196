import { CLOSE_LAYER, OPEN_LAYER } from '../constants/layer';

export const initialState = {
  isVisible: false,
  modifiers: [],
  onClose: null,
  target: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {{target, onClose}}
 */
export function layer(state = initialState, action) {
  switch (action.type) {
    case OPEN_LAYER: {
      const { payload } = action;
      const newState =
        typeof payload === 'string' ? { target: payload } : payload;

      return {
        ...state,
        ...newState,
        isVisible: true,
      };
    }

    case CLOSE_LAYER:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
