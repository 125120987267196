/* global window */
/* eslint-disable no-return-assign */
// @flow
import React from 'react';
import type { Embed as Props } from './embed.types.js';

type State = {
  // The width that is set in Contentful comes down as a prop, and the iframe's width
  // attribute is given the value. But the iframe may be smaller than this if the user
  // is viewing the site on mobile. The 'currentIframeWidth' state prop is calculated
  // based on the offsetWidth of the DOM node.
  currentIframeWidth: number,
};

class Embed extends React.Component<Props, State> {
  /**
   * @param {Object} props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      currentIframeWidth: props.width || 0,
    };
  }

  /**
   */
  componentDidMount() {
    this.setIframeWidth();
    window.addEventListener('resize', () => this.setIframeWidth());
  }

  /**
   */
  componentWillUnmount() {
    window.removeEventListener('resize', () => this.setIframeWidth());
  }

  setIframeWidth = () => {
    if (this.iframeElement) {
      this.setState({ currentIframeWidth: this.iframeElement.offsetWidth });
    }
  };

  iframeElement: ?HTMLIFrameElement = null;

  /**
   * @returns {*}
   */
  render() {
    const actualWidth = this.state.currentIframeWidth;
    return (
      <div className="c-embed">
        <iframe
          ref={ref => (this.iframeElement = ref)}
          className="c-embed__content"
          {...this.props}
          // The width and height props that come down from Contentful give us the
          // aspect ratio
          height={actualWidth * (this.props.height / this.props.width)}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    );
  }
}

export default Embed;
