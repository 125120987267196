// @flow
import React from 'react';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Link from 'brastrap/containers/link/LinkContainer';
import Image from 'brastrap/common/image/Image';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import type { Bustier as Props } from './bustier.types.js';

const Bustier = (props: Props) => {
  const { actions, makeClickable } = props;
  const mainButton = makeClickable && actions.length && actions[0];
  const bustierContent = (
    <section className="c-bustier">
      <header className="c-bustier__header">
        {props.image && (
          <Image
            className="c-bustier__img u-crop-circle"
            src={props.image.src}
            alt={props.image.label}
          />
        )}
        {props.title && (
          <h1 className="c-bustier__title">
            {props.index && (
              <span className="c-bustier__index">
                {props.index}
                <span className="u-hidden">:</span>
              </span>
            )}
            {props.title}
          </h1>
        )}
        {props.description && (
          <p className="c-bustier__desc">{props.description}</p>
        )}
      </header>
      <div className="c-bustier__main">
        {props.content && (
          <div className="s-prose s-prose--small">
            <MarkDown content={props.content} />
          </div>
        )}
        {!!props.actions.length && (
          <div className="c-bustier__actions">
            {props.actions.map((action, index) => (
              <ButtonLink
                key={index}
                {...action}
                isDummyButton={!!mainButton}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );

  return mainButton ? (
    <Link
      href={mainButton.link && mainButton.link.url}
      className="c-bustier__click-wrap"
    >
      {bustierContent}
    </Link>
  ) : (
    bustierContent
  );
};

Bustier.defaultProps = {
  actions: [],
};

export default Bustier;
