import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="24"
    viewBox="0 0 120 240"
  >
    <path d="M4 112c-4 4-4 12 0 16l100 100c4 4 9 3 12 0s4-8 0-12l-96-96 96-96c4-4 3-9 0-12s-8-4-12 0L4 112z" />
  </svg>
);

export default SvgIcon;
