import PropTypes from 'prop-types';
import React from 'react';
import FieldSelect from 'brastrap/common/field-select/FieldSelect';

const FieldSelectNumber = props => {
  const options = [];

  for (let i = props.min; i <= props.max; i++) {
    options.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  return <FieldSelect {...props} options={options} />;
};

FieldSelectNumber.propTypes = {
  ...FieldSelect.propTypes,

  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FieldSelectNumber.defaultProps = {
  ...FieldSelect.defaultProps,

  min: 0,
  max: 10,
};

export default FieldSelectNumber;
