import { CALL_API } from '../constants/api';
import {
  MAGAZINE_REQUESTED,
  CLOSE_MESSAGES,
  REQUEST_FAILED,
  REQUEST_SUCCEEDED,
} from '../constants/request-magazine';
import {
  dataLayerSendEmail,
  dataLayerRequestMagazine,
} from '../utils/data-layer';

/**
 * Dispatches an action that signifies that a magazine request was successful, along with an
 * action to update the data layer.
 * @param {object} details user details
 * @returns {{type, payload: object}}
 */
export const magazineRequestSucceeded = details => dispatch => {
  const { email } = details;
  const action = {
    type: REQUEST_SUCCEEDED,
    payload: {
      message: 'Magazine requested successfully',
      details,
    },
  };
  dispatch(action);
  dataLayerRequestMagazine();
  if (email) {
    dataLayerSendEmail(email);
  }
};

/**
 * Returns an action that signifies that a user has requested that they be sent the magazine.
 * @param {object} details user details
 * @returns {{type, payload: object}}
 */
export function requestMagazine(details) {
  return {
    type: MAGAZINE_REQUESTED,
    payload: {
      ...details,
    },
  };
}

/**
 * @param {string} message
 * @returns {{type, message, payload: {details: string}}}
 */
export function magazineRequestFailed(message) {
  return {
    type: REQUEST_FAILED,
    error: true,
    payload: {
      message,
    },
  };
}

/**
 * @param {string} details - Customer details object
 * @returns {Object} An action containing all the necessary field to make an API call
 */
export function apiRequestMagazine(details) {
  return {
    actions: {
      error: magazineRequestFailed,
      start: requestMagazine,
      success: magazineRequestSucceeded,
    },
    method: 'post',
    params: details,
    type: CALL_API,
    url: 'account/requestMagazine',
  };
}

/**
 * Close error and confirmation messages
 * @returns {{type: string}}
 */
export function closeMessages() {
  return {
    type: CLOSE_MESSAGES,
  };
}
