import {
  ADD_TO_QUEUE,
  EDITORIAL_MODAL_SUCCESS,
  EDITORIAL_MODAL_UPDATE,
  REMOVE_FROM_QUEUE,
  CLEAR_QUEUE,
} from '../constants/modal';

export const initialState = {
  content: {},
  queue: [],
};

/**
 * @param {Object} state
 * @param {{ payload: *, type: String }} action
 * @returns {*}
 */
export function modal(state = initialState, action = {}) {
  switch (action.type) {
    case EDITORIAL_MODAL_SUCCESS: {
      return {
        ...state,
        content: action.payload,
      };
    }

    case EDITORIAL_MODAL_UPDATE: {
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.modalId]: {
            ...state.content[action.payload.modalId],
            ...action.payload.data,
          },
        },
      };
    }

    case ADD_TO_QUEUE: {
      return {
        ...state,
        queue: [...state.queue, action.payload],
      };
    }

    case REMOVE_FROM_QUEUE: {
      return {
        ...state,
        queue: state.queue.filter(item => item !== action.payload),
      };
    }

    case CLEAR_QUEUE: {
      return {
        ...state,
        queue: [],
      };
    }

    default:
      return state;
  }
}
