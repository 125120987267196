import PropTypes from 'prop-types';
import React from 'react';

const Page = ({ children }) => (
  <main id="c-page" className="c-page" role="main">
    {children}
  </main>
);

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;

Page.Main = ({ children }) => <div className="c-page__main">{children}</div>;

Page.Main.propTypes = {
  children: PropTypes.node,
};
