import {
  SUBMIT_FAILURE,
  SUBMIT_SUCCESS,
  SUBMIT_REQUEST,
  PURCHASED_SIZE_SELECTED,
} from '../constants/review';

export const initialState = {
  displayConfirmMessage: false,
  isFetching: false,
  purchasedSize: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @return {*}
 */
export function review(state = initialState, action = {}) {
  switch (action.type) {
    case PURCHASED_SIZE_SELECTED:
      return {
        ...state,
        purchasedSize: action.payload,
      };

    case SUBMIT_REQUEST:
      return {
        ...state,
        displayConfirmMessage: true,
        isFetching: true,
      };

    case SUBMIT_FAILURE:
    case SUBMIT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
