// @flow
import type { GetState } from 'redux';
import { type Container } from 'brastrap/editorial/editorial-container/editorial-container.types';
import {
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAILURE,
  PRODUCT_SELECT_TAB,
  PRODUCT_SELECT_STYLECOLOUR,
  PRODUCT_QUANTITY_CHANGE,
  PRODUCT_SELECT_SKU,
  PRODUCT_CLOSE_MESSAGES,
  ACCESSORY_SIZE_SELECT,
  ACCESSORY_COLOUR_SELECT,
  RECEIVE_AVAILABLE_SIZES,
  AVAILABLE_SIZES_FAILURE,
  AVAILABLE_SIZES_REQUEST,
  RECEIVE_GIFT_VOUCHER_PAGE,
  PRODUCT_GALLERY_SET_ZOOM,
  RECEIVE_IMAGE_DIMENSIONS,
  RECEIVE_THUMBNAIL_WIDTH,
  SET_SIZE_GRID_CONTAINER_WIDTH,
  UPDATE_ACCESSORY_SKU,
  RECIEVE_COLOURS_NOT_IN_STOCK,
  COLOURS_NOT_IN_STOCK_REQUEST,
  COLOURS_NOT_IN_STOCK_FAILURE,
} from '../constants/product';

import { CALL_API } from '../constants/api';

import { setDynamicRemarketing } from '../actions/dynamic-remarketing';

import { dataLayerSetProduct } from '../utils/data-layer';

export type ProductTab = {
  id: string,
  content: string | string[] | Container[],
  current?: boolean,
};

type Dispatch = (action: Actions | ThunkAction) => any; // eslint-disable-line no-use-before-define
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

/**
 * Format the product page tabs
 *
 * @param {object} product
 * @returns {Array}
 */
const getTabs = (product: ProductData): ProductTab[] => {
  const tabs = [];

  if (product.description) {
    tabs.push({
      id: 'product-description',
      content: product.description,
    });
  }

  if (product.additionalDetails) {
    tabs.push({
      id: 'product-details',
      content: product.additionalDetails,
    });
  }

  if (product.deliveryInformation) {
    tabs.push({
      id: 'delivery-and-returns',
      content: product.deliveryInformation,
    });
  }

  if (product.containers && product.containers.length > 0) {
    const label = (product.containers[0] && product.containers[0].label) || '';
    tabs.push({
      id: label.toLowerCase().split(' ').join('-'),
      content: product.containers,
      title: label,
    });
  }

  if (tabs.length) {
    tabs[0].current = true;
  }

  return tabs;
};

type SetProductAction = {
  type: typeof PRODUCT_SUCCESS,
  payload: {
    data: ProductData,
    tabs: ProductTab[],
    selectedSku?: Sku | null,
  },
};

export const setProduct = (product: any) => (dispatch: any) => {
  const styleColour = {
    data: product,
    tabs: getTabs(product),
  };

  dataLayerSetProduct(styleColour.data);

  dispatch({
    type: PRODUCT_SUCCESS,
    payload: styleColour,
  });

  dispatch(setDynamicRemarketing());
};

type ProductRequestAction = {
  type: typeof PRODUCT_REQUEST,
  payload: string,
};
/**
 * When a product api call is made, pass the productId to the reducer.
 *
 * @param {string} productId
 * @returns {{type, payload: {string}}}
 */
export function productRequest(productId: string): ProductRequestAction {
  return {
    type: PRODUCT_REQUEST,
    payload: productId,
  };
}

/**
 * @param {object} product
 * @returns {{type, payload: {object}}}
 */
export function productSuccess(product: ProductData) {
  return setProduct(product);
}

type ProductFailureAction = {
  type: typeof PRODUCT_FAILURE,
  payload: Error,
  error: boolean,
};
/**
 * @param {string} message
 * @returns {{type: string, payload: string, error: boolean}}
 */
export function productFailure(message: Error): ProductFailureAction {
  return {
    type: PRODUCT_FAILURE,
    payload: message,
    error: true,
  };
}

type SelectTabAction = {
  type: typeof PRODUCT_SELECT_TAB,
  payload: ProductTab,
};
/**
 * @param {object} tab
 * @returns {{type, payload: object}}
 */
export function selectTab(tab: ProductTab): SelectTabAction {
  return {
    type: PRODUCT_SELECT_TAB,
    payload: tab,
  };
}

type SelectStyleColourAction = {
  type: typeof PRODUCT_SELECT_STYLECOLOUR,
  payload?: string,
};
/**
 * @param {string} styleColourCode
 * @returns {{type, payload: object}}
 */
export function selectStyleColour(
  styleColourCode?: string
): SelectStyleColourAction {
  return {
    type: PRODUCT_SELECT_STYLECOLOUR,
    payload: styleColourCode,
  };
}

type UpdateQuantityAction = {
  type: typeof PRODUCT_QUANTITY_CHANGE,
  payload: number,
};
/**
 * @param {number} quantity
 * @returns {{type, payload: number}}
 */
export function updateQuantity(quantity: number): UpdateQuantityAction {
  return {
    type: PRODUCT_QUANTITY_CHANGE,
    payload: parseInt(quantity, 10),
  };
}

type SelectSkuAction = {
  type: typeof PRODUCT_SELECT_SKU,
  payload: Sku,
};
/**
 * @param {object} sku
 * @returns {{type, payload: string}}
 */
export function selectSku(sku: Sku): SelectSkuAction {
  return {
    type: PRODUCT_SELECT_SKU,
    payload: sku,
  };
}

type SetZoomAction = {
  type: typeof PRODUCT_GALLERY_SET_ZOOM,
  payload: boolean,
};
/**
 * @param {boolean} isZoomed
 * @returns {{type, payload: number}}
 */
export function setZoom(isZoomed: boolean): SetZoomAction {
  return {
    type: PRODUCT_GALLERY_SET_ZOOM,
    payload: isZoomed,
  };
}

type ReceiveImageDimensionsAction = {
  type: typeof RECEIVE_IMAGE_DIMENSIONS,
  payload: {
    figureDimensions: number[],
  },
};
/**
 * @param {Array} dimensions
 * @returns {{type, payload: {}}}
 */
export function receiveImageDimensions(
  dimensions: number[]
): ReceiveImageDimensionsAction {
  return {
    type: RECEIVE_IMAGE_DIMENSIONS,
    payload: {
      figureDimensions: dimensions,
    },
  };
}

type ReceiveThumbnailWidthAction = {
  type: typeof RECEIVE_THUMBNAIL_WIDTH,
  payload: {
    galleryThumbnailWidth: number,
  },
};
/**
 * @param {Number} width
 * @returns {{type: string, payload: {}}}
 */
export function receiveThumbnailWidth(
  width: number
): ReceiveThumbnailWidthAction {
  return {
    type: RECEIVE_THUMBNAIL_WIDTH,
    payload: {
      galleryThumbnailWidth: width,
    },
  };
}

type SetSizeGridContainerWidthAction = {
  type: typeof SET_SIZE_GRID_CONTAINER_WIDTH,
  payload: number,
};
/**
 * @param {number} width
 * @returns {{type, payload: number}}
 */
export function setSizeGridContainerWidth(
  width: number
): SetSizeGridContainerWidthAction {
  return {
    type: SET_SIZE_GRID_CONTAINER_WIDTH,
    payload: width,
  };
}

type SelectAccessorySkuAction = {
  type: typeof ACCESSORY_SIZE_SELECT,
  payload: {
    styleCode: string,
    sku: string,
    defaultStyleColourId: number,
  },
};
/**
 * @param {string} styleCode
 * @param {string|object} sku
 * @param {number} defaultStyleColourId
 * @returns {{type: string, payload: object}}
 */
export function selectAccessorySku(
  styleCode: string,
  sku: string | Sku,
  defaultStyleColourId: number
): SelectAccessorySkuAction {
  return {
    type: ACCESSORY_SIZE_SELECT,
    payload: {
      styleCode,
      // TODO: find out who's being cheeky and sending a sku down in different forms
      sku: typeof sku === 'string' ? sku : sku.skuCode,
      defaultStyleColourId,
    },
  };
}

type SelectAccessoryColourAction = {
  type: typeof ACCESSORY_COLOUR_SELECT,
  payload: {
    defaultStyleColourId: number,
    styleColourCode: string,
  },
};
/**
 * @param {string} styleColourCode
 * @param {number} defaultStyleColourId
 * @returns {{type: string, payload: object}}
 */
export function selectAccessoryColour(
  styleColourCode: string,
  defaultStyleColourId: number
): SelectAccessoryColourAction {
  return {
    type: ACCESSORY_COLOUR_SELECT,
    payload: {
      defaultStyleColourId,
      styleColourCode,
    },
  };
}

type UpdateAccessorySkuAction = {
  type: typeof UPDATE_ACCESSORY_SKU,
  payload: {
    defaultStyleColourId: number,
    styleColourCode: string,
  },
};
/**
 * @param {string} styleColourCode
 * @param {number} defaultStyleColourId
 * @returns {{type: string, payload: string}}
 */
export function updateAccessorySku(
  styleColourCode: string,
  defaultStyleColourId: number
): UpdateAccessorySkuAction {
  return {
    type: UPDATE_ACCESSORY_SKU,
    payload: {
      defaultStyleColourId,
      styleColourCode,
    },
  };
}

type ApiGetProductAction = {
  actions: {
    success: (product: ProductData) => any,
    error: (err: Error) => ProductFailureAction,
    start: (productId: string) => ProductRequestAction,
  },
  type: typeof CALL_API,
  url: Url,
};
/**
 * Make the API request to the product api to retrieve the product data
 *
 * @param {string} productId
 *
 * @returns {{
 * actions: {
 *  error: productFailure,
 *  start: productRequest,
 *  success: productSuccess
 * },
 * type,
 * url: string
 * }}
 */
export function apiGetProduct(productId: string): ApiGetProductAction {
  return {
    actions: {
      error: productFailure,
      start: productRequest,
      success: productSuccess,
    },
    type: CALL_API,
    url: `products/${productId}`,
  };
}

type ColoursNotInStockFailure = {
  type: typeof COLOURS_NOT_IN_STOCK_FAILURE,
  error: boolean,
  payload: {
    message: Error,
  },
};

type ColoursNotInStockRequestAction = {
  type: typeof COLOURS_NOT_IN_STOCK_REQUEST,
};

type ColoursNotInStockRequestPayload = {
  styleCode: string,
  size: string,
};

type ApiGetColoursNotInStockAction = {
  actions: {
    success: (skus: string[]) => any,
    error: (err: Error) => ColoursNotInStockFailure,
    start: () => ColoursNotInStockRequestAction,
  },
  type: typeof CALL_API,
  url: Url,
};

/**
 *
 *
 * @param {object} payload
 * @returns {{type, payload: {}}}
 */
export function coloursNotInStockRequest(
  payload: ColoursNotInStockRequestPayload
): ColoursNotInStockRequestAction {
  return {
    type: COLOURS_NOT_IN_STOCK_REQUEST,
    payload: {
      payload,
    },
  };
}

/** Make the API request to the product api to retrieve the stock status for all colours
for a given a given stylecode and size
*
* @param {string} styleCode
* @param {string} size
*
* @returns {{
* actions: {
*  error: coloursNotInStockFailure,
*  start: coloursNotInStockRequest,
*  success: receiveColoursNotInStock
* },
* method,
* type,
* url: string
* }}
*/
export function apiGetColoursNotInStock(styleCode: string, size: string): any {
  return {
    actions: {
      error: coloursNotInStockFailure,
      start: coloursNotInStockRequest,
      success: receiveColoursNotInStock,
    },
    method: 'get',
    params: { styleCode, size },
    type: CALL_API,
    url: `products/stockStatus/${styleCode}/${size}`,
  };
}

type ReceiveColoursNotInStock = {
  type: typeof RECIEVE_COLOURS_NOT_IN_STOCK,
  payload: string[],
};

/**
 * @param {Array} skus
 * @return {{type, payload: *}}
 */
export function receiveColoursNotInStock(skus: string[] | null): any {
  return {
    type: RECIEVE_COLOURS_NOT_IN_STOCK,
    payload: skus,
  };
}

/**
 * @param {Array} error
 * @return {{type, payload: *}}
 */
function coloursNotInStockFailure(error: Error): ColoursNotInStockFailure {
  return {
    type: COLOURS_NOT_IN_STOCK_FAILURE,
    error: true,
    payload: {
      message: error,
    },
  };
}

/**
 * @param {string} styleCode
 * @param {string} size
 * @return {void}
 */
export const getColoursNotInStock = (styleCode: string, size: string) => (
  dispatch: Dispatch
) => {
  dispatch(apiGetColoursNotInStock(styleCode, size));
};

type CloseMessagesAction = {
  type: typeof PRODUCT_CLOSE_MESSAGES,
};
/**
 * Close error and confirmation messages
 * @returns {{type, payload: number}}
 */
export function closeMessages(): CloseMessagesAction {
  return {
    type: PRODUCT_CLOSE_MESSAGES,
  };
}

type ReceiveGiftVoucherPageAction = {
  type: typeof RECEIVE_GIFT_VOUCHER_PAGE,
  payload: boolean,
};
/**
 * @param {Boolean} paperVouchers
 * @returns {{type, payload: *}}
 */
export function receiveGiftVoucherPage(
  paperVouchers: boolean
): ReceiveGiftVoucherPageAction {
  return {
    type: RECEIVE_GIFT_VOUCHER_PAGE,
    payload: paperVouchers,
  };
}

type ReceiveAvailableSizes = {
  type: typeof RECEIVE_AVAILABLE_SIZES,
  payload: DropDownOption[],
};
/**
 * @param {Array} sizes
 * @return {{type, payload: *}}
 */
export function receiveAvailableSizes(sizes: string[]): ReceiveAvailableSizes {
  // Format sizes for dropdown menu
  const payload = sizes.map(size => ({ label: size, value: size }));
  return {
    type: RECEIVE_AVAILABLE_SIZES,
    payload,
  };
}

type AvailableSizesFailure = {
  type: typeof AVAILABLE_SIZES_FAILURE,
  error: boolean,
  payload: {
    message: Error,
  },
};
/**
 * @param {Array} error
 * @return {{type, payload: *}}
 */
function availableSizesFailure(error: Error): AvailableSizesFailure {
  return {
    type: AVAILABLE_SIZES_FAILURE,
    error: true,
    payload: {
      message: error,
    },
  };
}

type AvailableSizesRequest = {
  type: typeof AVAILABLE_SIZES_REQUEST,
};
/**
 * @return {{type}}
 */
function availableSizesRequest() {
  return {
    type: AVAILABLE_SIZES_REQUEST,
  };
}

type ApiGetProductSizes = {
  actions: {
    success: (sizes: string[]) => ReceiveAvailableSizes,
    error: (err: Error) => AvailableSizesFailure,
    start: () => AvailableSizesRequest,
  },
  type: typeof CALL_API,
  url: Url,
};

/**
 * Make the API request to the product api to retrieve the product sizes
 * @param {number} styleColourId
 * @returns {Object}
 */
export function apiGetProductSizes(styleColourId?: number): ApiGetProductSizes {
  return {
    actions: {
      success: receiveAvailableSizes,
      error: availableSizesFailure,
      start: availableSizesRequest,
    },
    type: CALL_API,
    url: styleColourId ? `products/sizes/${styleColourId}` : 'product/braSize',
  };
}

export const getBraSizes = () => (dispatch: Dispatch) => {
  dispatch(apiGetProductSizes());
};

export type Actions =
  | SetProductAction
  | ProductRequestAction
  | ProductFailureAction
  | SelectTabAction
  | UpdateQuantityAction
  | SelectSkuAction
  | SetZoomAction
  | ReceiveImageDimensionsAction
  | ReceiveThumbnailWidthAction
  | SetSizeGridContainerWidthAction
  | SelectAccessorySkuAction
  | SelectAccessoryColourAction
  | ApiGetProductAction
  | CloseMessagesAction
  | ReceiveGiftVoucherPageAction
  | ReceiveAvailableSizes
  | AvailableSizesFailure
  | AvailableSizesRequest
  | ApiGetProductSizes
  | UpdateAccessorySkuAction
  | ApiGetColoursNotInStockAction
  | ColoursNotInStockFailure
  | ReceiveColoursNotInStock;
