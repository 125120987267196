import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1 1 26 24"
    className="c-icon__glyph"
  >
    <path
      d="M1 8.38C1 11.57 3.75 14.96 6.49 17.57C9.23 20.17 11.97 21.98 11.97 21.98C11.98 21.98 11.98 21.99 12 22C12.02 21.99 12.02 21.98 12.03 21.98C12.03 21.98 14.77 20.17 17.51 17.57C20.25 14.96 23 11.57 23 8.38C23 4.74 20.7 2 17.48 2C16.1 2 14.95 2.2 14.03 2.73C13.13 3.24 12.46 4.06 12 5.28C11.54 4.06 10.87 3.24 9.97 2.73C9.05 2.2 7.9 2 6.52 2C3.3 2 1 4.74 1 8.38Z"
      id="a1jhSHXmY"
    />
  </svg>
);

export default SvgIcon;
