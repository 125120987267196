const namespace = 'bravissimo/delivery-addresses';

export const UPDATE_REQUEST = `${namespace}/UPDATE_REQUEST`;
export const UPDATE_FAILURE = `${namespace}/UPDATE_FAILURE`;
export const UPDATE_SUCCESS = `${namespace}/UPDATE_SUCCESS`;
export const CLOSE_MESSAGES = `${namespace}/CLOSE_MESSAGES`;
export const MODAL_CLOSED = `${namespace}/MODAL_CLOSED`;
export const ADDRESS_ADDED = `${namespace}/ADDRESS_ADDED`;
export const EDIT_STARTED = `${namespace}/EDIT_STARTED`;
export const DELETE_STARTED = `${namespace}/DELETE_STARTED`;
