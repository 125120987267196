// @flow
import React, { type Node } from 'react';
import { applyModifiers } from '../../utils.js';

type Props = {
  children: Node,
  className?: string,
  modifiers?: Modifier | Modifiers,
  hideContent?: boolean,
  style?: {}, // Could have any number of properties.
};

const Grid = ({
  className,
  children,
  modifiers,
  hideContent,
  style,
}: Props) => {
  if (hideContent) return null;

  const props: { className: string, style?: {} } = {
    className: `${applyModifiers('l-grid', modifiers)}${
      className ? ` ${className}` : ''
    }`,
  };

  if (style) {
    props.style = style;
  }

  return <div {...props}>{children}</div>;
};

export default Grid;

type UnitProps = {
  children: Node,
  className?: string,
  modifiers?: Modifiers | Modifier,
};

Grid.Unit = ({ className, children, modifiers }: UnitProps) => {
  let actualClassName = applyModifiers('l-grid__unit', modifiers);

  if (className) {
    actualClassName += ` ${className}`;
  }

  return <div className={actualClassName}>{children}</div>;
};

Grid.Content = (props: UnitProps) => (
  <Grid.Unit modifiers={['content']} {...props} />
);

Grid.Aside = (props: UnitProps) => (
  <Grid.Unit modifiers={['aside']} {...props} />
);

Grid.EditorialNav = (props: UnitProps) => (
  <Grid.Unit modifiers={['editorial-nav']} {...props} />
);

Grid.EditorialMain = (props: UnitProps) => (
  <Grid.Unit modifiers={['editorial-main']} {...props} />
);

// Explicitly name components so that they display correctly in React DevTools
Grid.Aside.displayName = 'Grid.Aside';
Grid.Content.displayName = 'Grid.Content';
Grid.Unit.displayName = 'Grid.Unit';
Grid.EditorialNav.displayName = 'Grid.EditorialNav';
Grid.EditorialMain.displayName = 'Grid.EditorialMain';
