// @flow
import React, { type ChildrenArray } from 'react';
import { FormattedMessage } from 'react-intl';
import { type ToolShape } from 'brastrap/common/toolbar/Tool';
import Link from 'brastrap/containers/link/LinkContainer';
import Toolbar from 'brastrap/common/toolbar/Toolbar';
import Icon from 'brastrap/common/icon/Icon';
import logoDesktop from '../../../assets/vectors/logos/bravissimo-desktop.svg';
import logoMobile from '../../../assets/vectors/logos/bravissimo-mobile.svg';
import Search, { type SearchProps } from './Search';

type Props = {
  links: Array<{
    id: string,
    label: string,
    onClick?: SyntheticEvent<HTMLAnchorElement>,
    url?: string,
  }>,
  onHeaderMenuPress: (SyntheticEvent<HTMLAnchorElement> | string) => void,
  toolbar: {
    name?: string,
    title?: string,
    tools?: Array<ToolShape>,
  },
  isDesktop: boolean,
  search: SearchProps,
  children: ChildrenArray<*>,
  locale: string
};

const Header = (props: Props) => (
  <div className="c-header" role="banner">
    <div className={`c-header__body ${props.locale.selected === 'en-US' ? 'c-header__body--usOnly' : ''}`}>
      {!props.isDesktop && props.locale.selected === 'en-GB' && (
        <div className="c-header__menu">
          <Link
            onClick={ev => {
              ev.preventDefault();
              props.onHeaderMenuPress(ev);
              return false;
            }}
            href="#navigation"
            data-menu="navigation"
          >
            <Icon icon="menu--large" />
          </Link>
        </div>
      )}
      {props.isDesktop && (
        <div className={`c-header__menu ${props.locale.selected === 'en-US' ? 'c-header__menu--usOnly' : ''}`}>
          <nav
            className="c-header__nav"
            role="navigation"
            aria-labelledby="info-nav"
          >
            <h1 className="c-header__nav__title u-hidden" id="info-nav">
              <FormattedMessage
                id="header.nav.title"
                defaultMessage="About Bravissimo"
                description="Header nav title"
              />
            </h1>
            <ul className="c-header__nav__items">
              {props.links.map(link => (
                <li key={link.id} className="c-header__nav__item">
                  <Link
                    href={link.url || '#'}
                    title={link.label}
                    onClick={link.onClick}
                  >
                    {<span>{link.label}</span>}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          {props.children}
        </div>
      )}

      <div
        className={`c-header__title ${props.locale.selected === 'en-US' ? 'c-header__title--usOnly' : ''}`}>
        <Link href="/" rel="home">
          <span
            id="header-logo"
            className={`c-header__logo ${props.locale.selected === 'en-US' ? 'c-header__logo--usOnly' : ''}`}
            dangerouslySetInnerHTML={{
              __html: props.locale.selected === 'en-US' ? logoMobile : props.isDesktop ? logoDesktop : logoMobile,
            }}
          />
        </Link>
      </div>

      <div className={`c-header__search-container ${props.locale.selected === 'en-US' ? 'c-header__search-container--usOnly' : ''}`}>
        <Toolbar
          {...props.toolbar}
          locale={props.locale}
          hamburgerMenu=
          {!props.isDesktop && props.locale.selected === 'en-US' && (
            <div className={`c-header__menu ${props.locale.selected === 'en-US' ? 'c-header__menu--usOnly' : ''}`}>
              <Link
                onClick={ev => {
                  ev.preventDefault();
                  props.onHeaderMenuPress('shop');
                  return false;
                }}
                href="#navigation"
                data-menu="navigation"
              >
                <Icon icon="menu--large" />
              </Link>
            </div>
          )}
          search=
          {!props.isDesktop && props.locale.selected === 'en-US' && (
            <div className={`c-header__menu ${props.locale.selected === 'en-US' ? 'c-header__menu--usOnly' : ''}`}>
              <Link
               onClick={ev => {
                ev.preventDefault();
                props.onHeaderMenuPress('search');
                return false;
              }}
                data-menu="navigation"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 240 240"
                >
                  <path
                    fillRule="evenodd"
                    fill='#662653'
                    d="M186 173l51 51c4 4 4 10 0 14-4 4-10 4-14 0L173 187c-18 15-42 25-67 25C47 211 0 163 0 106 0 48 48 0 106 0c58 0 106 48 106 106 0 25-9 49-25 67zM19 106c0-48 38-86 86-86 48 0 86 39 86 87S153 192 106 192c-48 0-86-39-86-87z"
                  />
                </svg>
              </Link>
            </div>
          )}
        />
        {props.isDesktop && <Search {...props.search} />}
      </div>
    </div>
    {!props.isDesktop && <Search {...props.search} />}
  </div>
);

Header.defaultProps = {
  links: [],
  onHeaderMenuPress: () => { },
};

export default Header;
