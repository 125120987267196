// @flow
// TODO: Upgrade Flow to a version that supports React hooks
// $FlowFixMe
import React, { type Node, useEffect } from 'react';
import { connect } from 'react-redux';
import core from '@bravissimolabs/website-core';
import EditorialModal from 'brastrap/containers/editorial-modal/EditorialModalContainer';
import ConsentModal from '../../brastrap/components/containers/consent-modal/ConsentModalContainer';
import RedirectModal from './RedirectModalContainer';

import {
  displaySuggestionModal,
  localeSuccess,
  shouldDisplayRedirectionModal,
} from '../actions/app/locale';

import { startTimer, cancelTimer } from '../actions/timers';

import { addToQueue, clearQueue, modalSuccess } from '../actions/modal';

import { REDIRECT_MODAL_ID, CONSENT_MODAL_ID } from '../constants/app';

type Props = {
  pathname: string,
  onLoad: () => void,
  component: Node | null,
};

const TIMER_PREFIX = 'modal_add_to_queue';

const Modal = (props: Props) => {
  useEffect(() => {
    props.onLoad();
  }, [props.pathname]);

  return props.component;
};

Modal.populateStore = async ({ dispatch, getState }, props, req) => {
  const editorialApi = core.content.editorial;
  const localeApi = core.content.locale;
  const { locale } = getState().app;

  if (locale && shouldDisplayRedirectionModal(locale)) {
    const { expected } = locale;
    const modalContent = await localeApi.getSuggestionModalForLocale(expected);
    dispatch(localeSuccess({ modalContent }));
  }

  const editorialModals = await editorialApi.getEditorialModals();

  const modalsToShow = editorialModals
    .filter(modal => {
      if (req.cookies[`${modal.modalId.toUpperCase()}_MODAL`]) {
        return false;
      }
      return modal;
    })
    .reduce((allModals, modal) => {
      const allModalsCopy = { ...allModals };

      if (!allModalsCopy[modal.modalId]) {
        allModalsCopy[modal.modalId] = modal;
      }

      return allModalsCopy;
    }, {});

  if (Object.keys(modalsToShow).length) {
    dispatch(modalSuccess(modalsToShow));
  }
};

const mapStateToProps = ({
  app: {
    locale,
    routing: { pathname },
  },
  auth: { isAuthenticated },
  modal: { content, queue },
  timers,
}) => {
  const modalId = queue.length && queue[0];
  let component;

  switch (modalId) {
    case 0:
      component = null;
      break;
    case REDIRECT_MODAL_ID:
      component = <RedirectModal modifiers={locale.expected} />;
      break;
    case CONSENT_MODAL_ID:
      component = <ConsentModal />;
      break;
    default:
      component = <EditorialModal />;
  }

  return {
    modalContent: content,
    component,
    pathname,
    timers,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...stateProps,
  ...ownProps,

  onLoad: () => {
    const { modalContent, pathname, timers, isAuthenticated } = stateProps;
    const modalIds = Object.keys(modalContent);
    dispatch(clearQueue());
    dispatch(displaySuggestionModal());

    // If there are still modal timers running that were kicked
    // off on a previous page then cancel them
    modalIds.forEach(id => {
      const timerId = `${TIMER_PREFIX}_${id}`;
      if (timers[timerId]) {
        dispatch(cancelTimer(timerId));
      }
    });

    modalIds
      .filter(
        id =>
          !(modalContent[id].pageExclusions || []).some(page =>
            pathname.includes(page)
          ) &&
          (modalContent[id].target === 'All' ||
            (modalContent[id].target === 'Guest' && !isAuthenticated) ||
            (modalContent[id].target === 'Authenticated' && isAuthenticated))
      )
      .forEach(id => {
        dispatch(
          startTimer(
            `${TIMER_PREFIX}_${id}`,
            parseInt(modalContent[id].delay, 10) * 1000,
            () => dispatch(addToQueue(id))
          )
        );
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Modal);
