import { CALL_API } from '../constants/api';
import {
  RECEIVE_EMAIL,
  RECEIVE_REASONS,
  RECEIVE_SOURCE,
  UNSUBSCRIBE,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_FAILURE,
} from '../constants/unsubscribe';

/**
 * @param {object} email - email address to unsubscribe
 * @returns {Object} Redux action
 */
export function receiveEmail(email) {
  return {
    type: RECEIVE_EMAIL,
    payload: {
      email,
    },
  };
}

/**
 * @param {object} sourceId - ID of unsubscribe source
 * @returns {Object} Redux action
 */
export function receiveSource(sourceId) {
  return {
    type: RECEIVE_SOURCE,
    payload: {
      sourceId,
    },
  };
}

/**
 * @param {array<object>} reasons - reason value/label list
 * @returns {Object} Redux action
 */
export function receiveReasons(reasons) {
  return {
    type: RECEIVE_REASONS,
    payload: {
      reasons,
    },
  };
}

/**
 * @param {object} email - email address to unsubscribe
 * @param {object} reason - reason code for unsubscription
 * @param {object} comments - any comments
 * @returns {Object} An action containing all the necessary field to make an API call
 */
export const unsubscribe = (email, reason, comments) => (
  dispatch,
  getState
) => {
  const { sourceId } = getState().unsubscribe;
  return dispatch({
    actions: {
      start: unsubscribeStart,
      success: unsubscribeSuccess,
      error: unsubscribeFailure,
    },
    method: 'post',
    params: { email, reason, comments, sourceId },
    type: CALL_API,
    url: 'account/unsubscribe',
  });
};

/**
 * @param {object} email
 * @returns {{type, payload: object}}
 */
export function unsubscribeStart({ email }) {
  return {
    type: UNSUBSCRIBE,
    payload: {
      email,
    },
  };
}

/**
 * @param {object} details
 * @returns {{type, payload: object}}
 */
export function unsubscribeSuccess(details) {
  return {
    type: UNSUBSCRIBE_SUCCESS,
    payload: details,
  };
}

/**
 * @param {object} details
 * @returns {{type, error: boolean, payload: object}}
 */
export function unsubscribeFailure(details) {
  return {
    type: UNSUBSCRIBE_FAILURE,
    error: true,
    payload: details,
  };
}
