// @flow
import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  type intlShape,
} from 'react-intl';
import Link from 'brastrap/containers/link/LinkContainer';
import LocaleSwitcher, {
  type Locales,
  type Locale,
} from 'brastrap/global/locale-switcher/LocaleSwitcher';

type Props = {
  links: Array<{
    label: string,
    url: string,
    id?: string,
  }>,
  intl: intlShape,
  locale: {
    available: Locales,
    selected: string,
  },
};

// Define the locale messages for the locale switcher.
// @LOCALE TODO - remove hardcoding
const localeLabels = defineMessages({
  'en-GB': { id: 'locale.en-GB', defaultMessage: 'UK/Rest of World' },
  'en-US': { id: 'locale.en-US', defaultMessage: 'USA' },
  'es-US': { id: 'locale.es-US', defaultMessage: 'USA (Spanish)' },
  'en-CA': { id: 'locale.en-CA', defaultMessage: 'Canada' },
  International: {
    id: 'locale.international',
    defaultMessage: 'International',
  },
  default: { id: 'locale.default', defaultMessage: 'Other' },
});

const ContentInfo = (props: Props) => {
  const {
    intl: { formatMessage },
    links,
    locale: { available, selected },
  } = props;

  return (
    <div className="c-contentinfo" role="contentinfo">
      <div className="c-contentinfo__body">
        <div />

        <div>
          <p className="c-contentinfo__legal">
            <small className="c-contentinfo__copyright">
              <FormattedMessage
                id="contentinfo.legal.copyright"
                defaultMessage="&#169; Bravissimo Ltd"
                description="Copyright"
              />
            </small>
            {links.map(link => (
              <Link
                key={link.id}
                className="c-contentinfo__policy-link"
                title={link.label}
                href={link.url}
              >
                {link.label}
              </Link>
            ))}
          </p>
        </div>

        <fieldset className="c-contentinfo__localise">
          <legend className="u-hidden">
            <FormattedMessage
              id="contentinfo.localise.legend"
              defaultMessage="Localise"
              description="Content info localise legend"
            />
          </legend>

          {available && available.length > 1 && (
            <span>
              <label className="u-hidden" htmlFor="localise-language">
                <FormattedMessage
                  id="contentinfo.localise.language"
                  defaultMessage="Language"
                  description="Content info localise language"
                />
              </label>
              <LocaleSwitcher
                target
                current={selected.split('-')[1].toLowerCase()}
                locales={available.map((locale: Locale) => ({
                  locale: locale.locale,
                  name: formatMessage(localeLabels[locale.locale || 'default']),
                  path: locale.path,
                  flagCode:
                    locale.flagCode ||
                    locale.locale.split('-')[1].toLowerCase(),
                }))}
              />
            </span>
          )}
        </fieldset>
      </div>
    </div>
  );
};

ContentInfo.defaultProps = {
  links: [],
  locale: {
    available: [],
  },
};

export default injectIntl(ContentInfo);
