import accountLarge from './IconAccountLarge';
import add from './IconAdd';
import arrowBox from './IconArrowBox';
import bagLarge from './IconBagLarge';
import brushStroke from './IconBrushStroke';
import contactLarge from './IconContactLarge';
import deleteIcon from './IconDelete';
import dismissLarge from './IconDismissLarge';
import dismiss from './IconDismiss';
import downLarge from './IconDownLarge';
import email from './IconEmail';
import facebook from './IconFacebook';
import favourite from './IconFavourite';
import favouritesLarge from './IconFavouritesLarge';
import filter from './IconFilter';
import heart from './IconHeart';
import information from './IconInformation';
import instagram from './IconInstagram';
import leftLarge from './IconLeftLarge';
import locateLarge from './IconLocateLarge';
import mapMarker from './IconMapMarker';
import menuLarge from './IconMenuLarge';
import menu from './IconMenu';
import ornamentUnderline from './IconOrnamentUnderline';
import paypal from './IconPaypal';
import pinterest from './IconPinterest';
import placeholder from './IconPlaceholder';
import rating from './IconRating';
import remove from './IconRemove';
import rightLarge from './IconRightLarge';
import searchLarge from './IconSearchLarge';
import search from './IconSearch';
import secure from './IconSecure';
import share from './IconShare';
import sizeGuideBriefs from './IconSizeGuideBriefs';
import sizeGuideLingerie from './IconSizeGuideLingerie';
import stockFinder from './IconStockFinder';
import thumbsDown from './IconThumbsDown';
import thumbsUp from './IconThumbsUp';
import tiktok from './IconTiktok';
import toolBarBagLarge from './IconToolbarBagLarge';
import toolBarSavedItemsLarge from './IconToolbarSavedItemsLarge';
import upLarge from './IconUpLarge';
import viewGridLarge from './IconViewGridLarge';
import viewGridSmall from './IconViewGridSmall';
import viewList from './IconViewList';
import youtube from './IconYoutube';

export default {
  'account--large': accountLarge,
  add,
  'arrow-box': arrowBox,
  'bag--large': bagLarge,
  'brush-stroke': brushStroke,
  'contact--large': contactLarge,
  delete: deleteIcon,
  'dismiss--large': dismissLarge,
  dismiss,
  'down--large': downLarge,
  email,
  facebook,
  favourite,
  'favourites--large': favouritesLarge,
  filter,
  heart,
  information,
  instagram,
  'left--large': leftLarge,
  'locate--large': locateLarge,
  'menu--large': menuLarge,
  'map-marker': mapMarker,
  menu,
  'ornament-underline': ornamentUnderline,
  paypal,
  pinterest,
  placeholder,
  rating,
  remove,
  'right--large': rightLarge,
  'search--large': searchLarge,
  search,
  secure,
  share,
  'stock-finder': stockFinder,
  'size-guide-lingerie': sizeGuideLingerie,
  'size-guide-briefs': sizeGuideBriefs,
  'thumbs-down': thumbsDown,
  'thumbs-up': thumbsUp,
  tiktok,
  'toolbar--bag--large': toolBarBagLarge,
  'toolbar--saved-items--large': toolBarSavedItemsLarge,
  'up--large': upLarge,
  'view-grid-large': viewGridLarge,
  'view-grid-small': viewGridSmall,
  'view-list': viewList,
  youtube,
};
