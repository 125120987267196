import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
  >
    <rect x="0" y="84" width="200" height="32" rx="16" />
    <rect x="0" y="16" width="200" height="32" rx="16" />
    <rect x="0" y="152" width="200" height="32" rx="16" />
  </svg>
);

export default SvgIcon;
