import { connect } from 'react-redux';
import HeroPod from 'brastrap/editorial/hero-pod/HeroPod';
import { shouldHideEditorialContent } from 'shared/utils/utils';

const mapStateToProps = ({ browser: { deviceIs } }, ownProps) => ({
  ...ownProps,
  isDesktop: deviceIs.desktop,
  isTablet: deviceIs.tablet,
  isMobile: deviceIs.mobile,
  hideContent: shouldHideEditorialContent(deviceIs, ownProps),
});

export default connect(mapStateToProps)(HeroPod);
