import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';

const Price = props => {
  const priceFormatted = (
    <FormattedNumber
      value={props.price}
      style="currency"
      currency={props.currency}
    />
  );

  if (props.originalPrice && props.originalPrice !== props.price) {
    return (
      <span>
        <del className="c-price--old">
          <span className="u-hidden">
            <FormattedMessage id="price.was" defaultMessage="Was" />:
          </span>
          <FormattedNumber
            value={props.originalPrice}
            style="currency"
            currency={props.currency}
          />
        </del>
        <ins className="c-price--new">
          <span className="u-hidden">
            <FormattedMessage id="price.now" defaultMessage="Now" />:
          </span>
          {priceFormatted}
        </ins>
      </span>
    );
  }

  return <span className="c-price">{priceFormatted}</span>;
};

Price.propTypes = {
  currency: PropTypes.string.isRequired,
  originalPrice: PropTypes.number,
  price: PropTypes.number.isRequired,
};

export default Price;
