import { SET_SCROLL_POINT, CLEAR_SCROLL_POINT } from 'shared/constants/scroll';

export const initialState = {
  scrollPoint: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} state
 */
export function scroll(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SCROLL_POINT:
      return {
        ...state,

        scrollPoint: action.payload,
      };

    case CLEAR_SCROLL_POINT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
