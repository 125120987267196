export const mapStateToDynamicRemarketingData = (state, url) => {
  const {
    app: {
      config: {
        basePath,
        dynamicRemarketing: { enabled },
      },
      routing: { pathname },
    },
    checkout: {
      confirmed: {
        bag: { items },
      },
    },
    product: {
      styleColour: {
        data: {
          prices: { price },
          styleColourCode,
        },
      },
    },
  } = state;

  const pathnameWithoutBasePath = pathname.replace(basePath, '');
  const pathnameRoot =
    pathnameWithoutBasePath === ''
      ? '/'
      : pathnameWithoutBasePath.match(/^([a-z|-]*)\//)[1];
  const page = url || pathnameRoot;
  let pageType;
  let prodId;
  let totalValue;

  switch (page) {
    case '/':
      pageType = 'home';
      break;

    case 'collections':
      pageType = 'category';
      break;

    case 'confirm':
      pageType = 'purchase';
      prodId = items.map(item => item.styleColourCode);
      break;

    case 'checkout':
      pageType = 'checkout';
      break;

    case 'products':
      pageType = 'product';
      prodId = styleColourCode;
      totalValue = price;
      break;

    case 'bag':
      pageType = 'cart';
      prodId = styleColourCode;
      totalValue = price;
      break;

    default:
      pageType = 'other';
  }

  const data = {
    event: 'dynamic_remarketing',
    dynamicRemarketingParams: {
      ecomm_pagetype: pageType,
    },
  };

  if (prodId) {
    data.dynamicRemarketingParams.ecomm_prodid = prodId;
  }

  if (totalValue) {
    data.dynamicRemarketingParams.ecomm_totalvalue = totalValue;
  }

  if (!enabled || (pageType === 'product' && !prodId)) {
    return null;
  }

  return data;
};
