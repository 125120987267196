import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
    aria-labelledby="title"
  >
    <title>Email</title>
    <path
      fillRule="evenodd"
      d="M0 48c0-8 8-16 16-16h168c8 0 16 8 16 16v112c0 8-8 16-16 16H16c-8 0-16-8-16-16V48zm100 68L44 60h112l-56 56zm-36-12l-36 36V68l36 36zm28 28s3 3 8 3 8-3 8-3l18-18 34 34H40l34-34 18 18zm44-28l36-36v72l-36-36z"
    />
  </svg>
);

export default SvgIcon;
