// @flow
import React, { type Node, Component } from 'react';
import ReactFilestack from 'filestack-react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Field from 'brastrap/common/field/Field';
import Button, {
  type Props as ButtonType,
} from 'brastrap/common/button/Button';
import Message from 'brastrap/common/message/Message';

export type Props = {
  button: ButtonType,
  disabled?: boolean,
  filestackConfig: {
    apiKey: string,
    acceptTypes: Array<string>,
    fromSources: Array<string>,
    security: {
      policy: string,
      signature: string,
    },
  },
  name: string,
  note?: Node,
  onChange: Function,
  required?: boolean,
  touched?: boolean,
  value?: string,
};

export type State = {
  uploadSuccess: boolean | null,
};

type File = {
  filename: string,
  handle: string,
  mimetype: string,
  originalFile: {
    name: string,
    type: string,
    size: number,
  },
  originalPath: string,
  size: number,
  source: string,
  status: string,
  uploadId: string,
  url: string,
};

type OnPick = (SyntheticEvent<HTMLButtonElement>) => void;

const messages = defineMessages({
  uploadComplete: {
    id: 'uploadFieldCompleteMessage',
    defaultMessage: 'Upload Complete',
  },
});

/**
 * @param {object} props
 * @return {XML}
 * @constructor
 */
class FieldUpload extends Component<Props, State> {
  static defaultProps = {
    filestackConfig: {},
  };

  state = {
    uploadSuccess: null,
  };

  /**
   * When file uploaded, update state value
   *
   * @param {Object} result
   */
  onSuccess = (result: {
    filesFailed: Array<File>,
    filesUploaded: Array<File>,
  }) => {
    const { url } = result.filesUploaded[0];

    this.props.onChange(url);
    this.setState({ uploadSuccess: true });
  };

  /**
   * Provides element for rendering on front-end
   *
   * @param {Function} onPick
   * @return {XML}
   */
  renderInput(onPick: OnPick) {
    return (
      <div className="c-field--upload__button">
        <Button
          {...this.props.button}
          onClick={onPick}
          disabled={this.props.disabled}
        />
        {this.state.uploadSuccess && (
          <Message
            modifiers={['banner', 'inform']}
            onClose={() => {
              this.setState({ uploadSuccess: null });
            }}
          >
            <FormattedMessage {...messages.uploadComplete} />
          </Message>
        )}
      </div>
    );
  }

  /**
   * Renders
   * @return {*}
   */
  render() {
    const { note, touched, filestackConfig } = this.props;

    const filestackOptions = {
      accept: filestackConfig.acceptTypes,
      maxFiles: 1,
      fromSources: filestackConfig.fromSources,
    };

    return (
      <Field {...this.props} modifiers={['upload']}>
        <ReactFilestack
          apikey={filestackConfig.apiKey}
          options={filestackOptions}
          onSuccess={this.onSuccess}
          security={filestackConfig.security}
          render={({ onPick }) => this.renderInput(onPick)}
        />
        {note && touched && <em className="c-field__note">{note}</em>}
      </Field>
    );
  }
}

export default FieldUpload;
