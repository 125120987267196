// @flow
import {
  ADD_PAYMENT_CARD_REQUEST,
  ADD_PAYMENT_CARD_FAILURE,
  ADD_PAYMENT_CARD_SUCCESS,
  ADD_PAYMENT_CARD_CLOSE_MESSAGES,
  ADD_PAYMENT_CARD_FORM_CLOSED,
  ADD_PAYMENT_CARD_FORM_OPEN,
  PAYMENT_SESSION_SCRIPT_URL,
  SET_PAYMENT_PROVIDER_ISSUE,
  SET_PAYMENT_OPTION,
  PAYPAL_CLIENT_TOKEN_SUCCESS,
} from '../constants/payment-card-details';

import { RECEIVE_PAYMENT_METHODS } from '../constants/account';
import { RECEIVE_BAG } from '../constants/bag';
import { LOGIN_SUCCESS } from '../constants/auth';
import type { ReceivePaymentMethods } from '../actions/account/payment';
import type { ReceiveBag } from '../actions/bag';
import type { Actions } from '../actions/add-payment-option';

type PaymentOptions = 'paypal' | 'card';

type State = {
  +confirmationMessage: string | null,
  +display: boolean,
  +errorMessage: string | null,
  +isFetching: boolean,
  +isSaving: boolean,
  +paymentSessionScriptUrl: string,
  +paypalClientToken?: string,
  +selectedPaymentOption: ?PaymentOptions,
};

export const initialState = {
  display: false,
  errorMessage: null,
  confirmationMessage: null,
  isSaving: false,
  paymentSessionScriptUrl: '',
  isFetching: false,
  selectedPaymentOption: null,
};

// actions/auth is not yet flow typed, creating the minimal type needed for LOGIN_SUCCESS here
type LoginSuccess = {
  type: typeof LOGIN_SUCCESS,
  payload: {
    paymentMethods: any[],
  },
};

type AllActions = Actions | ReceivePaymentMethods | ReceiveBag | LoginSuccess;

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function paymentCardDetailsForm(
  state: State = initialState,
  action: AllActions
) {
  switch (action.type) {
    case ADD_PAYMENT_CARD_REQUEST:
      return {
        ...state,

        isSaving: true,
        errorMessage: null,
        confirmationMessage: null,
      };

    case ADD_PAYMENT_CARD_FAILURE:
      return {
        ...state,

        isSaving: false,
        errorMessage: action.payload.message,
        confirmationMessage: null,
      };

    case ADD_PAYMENT_CARD_SUCCESS:
      return {
        ...state,

        isSaving: false,
        errorMessage: null,
        confirmationMessage: null,
      };

    case ADD_PAYMENT_CARD_CLOSE_MESSAGES:
      return {
        ...state,

        confirmationMessage: null,
        errorMessage: null,
      };

    case PAYMENT_SESSION_SCRIPT_URL:
      return {
        ...state,
        paymentSessionScriptUrl: action.payload,
        isFetching: false,
      };

    case SET_PAYMENT_PROVIDER_ISSUE:
    case PAYPAL_CLIENT_TOKEN_SUCCESS:
    case SET_PAYMENT_OPTION:
      return {
        ...state,
        ...action.payload,
      };

    case ADD_PAYMENT_CARD_FORM_OPEN:
      return {
        ...state,
        display: true,
      };

    case ADD_PAYMENT_CARD_FORM_CLOSED:
      return {
        ...state,
        display: false,
      };

    case RECEIVE_PAYMENT_METHODS: {
      // This is currently setting card as the default selected option if
      // the customer has any saved cards.
      // @paypal TODO: This logic will need to change when paypal phase 2 - saving paypal accounts is implemented
      return action.payload.length && !state.selectedPaymentOption
        ? {
            ...state,
            selectedPaymentOption: 'card',
            // Close the add card accordion
            display: false,
          }
        : {
            ...state,
            // Open the add card accordion
            display: true,
          };
    }

    // @paypal TODO: This logic will need to change when paypal phase 2 - saving paypal accounts is implemented
    case LOGIN_SUCCESS:
      return action.payload.paymentMethods &&
        action.payload.paymentMethods.length &&
        !state.selectedPaymentOption
        ? {
            ...state,
            selectedPaymentOption: 'card',
            // Close the add card accordion
            display: false,
          }
        : {
            ...state,
            // Open the add card accordion
            display: true,
          };

    case RECEIVE_BAG:
      return action.payload.payment && action.payload.payment.selectedMethod
        ? {
            ...state,
            selectedPaymentOption: action.payload.payment.selectedMethod,
          }
        : state;

    default:
      return state;
  }
}
