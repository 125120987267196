import { connect } from 'react-redux';
import core from '@bravissimolabs/website-core';
import config from 'config';
import omit from 'lodash/omit';

import ContentInfo from 'brastrap/global/contentinfo/Contentinfo';

import { receiveLocales } from '../actions/app/locale';

import { EXPLICIT_LOCALE_STORAGE_KEY } from '../constants/app';

/**
 * @param {Object} store
 * @param {Object} props
 * @param {Object} req
 */
ContentInfo.populateStore = async ({ dispatch }, props, req) => {
  const localeSwitcherEnabled =
    config.features.localeSwitcher && config.features.localeSwitcher.enabled;

  const localeApi = core.content.locale;
  const { CURRENT_LOCALE } = localeApi;

  // Get the available locales.
  const localeData = {
    available: localeSwitcherEnabled
      ? localeApi.getAvailableLocales(req.hostname, req.protocol)
      : [],
    explicit: req.cookies && req.cookies[EXPLICIT_LOCALE_STORAGE_KEY],
    selected: CURRENT_LOCALE,
  };
  await dispatch(receiveLocales(localeData));
};

const mapStateToProps = ({
  app: {
    config: { basePath },
    locale,
    routing,
  },
  footer: { bottomLinks },
}) => {
  let { pathname } = routing;
  const { search } = routing;
  // Remove the current base path from the pathname.
  // This will strip `/us/` from the pathname for example leaving us with the route pathname.
  // `/us/cookies` is now just `cookies/`
  pathname = pathname.replace(basePath, '');

  return {
    basePath,
    search,
    pathname,
    links: bottomLinks && bottomLinks.length && bottomLinks[0].links,
    locale: {
      ...omit(locale, ['translations']),
      available: locale.available.map(l => ({
        ...l,
        // Attach the query string params to the path.
        path: `${l.url}${pathname}${search}`,
      })),
    },
  };
};

export default connect(mapStateToProps)(ContentInfo);
