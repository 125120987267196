// @flow
import React from 'react';
import Bandeau from 'brastrap/common/bandeau/Bandeau';
import Container from 'brastrap/common/container/Container';
import EditorialContent from 'brastrap/editorial/editorial-content/EditorialContent';
import Grid from 'brastrap/containers/grid/GridContainer';
import UtilityNav from 'brastrap/containers/utility-nav/UtilityNavContainer';
import type { EditorialContainerProps as Props } from './editorial-container.types.js';

/**
 * The EditorialContainer is part of the 'Editorial Framework', the engine that is responsible
 * for rendering the bulk of the editorial content on the site. For more information on this component,
 * and the Editorial Framework as a whole, check out the docs: /docs/editorial-framework.md
 * @param {Object} props
 * @returns {HTML}
 */
const EditorialContainer = (props: Props) => (
  <div>
    {props.title && props.showTitle && <Bandeau title={props.title} />}
    {props.containers && props.showNavigation && props.navigationTree && (
      <Container>
        <Grid modifiers={['wraps']}>
          <Grid.EditorialNav>
            <UtilityNav
              {...{
                ...props.navigationTree,
                currentPage: props.id,
                modifiers: ['section', 'editorial'],
              }}
            />
          </Grid.EditorialNav>
          <Grid.EditorialMain>
            <EditorialContent id={props.id} content={props.containers} />
          </Grid.EditorialMain>
        </Grid>
      </Container>
    )}
    {props.containers && !props.showNavigation && (
      <EditorialContent id={props.id} content={props.containers} />
    )}
  </div>
);

EditorialContainer.defaultProps = {
  containers: [],
  modifiers: [],
  showNavigation: false,
  showTitle: false,
};

export default EditorialContainer;
