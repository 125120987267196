// @flow
import * as React from 'react';
import { startCase } from 'lodash';
import { FormattedMessage } from 'react-intl';
import EditorialContainer from 'brastrap/editorial/editorial-container/EditorialContainer';
import UtilityNav from 'brastrap/common/utility-nav/UtilityNav';
import Container from 'brastrap/common/container/Container';
import Icon from 'brastrap/common/icon/Icon';
import Image from 'brastrap/common/image/Image';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import EmailSignUp from 'brastrap/containers/email-signup/EmailSignupContainer';
import TouchPoint from 'brastrap/global/touchpoint/Touchpoint';
import Link from 'brastrap/containers/link/LinkContainer';
import type { Container as ContainerProps } from 'brastrap/editorial/editorial-container/editorial-container.types.js';
import type { UtilityNavLinks } from 'brastrap/common/utility-nav/utility-nav.types.js';
import type { Props as Action } from 'brastrap/common/button-link/ButtonLink';
import Accordion from '../../common/accordion/Accordion';
import SectionNav from '../../common/section-nav/SectionNav';
import AcceptedPaymentMethods from './UsFooterAcceptedPaymentMethods.jsx';

type SignUp = {
  actions: Action[],
  title: string,
  slug: Url,
};

type Social = { [social_channel: string]: Url };

type Props = {
  locale: String,
  containers: ContainerProps[],
  navs: UtilityNavLinks[],
  signUp: SignUp[],
  social: Social,
  children: React.Node,
  securePayments: SettingGroup,
  awards: SettingGroup,
  isMobile: Boolean,
};
const SOCIAL_ICONS = [
  'facebook',
  'instagram',
  'pinterest',
  'tiktok',
  'youtube',
];

const preferredSocialOrder = [
  'instagram',
  'tiktok',
  'facebook',
  'pinterest',
  'youtube',
];

const isValidSocialIcon = name => SOCIAL_ICONS.indexOf(name) > -1;

const sortBySocialOrder = (a, b) => {
  const aIndex = preferredSocialOrder.findIndex(social => social === a);
  const bIndex = preferredSocialOrder.findIndex(social => social === b);
  return aIndex - bIndex;
};

const renderImages = (settingGroup, className) =>
  Object.keys(settingGroup).map(setting => {
    const { description = '', file: { url = '' } = {} } = settingGroup[setting];
    return (
      !!url && (
        <Image
          src={url}
          className={className}
          key={setting}
          alt={description}
        />
      )
    );
  });

const getFiles = settingGroup =>
  Object.keys(settingGroup)
    .map(setting => settingGroup[setting] && settingGroup[setting].file)
    .filter(file => !!file);

const hasFiles = settingGroup => !!getFiles(settingGroup).length;

const Usfooter = (props: Props) => {
  const icons = Object.keys(props.social)
    .filter(isValidSocialIcon)
    .sort(sortBySocialOrder);

  return (
    <footer className="c-footer">
      <div className="c-footer__channels">
        <EditorialContainer containers={props.containers} />
      </div>
      <div className="c-footer__us-footer">
        <div className="c-footer__utilities">
          <Container modifiers={['offset']}>
            {props.isMobile && (
              <div>
                <div className="c-footer__accordion">
                  <Accordion footerAccordion>
                    {props.navs.map(nav => (
                      <Accordion.Item
                        id={nav.id}
                        key={nav.id}
                        label={nav.heading}
                      >
                        <div key={nav.id} className="l-grid__unit">
                          <SectionNav
                            id="shops-section__nav"
                            title={nav.heading}
                            items={nav.links}
                            footerSectionNav
                          />
                        </div>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            )}
            <div className="l-grid l-grid--wraps l-grid--4up">
              {!props.isMobile &&
                props.navs.map(nav => (
                  <div key={nav.id} className="l-grid__unit">
                    <UtilityNav {...nav} />
                  </div>
                ))}
            </div>
            {hasFiles(props.awards) && (
              <div className="c-footer__awards c-footer--usOnly-HideAtMobile">
                <h4 className="c-footer__awards__header c-footer__awards__header--usOnly">
                  <FormattedMessage
                    id="footer.awards"
                    defaultMessage="Our Awards"
                  />
                </h4>
                <div className="c-footer__awards__logo c-footer__awards__logo--usOnly">
                  {renderImages(props.awards, 'c-footer__awards__logo__img')}
                </div>
              </div>
            )}
            {hasFiles(props.securePayments) && (
              <div className="c-footer__payment-methods  c-footer__payment-methods--usOnly c-footer--usOnly-HideAtMobile">
                <h4 className="c-footer__awards__header c-footer__awards__header--usOnly">
                  <FormattedMessage
                    id="footer.accept"
                    defaultMessage="We Accept"
                  />
                </h4>
                <div className="c-footer__payment-methods__logos c-footer__payment-methods__logos--usOnly">
                  <AcceptedPaymentMethods />
                </div>
                <div className="c-footer__payment-methods__logos c-footer__payment-methods__logos--usOnly">
                  {renderImages(
                    props.securePayments,
                    'c-footer__payment-methods__payment__img--usOnly'
                  )}
                </div>
              </div>
            )}
          </Container>
        </div>
        <div className="c-footer__touchpoint c-footer__touchpoint--usOnly">
          {props.signUp.map(signUp => (
            <TouchPoint key={`touchpoint-${signUp.title}`} title={signUp.title}>
              <div className="c-touchpoint--email__sign-up c-touchpoint--email__sign-up--usOnly">
                <h5 className="c-footer__email-sign-up__header c-footer__email-sign-up__header--usOnly">
                  <FormattedMessage
                    id="footer.email-sign-up"
                    defaultMessage="Be the first to hear about new collections, sales and more, just pop your email address in."
                  />
                </h5>
                <EmailSignUp
                  analyticsRef={'Email Signup: Footer Email Signup Form'}
                  formKey="footerEmailSignUp"
                />
              </div>
              <div className="c-touchpoint__magazine">
                <h1 className="c-touchpoint__title c-touchpoint__title--usOnly">
                  REQUEST OUR FREE MAGAZINE
                </h1>
                <h5 className="c-footer__email-sign-up__header c-footer__email-sign-up__header--usOnly">
                  <FormattedMessage
                    id="footer.requests-magazine"
                    defaultMessage="Our magazine is bursting at the seams with our newest styles, fitters top tips and your uplifting stories."
                  />
                </h5>
              </div>
              <div className="c-touchpoint--magazine__link">
                <ButtonLink
                  label={signUp.actions[0].label}
                  url={signUp.actions[0].url}
                  modifiers={['filled', 'small']}
                  analyticsRef={`Footer: ${startCase(signUp.actions[0].label)}`}
                />
              </div>
            </TouchPoint>
          ))}
          <div className="c-footer__social__logo c-footer__social__logo--usOnly">
            {icons.map(icon => (
              <Link
                key={`link-to-${props.social[icon]}`}
                className="c-footer__social__logo__link c-footer__social__logo__link--usOnly"
                href={props.social[icon]}
                rel="me noopener noreferrer"
                target="_blank"
              >
                <Icon icon={icon} />
              </Link>
            ))}
          </div>
        </div>
        <div>{props.children}</div>
      </div>
      {hasFiles(props.awards) && (
        <div className="c-footer__awards c-footer--usOnly-ShowAtMobile">
          <h4 className="c-footer__awards__header c-footer__awards__header--usOnly">
            <FormattedMessage id="footer.awards" defaultMessage="Our Awards" />
          </h4>
          <div className="c-footer__awards__logo c-footer__awards__logo--usOnly">
            {renderImages(props.awards, 'c-footer__awards__logo__img')}
          </div>
        </div>
      )}
      {hasFiles(props.securePayments) && (
        <div className="c-footer__payment-methods c-footer__payment-methods--usOnly c-footer--usOnly-ShowAtMobile">
          <h4 className="c-footer__awards__header c-footer__awards__header--usOnly">
            <FormattedMessage id="footer.accept" defaultMessage="We Accept" />
          </h4>
          <div className="c-footer__payment-methods__logos c-footer__payment-methods__logos--usOnly">
            <AcceptedPaymentMethods />
          </div>
          <div className="c-footer__payment-methods__logos c-footer__payment-methods__logos--usOnly">
            {renderImages(
              props.securePayments,
              'c-footer__payment-methods__payment__img--usOnly'
            )}
          </div>
        </div>
      )}
    </footer>
  );
};

export default Usfooter;
