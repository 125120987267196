import { RECEIVE_SETTINGS_GROUP } from 'shared/constants/footer';

export const initialState = {};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function footer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_SETTINGS_GROUP:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
