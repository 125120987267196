// @flow
import type { GetState } from 'redux';
import {
  RECEIVE_SAVED_ITEMS_MAP,
  RECEIVE_DECORATED_SAVED_ITEMS,
  GET_SAVED_ITEMS_FAILURE,
  GET_SAVED_ITEMS_REQUEST,
  ADD_SAVED_ITEM_FAILURE,
  ADD_SAVED_ITEM_REQUEST,
  DELETE_SAVED_ITEM_FAILURE,
  DELETE_SAVED_ITEM_REQUEST,
  SAVE_PENDING_ITEM,
  RECEIVE_SIZES,
  GET_SIZES_FAILURE,
  GET_SIZES_REQUEST,
} from '../constants/saved-items';
import { setContent, receivePageContainers } from './editorial';
import { CALL_API } from '../constants/api';
import { push, type Push } from '../actions/app/routing';

type ReceiveSavedItemsList = {|
  type: typeof RECEIVE_SAVED_ITEMS_MAP,
  payload: { itemMap: ItemMap, itemCount: number },
|};
/**
 * Puts array of saved item product codes into state
 * @param {array} itemMap
 * @return {object}
 */
export function receiveSavedItemsList(itemMap: ItemMap): ReceiveSavedItemsList {
  const itemCount = Object.keys(itemMap).length;
  return {
    type: RECEIVE_SAVED_ITEMS_MAP,
    payload: { itemMap, itemCount },
  };
}

type ReceiveDecoratedSavedItems = {|
  type: typeof RECEIVE_DECORATED_SAVED_ITEMS,
  payload: { items: DecoratedSavedItem[] },
|};
/**
 * Puts array of saved item with style colour data into state
 * @param {array} items
 * @return {object}
 */
export function receiveDecoratedSavedItems(
  items: DecoratedSavedItem[]
): ReceiveDecoratedSavedItems {
  return {
    type: RECEIVE_DECORATED_SAVED_ITEMS,
    payload: { items },
  };
}

type GetSavedItemsFailure = {|
  type: typeof GET_SAVED_ITEMS_FAILURE,
  error: boolean,
  payload: { message: Error },
|};
/**
 * Saved items returned an error
 * @param {object} error
 * @return {object}
 */
export function getSavedItemsFailure(error: Error): GetSavedItemsFailure {
  return {
    type: GET_SAVED_ITEMS_FAILURE,
    error: true,
    payload: {
      message: error,
    },
  };
}

type GetSavedItemsRequest = {|
  type: typeof GET_SAVED_ITEMS_REQUEST,
|};
/**
 * Api request to get customer's saved items started
 * @return {object}
 */
export function getSavedItemsRequest(): GetSavedItemsRequest {
  return {
    type: GET_SAVED_ITEMS_REQUEST,
  };
}

type ApiGetDecoratedSavedItems = {|
  actions: {
    success: (DecoratedSavedItem[]) => ReceiveDecoratedSavedItems,
    error: Error => GetSavedItemsFailure,
    start: () => GetSavedItemsRequest,
  },
  type: typeof CALL_API,
  url: Url,
|};
/**
 * @return {object}
 */
export function apiGetDecoratedSavedItems(): ApiGetDecoratedSavedItems {
  return {
    actions: {
      error: getSavedItemsFailure,
      start: getSavedItemsRequest,
      success: receiveDecoratedSavedItems,
    },
    type: CALL_API,
    url: 'savedItems/decorate',
  };
}

type ReceiveSizes = {|
  type: typeof RECEIVE_SIZES,
  payload: {
    sizes: Sku[],
    itemIndex: number,
  },
|};
/**
 * Puts array of saved item with style colour data into state
 * @param {array} sizes
 * @param {number} itemIndex
 * @param {string} styleColourCode
 * @return {object}
 */
export function receiveSizes(
  sizes: Sku[],
  itemIndex: number,
  styleColourCode: string
): ReceiveSizes {
  return {
    type: RECEIVE_SIZES,
    payload: { sizes, itemIndex, styleColourCode },
  };
}

type GetSizesFailure = {|
  type: typeof GET_SIZES_FAILURE,
  error: boolean,
  payload: { message: Error },
|};
/**
 * Saved items returned an error
 * @param {object} error
 * @return {object}
 */
export function getSizesFailure(error: Error): GetSizesFailure {
  return {
    type: GET_SIZES_FAILURE,
    error: true,
    payload: {
      message: error,
    },
  };
}

type GetSizesRequest = {|
  type: typeof GET_SIZES_REQUEST,
|};
/**
 * Api request to get customer's saved items started
 * @return {object}
 */
export function getSizesRequest(): GetSizesRequest {
  return {
    type: GET_SIZES_REQUEST,
  };
}

type ApiGetSizes = {|
  actions: {
    success: (Sku[]) => ReceiveSizes,
    error: Error => GetSizesFailure,
    start: () => GetSizesRequest,
  },
  type: typeof CALL_API,
  url: Url,
|};
/**
 * @param {string} styleColourCode
 * @param {number} itemIndex
 * @return {object}
 */
export function apiGetSizes(
  styleColourCode: string,
  itemIndex: number
): ApiGetSizes {
  return {
    actions: {
      error: getSizesFailure,
      start: getSizesRequest,
      success: sizes => receiveSizes(sizes, itemIndex, styleColourCode),
    },
    type: CALL_API,
    url: `savedItems/sizes/${styleColourCode}`,
  };
}

type AddSavedItemFailure = {|
  type: typeof ADD_SAVED_ITEM_FAILURE,
  error: boolean,
  payload: { message: Error },
|};
/**
 * Saved items returned an error
 * @param {object} error
 * @return {object}
 */
export function addSavedItemFailure(error: Error): AddSavedItemFailure {
  return {
    type: ADD_SAVED_ITEM_FAILURE,
    error: true,
    payload: {
      message: error,
    },
  };
}

type AddSavedItemRequest = {|
  type: typeof ADD_SAVED_ITEM_REQUEST,
|};
/**
 * Api request to add customer's saved items started
 * @return {object}
 */
export function addSavedItemRequest(): AddSavedItemRequest {
  return {
    type: ADD_SAVED_ITEM_REQUEST,
  };
}

type ItemToSave = {
  styleCode: string,
  styleColourCode: string,
  skuCode?: string,
};
type ApiSaveItem = {|
  actions: {
    success: ItemMap => ReceiveSavedItemsList,
    error: Error => AddSavedItemFailure,
    start: () => AddSavedItemRequest,
  },
  params: {
    item: ItemToSave,
  },
  type: typeof CALL_API,
  method: 'put',
  url: Url,
|};

/**
 * @param {object} item
 * @param {string} replacedItem
 * @return {object}
 */
export function apiSaveItem(
  item: ItemToSave,
  replacedItem: ?string
): ApiSaveItem {
  return {
    actions: {
      error: addSavedItemFailure,
      start: addSavedItemRequest,
      success: receiveSavedItemsList,
    },
    type: CALL_API,
    method: 'put',
    url: 'savedItems',
    params: { item, replacedItem },
  };
}

type SavePendingItem = {|
  type: typeof SAVE_PENDING_ITEM,
  payload: ItemToSave,
|};

const savePendingItem = (item: ItemToSave): SavePendingItem => ({
  type: SAVE_PENDING_ITEM,
  payload: {
    ...item,
  },
});

// Thunk types
type Dispatch = (action: Actions | Push | ThunkAction) => any; // eslint-disable-line no-use-before-define
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

type UnauthenticatedSaveItem = ?ThunkAction;

const unAuthenticatedSaveItem = (item: ItemToSave) => (
  dispatch,
  getState
): UnauthenticatedSaveItem => {
  const {
    app: {
      config,
      routing: { url, hash },
    },
  } = getState();
  dispatch(savePendingItem(item));
  const pathname = `${config.basePath}login/`;
  return dispatch(
    push({
      pathname,
      state: { nextPathname: `${url}${hash}` },
    })
  );
};

type SaveItem = ThunkAction;

export const saveItem = (item: ItemToSave) => (
  dispatch: Dispatch,
  getState: GetState
): SaveItem => {
  const { isAuthenticated } = getState().auth;
  return dispatch(
    isAuthenticated ? apiSaveItem(item) : unAuthenticatedSaveItem(item)
  );
};

type DeleteSavedItemFailure = {|
  type: typeof DELETE_SAVED_ITEM_FAILURE,
  error: boolean,
  payload: { message: Error },
|};
/**
 * Saved items returned an error
 * @param {object} error
 * @return {object}
 */
export function deleteSavedItemFailure(error: Error): DeleteSavedItemFailure {
  return {
    type: DELETE_SAVED_ITEM_FAILURE,
    error: true,
    payload: {
      message: error,
    },
  };
}

type DeleteSavedItemRequest = {|
  type: typeof DELETE_SAVED_ITEM_REQUEST,
|};
/**
 * Api request to add customer's saved items started
 * @return {object}
 */
export function deleteSavedItemRequest(): DeleteSavedItemRequest {
  return {
    type: DELETE_SAVED_ITEM_REQUEST,
  };
}

type ApiDeleteItem = {|
  actions: {
    success: ItemMap => ReceiveSavedItemsList,
    error: Error => DeleteSavedItemFailure,
    start: () => DeleteSavedItemRequest,
  },
  type: typeof CALL_API,
  method: 'delete',
  url: Url,
|};

/**
 * @param {string} itemCode
 * @return {object}
 */
export function apiDeleteItem(itemCode: string): ApiDeleteItem {
  return {
    actions: {
      error: deleteSavedItemFailure,
      start: deleteSavedItemRequest,
      success: receiveSavedItemsList,
    },
    type: CALL_API,
    method: 'delete',
    url: `savedItems/${itemCode}`,
  };
}

type SavedItemsContainers = {
  headerContainers: Array<any>,
  footerContainers: Array<any>,
  noSavedItems: Array<any>,
};

const setEditorialContent = (data: SavedItemsContainers) => (
  dispatch: Dispatch
) => {
  const { headerContainers, footerContainers, noSavedItems } = data;
  dispatch(setContent(noSavedItems));
  dispatch(receivePageContainers({ headerContainers, footerContainers }));
};

type ApiGetContainers = {|
  actions: {
    success: SavedItemsContainers => ThunkAction,
  },
  type: typeof CALL_API,
  method: 'get',
  url: Url,
|};

/**
 * apiGetContainers
 * @returns {Object}
 */
export function apiGetContainers(): ApiGetContainers {
  return {
    actions: {
      success: setEditorialContent,
    },
    type: CALL_API,
    method: 'get',
    url: 'savedItems/content',
  };
}

export type Actions =
  | ReceiveSavedItemsList
  | ReceiveDecoratedSavedItems
  | GetSavedItemsFailure
  | GetSavedItemsRequest
  | ApiGetDecoratedSavedItems
  | AddSavedItemFailure
  | AddSavedItemRequest
  | ApiSaveItem
  | DeleteSavedItemFailure
  | DeleteSavedItemRequest
  | ApiDeleteItem
  | GetSizesFailure
  | GetSizesRequest
  | ApiGetSizes
  | SavePendingItem
  | ApiGetContainers;
