import React from 'react';

const SvgIcon = () => (
  <svg
    className="c-icon__glyph"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 240 240"
    role="img"
  >
    <path
      fillRule="evenodd"
      d="M30 80c0-50 42-80 90-80s90 30 90 80c0 70-90 160-90 160S30 150 30 80zm20 3C50 44 82 20 120 20c37 0 70 24 70 63 0 55-70 125-70 125S50 138 50 83zm70 47c22 0 40-18 40-40s-18-40-40-40-40 18-40 40 18 40 40 40zm0-10c17 0 30-13 30-30s-13-30-30-30-30 13-30 30 13 30 30 30z"
    />
  </svg>
);

export default SvgIcon;
