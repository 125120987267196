import { replaceAttribute } from 'brastrap/promotional/shop-by-container/ShopByContainer';
import { updateQueryParams } from './update-query';
import { buildQuery } from '../utils/product-listing';
import { CALL_API } from '../constants/api';
import {
  RECEIVE_PRODUCT_LISTING,
  RECEIVE_PRODUCT_LISTING_FAILURE,
  RECEIVE_PRODUCT_LISTING_REQUEST,
  SELECT_STYLE_COLOUR,
  CLEAR_SCROLL,
} from '../constants/product-listing';

/**
 * @param {Object} data
 * @returns {{type: string, payload: *}}
 */
export function receiveProductListing(data) {
  return {
    type: RECEIVE_PRODUCT_LISTING,
    payload: data,
  };
}

/**
 * Thunk that receives the product data from the server then updates the query parameters with the correct query
 * strings. This enables us to use the browser navigation to filter through the search results.
 *
 * @param {Object} data
 * @param {Object} options - add any additional facets and scroll to information.
 * @return {Promise}
 */
const receiveProductListingAndUpdateUrl = (
  data,
  { additionalQueryParams, scrollTo }
) => async (dispatch, getState) => {
  await dispatch(receiveProductListing({ ...data, scrollTo }));
  const {
    productListing: {
      facets,
      sizeFacets,
      filter: { subcollection, limit, sortBy },
      pagination: { page },
    },
  } = getState();
  const queryParams = buildQuery(
    { limit, page, sortBy, subcollection },
    { facets, sizeFacets, joinSizeFacets: false, includeMultipleSizes: false },
    null,
    additionalQueryParams
  );
  return dispatch(updateQueryParams(queryParams));
};

/**
 * @param {ProductListingOptions} opts
 * @param {Object} sizeFacets - bra and clothing size facets
 * @returns {Function}
 */
const getProductListingSuccessAction = (opts, sizeFacets) => {
  const options = { ...opts };
  const func = opts.updateUrl
    ? receiveProductListingAndUpdateUrl
    : receiveProductListing;
  return data => (dispatch, getState) => {
    const dataToDispatch = {
      ...data,
      sizeFacets,
    };

    const {
      isShopByCollection,
      containers,
      shopByCollectionData,
    } = getState().productListing;

    if (isShopByCollection) {
      // If it is a shopby collection then ensure the facet for the chosen attribute is set.
      // Will either be size or colour.
      // This will destroy any existing value for the facet but that doesn't matter as shop by pages only support one
      // value which cannot be filtered.
      const { attribute, value } = shopByCollectionData;

      options.additionalQueryParams = { [`f_${attribute}`]: [value] };

      // We also want to make sure the containers do not get replaced.
      // FYI that means that, although not impossible, introducing client site routing to the shopby page
      // is a non-trivial change.
      dataToDispatch.isShopByCollection = true;
      dataToDispatch.containers = containers;
      dataToDispatch.shopByCollectionData = shopByCollectionData;

      // Make sure pageTitle is replaced with attribute across pagination
      dataToDispatch.pageTitle = replaceAttribute(
        dataToDispatch.pageTitle,
        attribute,
        value,
        attribute === 'size'
      );
    }

    return dispatch(func(dataToDispatch, options));
  };
};

/**
 * @returns {{type: string}}
 */
export function receiveProductListingRequest() {
  return {
    type: RECEIVE_PRODUCT_LISTING_REQUEST,
  };
}

/**
 * @param {string} message
 * @returns {{type: string, payload: string, error: boolean}}
 */
export function receiveProductListingFailure(message) {
  return {
    type: RECEIVE_PRODUCT_LISTING_FAILURE,
    payload: message,
    error: true,
  };
}

/**
 * Options to control actions relating to product listing updates.
 * @typedef {Object} ProductListingOptions
 * @property {Boolean} updateUrl - push changes to query params or not. Defaults to true.
 * @property {String|Object} scrollTo - element to optionally scroll to after reload.
 */

/**
 * @param {string} collection
 * @param {string} searchPhrase
 * @param {Object} sizeFacets
 * @param {Object} params
 * @param {ProductListingOptions} options
 * @returns {Object}
 */
export function apiProductListingRequest(
  collection,
  searchPhrase,
  sizeFacets,
  params,
  options
) {
  const optionsWithDefaults = {
    updateUrl: true,
    ...options,
  };
  return {
    actions: {
      error: receiveProductListingFailure,
      start: receiveProductListingRequest,
      success: getProductListingSuccessAction(optionsWithDefaults, sizeFacets),
    },
    params,
    type: CALL_API,
    url: searchPhrase
      ? `/search/${searchPhrase}`
      : `/collections/${collection}`,
  };
}

/**
 * @param {string} styleColourCode
 * @return {{type, payload: *}}
 */
export function selectStyleColour(styleColourCode) {
  return {
    type: SELECT_STYLE_COLOUR,
    payload: styleColourCode,
  };
}

/**
 * @returns {{type: string}}
 */
export function clearScroll() {
  return {
    type: CLEAR_SCROLL,
  };
}
