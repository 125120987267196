// @flow
import React from 'react';
import type { Pagination as Props } from 'brastrap/common/carousel/carousel.type';

const STYLE_PREFIX = 'c-carousel-pagination';

const CarouselPagination = ({ children, current, onClick }: Props) => (
  <ul className={STYLE_PREFIX}>
    {React.Children.map(children, (item, index) => (
      <li key={`listItem-${index}`}>
        <button
          className={`${STYLE_PREFIX}__item${
            current === index ? ' is-current' : ''
          }`}
          onClick={() => onClick(index)}
        />
      </li>
    ))}
  </ul>
);

CarouselPagination.defaultProps = {
  onClick: () => {},
};

export default CarouselPagination;
