import { backSizesData, usCupSizesData, ukCupSizesData } from '../data/sizes';
import {
  EN_GB,
  EN_US,
  BRAND_ADJUSTMENTS,
  CUP_COMPENSATION,
  UPDATE_BACK,
  UPDATE_CUP,
} from '../data/constants';

/**
 * @description Switches country string
 * @param {String} currentCountry
 * @return {string}
 */
export const switchCountry = currentCountry => {
  switch (currentCountry) {
    case EN_US:
      return EN_GB;
    case EN_GB:
      return EN_US;
    default:
      return EN_GB;
  }
};

/**
 * @description Stores brand base settings
 * @param {Element} target
 * @param {Function} setBrandBackChange
 * @param {Function} setBrandCupChange
 */
export const updateBrandSizes = (
  target,
  setBrandBackChange,
  setBrandCupChange
) => {
  if (!target) return;

  if (target.getAttribute('data-cup')) {
    setBrandCupChange(parseInt(target.dataset.cup, 10));
  }

  if (target.getAttribute('data-back')) {
    setBrandBackChange(parseInt(target.dataset.back, 10));
  }
};

/**
 * @description Returns a size map based on the country passed in
 * @param {string} country
 * @return {object}
 */
export const countrySizeMap = (country = EN_GB) => {
  const countryCupSizes = country === EN_GB ? ukCupSizesData : usCupSizesData;
  return countryCupSizes.reduce(
    (result, cupSize) => ({
      ...result,
      [cupSize.size]: backSizesData.map(backSize => backSize.size),
    }),
    {}
  );
};

/**
 * @description Returns updated size selection data
 * @param {array} sizesArray
 * @param {string} selectedSize
 * @return {array}
 */
export const updateSizeSelection = (sizesArray, selectedSize) =>
  sizesArray.map(size => {
    if (size.size === selectedSize) {
      return {
        size: size.size,
        selected: true,
      };
    }
    return {
      size: size.size,
      selected: false,
    };
  });

/**
 * @description Returns updated size selection data
 * @param {array} stages
 * @param {number} stageNumber
 * @return {jsx}
 */
export const getStage = (stages, stageNumber) =>
  stages.find((_stage, idx) => stageNumber === idx);

/**
 * @description Returns the updated cup size
 * @param {string} size
 * @param {number} numberOfSizes
 * @param {string} country
 * @param {boolean} isFinalAdjustment
 * @return {string}
 */
export const updatedCupSize = (
  size,
  numberOfSizes,
  country,
  isFinalAdjustment = false
) => {
  const sizeKeys = Object.keys(countrySizeMap(country));

  const expandedSizeKeys = [...sizeKeys];

  if (country === 'en-US') {
    expandedSizeKeys.push('R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z');
  } else {
    expandedSizeKeys.push(
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z'
    );
  }

  return isFinalAdjustment
    ? sizeKeys[expandedSizeKeys.indexOf(size) + numberOfSizes] ||
        'No size found'
    : expandedSizeKeys[expandedSizeKeys.indexOf(size) + numberOfSizes];
};

/**
 * @description Returns final adjustment to the cup size
 * @param {number} backChange
 * @param {string} country
 * @param {number} cupChange
 * @param {string} cupSize
 * @return {string}
 */
export const finalAdjustment = (backChange, country, cupChange, cupSize) => {
  if (backChange !== 0 && cupChange === 0) {
    if (backChange > 0) return updatedCupSize(cupSize, -1, country, true);
    if (backChange < 0) return updatedCupSize(cupSize, 1, country, true);
  }
  return cupSize;
};

/**
 * @description Sets the backSize, backChange and tempBackCache based on updateType
 * @param {string} updateType
 * @param {string} newBackSize
 * @param {function} setBackSize
 * @param {function} setTempBackCache
 * @param {number} numberOfSizes
 * @param {function} setBackChange
 */
export const updateBack = ({
  updateType,
  newBackSize,
  setBackSize,
  setTempBackCache,
  numberOfSizes,
  setBackChange,
}) => {
  if (updateType === BRAND_ADJUSTMENTS) {
    setTempBackCache(newBackSize);
    setBackSize(newBackSize);
    setBackChange(0);
  }
  if (updateType === UPDATE_BACK) {
    setBackSize(newBackSize);
    setBackChange(numberOfSizes);
  }
};

/**
 * @description Returns the updated back size
 * @param {number} sizeChange
 * @param {string} country
 * @param {string} backSize
 * @param {string} cupSize
 * @return {string}
 */
export const updatedBackSize = ({ sizeChange, country, backSize, cupSize }) => {
  const sizeArr = countrySizeMap(country)[cupSize];
  const currentIndex = sizeArr.indexOf(backSize);
  return sizeArr[currentIndex + sizeChange];
};

/**
 * @description Sets the cupSize and cupChange based on updateType
 * @param {string} country
 * @param {string} cup
 * @param {number} numberOfSizes
 * @param {function} setCupSize
 * @param {function} setCupChange
 * @param {function} updateType
 */
export const updateCup = ({
  country,
  cup,
  numberOfSizes,
  setCupChange,
  setCupSize,
  updateType,
}) => {
  if (numberOfSizes === 0) {
    setCupSize(cup.cupSize || cup);
    if (setCupChange) setCupChange(cup.cupChange + numberOfSizes);
    return;
  }

  const newCupSize = updatedCupSize(cup.cupSize || cup, numberOfSizes, country);

  if (updateType === BRAND_ADJUSTMENTS || updateType === CUP_COMPENSATION) {
    setCupSize(newCupSize);
    return;
  }
  if (updateType === UPDATE_CUP) {
    setCupSize(newCupSize);
    setCupChange(cup.cupChange + numberOfSizes);
  }
};

export const stageProps = ({
  cupSize,
  resetSelected,
  selected,
  setPrevStage,
  setNextStage,
  setSelected,
  stageNumber,
}) => {
  return {
    defaultButtonProps: (text, idx, func) => ({
      selected,
      setSelected,
      text: text,
      id: idx + 1,
      ...(func && { customFunc: func }),
    }),
    defaultNextProps: (numberOfSteps = 1, text) => ({
      cupSize: cupSize,
      resetSelected,
      setStageNumber: steps => {
        setNextStage(steps || numberOfSteps);
      },
      stageNumber,
      text: text || 'Next',
    }),
    defaultPreviousProps: (numberOfSteps = 1, text) => ({
      setStageNumber: () => setPrevStage(numberOfSteps),
      resetSelected,
      text: text || 'Previous Question',
    }),
  };
};

export const convertFromUSToUKSize = usSize => {
  const DIFFERENCE_CHARCODE_AND_LETTERS = 96;
  const ALPHA_POS_H = 8;
  const ALPHA_POS_L = 12;
  let ukSize;

  const alphabeticalPos =
    usSize.toLowerCase().charCodeAt(0) - DIFFERENCE_CHARCODE_AND_LETTERS;
  const convertFromCharCodeToString = offSet => {
    return String.fromCharCode(
      DIFFERENCE_CHARCODE_AND_LETTERS +
        Math.ceil(alphabeticalPos - alphabeticalPos / 2 + offSet)
    ).toUpperCase();
  };

  if (alphabeticalPos >= ALPHA_POS_H && alphabeticalPos % 2 === 0) {
    ukSize =
      alphabeticalPos > ALPHA_POS_L
        ? convertFromCharCodeToString(3) + convertFromCharCodeToString(3)
        : convertFromCharCodeToString(2) + convertFromCharCodeToString(2);
  } else if (alphabeticalPos >= ALPHA_POS_H && alphabeticalPos % 2 === 1) {
    ukSize =
      alphabeticalPos > ALPHA_POS_L
        ? convertFromCharCodeToString(3)
        : convertFromCharCodeToString(2);
  } else if (usSize === 'DD') {
    ukSize = 'DD';
  } else if (usSize === 'DDD') {
    ukSize = 'E';
  } else if (usSize === 'DDDD') {
    ukSize = 'F';
  } else {
    ukSize = usSize;
  }
  return ukSize;
};
