import debugModule from 'debug';
import url from 'url';
import { matchRoutes } from '@bravissimolabs/react-router-config';

const debug = debugModule('website:utils');

/**
 * Throws an error in the console if the page wasn't able to load
 * @param {String} error the exeption thrown.
 */
export function errorLoading(error) {
  debug(`Dynamic page loading failed: ${error}`);
  throw error;
}

/**
 * Utility to facility code-splitting, react-router and webpack.
 * @param {array} branch a list of routes supplied by matchRoutes
 * @return {Promise}
 */
export function loadAsyncRouteComponents(branch) {
  return Promise.all(
    branch
      .filter(({ route: { component } }) => component.load instanceof Function)
      .map(({ route: { component } }) => component.load())
  );
}

/**
 * Used to avoid having to specify the full path for each child route. The full path will be created
 * by convertCustomRouteConfig, which will recursively add parent paths to all child route names
 * @param {string} route
 * @return {function(string): string}
 */
export function createChildPath(route) {
  return parentRoute => `${parentRoute}/${route}`;
}

/**
 * Appends parent or the website's base path to all routes
 *
 * This is a convenience function so that child routes paths can be defined as 'child'
 * instead of /basepath/parent/child
 *
 * Based on (aka stolen from) AirBnB's approach to code splitting by route with React Router 4
 * https://medium.com/airbnb-engineering/server-rendering-code-splitting-and-lazy-loading-with-react-router-v4-bfe596a6af70
 *
 * @param {object} routes,
 * @param {string} parentRouteOrBasePath
 * @return {object} routes with parent paths prefixed onto child paths
 */
export const convertCustomRouteConfig = (routes, parentRouteOrBasePath) =>
  routes.map(childRoute => {
    const routeCopy = { ...childRoute };
    // If the route has a path, append the parent or the base path to it
    if (routeCopy.path !== undefined) {
      routeCopy.path =
        typeof childRoute.path === 'function'
          ? childRoute.path(parentRouteOrBasePath || '')
          : `${parentRouteOrBasePath || ''}/${childRoute.path}`;
      routeCopy.path = routeCopy.path.replace('//', '/');
    }
    return {
      ...routeCopy,
      // Recursively add parent path to any child paths
      routes: childRoute.routes
        ? convertCustomRouteConfig(
            childRoute.routes,
            routeCopy.path || parentRouteOrBasePath
          )
        : [],
    };
  });

/**
 * Strips query params from url before matching against route config
 * @param {array} routes
 * @param {string} currentUrl
 * @returns {array} matched routes
 */
export const match = (routes, currentUrl) => {
  const { pathname } = url.parse(currentUrl);
  return matchRoutes(routes, pathname);
};
