import PropTypes from 'prop-types';
import React from 'react';

import Swatch from 'brastrap/promotional/swatch/Swatch';

const StyleSize = props => (
  <span className="c-style-size">
    {props.colour && (
      <Swatch
        colour={props.colour}
        swatchPatternImage={props.swatchPatternImage}
      />
    )}{' '}
    {props.style && <span className="c-style-size__style">{props.style}</span>}
    {props.size && <span className="c-style-size__size">{props.size}</span>}
  </span>
);

StyleSize.propTypes = {
  size: PropTypes.string,
  style: PropTypes.string,
  colour: PropTypes.string,
  swatchPatternImage: PropTypes.object,
};

export default StyleSize;
