import generateAsyncRouteComponent from '../generateAsyncRouteComponent';

export default [
  {
    path: 'shops/write-review',
    component: generateAsyncRouteComponent({
      loader: () => import('./components/Review/containers/FormAuthenticated'),
    }),
  },
  {
    path: 'shops/:shop',
    component: generateAsyncRouteComponent({
      loader: () => import('./components/ShopPageContainer'),
    }),
  },
];
