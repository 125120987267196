// @flow
import React from 'react';
import LazyLoad from 'react-lazyload';
import { CSSTransitionGroup } from 'react-transition-group';
import RatioBox from 'brastrap/helpers/ratio-box/ratio-box';
import Placeholder, { getAspectRatio } from './Placeholder';
import Picture from './Picture';
import type { Image as ImageProps, LazyLoadProps } from './image.types';

type Props = {
  ...ImageProps,
  deviceIs: {
    desktop: boolean,
    mobile: boolean,
    tablet: boolean,
  },
  desktop?: ImageProps,
  lazyLoad?: LazyLoadProps,
  mobile?: ImageProps,
  tablet?: ImageProps,
  fetchPriority: string
};

const LAZYLOAD_DEFAULTS = {
  fadeIn: true,
  once: true,
};

/**
 * Work out what image to render, based on what device we are on.
 *
 * @param {Object} props
 * @return {*}
 */
const getImage: Props => ImageProps = props => {
  const { deviceIs } = props;

  const defaultProps = {
    alt: props.alt,
    height: props.height,
    src: props.src,
    width: props.width,
  };

  switch (true) {
    case props.mobile && deviceIs.mobile:
      return props.mobile;

    case props.tablet && deviceIs.tablet:
      return props.tablet;

    // Tablet layout is very rarely used, see: https://git.bravissimolabs.com/website/web-platform/issues/1362
    // Marketing would prefer that anything below desktop defaults to the mobile image.
    case deviceIs.tablet && !props.tablet:
      return props.mobile && props.mobile.src
        ? props.mobile
        : props.desktop || defaultProps;

    case props.desktop && deviceIs.desktop:
      return props.desktop;

    default:
      return defaultProps;
  }
};

/**
 * @param {Object} props
 * @returns {JSX}
 * @constructor
 */
const Image = (props: Props) => {
  const imageProps = getImage(props);
  const { alt, height, src, width } = imageProps;
  const { className } = props;
  const lazyLoadProps = {
    ...LAZYLOAD_DEFAULTS,
    ...props.lazyLoad,
    height,
    width,
  };

  const { fadeIn } = lazyLoadProps;
  const aspectRatio = getAspectRatio(height, width);

  const sources = [];

  if (src) {
    sources.push({
      srcSet: `${src}${src.indexOf('?') === -1 ? '?' : '&'}fm=webp`,
      type: 'image/webp',
    });
  }

  const pictureProps = {
    img: {
      alt,
      src,
      className,
    },
    sources,
    fetchPriority: props.fetchPriority
  };

  // We are injecting a webp image source to the Picture element
  //  to take advantage of webp images on supported browsers
  const image = <Picture {...pictureProps} />;

  const placeholder = (
    <Placeholder height={height} loader={lazyLoadProps.loader} width={width} />
  );

  const content = aspectRatio ? (
    <RatioBox aspectRatio={aspectRatio}>{image}</RatioBox>
  ) : (
    image
  );

  return (
    <LazyLoad placeholder={placeholder} {...lazyLoadProps}>
      {fadeIn ? (
        <CSSTransitionGroup
          transitionName="c-image--lazyload-fade"
          transitionAppear
          transitionAppearTimeout={500}
          transitionEnter={false}
          transitionLeave={false}
        >
          {content}
        </CSSTransitionGroup>
      ) : (
        content
      )}
    </LazyLoad>
  );
};

Image.defaultProps = {
  className: 'c-image',
  deviceIs: {},
  lazyLoad: {},
};

export default Image;
