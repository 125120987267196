// @flow
import React from 'react';
import Link from 'brastrap/containers/link/LinkContainer';

const WRAPPING_TAG_NAME = 'div';

/**
 * Get the wrapping tag
 * @param {Array} styledTextItems
 * @param {string} tagName
 * @returns {string}
 */
export const getWrappingTag = (
  styledTextItems: Array<StyledTextType>,
  tagName: string = 'div'
) =>
  (styledTextItems || []).some(item => item.headerTag)
    ? WRAPPING_TAG_NAME
    : tagName;

const StyledText = (props: StyledTextType) => {
  const style = {};
  const className = ['u-text-style'];
  if (props.color) style.color = props.color;
  if (props.style) className.push(`u-text-style-${props.style}`);
  if (props.size) className.push(`u-text-size-${props.size}`);
  if (props.letterSpacing)
    className.push(`u-text-letter-spacing-${props.letterSpacing}`);
  if (props.link && props.color) {
    style.borderBottom = `1px solid ${props.color}`;
    style.paddingBottom = '1px';
  }

  if (props.modifiers && props.modifiers.length > 0) {
    props.modifiers.forEach(modifier =>
      className.push(`u-text-style--${modifier}`)
    );
  }
  const titleProps: { className: string, style?: { color: string } } = {
    className: className.join(' '),
    style,
  };

  const TagName = props.headerTag || 'span';
  const styledTextChild = <TagName {...titleProps}>{props.content}</TagName>;
  return props.link ? (
    <Link href={props.link}>{styledTextChild}</Link>
  ) : (
    styledTextChild
  );
};

export default StyledText;
