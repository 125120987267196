import {
  RECEIVE_MEGA_MENU_ITEMS,
  TOGGLE_MEGA_MENU_NAVIGATION,
  CLOSE_MEGA_MENU_NAVIGATION,
} from '../../constants/masthead';

export const initialState = [];

/**
 * @param {Array} state
 * @param {Object} action
 * @returns {Object}
 */
export function megaMenu(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_MEGA_MENU_ITEMS:
      return [...action.payload];

    case TOGGLE_MEGA_MENU_NAVIGATION: {
      return state
        .filter(menuItem => action.payload.isDesktop || !menuItem.disabledMenuItem)
        .map((menu, index) => ({
          ...menu,

          // Toggle visibility if we have the correct menu item
          // Close all others
          isVisible: index === action.payload.index ? !menu.isVisible : false,
        }));
    }

    case CLOSE_MEGA_MENU_NAVIGATION: {
      return state.map(menu => ({
        ...menu,
        // Close all menus
        isVisible: false,
      }));
    }

    default:
      return state;
  }
}
