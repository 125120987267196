/* eslint-disable no-nested-ternary */

// @flow
import React from 'react';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Link from 'brastrap/containers/link/LinkContainer';
import Image from 'brastrap/containers/image/ImageContainer';
import { Image as ImageType } from 'brastrap/common/image/image.types';
import StyledText, {
  getWrappingTag,
} from 'brastrap/editorial/styled-text/StyledText';
import type { HeroPod as Props } from './hero-pod.types';
import { applyModifiers } from '../../utils';

/**
 * @param {Object} props
 * @returns {Object}
 */
const getImageProps = (props: Props): ImageType => {
  const {
    isMobile,
    isTablet,
    desktopBackgroundImage,
    mobileBackgroundImage,
    tabletBackgroundImage,
  } = props;

  switch (true) {
    case isMobile && mobileBackgroundImage && !!mobileBackgroundImage.src:
      return mobileBackgroundImage;

    case isTablet && tabletBackgroundImage && !!tabletBackgroundImage.src:
      return tabletBackgroundImage;

    default:
      // Desktop image is the only required image in Contentful.
      return desktopBackgroundImage;
  }
};

/**
 * @param {Object} props
 * @returns {XML}
 * @constructor
 */
const HeroPod = (props: Props) => {
  const {
    styledText,
    styledParagraph,
    callToAction,
    callToActionSecondary,
    layout,
    contentBackground,
    makeClickable,
    analyticsRef,
    hideContent,
    actions,
    isHalfContentLayout,
    isThirdContentLayout,
    hasBottomMargin,
  } = props;

  if (hideContent) return null;

  const headerText = (styledText || []).map(item => (
    <StyledText key={`heroTitle-${item.id}`} {...item} />
  ));

  const HeaderTextTagName = getWrappingTag(styledText || [], 'h1');

  const paragraphText = (styledParagraph || []).map(item => (
    <StyledText key={`heroParagraph-${item.id}`} {...item} />
  ));

  const ParagraphTextTagName = getWrappingTag(styledParagraph || [], 'h2');

  const imageProps = getImageProps(props);
  imageProps.alt = (styledText || []).map(item => item.content).join(' ');
  imageProps.className = 'c-hero-pod__image';

  const isClickable =
    !!makeClickable &&
    ((callToAction && callToAction.link && callToAction.link.url) ||
      (actions && actions[0].link)) &&
    !callToActionSecondary;

  let mappedActions;
  if (actions) {
    mappedActions = actions.map((action, index) => (
      <ButtonLink
        key={index}
        isDummyButton={isClickable}
        analyticsRef={
          actions.length > 1
            ? `${String(analyticsRef)}-${index + 1}`
            : analyticsRef
        }
        {...action}
      />
    ));
  }

  const image = <Image {...imageProps} />;

  const imageWithLink = isClickable ? (
    <Link
      analyticsRef={analyticsRef}
      href={callToAction.link && callToAction.link.url}
    >
      {image}
    </Link>
  ) : (
    image
  );

  const modifiers = [...layout];
  if (isClickable) {
    modifiers.push('is-clickable');
  }

  return (
    <section
      className={`c-hero-pod ${
        hasBottomMargin ? 'c-hero-pod--bottomMargin' : ''
      }`}
    >
      {imageWithLink}
      <div
        className={`${applyModifiers('c-hero-pod__body', modifiers)} ${
          isHalfContentLayout
            ? 'c-hero-pod__body--halfContentLayout'
            : isThirdContentLayout
            ? 'c-hero-pod__body--thirdContentLayout'
            : ''
        }`}
      >
        <div
          className={`c-hero-pod__body__inner ${
            isHalfContentLayout
              ? 'c-hero-pod__body__inner--halfContentLayout'
              : isThirdContentLayout
              ? 'c-hero-pod__body__inner--thirdContentLayout'
              : ''
          }`}
          style={{ backgroundColor: contentBackground }}
        >
          <header className="c-hero-pod__header">
            <HeaderTextTagName>{headerText}</HeaderTextTagName>
          </header>

          <header className="c-hero-pod__paragraph">
            <ParagraphTextTagName>{paragraphText}</ParagraphTextTagName>
          </header>

          {callToAction && (
            <div className="c-hero-pod__actions">
              <ButtonLink
                isDummyButton={isClickable}
                {...callToAction}
                analyticsRef={`${analyticsRef || ''} primary-action`}
              />

              {callToActionSecondary && (
                <ButtonLink
                  isDummyButton={isClickable}
                  {...callToActionSecondary}
                  analyticsRef={
                    callToAction && callToActionSecondary
                      ? `${analyticsRef || ''} secondary-action`
                      : analyticsRef
                  }
                />
              )}
            </div>
          )}

          {actions && (
            <div className="c-hero-pod__quickLinks">{mappedActions}</div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroPod;
