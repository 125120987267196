// @flow

type RemoveGiftVoucherPayment = 'bravissimo/bag/REMOVE_GIFT_VOUCHER_PAYMENT';
type SetPrivate = 'bravissimo/bag/SET_PRIVATE';
type SetSingleParcel = 'bravissimo/bag/SET_SINGLE_PARCEL';
type UpdateDeliveryMethodField = 'bravissimo/bag/UPDATE_DELIVERY_METHOD_FIELD';
type UpdateDeliveryMethod = 'bravissimo/bag/UPDATE_DELIVERY_METHOD';
type AddDeliveryMethodSuccess = 'bravissimo/bag/ADD_DELIVERY_METHOD_SUCCESS';
type AddDeliveryMethodFailure = 'bravissimo/bag/ADD_DELIVERY_METHOD_FAILURE';
type AddDeliveryMethodRequest = 'bravissimo/bag/ADD_DELIVERY_METHOD_REQUEST';
type AddDeliveryAddressSuccess = 'bravissimo/bag/ADD_DELIVERY_ADDRESS_SUCCESS';
type AddDeliveryAddressFailure = 'bravissimo/bag/ADD_DELIVERY_ADDRESS_FAILURE';
type AddDeliveryAddressRequest = 'bravissimo/bag/ADD_DELIVERY_ADDRESS_REQUEST';
type AddPaymentSuccess = 'bravissimo/bag/ADD_PAYMENT_SUCCESS';
type AddPaymentFailure = 'bravissimo/bag/ADD_PAYMENT_FAILURE';
type AddPaymentRequest = 'bravissimo/bag/ADD_PAYMENT_REQUEST';
type ReceiveBag = 'bravissimo/bag/RECEIVE';
type UpdateQuantityFailure = 'bravissimo/bag/UPDATE_QUANTITY_FAILURE';
type UpdateQuantityRequest = 'bravissimo/bag/UPDATE_QUANTITY_REQUEST';
type UpdateQuantitySuccess = 'bravissimo/bag/UPDATE_QUANTITY_SUCCESS';
type RemoveItemFailure = 'bravissimo/bag/REMOVE_ITEM_FAILURE';
type RemoveItemRequest = 'bravissimo/bag/REMOVE_ITEM_REQUEST';
type RemoveItemSuccess = 'bravissimo/bag/REMOVE_ITEM_SUCCESS';
type AddItemFailure = 'bravissimo/bag/ADD_ITEM_FAILURE';
type AddItemRequest = 'bravissimo/bag/ADD_ITEM_REQUEST';
type AddItemSuccess = 'bravissimo/bag/ADD_ITEM_SUCCESS';
type ValidateBagFailure = 'bravissimo/bag/VALIDATE_BAG_FAILURE';
type ValidateBagRequest = 'bravissimo/bag/VALIDATE_BAG_REQUEST';
type ValidateBagSuccess = 'bravissimo/bag/VALIDATE_BAG_SUCCESS';
type UpdateTotalWithUsTax = 'bravissimo/bag/UPDATE_TOTAL_WITH_US_TAX';
type hasUSDefaultAddressForUKSite =
  'bravissimo/bag/HAS_US_DEFAULT_ADDRESS_FOR_UK_SITE';

export const ADD_ITEM_SUCCESS: AddItemSuccess =
  'bravissimo/bag/ADD_ITEM_SUCCESS';
export const ADD_ITEM_REQUEST: AddItemRequest =
  'bravissimo/bag/ADD_ITEM_REQUEST';
export const ADD_ITEM_FAILURE: AddItemFailure =
  'bravissimo/bag/ADD_ITEM_FAILURE';

export const REMOVE_ITEM_SUCCESS: RemoveItemSuccess =
  'bravissimo/bag/REMOVE_ITEM_SUCCESS';
export const REMOVE_ITEM_REQUEST: RemoveItemRequest =
  'bravissimo/bag/REMOVE_ITEM_REQUEST';
export const REMOVE_ITEM_FAILURE: RemoveItemFailure =
  'bravissimo/bag/REMOVE_ITEM_FAILURE';

export const UPDATE_QUANTITY_SUCCESS: UpdateQuantitySuccess =
  'bravissimo/bag/UPDATE_QUANTITY_SUCCESS';
export const UPDATE_QUANTITY_REQUEST: UpdateQuantityRequest =
  'bravissimo/bag/UPDATE_QUANTITY_REQUEST';
export const UPDATE_QUANTITY_FAILURE: UpdateQuantityFailure =
  'bravissimo/bag/UPDATE_QUANTITY_FAILURE';

export const RECEIVE_BAG: ReceiveBag = 'bravissimo/bag/RECEIVE';

export const ADD_PAYMENT_REQUEST: AddPaymentRequest =
  'bravissimo/bag/ADD_PAYMENT_REQUEST';
export const ADD_PAYMENT_FAILURE: AddPaymentFailure =
  'bravissimo/bag/ADD_PAYMENT_FAILURE';
export const ADD_PAYMENT_SUCCESS: AddPaymentSuccess =
  'bravissimo/bag/ADD_PAYMENT_SUCCESS';

export const ADD_DELIVERY_ADDRESS_REQUEST: AddDeliveryAddressRequest =
  'bravissimo/bag/ADD_DELIVERY_ADDRESS_REQUEST';
export const ADD_DELIVERY_ADDRESS_FAILURE: AddDeliveryAddressFailure =
  'bravissimo/bag/ADD_DELIVERY_ADDRESS_FAILURE';
export const ADD_DELIVERY_ADDRESS_SUCCESS: AddDeliveryAddressSuccess =
  'bravissimo/bag/ADD_DELIVERY_ADDRESS_SUCCESS';

export const ADD_DELIVERY_METHOD_REQUEST: AddDeliveryMethodRequest =
  'bravissimo/bag/ADD_DELIVERY_METHOD_REQUEST';
export const ADD_DELIVERY_METHOD_FAILURE: AddDeliveryMethodFailure =
  'bravissimo/bag/ADD_DELIVERY_METHOD_FAILURE';
export const ADD_DELIVERY_METHOD_SUCCESS: AddDeliveryMethodSuccess =
  'bravissimo/bag/ADD_DELIVERY_METHOD_SUCCESS';
export const UPDATE_DELIVERY_METHOD: UpdateDeliveryMethod =
  'bravissimo/bag/UPDATE_DELIVERY_METHOD';
export const UPDATE_DELIVERY_METHOD_FIELD: UpdateDeliveryMethodField =
  'bravissimo/bag/UPDATE_DELIVERY_METHOD_FIELD';

export const SET_SINGLE_PARCEL: SetSingleParcel =
  'bravissimo/bag/SET_SINGLE_PARCEL';
export const SET_PRIVATE: SetPrivate = 'bravissimo/bag/SET_PRIVATE';

export const REMOVE_GIFT_VOUCHER_PAYMENT: RemoveGiftVoucherPayment =
  'bravissimo/bag/REMOVE_GIFT_VOUCHER_PAYMENT';

export const VALIDATE_BAG_FAILURE: ValidateBagFailure =
  'bravissimo/bag/VALIDATE_BAG_FAILURE';
export const VALIDATE_BAG_REQUEST: ValidateBagRequest =
  'bravissimo/bag/VALIDATE_BAG_REQUEST';
export const VALIDATE_BAG_SUCCESS: ValidateBagSuccess =
  'bravissimo/bag/VALIDATE_BAG_SUCCESS';
export const UPDATE_TOTAL_WITH_US_TAX: UpdateTotalWithUsTax =
  'bravissimo/bag/UPDATE_TOTAL_WITH_US_TAX';
export const HAS_US_DEFAULT_ADDRESS_FOR_UK_SITE: hasUSDefaultAddressForUKSite =
  'bravissimo/bag/HAS_US_DEFAULT_ADDRESS_FOR_UK_SITE';
