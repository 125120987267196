// @flow
import React, { type ChildrenArray } from 'react';
import { defineMessages, injectIntl, type IntlShape } from 'react-intl';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-field';

const ACTIVE_CLASS = 'is-current';

const INVALID_CLASS = 'is-invalid';

const messages = defineMessages({
  required: { id: 'field.required', defaultMessage: 'Required' },
});

type Props = {
  active: boolean,
  children: ChildrenArray<*>, // Props Node fails flow type checking on line 43. Set to array of any element.
  floatLabel: boolean,
  hiddenLabel: boolean,
  id: string,
  intl: IntlShape,
  invalid: boolean,
  label: string,
  modifiers: Modifiers,
  required: boolean,
  touched: boolean,
};

const Field = (props: Props) => {
  let className = applyModifiers(STYLE_PREFIX, props.modifiers);
  let labelClassName = `${STYLE_PREFIX}__label`;

  if (props.floatLabel) {
    className += ' js-field-floatlabel';
  }

  if (props.hiddenLabel) {
    className += ' c-field--label-hidden';
    labelClassName += ' u-hidden';
  }

  const hasValue = React.Children.map(
    props.children,
    child => child && child.props && child.props.value
  ).some(bool => bool);

  if (hasValue || props.active) {
    className += ` ${ACTIVE_CLASS}`;
  }

  if (props.touched && props.invalid) {
    className += ` ${INVALID_CLASS}`;
  }

  const requiredText = props.intl.formatMessage(messages.required);
  const label = (props.label || props.required) && (
    <label className={labelClassName} htmlFor={props.id}>
      {props.label}
      {props.required && (
        <em className="c-field__required" title={requiredText}>
          {requiredText}
        </em>
      )}
    </label>
  );

  return (
    <div className={className}>
      {label}
      {props.children}
    </div>
  );
};

export default injectIntl(Field);
