// @flow
import { RECEIVE_COUNTRIES } from '../../constants/countries';
import type { CallApi } from '../../constants/api';

export type Country = {
  deliveryAllowed: boolean,
  id: number,
  isoCode: string,
  name: string,
};

export type Countries = Array<Country>;

export type ReceiveCountries = Countries => {
  type: RECEIVE_COUNTRIES,
  payload: Countries,
};

export type ApiGetCountries = {
  actions: {
    success: ReceiveCountries,
  },
  type: CallApi,
  url: string,
};
