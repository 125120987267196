// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'brastrap/containers/link/LinkContainer';
import type { ReturnNav as Props } from './return-nav.types.js';

const ReturnNav = (props: Props) => (
  <nav className="c-return-nav" role="navigation">
    <Link className="c-return-nav__label" href={props.url}>
      {props.displayBackTo ? (
        <FormattedMessage
          id="return-nav.label"
          description="Return nav text"
          defaultMessage="Back to {label}"
          values={{
            label: props.label,
          }}
        />
      ) : (
        props.label
      )}
    </Link>
  </nav>
);

ReturnNav.defaultProps = {
  displayBackTo: true,
};

export default ReturnNav;
