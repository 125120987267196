// @flow
import { getSizeCodeFromSku } from '../utils/utils';
import {
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAILURE,
  PRODUCT_SELECT_TAB,
  PRODUCT_SELECT_STYLECOLOUR,
  PRODUCT_QUANTITY_CHANGE,
  PRODUCT_SELECT_SKU,
  PRODUCT_GALLERY_SET_ZOOM,
  SET_SIZE_GRID_CONTAINER_WIDTH,
  ACCESSORY_SIZE_SELECT,
  RECEIVE_AVAILABLE_SIZES,
  ACCESSORY_COLOUR_SELECT,
  RECEIVE_GIFT_VOUCHER_PAGE,
  RECEIVE_IMAGE_DIMENSIONS,
  RECEIVE_THUMBNAIL_WIDTH,
  UPDATE_ACCESSORY_SKU,
  RECIEVE_COLOURS_NOT_IN_STOCK,
  COLOURS_NOT_IN_STOCK_REQUEST,
  COLOURS_NOT_IN_STOCK_FAILURE,
} from '../constants/product';

import { type Actions, type ProductTab } from '../actions/product';

type MatchingStyle = {
  defaultStyleColourId: number,
  enabled: boolean,
  styleCode: string,
  isAccessory: boolean,
};

type State = {
  availableSizes: DropDownOption[],
  displayGalleryCarousel: boolean,
  galleryThumbnailWidth: number,
  isFetching: boolean,
  quantity: number,
  isZoomed: boolean,
  figureDimensions: number[],
  sizeGridContainerWidth: number,
  selectedStyleColour: string | null,
  styleColour: {
    data: ProductData,
    tabs: ProductTab[],
    selectedSku?: Sku | null,
    matchingStyles: MatchingStyle[],
  },
  coloursNotInStock: string[] | null,
};

export const initialState = {
  availableSizes: [],
  displayGalleryCarousel: false,
  galleryThumbnailWidth: 0,
  isFetching: false,
  quantity: 1,
  styleColour: {
    data: {
      additionalDetails: [],
      availableColours: [],
      brand: '',
      colour: {
        categories: [],
        collection: '',
        colour: '',
        core: false,
        swatch: '',
        swatchPatternImage: {},
      },
      deliveryInformation: '',
      description: '',
      images: [],
      launchDate: {},
      matchingStyleColours: {
        label: '',
        styleColours: [],
      },
      pageDescription: '',
      pageTitle: '',
      prices: {
        originalPrice: 0,
        price: 0,
        promotions: [],
      },
      containers: [],
      ratings: {
        id: '',
        rating: {
          total: 0,
          value: 0,
        },
        styleCode: '',
        styleColours: [],
      },
      reviews: [],
      sizes: {
        value: '',
        variants: [],
      },
      styleCode: '',
      styleColourCode: '',
      styleColourId: 0,
      styleColourName: '',
      styleName: '',
      url: '',
      video: {
        id: '',
        url: '',
      },
    },
    tabs: [],
    matchingStyles: [],
  },
  isZoomed: false,
  figureDimensions: [],
  sizeGridContainerWidth: 0,
  selectedStyleColour: null,
  coloursNotInStock: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function product(state: State = initialState, action: Actions) {
  switch (action.type) {
    case PRODUCT_REQUEST:
      return {
        ...state,

        isFetching: true,
      };

    case PRODUCT_SUCCESS: {
      // If there is only one size variant automatically select it when we receive the product data.
      const styleColour = action.payload;
      const sizeVariants = styleColour.data.sizes.variants;
      let sku = styleColour.selectedSku;
      if (sku) {
        const matchedSku = getSizeCodeFromSku(sku, styleColour);

        if (matchedSku && matchedSku.stockStatus !== 'Sold out') {
          sku = matchedSku;
        }
      }

      if (sizeVariants.length === 1 && sizeVariants[0].skuCode) {
        sku = sizeVariants[0];
      }

      const selectedSku = sku;
      // If we have any matching styles then add them here
      const {
        styleColours: matchingStyleColours,
      } = styleColour.data.matchingStyleColours;
      const matchingStyles =
        matchingStyleColours && matchingStyleColours.length
          ? matchingStyleColours.map(matchingStyleColour => {
              const data = {
                defaultStyleColourId: matchingStyleColour.defaultStyleColourId,
                enabled: false,
                styleCode: matchingStyleColour.styleCode,
                isAccessory: matchingStyleColour.isAccessory,
              };

              // If an accessory only has one sku, then need to add it here as the size picker will not be used
              // to select it.
              const hasOne =
                matchingStyleColour.colours.length &&
                matchingStyleColour.colours[0].sizes.variants.length === 1;
              if (hasOne) {
                data.enabled = true;
                // To get this to type correctly we may need to add a "kind" property to the data
                // to distinguish between "LeafSizes" and "Level1Sizes"
                // See https://stackoverflow.com/questions/40874530/idiomatic-way-to-access-properties-of-union-type
                // $FlowFixMe - flow is not correctly picking up the disjoint union type.
                data.sku =
                  // $FlowFixMe
                  matchingStyleColour.colours[0].sizes.variants[0].skuCode;
              }

              return data;
            })
          : [];

      const productData = {
        ...styleColour,
        matchingStyles,
        selectedSku,
      };

      return {
        ...state,

        isFetching: false,
        selectedStyleColour: styleColour.data.styleColourCode,
        styleColour: productData,
      };
    }

    case PRODUCT_FAILURE:
      return {
        ...state,

        isFetching: false,
        message: action.payload,
      };

    case PRODUCT_SELECT_STYLECOLOUR: {
      const selectedStyleColour = action.payload;
      const sizeVariants =
        state.styleColour.data &&
        state.styleColour.data.sizes &&
        state.styleColour.data.sizes.variants;
      let sku = state.styleColour.selectedSku;

      if (sku) {
        const matchedSku = getSizeCodeFromSku(sku, state.styleColour);

        if (matchedSku && matchedSku.stockStatus !== 'Sold out') {
          sku = matchedSku;
        }

        if (sizeVariants.length === 1 && sizeVariants[0].skuCode) {
          sku = sizeVariants[0];
        }
      }

      return {
        ...state,
        selectedStyleColour,
        styleColour: {
          ...state.styleColour,
          selectedSku: sku,
        },
      };
    }

    case PRODUCT_SELECT_TAB: {
      const { id } = action.payload;
      const tabs = [...state.styleColour.tabs];
      const selectIndex = tabs.findIndex(tab => tab.id === id);

      tabs.forEach((tab, index) => {
        tabs[index].current = selectIndex === index;
      });

      return {
        ...state,
        styleColour: {
          ...state.styleColour,
          tabs,
        },
      };
    }

    case PRODUCT_QUANTITY_CHANGE:
      return {
        ...state,
        quantity: action.payload,
      };

    case PRODUCT_SELECT_SKU: {
      return {
        ...state,
        styleColour: {
          ...state.styleColour,
          selectedSku: action.payload || null,
        },
      };
    }

    case PRODUCT_GALLERY_SET_ZOOM:
      return {
        ...state,
        isZoomed: action.payload,
      };

    case RECEIVE_IMAGE_DIMENSIONS:
    case RECEIVE_THUMBNAIL_WIDTH: {
      const newState = {
        ...state,
        ...action.payload,
      };

      const { figureDimensions, galleryThumbnailWidth } = newState;
      newState.displayGalleryCarousel =
        figureDimensions && figureDimensions[0] < galleryThumbnailWidth;

      return newState;
    }

    case SET_SIZE_GRID_CONTAINER_WIDTH:
      return {
        ...state,
        sizeGridContainerWidth: action.payload,
      };

    case ACCESSORY_SIZE_SELECT: {
      const { styleCode, sku, defaultStyleColourId } = action.payload;
      // Add the selected sku to the matching styles array.
      // If sku is an empty string then disable the button.
      const { data } = state.styleColour;
      if (data && Object.keys(data).length) {
        return {
          ...state,
          styleColour:
            data.styleColourCode === state.selectedStyleColour
              ? {
                  ...state.styleColour,
                  matchingStyles: state.styleColour.matchingStyles.map(
                    style => {
                      const newStyle = { ...style };

                      if (
                        style.styleCode === styleCode &&
                        style.defaultStyleColourId === defaultStyleColourId
                      ) {
                        newStyle.sku = sku;
                        newStyle.enabled = !!sku;
                      }

                      return newStyle;
                    }
                  ),
                }
              : state.styleColour,
        };
      }
      return state;
    }

    case ACCESSORY_COLOUR_SELECT: {
      const { defaultStyleColourId, styleColourCode } = action.payload;
      const { data } = state.styleColour;
      if (state.selectedStyleColour && Object.keys(data).length) {
        const styleColours = [...data.matchingStyleColours.styleColours];
        const accessoryToUpdate = styleColours.find(
          accessory => accessory.defaultStyleColourId === defaultStyleColourId
        );
        if (accessoryToUpdate) {
          accessoryToUpdate.colours = accessoryToUpdate.colours.map(colour => ({
            ...colour,
            selected: colour.styleColourCode === styleColourCode,
          }));
        }
        return {
          ...state,
          styleColour: {
            ...state.styleColour,
            data: {
              ...data,
              matchingStyleColours: {
                ...data.matchingStyleColours,
                styleColours,
              },
            },
          },
        };
      }
      return state;
    }

    case UPDATE_ACCESSORY_SKU: {
      const { defaultStyleColourId, styleColourCode } = action.payload;
      const { data } = state.styleColour;

      if (state.selectedStyleColour && Object.keys(data).length) {
        if (data.styleColourCode === state.selectedStyleColour) {
          const accessories = data.matchingStyleColours.styleColours;
          const matchingStyles = state.styleColour.matchingStyles.map(
            matchingStyle => {
              if (
                styleColourCode.match(matchingStyle.styleCode) &&
                defaultStyleColourId === matchingStyle.defaultStyleColourId
              ) {
                const shared = {
                  defaultStyleColourId: matchingStyle.defaultStyleColourId,
                  styleCode: matchingStyle.styleCode,
                  isAccessory: matchingStyle.isAccessory,
                };

                // If the style colour is only available in one size then it should be enabled by default with the sku
                // pre-selected
                const accessoryData = accessories.find(
                  item =>
                    styleColourCode.match(item.styleCode) &&
                    defaultStyleColourId === item.defaultStyleColourId
                );
                if (accessoryData && accessoryData.colours) {
                  const accessoryColour = accessoryData.colours.find(
                    item => item.styleColourCode === styleColourCode
                  );

                  if (
                    accessoryColour &&
                    accessoryColour.sizes.variants.length === 1
                  ) {
                    return {
                      ...shared,
                      enabled: true,
                      // $FlowFixMe - see comment beginning on line 110
                      sku: accessoryColour.sizes.variants[0].skuCode,
                    };
                  }
                }

                // If the style colour has more than one size then it will not be enabled by default,
                // and no sku will be pre-selected
                return {
                  ...shared,
                  enabled: false,
                };
              }
              return matchingStyle;
            }
          );
          return {
            ...state,
            styleColour: {
              ...state.styleColour,
              matchingStyles,
            },
          };
        }
      }

      return state;
    }

    case RECEIVE_AVAILABLE_SIZES: {
      return {
        ...state,
        availableSizes: action.payload,
      };
    }

    case RECEIVE_GIFT_VOUCHER_PAGE:
      return {
        ...state,
        paperVouchers: action.payload,
      };

    case RECIEVE_COLOURS_NOT_IN_STOCK: {
      return {
        ...state,
        coloursNotInStock: action.payload || null,
        isFetching: false,
      };
    }

    case COLOURS_NOT_IN_STOCK_FAILURE:
      return {
        ...state,

        isFetching: false,
        message: action.payload,
      };

    case COLOURS_NOT_IN_STOCK_REQUEST:
      return {
        ...state,

        isFetching: true,
      };

    default:
      return state;
  }
}
