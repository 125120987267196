/* WARNING: this file is intended to be replaced and
this data will eventually come from contentful or b2.
Until this happens, the button names are not localised
(curvy, really curvy, etc.)
*/
export const sizeFacetData = {
  braFacets: [
    {
      id: 'backsize',
      name: 'Back size',
      values: [
        { id: '28', name: '28' },
        { id: '30', name: '30' },
        { id: '32', name: '32' },
        { id: '34', name: '34' },
        { id: '36', name: '36' },
        { id: '38', name: '38' },
        { id: '40', name: '40' },
        { id: '42', name: '42' },
      ],
      style: 'disc',
      enhanced: true,
    },
    {
      id: 'cupsize',
      name: 'Cup size',
      values: [
        { id: 'd', name: 'd' },
        { id: 'dd', name: 'dd' },
        { id: 'e', name: 'e' },
        { id: 'f', name: 'f' },
        { id: 'ff', name: 'ff' },
        { id: 'g', name: 'g' },
        { id: 'gg', name: 'gg' },
        { id: 'h', name: 'h' },
        { id: 'hh', name: 'hh' },
        { id: 'j', name: 'j' },
        { id: 'jj', name: 'jj' },
        { id: 'k', name: 'k' },
        { id: 'kk', name: 'kk' },
        { id: 'l', name: 'l' },
      ],
      style: 'disc',
      enhanced: true,
    },
  ],
  clothingFacets: [
    {
      id: 'dresssize',
      name: 'Dress size',
      values: [
        { id: '08', name: '08' },
        { id: '10', name: '10' },
        { id: '12', name: '12' },
        { id: '14', name: '14' },
        { id: '16', name: '16' },
        { id: '18', name: '18' },
      ],
      style: 'disc',
      enhanced: true,
    },
    {
      id: 'curvysize',
      name: 'Curvy size',
      values: [
        { id: 'cv', name: 'Curvy' },
        { id: 'rc', name: 'Really Curvy' },
        { id: 'sc', name: 'Super Curvy' },
      ],
      style: 'button',
      enhanced: true,
    },
  ],
};

export const multiCategoryMap = {
  cupsize: {
    d: ['de', 'ddde'],
    dd: ['de', 'ddde'],
    e: ['de', 'ddde'],
    f: ['fg', 'fffg'],
    ff: ['fg', 'fffg'],
    g: ['fg', 'fffg'],
    gg: ['ggh', 'gghhh'],
    h: ['ggh', 'gghhh'],
    hh: ['hhj'],
    j: ['hhj'],
    jj: [],
    k: [],
    kk: [],
    l: [],
  },
  combined: {
    '28e': ['s'],
    '28f': ['s'],
    '28ff': ['s'],
    '28g': ['m'],
    '28gg': ['m'],
    '28h': ['l'],
    '28hh': ['l'],

    '30d': ['xs', 's'],
    '30dd': ['xs', 's'],
    '30e': ['xs', 's'],
    '30f': ['xs', 's'],
    '30ff': ['xs', 's', 'm'],
    '30g': ['xs', 's', 'm'],
    '30gg': ['xs', 's', 'm', 'l'],
    '30h': ['xs', 's', 'm', 'l'],
    '30hh': ['m', 'l'],

    '32d': ['xs', 's'],
    '32dd': ['xs', 's'],
    '32e': ['xs', 's', 'm'],
    '32f': ['s', 'm'],
    '32ff': ['s', 'm'],
    '32g': ['s', 'm', 'l'],
    '32gg': ['s', 'm', 'l'],
    '32h': ['m', 'l'],
    '32hh': ['l'],

    '34d': ['xs', 's'],
    '34dd': ['xs', 's', 'm'],
    '34e': ['xs', 's', 'm'],
    '34f': ['m'],
    '34ff': ['m', 'l'],
    '34g': ['m', 'l'],
    '34gg': ['m', 'l'],
    '34h': ['l'],
    '34hh': ['xl'],

    '36d': ['xs', 's', 'm'],
    '36dd': ['xs', 's', 'm'],
    '36e': ['xs', 's', 'm'],
    '36f': ['l'],
    '36ff': ['l'],
    '36g': ['l'],
    '36gg': ['l'],
    '36h': ['xl'],
    '36hh': ['xl'],

    '38d': ['xs', 's', 'm'],
    '38dd': ['xs', 's', 'm', 'l'],
    '38e': ['xs', 's', 'm', 'l'],
    '38f': ['l', 'xl'],
    '38ff': ['l', 'xl'],

    '38g': ['l', 'xl'],
    '38gg': ['xl'],
    '38h': ['xl', 'xxl'],
    '38hh': ['xl'],

    '40f': ['xxl'],
    '40ff': ['xxl'],
    '40g': ['xxl'],
    '40gg': ['xxl'],

    '42f': ['xxl'],
    '42ff': ['xxl'],
    '42g': ['xxl'],
  },
  dresssize: {
    '08': ['xs'],
    10: ['xs', 's'],
    12: ['s', 'm'],
    14: ['m', 'l'],
    16: ['l', 'xl'],
    18: ['xl', 'xxl'],
  },
  curvysize: {
    cv: ['cr', 'curvy', 'curvyreallycurvy'],
    rc: ['cr', 'rs', 'reallycurvy', 'curvyreallycurvy', 'reallysupercurvy'],
    sc: ['rs', 'supercurvy', 'reallysupercurvy'],
  },
};

export const sizeSelectorFacets = [
  'cupsize',
  'backsize',
  'dresssize',
  'curvysize',
];
