// @flow
import React from 'react';
import EditorialBlock from 'brastrap/editorial/editorial-block/EditorialBlock';
import type { ContentStack as Props } from './content-stack.types';

const STYLE_PREFIX = 'c-content-stack';

const ContentStack = (props: Props) => (
  <div className={STYLE_PREFIX}>
    {props.items.map(item => (
      <div key={item.id} className={`${STYLE_PREFIX}__item`}>
        <EditorialBlock content={item} />
      </div>
    ))}
  </div>
);

ContentStack.defaultProps = {
  items: [],
};

export default ContentStack;
