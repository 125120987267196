import PropTypes from 'prop-types';
import React from 'react';
import Link from 'brastrap/containers/link/LinkContainer';
import Image from 'brastrap/common/image/Image';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-section-nav__item';

const SectionNav = props => (
  <nav className="c-section-nav" aria-labelledby={props.id}>
    <h1 className="c-section-nav__title u-hidden" id={props.id}>
      {props.title}
    </h1>
    <ul
      className={
        props.footerSectionNav
          ? 'c-section-nav__footer-text'
          : 'c-section-nav__items'
      }
    >
      {props.items.map(item => {
        let className = applyModifiers(STYLE_PREFIX, item.modifiers);
        if (item.current) {
          className += ' is-current';
        }
        const image = !item.img ? null : (
          <Image
            className="c-section-nav__img u-crop-circle"
            src={item.img.src}
            alt={item.img.alt}
          />
        );

        const aProps = {
          className: 'c-section-nav__label u-block-link',
        };

        if (item.onClick) {
          aProps.onClick = item.onClick;
        }

        if (item.url) {
          aProps.href = item.url;
        }

        let link;

        switch (item.urlTarget) {
          case 'label':
            link = (
              <div className="c-section-nav__label">
                {image}
                <Link
                  {...aProps}
                  className="c-section-nav__text u-text-style--underline"
                >
                  {item.label}
                </Link>
                {item.note && (
                  <span className="c-section-nav__note">{item.note}</span>
                )}
              </div>
            );
            break;
          default:
            link = (
              <Link {...aProps}>
                {image}
                <span className="c-section-nav__text">{item.label}</span>
                {item.note && (
                  <span className="c-section-nav__note">{item.note}</span>
                )}
              </Link>
            );
            break;
        }

        // This used to be an index :sigh:
        // Quick fix to make it the label.
        const key = item.label.replace(' ', '-');

        return (
          <li key={key} className={className}>
            {link}
          </li>
        );
      })}
    </ul>
  </nav>
);

SectionNav.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.array,
  footerSectionNav: PropTypes.bool,
};

SectionNav.defaultProps = {
  items: [],
};

export default SectionNav;
