import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 200 200"
  >
    <path d="M200 129c0 4-3 9-4 11l-25 33c-4 6-10 11-17 11H63c-4 0-7-3-7-7V75c0-5 3-11 6-14l53-55c2-2 4-2 8-2 0 0 9 2 13 3 4 1 5 4 5 9 0 5-15 42-15 42-1 4 2 8 6 8h50c10 0 18 8 18 18v45zM0 80c0-9 7-16 16-16h8c9 0 16 7 16 16v88c0 9-7 16-16 16h-8c-9 0-16-7-16-16V80z" />
  </svg>
);

export default SvgIcon;
