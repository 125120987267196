// @flow
import React from 'react';
import type { Node } from 'react';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import StyledText, {
  getWrappingTag,
} from 'brastrap/editorial/styled-text/StyledText';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Button from 'brastrap/common/button/Button';
import type { Props as Action } from 'brastrap/common/button-link/ButtonLink';
import { applyModifiers } from '../../utils';

export type Props = {
  children?: Node,
  hideTitle: boolean,
  hideActions: boolean,
  introduction?: string,
  modifiers?: Modifiers,
  title?: string,
  id?: string,
  actions: Array<Action>,
  styledTextTitle?: Array<StyledTextType>,
  analyticsRef?: string,
  adjacentItem?: Node,
  noBodyMargins: boolean,
};

const STYLE_PREFIX = 'c-container';

const Container = (props: Props) => {
  let styledTitle;
  if (props.styledTextTitle) {
    styledTitle = props.styledTextTitle.map((item, index) => (
      <StyledText key={index} {...item} />
    ));
  }
  const showActions = props.actions.length > 0 && !props.hideActions;
  const sectionProps: {
    className: string,
    id?: string,
    'data-ref'?: string,
  } = {
    className: applyModifiers(STYLE_PREFIX, props.modifiers),
  };

  if (!props.children && (props.actions.length === 0 || props.hideActions)) {
    sectionProps.className += ` ${STYLE_PREFIX}--empty`;
  }

  if (props.id) {
    sectionProps.id = props.id;
  }

  if (props.analyticsRef) {
    sectionProps['data-ref'] = props.analyticsRef;
  }

  const TagName = getWrappingTag(props.styledTextTitle || [], 'h2');
  const title = styledTitle || props.title;

  const containerHeader = (
    <header className="c-container__header">
      <TagName className="c-container__title">{title}</TagName>
      {props.introduction && (
        <div className="c-container__desc">
          <MarkDown content={props.introduction} />
        </div>
      )}
    </header>
  );

  let containerBodyClasses = 'c-container__body';
  containerBodyClasses += props.noBodyMargins
    ? ' c-container__body--noMargins'
    : '';

  return (
    <section {...sectionProps}>
      <div className={containerBodyClasses}>
        {title &&
          !props.hideTitle &&
          (props.adjacentItem ? (
            <div className="c-container__headerContainer">
              {containerHeader}
              {props.adjacentItem}
            </div>
          ) : (
            containerHeader
          ))}
        {props.children && (
          <div className="c-container__main">{props.children}</div>
        )}
        {showActions && (
          <div className="c-container__actions">
            {props.actions.map((action, index) =>
              action.submit ? (
                <Button key={`action${index}`} {...action} />
              ) : (
                <ButtonLink key={`action${index}`} {...action} />
              )
            )}
          </div>
        )}
      </div>
    </section>
  );
};

Container.defaultProps = {
  hideTitle: false,
  hideActions: false,
  modifiers: [],
  actions: [],
  noBodyMargins: false,
};

export default Container;
