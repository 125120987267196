import { redirect } from '../redirect';
import { startTimer } from '../timers';
import { addToQueue, removeFromQueue } from '../modal';
import {
  RECEIVE_LOCALES,
  LOCALE_CHANGE_SUCCESS,
  REDIRECT_MODAL_ID,
} from '../../constants/app';
import { CALL_API } from '../../constants/api';

/**
 * This is not really an action. So not sure if this is the best place for it...
 * It's more of a helper function that is required by containers and actions.
 *
 * Should the redirection modal be displayed?
 * It should if:
 * - There are other instances of the website running (we have other locales to switch to).
 * - The expected locale does not match the selected locale AND the user has not explicitly chosen to be here.
 * It should not if:
 * - There is not an instance for the expected locale.
 *
 * @param {Object} localeState
 * @returns {boolean}
 */
export const shouldDisplayRedirectionModal = localeState => {
  const { available, explicit, expected, selected } = localeState;
  const hasOtherSitesAvailable = available && available.length;
  const isOnWrongSite = expected !== selected;
  const hasChosenToBeOnWrongSite = isOnWrongSite && explicit === selected;
  const expectedLocaleIsAvailable = !!available.find(
    ({ locale }) => locale === expected
  );

  if (!expected || !hasOtherSitesAvailable) {
    // No other sites available or we cannot determine the user's expected locale.
    return false;
  }

  if (!expectedLocaleIsAvailable) {
    // There is no locale available for the expected locale.
    // Therefore only display the modal if we're not on the international site (en-GB) or
    // the user has chosen to be on the selected site.
    const isOnInternationalSite = selected === 'en-GB';
    return hasChosenToBeOnWrongSite
      ? !hasChosenToBeOnWrongSite
      : !isOnInternationalSite;
  }

  // If the user is on the wrong site, then only show the modal if the user has not chosen to be on this site.
  return hasChosenToBeOnWrongSite ? !hasChosenToBeOnWrongSite : isOnWrongSite;
};

/**
 * Called when the api request to change the locale is successful.
 *
 *  @param {Object} payload
 *  @returns {{type: {string}, payload: {Object}}}
 */
export function localeSuccess(payload) {
  return {
    payload,
    type: LOCALE_CHANGE_SUCCESS,
  };
}

/**
 * @param {Object} payload
 * @return {{payload: *, type: String}}
 */
export function receiveLocales(payload) {
  return {
    payload,
    type: RECEIVE_LOCALES,
  };
}

/**
 * Send an api request to the server to ensure the user is not prompted to change their locale again.
 * @param {String} locale
 * @return {{method: string, type: string, url: string}}
 */
function apiRememberChoice(locale) {
  return {
    method: 'post',
    params: { locale },
    type: CALL_API,
    url: 'locale/dont-prompt',
  };
}

/**
 * Redirect the user to the expected locale
 * @returns {Promise}
 */
export const redirectToExpected = () => (dispatch, getState) => {
  const {
    app: {
      config: { basePath },
      locale: { available, expected },
      routing: { pathname, search },
    },
  } = getState();
  const { url } = available.find(locale => locale.locale === expected);
  if (url) {
    dispatch(redirect(`${url}${pathname.replace(basePath, '')}${search}`));
  }
};

/**
 * User has chosen to ignore the popup. Remember choice and close the layer.
 * @returns {Promise}
 */
export const remainOnSelected = () => (dispatch, getState) => {
  const { locale } = getState().app;
  const chosen = locale.selected;

  dispatch(apiRememberChoice(chosen));
  dispatch(
    receiveLocales({
      ...locale,
      chosen,
    })
  );
  dispatch(removeFromQueue(REDIRECT_MODAL_ID));
};

/**
 * Only open the layer if needed.
 * @returns {Function}
 */
export const displaySuggestionModal = () => (dispatch, getState) => {
  const {
    app: { locale },
  } = getState();
  const { modalContent: { delay } = {} } = locale;
  if (shouldDisplayRedirectionModal(locale)) {
    dispatch(
      startTimer('displaySuggestionModal', delay, () => {
        dispatch(addToQueue(REDIRECT_MODAL_ID));
      })
    );
  }
};
