// @flow
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import StyledText, {
  getWrappingTag,
} from 'brastrap/editorial/styled-text/StyledText';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Image from 'brastrap/common/image/Image';
import Link from 'brastrap/containers/link/LinkContainer';
import type { GuideSummary as Props } from './guide-summary.types.js';

const STYLE_PREFIX = 'c-guide-summary';

const messages = defineMessages({
  action: { id: 'guide-summary.action', defaultMessage: 'Shop the collection' },
});

const GuideSummary = (props: Props) => {
  const {
    intl: { formatMessage },
  } = props;
  let className = STYLE_PREFIX;
  if (props.image) {
    className += ` ${STYLE_PREFIX}--with-img`;
  }
  const TagName = getWrappingTag(props.title || [], 'h1');
  const href = props.url.charAt(0) === '/' ? props.url : `/${props.url}`;
  const hasCollectionUrl = href.includes('collections/');

  const content = (
    <div className="c-guide-summary__body">
      <header className="c-guide-summary__header">
        {props.image && (
          <Image
            className="c-guide-summary__img"
            src={props.image.src}
            alt={props.image.alt}
          />
        )}
        <TagName className="c-guide-summary__title">
          {props.label && (
            <span className="c-guide-summary__label u-hidden">
              {props.label}
            </span>
          )}
          <span className="u-hidden">:</span>
          {props.title.map((item, index) => (
            <StyledText key={index} {...item} />
          ))}
        </TagName>
      </header>
      <div className="c-guide-summary__main">
        <div className="s-prose s-prose--small">
          {props.introduction && <MarkDown content={props.introduction} />}
        </div>
      </div>
    </div>
  );

  return (
    <article className={className}>
      {hasCollectionUrl ? (
        content
      ) : (
        <Link className="u-block-link" href={href}>
          {content}
        </Link>
      )}
      {hasCollectionUrl && (
        <div className="c-guide-summary__actions">
          <ButtonLink
            url={href}
            linkType="href"
            modifiers={['filled']}
            label={props.buttonText || formatMessage(messages.action)}
          />
        </div>
      )}
    </article>
  );
};

GuideSummary.defaultProps = {
  title: [],
};

export default injectIntl(GuideSummary);
