// @flow
import React from 'react';
import _template from 'lodash/template';
import _upperFirst from 'lodash/upperFirst';
import Container, {
  type Props as ContainerProps,
} from 'brastrap/common/container/Container';
import Banner from 'brastrap/containers/banner/BannerContainer';
import {
  DEFAULT_BACKGROUND_COLOUR,
  DEFAULT_CHARACTERS_TO_SHOW,
} from 'brastrap/editorial/banner/Banner';
import type { Props as Action } from 'brastrap/common/button-link/ButtonLink';

type Attribute = string;
type Value = string;
type Props = {
  ...ContainerProps,
  attribute: Attribute, // type eg: size
  value: Value, // value eg: 30F
  shopBySizeUseBanner: boolean,
};

/**
 * Replace all occurrences of an attribute with a value.
 * For example: `{size}` to `28E`, `{colour}` to `Blue`
 *
 * @param {string} templateString
 * @param {string} attribute
 * @param {string} value
 *
 * @returns {string}
 */
const toTemplate = (
  templateString: string,
  attribute: Attribute,
  value: Value
) => {
  const compiled = _template(templateString, {
    interpolate: /{([\s\S]+?)}/g,
  });

  return compiled({ [attribute]: value });
};

/**
 * Upper case the first letter of each word in a string
 *
 * @param {string} words
 *
 * @returns {string}
 */
const upperCaseEachFirstLetter = (words = '') =>
  words
    .split(' ')
    .map(word => _upperFirst(word))
    .join(' ');

/**
 * Formats the value to uppercase or first character uppercase before templating it.
 *
 * @param {string} templateString
 * @param {string} attribute
 * @param {string} value
 * @param {boolean} uppercaseAll
 *
 * @returns {string}
 */
export const replaceAttribute = (
  templateString: string,
  attribute: Attribute,
  value: Value,
  uppercaseAll: boolean = false
) => {
  const formattedValue =
    uppercaseAll && (attribute === 'size' || attribute === 'colour')
      ? value.toUpperCase()
      : upperCaseEachFirstLetter(value);

  return toTemplate(templateString, attribute, formattedValue);
};

/**
 * Apply the template function to all properties in the container that need the size adding.
 *
 * @param {Object} props
 *
 * @returns {{}}
 */
const applyTemplate = (props: Props): ContainerProps => {
  const containerProps = { ...props };
  const { attribute, value } = props;

  // I did have a recursive function here that spun through all the properties and ran the template
  // function through all the string properties we want to update:
  // - content
  // - label
  // - url
  // Unfortunately flow moaned and moaned until I removed it... so we're left with this
  containerProps.actions = (containerProps.actions || []).map(
    (action: Action) => ({
      ...action,
      label: replaceAttribute(action.label, attribute, value),
      url: toTemplate(action.url || '', attribute, value),
    })
  );

  if (props.shopBySizeUseBanner) {
    containerProps.backgroundColour = DEFAULT_BACKGROUND_COLOUR;

    containerProps.contentBlock = {
      charactersToShow: DEFAULT_CHARACTERS_TO_SHOW,
      content: replaceAttribute(
        containerProps.introduction,
        attribute,
        value,
        attribute === 'size'
      ),
    };

    containerProps.text = {
      desktop: (containerProps.styledTextTitle || []).map(
        (styledText: StyledTextType) => ({
          ...styledText,
          content: replaceAttribute(styledText.content, attribute, value, true),
        })
      ),
    };

    containerProps.modifiers = ['center'];
  } else {
    containerProps.introduction = replaceAttribute(
      containerProps.introduction,
      attribute,
      value,
      attribute === 'size'
    );
    containerProps.styledTextTitle = (containerProps.styledTextTitle || []).map(
      (styledText: StyledTextType) => ({
        ...styledText,
        content: replaceAttribute(styledText.content, attribute, value, true),
      })
    );
    containerProps.hideActions = !!value.match(/^[0-9]{0,2}((l|[k-l]{2}))$/i);
  }

  delete containerProps.attribute;
  delete containerProps.value;

  return containerProps;
};

const ShopByContainer = (props: Props) =>
  props.shopBySizeUseBanner ? (
    <Container>
      <Banner {...applyTemplate(props)} />
    </Container>
  ) : (
    <Container {...applyTemplate(props)} />
  );

export default ShopByContainer;
