import { CALL_API } from '../constants/api';
import {
  UPDATE_COMMUNICATION_PREFS_SUCCESS,
  UPDATE_COMMUNICATION_PREFS_REQUEST,
  UPDATE_COMMUNICATION_PREFS_FAILURE,
  COMMUNICATION_PREFS_CLOSE_MESSAGES,
} from '../constants/communication-prefs';

/**
 * @param {object} authData authenticated data returned by api/customer.
 * @returns {{type, message}}
 */
export function updateCommunicationPrefsSuccess(authData) {
  return {
    type: UPDATE_COMMUNICATION_PREFS_SUCCESS,
    message: authData.message,
  };
}

/**
 * @param {object} details user details
 * @returns {{type, payload: {code: string}}}
 */
export function updateCommunicationPrefsRequest(details) {
  return {
    type: UPDATE_COMMUNICATION_PREFS_REQUEST,
    payload: {
      details,
    },
  };
}

/**
 * @param {string} details
 * @returns {{type, payload: {message: string}}}
 */
export function updateCommunicationPrefsFailure(details) {
  return {
    type: UPDATE_COMMUNICATION_PREFS_FAILURE,
    message: details.message,
    payload: {
      details,
    },
  };
}

/**
 * @param {string} details - Communication prefs object
 * @returns {Object}
 */
export function apiUpdateCommunicationPrefs(details) {
  return {
    actions: {
      error: updateCommunicationPrefsFailure,
      start: updateCommunicationPrefsRequest,
      success: updateCommunicationPrefsSuccess,
    },
    method: 'post',
    params: details,
    type: CALL_API,
    url: 'account/communicationPrefs',
  };
}

/**
 * @returns {{type, payload: number}}
 */
export function closeMessages() {
  return {
    type: COMMUNICATION_PREFS_CLOSE_MESSAGES,
  };
}
