import { CALL_API } from '../../constants/api';
import {
  ACCOUNT_SIGNUP,
  CLOSE_MESSAGES,
  SIGN_UP_FAILED,
} from '../../constants/account';
import { apiLoginSuccess } from '../auth';
import { dataLayerSendEmail } from '../../utils/data-layer';
import { addMessage } from '../messages';
import { setScrollPoint } from '../scroll';

const DEFAULT_REDIRECT_URL = 'account/personal/';

/**
 * Given that an account was created successfully, raise an action that forwards the user to their account page
 * or referring page (found in redirect query string params).
 *
 * @param {object} payload user details
 * @returns {{type, payload: object}}
 */
export function accountSignUpSucceeded(payload) {
  return (dispatch, getState) => {
    const { email } = payload.user;
    const state = getState();
    const {
      config: { basePath },
      routing,
    } = state.app;

    const redirectUrl =
      // If we have a next pathname redirect to that page
      (routing.state && routing.state.nextPathname) ||
      // If not redirect the user to the account page.
      `${basePath}${DEFAULT_REDIRECT_URL}`;

    dispatch(apiLoginSuccess(payload, redirectUrl)).then(
      dataLayerSendEmail(email)
    );

    dispatch(addMessage(payload.successMessage, 'accountMessages')).then(
      setScrollPoint('scrollPoint.AccountMessages')
    );
  };
}

/**
 * Returns an action that signifies that a user has tried to create an account
 * @param {object} payload user details
 * @returns {{type, payload: object}}
 */
export function accountSignUp(payload) {
  return {
    type: ACCOUNT_SIGNUP,
    payload,
  };
}

/**
 * @param {string} response The response object from the server
 * @returns {{type, message, payload: {message: string}}}
 */
export function accountSignUpFailed(response) {
  return {
    type: SIGN_UP_FAILED,
    error: true,
    payload: {
      message: response.message,
    },
  };
}

/**
 * @param {string} details - Customer details object
 * @returns {Object} An action containing all the necessary fields to make an API call
 */
export function apiAccountSignUp(details) {
  return {
    actions: {
      error: accountSignUpFailed,
      start: accountSignUp,
      success: accountSignUpSucceeded,
    },
    method: 'post',
    params: details,
    type: CALL_API,
    url: 'account/signup',
  };
}

/**
 * Close error and confirmation messages
 * @returns {{type: string}}
 */
export function closeMessages() {
  return {
    type: CLOSE_MESSAGES,
  };
}
