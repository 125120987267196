import { RECEIVE_CONTAINERS } from '../../constants/masthead';

/**
 * @param {Array} containers
 * @returns {{type, payload: *}}
 */
export function receiveContainers(containers) {
  return {
    type: RECEIVE_CONTAINERS,
    payload: containers,
  };
}
