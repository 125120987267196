import {
  RECEIVE_PAYMENT_METHODS,
  PAYMENT_METHODS_REQUEST,
  PAYMENT_METHODS_FAILURE,
  DELETE_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_FAILURE,
} from '../../constants/account';
import { ADD_PAYMENT_CARD_SUCCESS } from '../../constants/payment-card-details';
import { CALL_API } from '../../constants/api';

export type ReceivePaymentMethods = {
  type: typeof RECEIVE_PAYMENT_METHODS,
  payload: SavedPaymentCard[],
};

/**
 * @param {Array} methods
 * @returns {{type: string, payload: Array}}
 */
export function receivePaymentMethods(methods): ReceivePaymentMethods {
  return {
    type: RECEIVE_PAYMENT_METHODS,
    payload: methods,
  };
}

/**
 * @param {Array} card
 * @returns {{type: string, payload: Array}}
 */
export function addPaymentCard(card) {
  return {
    type: ADD_PAYMENT_CARD_SUCCESS,
    payload: card,
  };
}

/**
 * @return {{type}}
 */
function getPaymentMethods() {
  return {
    type: PAYMENT_METHODS_REQUEST,
  };
}

/**
 * @param {String} payload
 * @return {{error: boolean, payload: *, type}}
 */
function getPaymentMethodsError(payload) {
  return {
    error: true,
    payload,
    type: PAYMENT_METHODS_FAILURE,
  };
}

/**
 * @return {{actions: {start: getPaymentMethods, success: receivePaymentMethods, error: getPaymentMethodsError}, type}}
 */
export function apiGetPaymentMethods() {
  return {
    actions: {
      start: getPaymentMethods,
      success: receivePaymentMethods,
      error: getPaymentMethodsError,
    },
    url: 'account/payment',
    type: CALL_API,
  };
}

/**
 * @returns {{type}}
 */
function deletePaymentMethod() {
  return {
    type: DELETE_PAYMENT_METHOD_REQUEST,
  };
}

/**
 * @param {Object} payload
 * @returns {{error: boolean, payload: *, type}}
 */
function deletePaymentMethodFailure(payload) {
  return {
    error: true,
    payload,
    type: DELETE_PAYMENT_METHOD_FAILURE,
  };
}

/**
 * @param {String} id
 * @returns {{
 *  actions: {start: deletePaymentMethod, success: receivePaymentMethods, error: deletePaymentMethodFailure},
 *  method: string,
 *  url: string
 * }}
 */
export function apiDeletePaymentMethod(id) {
  return {
    actions: {
      start: deletePaymentMethod,
      success: receivePaymentMethods,
      error: deletePaymentMethodFailure,
    },
    method: 'delete',
    type: CALL_API,
    url: `account/payment/${id}`,
  };
}
