// @flow
import React from 'react';
import Button from 'brastrap/common/button/Button.jsx';
import FieldInput, {
  type Props as FieldInputType,
} from 'brastrap/common/field-input/FieldInput.jsx';
import FieldSearch from 'brastrap/common/field-search/FieldSearch';
import { applyModifiers } from '../../utils';

type Props = {
  input: FieldInputType,
  button: {
    label: string,
    submit: boolean,
    modifiers?: Modifiers,
  },
  analyticsRef?: string,
  modifiers?: Modifiers,
  onSubmit: SyntheticEvent<HTMLFormElement>,
};

const STYLE_PREFIX = 'c-field-input-submit';

const FieldInputSubmit = (props: Props) => {
  const className = applyModifiers(STYLE_PREFIX, props.modifiers);

  const formProps: {
    className: string,
    onSubmit: SyntheticEvent<HTMLFormElement>,
    'data-ref'?: string,
  } = {
    className,
    onSubmit: props.onSubmit,
  };

  if (props.analyticsRef) {
    formProps['data-ref'] = props.analyticsRef;
  }

  return (
    <form {...formProps}>
      {props.modifiers && props.modifiers.includes('search') ? (
        <FieldSearch {...props.input} />
      ) : (
        <FieldInput {...props.input} />
      )}
      <Button {...props.button}>{props.button.label}</Button>
    </form>
  );
};

FieldInputSubmit.defaultProps = {
  input: {
    name: 'input',
    type: 'email',
    placeholder: 'me@domain.com',
    modifiers: [],
  },
  button: {
    label: 'Sign up',
    submit: true,
    modifiers: [],
  },
  modifiers: [],
  onSubmit: () => {},
};

export default FieldInputSubmit;
