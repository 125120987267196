import {
  RECEIVE_ALL_SHOPS,
  RECEIVE_SHOP,
  UPDATE_ACCORDION,
} from '../constants/shops';

export const initialState = {
  accordion: null,
  active: {},
  all: [],
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function shops(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_ALL_SHOPS:
      return {
        ...state,
        all: action.payload,
        active: {},
      };

    case RECEIVE_SHOP:
      return {
        ...state,
        all: [],
        active: action.payload,
      };

    case UPDATE_ACCORDION:
      return {
        ...state,
        accordion: action.payload,
      };

    default:
      return state;
  }
}
