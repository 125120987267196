// @flow
import React from 'react';
import Link from 'brastrap/containers/link/LinkContainer';
import { type Sizes } from './ShopBy';

type Props = {
  sizes: Sizes,
  type: string,
};

const SizeTable = ({ sizes: { variants }, type }: Props) => (
  <table className="c-shop-by-table">
    <tbody>
      {variants.map(variant => (
        <tr key={variant.value}>
          {variant.sizes.variants.map(size => (
            <td key={size.label}>
              <Link
                className="c-shop-by-table__link"
                href={`${size.href}-${type}/`}
                title={size.title}
              >
                {size.label}
              </Link>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default SizeTable;
