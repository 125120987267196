import generateAsyncRouteComponent from '../generateAsyncRouteComponent';
import Payment from './routes/Payment';
import Personal from './routes/Personal';
import Delivery from './routes/DeliveryAddresses';
import Orders from './routes/OrdersAndReturns';
import LoyaltyPoints from './routes/LoyaltyPoints';

export default {
  path: 'account',

  routes: [Delivery, ...LoyaltyPoints, Orders, Payment, Personal],

  component: generateAsyncRouteComponent({
    loader: () => import('./containers/AccountPageAuthenticatedContainer'),
  }),
};
