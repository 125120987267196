// @flow
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import Button from 'brastrap/common/button/Button';
import Icon from 'brastrap/common/icon/Icon';

const messages = defineMessages({
  unsaved: {
    id: 'save-item-button.unsaved',
    defaultMessage: 'Save {item} for later',
  },
  saved: {
    id: 'save-item-button.saved',
    defaultMessage: '{item} saved',
  },
});

type Props = {
  intl: intlShape,
  isItemSaved: boolean,
  isSizeSelected: boolean,
  onClick: any => void,
  isForMainPdpImage: Boolean,
};

const SaveItemButton = (props: Props) => {
  const {
    intl: { formatMessage },
    isItemSaved,
    isSizeSelected,
    onClick,
    isForMainPdpImage,
  } = props;

  const modifiers = ['like-link', 'save-item'];
  const messageKey = isItemSaved ? 'saved' : 'unsaved';

  if (isItemSaved) {
    modifiers.push('saved');
  }

  return (
    <Button
      icon={<Icon icon="heart" />}
      label={
        !isForMainPdpImage &&
        formatMessage(messages[messageKey], {
          item: isSizeSelected ? 'size' : 'style',
        })
      }
      modifiers={modifiers}
      onClick={onClick}
      ariaLabel="Add to favourite"
    />
  );
};

SaveItemButton.defaultProps = {
  onClick: () => {},
  isItemSaved: false,
  isSizeSelected: false,
};

export default injectIntl(SaveItemButton);
