import { CALL_API } from '../constants/api';
import {
  GDPR_CONTENT_SUCCESS,
  GDPR_CONTENT_FAILURE,
  GDPR_CONTENT_REQUEST,
  UPDATE_TERMS_DECISION,
} from '../constants/gdpr';

/**
 * @param {string} content - Data from Contentful
 * @returns {Object}
 */
export function gdprContentSuccess(content) {
  return {
    type: GDPR_CONTENT_SUCCESS,
    payload: content,
  };
}

/**
 * @param {object} payload
 * @returns {Object}
 */
function gdprContentFailure(payload) {
  return {
    error: true,
    payload,
    type: GDPR_CONTENT_FAILURE,
  };
}

/**
 * @returns {Object}
 */
function gdprContentRequest() {
  return {
    type: GDPR_CONTENT_REQUEST,
  };
}

/**
 * @returns {Object}
 */
export function getGdprContent() {
  return {
    actions: {
      error: gdprContentFailure,
      success: gdprContentSuccess,
      start: gdprContentRequest,
    },
    method: 'get',
    type: CALL_API,
    url: 'gdpr',
  };
}

/**
 * @param {object} payload
 * @returns {Object}
 */
export function updateTermsDecision(payload) {
  return {
    type: UPDATE_TERMS_DECISION,
    payload,
  };
}
