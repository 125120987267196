import {
  RECEIVE_SIDEBAR_CONTENT,
  GET_SIDEBAR_CONTENT_REQUEST,
  GET_SIDEBAR_CONTENT_FAILURE,
  GET_SIDEBAR_CONTENT_SUCCESS,
} from '../constants/sidebar';

import { CALL_API } from '../constants/api';

/**
 * @param {Object} data
 * @returns {{type, payload: *}}
 */
export function receiveSidebarContent(data) {
  return {
    type: RECEIVE_SIDEBAR_CONTENT,
    payload: data,
  };
}

/**
 * @return {{type}}
 */
function getSidebarContentRequest() {
  return {
    type: GET_SIDEBAR_CONTENT_REQUEST,
  };
}

/**
 * @param {Object} payload
 * @return {{error: boolean, payload: *, type}}
 */
function getSidebarContentFailure(payload) {
  return {
    error: true,
    payload,
    type: GET_SIDEBAR_CONTENT_FAILURE,
  };
}

/**
 * @param {String} payload
 * @return {{payload: *, type}}
 */
function getSidebarContentSuccess(payload) {
  return {
    payload,
    type: GET_SIDEBAR_CONTENT_SUCCESS,
  };
}

/**
 * @param {String} settingId
 * @param {String} pageId
 * @return {{type, url: string}}
 */
export function getSidebarContent(settingId, pageId = null) {
  const url = `sidebar/${settingId}/${pageId}`;

  return {
    actions: {
      error: getSidebarContentFailure,
      start: getSidebarContentRequest,
      success: getSidebarContentSuccess,
    },
    type: CALL_API,
    url,
  };
}
