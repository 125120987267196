import React from 'react';

const SvgIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#616365"
      stroke="none"
    >
      <path
        d="M25 3905 l-25 -24 0 -359 c0 -356 0 -359 22 -391 35 -48 1977 -1924
 2007 -1938 32 -15 1026 -18 1063 -4 19 8 1699 1627 1951 1880 l77 78 0 367 0
 367 -25 24 c-14 15 -36 25 -52 25 -16 0 -209 -36 -428 -80 -888 -179 -1332
 -236 -1920 -247 -676 -12 -1152 41 -2191 247 -220 44 -413 80 -428 80 -15 0
 -37 -11 -51 -25z m494 -230 c963 -190 1381 -239 2041 -239 652 0 1070 48 1988
 229 209 41 385 75 391 75 8 0 11 -73 11 -253 l0 -253 -132 -13 c-786 -73
 -1375 -441 -1658 -1034 -100 -208 -153 -419 -173 -684 l-11 -153 -422 0 -421
 0 -7 123 c-42 789 -492 1378 -1245 1627 -162 54 -342 93 -501 110 -58 6 -129
 13 -157 16 l-53 5 0 255 c0 186 3 254 12 254 6 0 158 -29 337 -65z m61 -666
 c236 -47 504 -155 686 -278 403 -269 630 -659 689 -1186 l7 -60 -28 25 c-42
 38 -1584 1531 -1584 1534 0 5 124 -14 230 -35z m4170 7 c-174 -172 -1595
 -1544 -1597 -1542 -1 1 2 42 7 92 22 203 81 407 167 578 137 273 374 513 660
 668 182 99 452 187 678 222 126 20 124 20 85 -18z"
      />
    </g>
  </svg>
);

export default SvgIcon;
