import PropTypes from 'prop-types';
import React from 'react';

const Tag = props => <span className="c-tag">{props.label}</span>;

Tag.propTypes = {
  label: PropTypes.string.isRequired,
};

Tag.defaultProps = {
  label: 'label',
};

export default Tag;
