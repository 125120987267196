// @flow
import React from 'react';
import Embed from 'brastrap/editorial/embed/Embed';
import Modal from 'brastrap/common/modal/Modal';
import ImageCardContent from './ImageCardPositionedContent';
import type { ImageCardOverlay as Props } from './image-card.types.js';

const STYLE_PREFIX = 'c-image-card';

const ImageCardOverlay = (props: Props) => {
  const {
    onClick,
    isOpen,
    onModalClose,
    video,
    image,
    text,
    analyticsRef,
    id,
  } = props;
  const imageProps = { image, text };
  return (
    <div className={`${STYLE_PREFIX}__overlay`}>
      <span onClick={onClick} data-ref={analyticsRef}>
        <ImageCardContent {...imageProps} />
      </span>

      <Modal
        isOpen={isOpen}
        modalId={id}
        actions={{
          dismiss: {
            icon: {
              icon: 'dismiss--large',
            },
            onClick: onModalClose,
          },
        }}
      >
        {isOpen && <Embed {...video} />}
      </Modal>
    </div>
  );
};

export default ImageCardOverlay;
