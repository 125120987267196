import {
  UPDATE_PERSONAL_DETAILS_FAILURE,
  UPDATE_PERSONAL_DETAILS_REQUEST,
  UPDATE_PERSONAL_DETAILS_SUCCESS,
  PERSONAL_DETAILS_CLOSE_MESSAGES,
} from 'shared/constants/personal-details';

export const initialState = {
  confirmation: null,
  isSaving: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function personalDetailsForm(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PERSONAL_DETAILS_REQUEST:
      return {
        ...state,

        isSaving: true,
        errorMessage: null,
        confirmationMessage: null,
      };

    case UPDATE_PERSONAL_DETAILS_FAILURE:
      return {
        ...state,

        isSaving: false,
        errorMessage: action.payload.message,
        confirmationMessage: null,
      };

    case UPDATE_PERSONAL_DETAILS_SUCCESS:
      return {
        ...state,

        isSaving: false,
        errorMessage: null,
        confirmationMessage: action.payload.message,
      };

    case PERSONAL_DETAILS_CLOSE_MESSAGES:
      return {
        ...state,

        confirmationMessage: null,
        errorMessage: null,
      };

    default:
      return state;
  }
}
