// @flow
import React from 'react';
import { connect } from 'react-redux';
import Carousel from 'brastrap/common/carousel/Carousel';

type Props = {
  isDesktop: boolean,
  slidesToShow: number,
};

const mapStateToProps = ({ browser: { deviceIs } }, ownProps) => ({
  ...ownProps,
  isDesktop: deviceIs.desktop,
});

const WrappedCarousel = (props: Props) => {
  const newProps = {
    ...props,
    slidesToShow: !props.isDesktop ? 1 : props.slidesToShow,
  };

  return <Carousel {...newProps} />;
};

export default connect(mapStateToProps)(WrappedCarousel);
