// @flow
import { CALL_API } from '../constants/api';

import {
  ALL_ORDERS_UPDATE_REQUEST,
  ALL_ORDERS_UPDATE_SUCCESS,
  ALL_ORDERS_UPDATE_FAILURE,
  CHECK_ORDER_STATUS_REQUEST,
  CHECK_ORDER_STATUS_SUCCESS,
  CHECK_ORDER_STATUS_FAILURE,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILURE,
  CANCEL_ORDER_UPDATE_SUCCESS,
  CANCEL_ORDER_UPDATE_FAILURE,
  CANCEL_ORDER_UPDATE_REQUEST,
  ALL_ORDERS_RETRY,
  DISMISS_CANCEL_MESSAGES,
} from '../constants/orders';

type GetAllOrdersDetails = {|
  page: number,
  endDate: string,
|};

type GetAllOrdersStart = {|
  type: typeof ALL_ORDERS_UPDATE_REQUEST,
  payload: { details: ?GetAllOrdersDetails },
|};
/**
 * Returns an action that represents the start of a data fetch of all a customer's orders.
 * @param {Object} details the parameters for the fetch
 * @returns {Object}
 */
export function getAllOrdersStart(
  details: ?GetAllOrdersDetails
): GetAllOrdersStart {
  return {
    type: ALL_ORDERS_UPDATE_REQUEST,
    payload: {
      details,
    },
  };
}

export type Orders = {|
  _metadata: {
    endDate: string,
    page: number,
    perPage: number,
    totalCount: number,
    totalPages: number,
  },
  records: OrderSummaryType[],
|};
type GetAllOrdersSuccess = {|
  type: typeof ALL_ORDERS_UPDATE_SUCCESS,
  orders: Orders,
|};
/**
 * Returns an action that represents the successful completion of a data fetch of all a customer's orders.
 * @param {Object[]} orders the retrieved orders
 * @returns {Object}
 */
export function getAllOrdersSuccess(orders: Orders): GetAllOrdersSuccess {
  return {
    type: ALL_ORDERS_UPDATE_SUCCESS,
    orders,
  };
}

type ErrorMessage = { msg: string };
type GetAllOrdersFailure = {|
  type: typeof ALL_ORDERS_UPDATE_FAILURE,
  error: boolean,
  payload: string,
|};
/**
 * Returns an action that represents the unsuccessful completion of a data fetch of all a customer's orders.
 * @param {Object} details the specifics of what went wrong
 * @returns {Object}
 */
export function getAllOrdersFailure(
  details: ?ErrorMessage
): GetAllOrdersFailure {
  return {
    type: ALL_ORDERS_UPDATE_FAILURE,
    error: true,
    payload: details ? details.msg : '<no message>',
  };
}

type GetAllOrders = {|
  actions: {
    success: Orders => GetAllOrdersSuccess,
    error: (?ErrorMessage) => GetAllOrdersFailure,
    start: () => GetAllOrdersStart,
  },
  type: typeof CALL_API,
  method: 'get',
  url: 'orders',
  params: {
    page: number,
    endDate: string,
  },
|};
/**
 * Returns an API action that fetches all off a customer's orders.
 * @param {Number} page - the page from the list of orders to fetch
 * @param {string} endDate - the end date for all orders to filter for.
 * @returns {Object}
 */
export function getAllOrders(
  page: number = 1,
  endDate: string = ''
): GetAllOrders {
  return {
    actions: {
      start: getAllOrdersStart,
      success: getAllOrdersSuccess,
      error: getAllOrdersFailure,
    },
    method: 'get',
    type: CALL_API,
    url: 'orders',
    params: {
      page,
      endDate,
    },
  };
}

type Retry = {|
  type: typeof ALL_ORDERS_RETRY,
|};
/**
 * @returns {{type}}
 */
export function retry(): Retry {
  return {
    type: ALL_ORDERS_RETRY,
  };
}

type DismissMessage = {|
  type: typeof DISMISS_CANCEL_MESSAGES,
|};
export const dismissMessage = (): DismissMessage => ({
  type: DISMISS_CANCEL_MESSAGES,
});

type SuccessPayload = {
  canCancelOrder: boolean,
};
type CheckOrderStatusSuccess = {|
  type: typeof CHECK_ORDER_STATUS_SUCCESS,
  payload: SuccessPayload,
|};
const checkOrderStatusSuccess = (
  payload: SuccessPayload
): CheckOrderStatusSuccess => ({
  type: CHECK_ORDER_STATUS_SUCCESS,
  payload,
});

type CheckOrderStatusRequest = {|
  type: typeof CHECK_ORDER_STATUS_REQUEST,
|};
const checkOrderStatusRequest = (): CheckOrderStatusRequest => ({
  type: CHECK_ORDER_STATUS_REQUEST,
});

type CheckOrderStatusFailure = {|
  type: typeof CHECK_ORDER_STATUS_FAILURE,
  error: boolean,
  payload: string,
|};
const checkOrderStatusFailure = (error: {
  message: string,
}): CheckOrderStatusFailure => ({
  type: CHECK_ORDER_STATUS_FAILURE,
  error: true,
  payload: error.message,
});

type B2ApiCheckOrderStatus = {|
  actions: {
    success: SuccessPayload => CheckOrderStatusSuccess,
    error: Error => CheckOrderStatusFailure,
    start: () => CheckOrderStatusRequest,
  },
  type: typeof CALL_API,
  method: 'get',
  url: Url,
|};
/**
 * Returns an API action that checks which items in an order can be cancelled.
 * @param {string} id
 * @returns {Object}
 */
export function b2ApiCheckOrderStatus(id: string): B2ApiCheckOrderStatus {
  return {
    actions: {
      start: checkOrderStatusRequest,
      success: checkOrderStatusSuccess,
      error: checkOrderStatusFailure,
    },
    method: 'get',
    type: CALL_API,
    url: `/order/cancellable/${id}`,
  };
}
type CancelOrderSuccess = {|
  type: typeof CANCEL_ORDER_SUCCESS,
|};
/**
 * @param {object} payload details of the order
 * @returns {Promise}
 */
export const cancelOrderSuccess = (): CancelOrderSuccess => ({
  type: CANCEL_ORDER_SUCCESS,
});

type CancelOrderFailure = {|
  type: typeof CANCEL_ORDER_FAILURE,
  error: boolean,
  payload: string,
|};
/**
 * @param {object} error details of the failure
 * @returns {{type, payload: {bag: object}}}
 */
export function cancelOrderFailure(error: {
  message: string,
}): CancelOrderFailure {
  return {
    type: CANCEL_ORDER_FAILURE,
    payload: error.message,
    error: true,
  };
}

export type OrderToCancel = {
  orderId: string,
  customerId: string,
};
export type ApiCancelOrder = {|
  actions: {
    success: () => CancelOrderSuccess,
    error: Error => CancelOrderFailure,
  },
  method: 'post',
  params: OrderToCancel,
  type: typeof CALL_API,
  url: 'order/cancel',
|};
/**
 * @param {object} order
 * @returns {{type, payload: {bag: object}}}
 */
export function apiCancelOrder(order: OrderToCancel): ApiCancelOrder {
  return {
    actions: {
      success: cancelOrderSuccess,
      error: cancelOrderFailure,
    },
    method: 'post',
    params: order,
    type: CALL_API,
    url: 'order/cancel',
  };
}

type CancelOrderUpdateSuccess = {|
  type: typeof CANCEL_ORDER_UPDATE_SUCCESS,
  payload: OrderSummaryType,
|};
/**
 * @param {object} payload details of the order
 * @returns {object}
 */
export const cancelOrderUpdateSuccess = (
  payload: OrderSummaryType
): CancelOrderUpdateSuccess => ({
  type: CANCEL_ORDER_UPDATE_SUCCESS,
  payload,
});

type CancelOrderUpdateFailure = {|
  type: typeof CANCEL_ORDER_UPDATE_FAILURE,
  error: boolean,
  payload: Error,
|};
/**
 * @param {object} payload details of the failure
 * @returns {object}
 */
export function cancelOrderUpdateFailure(
  payload: Error
): CancelOrderUpdateFailure {
  return {
    type: CANCEL_ORDER_UPDATE_FAILURE,
    payload,
    error: true,
  };
}

type CancelOrderUpdateRequest = {|
  type: typeof CANCEL_ORDER_UPDATE_REQUEST,
|};
/**
 * @returns {object}
 */
export function cancelOrderUpdateRequest(): CancelOrderUpdateRequest {
  return {
    type: CANCEL_ORDER_UPDATE_REQUEST,
  };
}

type ApiGetCancelOrderUpdate = {|
  actions: {
    start: () => CancelOrderUpdateRequest,
    success: OrderSummaryType => CancelOrderUpdateSuccess,
    error: Error => CancelOrderUpdateFailure,
  },
  method: 'get',
  type: typeof CALL_API,
  url: string,
|};
/**
 * @param {array} items
 * @param {string} orderId
 * @returns {*}
 */
export function apiGetCancelOrderUpdate(
  items: string[],
  orderId: string
): ApiGetCancelOrderUpdate {
  return {
    actions: {
      start: cancelOrderUpdateRequest,
      success: cancelOrderUpdateSuccess,
      error: cancelOrderUpdateFailure,
    },
    method: 'get',
    type: CALL_API,
    url: `order/cancel/${items.join(',')}/${orderId}`,
  };
}

export type Actions =
  | GetAllOrdersStart
  | GetAllOrdersFailure
  | GetAllOrdersSuccess
  | GetAllOrders
  | CheckOrderStatusRequest
  | CheckOrderStatusFailure
  | CheckOrderStatusSuccess
  | B2ApiCheckOrderStatus
  | CancelOrderSuccess
  | CancelOrderFailure
  | CancelOrderUpdateFailure
  | CancelOrderUpdateRequest
  | CancelOrderUpdateSuccess
  | DismissMessage
  | Retry;
