import { connect } from 'react-redux';
import Sticker from 'brastrap/promotional/sticker/Sticker';

const mapStateToProps = (
  {
    app: {
      config: { roundals },
    },
  },
  ownProps
) => ({
  ...ownProps,
  roundals,
});

export default connect(mapStateToProps)(Sticker);
