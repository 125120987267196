import {
  ADD_TO_QUEUE,
  EDITORIAL_MODAL_SUCCESS,
  EDITORIAL_MODAL_UPDATE,
  SET_REDISPLAY_TIME_REQUEST,
  SET_REDISPLAY_TIME_SUCCESS,
  SET_REDISPLAY_TIME_FAILURE,
  REMOVE_FROM_QUEUE,
  CLEAR_QUEUE,
} from '../constants/modal';

import { REDIRECT_MODAL_ID } from '../constants/app';

import { OPEN_LAYER } from '../constants/layer';
import { closeLayer } from '../actions/layer';
import { CALL_API } from '../constants/api';

import {
  formatOpenLayerPayload,
  isModalCollapsedInitially,
} from '../utils/modal';

/**
 * Called when modal content is successfully received.
 *
 *  @param {Array} payload
 *  @returns {{type: {string}, payload: {Object}}}
 */
export function modalSuccess(payload) {
  return {
    payload,
    type: EDITORIAL_MODAL_SUCCESS,
  };
}

/**
 * Called when we update a modal's content.
 *
 *  @param {Object} payload
 *  @returns {{type: {string}, payload: {Object}}}
 */
export function modalUpdate(payload) {
  return {
    payload,
    type: EDITORIAL_MODAL_UPDATE,
  };
}

/**
 * Remove a modal id from the queue
 *
 *  @param {String} modalId
 *  @returns {{type: {string}, payload: {String}}}
 */
export const removeFromQueue = modalId => (dispatch, getState) => {
  const {
    modal: { content, queue },
  } = getState();

  const nextModalId = queue[1];

  if (!nextModalId) {
    dispatch(closeLayer(modalId));
  } else {
    const modalContent = content[nextModalId];
    const isNextModalCollapsedInitially =
      nextModalId === REDIRECT_MODAL_ID
        ? false
        : modalContent.isCollapsedInitially;

    if (isNextModalCollapsedInitially) {
      dispatch(closeLayer(modalId));
    } else {
      dispatch({
        payload: formatOpenLayerPayload(nextModalId, modalContent, () =>
          dispatch(closeModal(nextModalId, modalContent.redisplayTime))
        ),
        type: OPEN_LAYER,
      });
    }
  }

  dispatch({
    payload: modalId,
    type: REMOVE_FROM_QUEUE,
  });
};

/**
 * Add a modal id to the queue
 *
 *  @param {String} modalId
 *  @returns {{type: {string}, payload: {String}}}
 */
export const addToQueue = modalId => (dispatch, getState) => {
  const state = getState();
  const {
    modal: { content, queue },
  } = state;
  let isCollapsedInitially = false;

  if (modalId !== REDIRECT_MODAL_ID) {
    isCollapsedInitially = isModalCollapsedInitially(state, modalId);
    dispatch(modalUpdate({ modalId, data: { isCollapsedInitially } }));
  }

  const modalContent = content[modalId];

  if (!queue.length && !isCollapsedInitially) {
    dispatch({
      payload: formatOpenLayerPayload(modalId, modalContent, () =>
        dispatch(closeModal(modalId, modalContent.redisplayTime))
      ),
      type: OPEN_LAYER,
    });
  }

  dispatch({
    payload: modalId,
    type: ADD_TO_QUEUE,
  });
};

/**
 * Close a modal that is open
 *
 *  @param {String} modalId
 *  @param {Number} days
 *  @param {Number} value
 *
 *  @returns {*}
 */
export function closeModal(modalId, days, value = 1) {
  return dispatch => {
    dispatch(setRedisplayTime(modalId, days, value));
    dispatch(removeFromQueue(modalId));
  };
}

/**
 * Clear the queue
 *
 *  @returns {{type: {string}}}
 */
export function clearQueue() {
  return {
    type: CLEAR_QUEUE,
  };
}

/**
 * @returns {Object}
 */
function setRedisplayTimeRequest() {
  return {
    type: SET_REDISPLAY_TIME_REQUEST,
  };
}

/**
 * @param {Object} payload
 *
 * @returns {Object}
 */
function setRedisplayTimeFailure(payload) {
  return {
    type: SET_REDISPLAY_TIME_FAILURE,
    error: true,
    payload,
  };
}

/**
 * @param {Object} payload
 *
 * @returns {Object}
 */
function setRedisplayTimeSuccess(payload) {
  return {
    type: SET_REDISPLAY_TIME_SUCCESS,
    payload,
  };
}

/**
 * @param {String} modalId
 * @param {Number} days
 * @param {Number} value
 *
 * @returns {Object}
 */
function setRedisplayTime(modalId, days, value) {
  return {
    actions: {
      error: setRedisplayTimeFailure,
      success: setRedisplayTimeSuccess,
      start: setRedisplayTimeRequest,
    },
    method: 'post',
    params: {
      modalId,
      days,
      value,
    },
    type: CALL_API,
    url: 'modal',
  };
}
