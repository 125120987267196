import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

import Message from 'brastrap/common/message/Message';

const MessageContainer = props => (
  <div className="c-message-container">
    <CSSTransitionGroup
      transitionName="c-message-container"
      transitionEnterTimeout={200}
      transitionLeaveTimeout={200}
      transitionAppear
      transitionAppearTimeout={200}
    >
      {props.messages.map((message, key) => (
        <Message
          key={key}
          {...message}
          onClose={e => {
            e.preventDefault();
            return props.onClose(key);
          }}
        />
      ))}
    </CSSTransitionGroup>
  </div>
);

MessageContainer.propTypes = {
  messages: PropTypes.array,
  onClose: PropTypes.func,
};

MessageContainer.defaultProps = {
  messages: [],
  onClose: () => {},
};

export default MessageContainer;
