import React from 'react';

const SvgIcon = () => (
  <svg
    className="c-icon__glyph"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
  >
    <path d="M120 174c0 9 5 11 11 4l64-72c3-3 3-9 0-12l-64-72c-6-7-11-5-11 4v34C10 60 0 200 0 200s40-80 120-70v44z" />
  </svg>
);

export default SvgIcon;
