// @flow
import React from 'react';
import Slider from 'react-slick';
import omit from 'lodash/omit';
import Nav from 'brastrap/common/carousel-nav/CarouselNav';
import Pagination from 'brastrap/common/carousel-pagination/CarouselPagination';
import type { Carousel as Props, Advance, Current } from './carousel.type';
import { applyModifiers } from '../../utils';

type State = {
  current: Current,
};

const STYLE_PREFIX = 'c-carousel';

const propsToOmitFromSlider = [
  'children',
  'intl',
  'labels',
  'dots',
  'arrows',
  'onInitialise',
];
// We use our own "arrows" & "dots".

const propsToModifiersMapping = {
  guttered: 'guttered',
  noPaddingForNavControls: 'noPaddingForNavControls',
};

const NEXT = 'next';
const PREV = 'prev';

class Carousel extends React.Component<Props, State> {
  static defaultProps = {
    arrows: true,
    draggable: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    onInitialise: () => {},
    onCurrentImageChange: () => {},
    verticalScroll: false,
    thumbnailIndex: 0,
    isMobile: false,
  };

  state = {
    current: 0,
  };

  /**
   * When component updates, show slider
   */
  componentDidMount() {
    if (this.slider !== null) {
      this.props.onInitialise();
    }
  }

  /**
   * Contains logic to move the thumbnail carousel in step with main image changes
   *
   * @param {object} prevProps
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.thumbnailIndex > -1 && !this.props.isMobile) {
      const currentPosRoundedDown = Math.floor(this.props.thumbnailIndex / 4);
      const lastPosRoundedDown = Math.floor(prevProps.thumbnailIndex / 4);

      if (currentPosRoundedDown > lastPosRoundedDown && this.slider) {
        this.slider.slickNext();
      } else if (currentPosRoundedDown < lastPosRoundedDown && this.slider) {
        this.slider.slickPrev();
      }
    } else if (
      this.props.thumbnailIndex > -1 &&
      this.props.isMobile &&
      this.props.children
    ) {
      if (
        this.props.thumbnailIndex > prevProps.thumbnailIndex &&
        this.props.children.length - this.props.thumbnailIndex > 3 &&
        this.slider
      ) {
        this.slider.slickNext();
      } else if (
        this.props.thumbnailIndex < prevProps.thumbnailIndex &&
        this.slider
      ) {
        this.slider.slickPrev();
      }
    }
  }

  // Ref to the slider component
  slider = null;

  /**
   * Ensure the carousel's state is in sync with React-Slick
   *
   * @param {Number} current
   */
  handleChange = (current: Current) => {
    if (this.props.onCurrentImageChange) {
      this.props.onCurrentImageChange(current);
    }
    this.setState({ current });
  };

  /**
   * On pressing the navigation ensure slick moves the carousel in the correct direction.
   *
   * @param {String} advance
   */
  onNavigationPress = (advance: Advance) => {
    if (this.slider) {
      if (advance === NEXT) {
        this.slider.slickNext();
      }

      if (advance === PREV) {
        this.slider.slickPrev();
      }
    }
  };

  /**
   * On pressing one of the pagination buttons move the slider to the correct slide.
   *
   * @param {Number} requested
   */
  onPaginationPress = (requested: Current) => {
    if (this.slider) this.slider.slickGoTo(requested);
  };

  /**
   * In certain circumstances (e.g on the PDP) we want to be able to reset
   * the carousel
   */
  resetSlider = () => {
    if (this.slider) this.slider.slickGoTo(0);
  };

  /**
   * Is the navigation button disabled?
   * - If infinitely scrolling never disable.
   * - Only disable if:
   *    - This is the previous button and we're on the first slide.
   *    - This is the next button and we're on the last slide.
   *
   * @param {string} advance - 'prev' or 'next'.
   * @returns {boolean}
   */
  isDisabled = (advance: Advance) => {
    if (this.props.infinite) {
      return false;
    }

    const { current } = this.state;

    if (advance === PREV) {
      return current === 0;
    }

    const { children, slidesToScroll } = this.props;
    const total = React.Children.count(children);
    return current + 1 /* index offset */ + slidesToScroll > total;
  };

  /**
   * @returns {XML}
   */
  render() {
    const {
      props,
      props: { children, labels, verticalScroll },
    } = this;
    const { current } = this.state;

    const nextLabel = labels && labels.next;
    const prevLabel = labels && labels.prev;

    const displayPagination = props.dots;
    const displayNavigation =
      (props.arrows && !verticalScroll) ||
      (props.arrows && children && children.length > 4 && verticalScroll);
    const sliderProps = omit(props, propsToOmitFromSlider);

    // Apply modifiers to the class name based on the mapping defined above.
    // Example: add the '--paginated' modifier if props.dots exists.
    const modifiers = Object.keys(propsToModifiersMapping)
      .filter(prop => props[prop])
      .map(prop => propsToModifiersMapping[prop]);

    const className = applyModifiers(STYLE_PREFIX, modifiers);

    return (
      <div className={className}>
        {displayNavigation ? (
          <div
            className={`${
              displayNavigation &&
              verticalScroll &&
              children &&
              children.length > 4
                ? 'c-carousel-nav--up'
                : ''
            }`}
          >
            <Nav
              advance={PREV}
              label={prevLabel}
              disabled={this.isDisabled(PREV)}
              onClick={() => this.onNavigationPress(PREV)}
              verticalScroll={verticalScroll}
            />
          </div>
        ) : (
          <div />
        )}

        <Slider
          ref={slider => {
            this.slider = slider;
          }}
          {...sliderProps}
          afterChange={this.handleChange}
          arrows={false}
        >
          {children}
        </Slider>

        {displayNavigation ? (
          <div
            className={`${
              displayNavigation &&
              verticalScroll &&
              children &&
              children.length > 4
                ? 'c-carousel-nav--down'
                : ''
            }`}
          >
            <Nav
              advance={NEXT}
              label={nextLabel}
              disabled={this.isDisabled(NEXT)}
              onClick={() => this.onNavigationPress(NEXT)}
              verticalScroll={verticalScroll}
            />
          </div>
        ) : (
          <div />
        )}

        {displayPagination && (
          <Pagination current={current} onClick={this.onPaginationPress}>
            {children}
          </Pagination>
        )}
      </div>
    );
  }
}

export default Carousel;
