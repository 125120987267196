import generateAsyncRouteComponent from '../generateAsyncRouteComponent';

export default [
  {
    path: 'collections/:collection',
    component: generateAsyncRouteComponent({
      loader: () => import('./containers/ProductListingPageContainer'),
    }),
  },
  {
    path: 'search/:searchPhrase',
    component: generateAsyncRouteComponent({
      loader: () => import('./containers/ProductListingPageContainer'),
    }),
  },
  {
    path: 'shop-by/:attribute/:value',
    component: generateAsyncRouteComponent({
      loader: () => import('./containers/ProductListingPageContainer'),
    }),
  },
];
