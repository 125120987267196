// @flow
import React from 'react';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Markdown from 'brastrap/editorial/markdown/Markdown';
import type { Poster as Props } from './poster.types.js';

const Poster = (props: Props) => (
  <header className="c-poster">
    <div className="c-poster__body">
      <h1 className="c-poster__title">
        {props.heading && (
          <span className="u-text-style-cursive">{props.heading}</span>
        )}
        {props.title}
      </h1>
      <div className="c-poster__desc s-prose">
        <Markdown content={props.desc} />
      </div>
      {!!props.actions.length && (
        <div className="c-poster__actions">
          {props.actions.map((action, key) => (
            <span key={key}>
              <ButtonLink {...action} />
            </span>
          ))}
        </div>
      )}
    </div>
  </header>
);

Poster.defaultProps = {
  actions: [],
};

export default Poster;
