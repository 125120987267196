import PropTypes from 'prop-types';
import React from 'react';

const Form = ({ children, className = '', ...otherProps }) => (
  <form className={`c-form ${className}`} {...otherProps}>
    {children}
  </form>
);

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Form;

Form.Actions = props => <div className="c-form__actions">{props.children}</div>;

Form.Actions.propTypes = {
  children: PropTypes.node,
};
