// @flow

export type ShopReviewsFailure = 'bravissimo/shop-reviews/SHOP_REVIEWS_FAILURE';
export type ShopReviewsRequest = 'bravissimo/shop-reviews/SHOP_REVIEWS_REQUEST';
export type ReceiveShopReviews = 'bravissimo/shop-reviews/RECEIVE_SHOP_REVIEWS';
export type ReceiveShopRating = 'bravissimo/shop-reviews/RECEIVE_SHOP_RATING';

export const RECEIVE_SHOP_REVIEWS: ReceiveShopReviews =
  'bravissimo/shop-reviews/RECEIVE_SHOP_REVIEWS';
export const RECEIVE_SHOP_RATING: ReceiveShopRating =
  'bravissimo/shop-reviews/RECEIVE_SHOP_RATING';
export const SHOP_REVIEWS_REQUEST: ShopReviewsRequest =
  'bravissimo/shop-reviews/SHOP_REVIEWS_REQUEST';
export const SHOP_REVIEWS_FAILURE: ShopReviewsFailure =
  'bravissimo/shop-reviews/SHOP_REVIEWS_FAILURE';
