// NOTE: This list does not include any territories,
// as Bravissimo does not currently deliver to any US territories

export const validStates = {
  AL: true,
  ALABAMA: true,
  AK: true,
  ALASKA: true,
  AZ: true,
  ARIZONA: true,
  AR: true,
  ARKANSAS: true,
  CA: true,
  CALIFORNIA: true,
  CO: true,
  COLORADO: true,
  CT: true,
  CONNECTICUT: true,
  DC: true,
  DE: true,
  DELAWARE: true,
  FL: true,
  FLORIDA: true,
  GA: true,
  GEORGIA: true,
  HI: true,
  HAWAII: true,
  ID: true,
  IDAHO: true,
  IL: true,
  ILLINOIS: true,
  IN: true,
  INDIANA: true,
  IA: true,
  IOWA: true,
  KS: true,
  KANSAS: true,
  KY: true,
  KENTUCKY: true,
  LA: true,
  LOUISIANA: true,
  ME: true,
  MAINE: true,
  MD: true,
  MARYLAND: true,
  MA: true,
  MASSACHUSETTS: true,
  MI: true,
  MICHIGAN: true,
  MN: true,
  MINNESOTA: true,
  MS: true,
  MISSISSIPPI: true,
  MO: true,
  MISSOURI: true,
  MT: true,
  MONTANA: true,
  NE: true,
  NEBRASKA: true,
  NV: true,
  NEVADA: true,
  NH: true,
  'NEW HAMPSHIRE': true,
  NJ: true,
  'NEW JERSEY': true,
  NM: true,
  'NEW MEXICO': true,
  NY: true,
  'NEW YORK': true,
  NC: true,
  'NORTH CAROLINA': true,
  ND: true,
  'NORTH DAKOTA': true,
  OH: true,
  OHIO: true,
  OK: true,
  OKLAHOMA: true,
  OR: true,
  OREGON: true,
  PA: true,
  PENNSYLVANIA: true,
  RI: true,
  'RHODE ISLAND': true,
  SC: true,
  'SOUTH CAROLINA': true,
  SD: true,
  'SOUTH DAKOTA': true,
  TN: true,
  TENNESSEE: true,
  TX: true,
  TEXAS: true,
  UT: true,
  UTAH: true,
  VT: true,
  VERMONT: true,
  VA: true,
  VIRGINIA: true,
  WA: true,
  WASHINGTON: true,
  WV: true,
  'WEST VIRGINIA': true,
  WI: true,
  WISCONSIN: true,
  WY: true,
  WYOMING: true,
};

export const stateValues = {
  AL: 'AL',
  ALABAMA: 'AL',
  AK: 'AK',
  ALASKA: 'AK',
  AZ: 'AZ',
  ARIZONA: 'AZ',
  AR: 'AR',
  ARKANSAS: 'AR',
  CA: 'CA',
  CALIFORNIA: 'CA',
  CO: 'CO',
  COLORADO: 'CO',
  CT: 'CT',
  CONNECTICUT: 'CT',
  DC: 'DC',
  DE: 'DE',
  DELAWARE: 'DE',
  FL: 'FL',
  FLORIDA: 'FL',
  GA: 'GA',
  GEORGIA: 'GA',
  HI: 'HI',
  HAWAII: 'HI',
  ID: 'ID',
  IDAHO: 'ID',
  IL: 'IL',
  ILLINOIS: 'IL',
  IN: 'IN',
  INDIANA: 'IN',
  IA: 'IA',
  IOWA: 'IA',
  KS: 'KS',
  KANSAS: 'KS',
  KY: 'KY',
  KENTUCKY: 'KY',
  LA: 'LA',
  LOUISIANA: 'LA',
  ME: 'ME',
  MAINE: 'ME',
  MD: 'MD',
  MARYLAND: 'MD',
  MA: 'MA',
  MASSACHUSETTS: 'MA',
  MI: 'MI',
  MICHIGAN: 'MI',
  MN: 'MN',
  MINNESOTA: 'MN',
  MS: 'MS',
  MISSISSIPPI: 'MS',
  MO: 'MO',
  MISSOURI: 'MO',
  MT: 'MT',
  MONTANA: 'MT',
  NE: 'NE',
  NEBRASKA: 'NE',
  NV: 'NV',
  NEVADA: 'NV',
  NH: 'NH',
  'NEW HAMPSHIRE': 'NH',
  NJ: 'NJ',
  'NEW JERSEY': 'NJ',
  NM: 'NM',
  'NEW MEXICO': 'NM',
  NY: 'NY',
  'NEW YORK': 'NY',
  NC: 'NC',
  'NORTH CAROLINA': 'NC',
  ND: 'ND',
  'NORTH DAKOTA': 'ND',
  OH: 'OH',
  OHIO: 'OH',
  OK: 'OK',
  OKLAHOMA: 'OK',
  OR: 'OR',
  OREGON: 'OR',
  PA: 'PA',
  PENNSYLVANIA: 'PA',
  RI: 'RI',
  'RHODE ISLAND': 'RI',
  SC: 'SC',
  'SOUTH CAROLINA': 'SC',
  SD: 'SD',
  'SOUTH DAKOTA': 'SD',
  TN: 'TN',
  TENNESSEE: 'TN',
  TX: 'TX',
  TEXAS: 'TX',
  UT: 'UT',
  UTAH: 'UT',
  VT: 'VT',
  VERMONT: 'VT',
  VA: 'VA',
  VIRGINIA: 'VA',
  WA: 'WA',
  WASHINGTON: 'WA',
  WV: 'WV',
  'WEST VIRGINIA': 'WV',
  WI: 'WI',
  WISCONSIN: 'WI',
  WY: 'WY',
  WYOMING: 'WY',
};
