import { defineMessages } from 'react-intl';
import isUndefined from 'lodash/isUndefined';
import isObject from 'lodash/isObject';
import { validStates } from './usStates';
import validProvinces from './caProvinces';

/**
 * @param {string} value
 * @returns {boolean}
 */
export function isEmpty(value) {
  if (value === false || value === null || isUndefined(value)) {
    return true;
  }

  const string = String(value);
  return string.length === 0 || !string.trim();
}

/**
 * @param {string} value
 * @returns {boolean}
 */
export function isRequired(value) {
  return !isEmpty(value);
}

/**
 * @param {string} email
 * @returns {boolean}
 */
export function isEmail(email) {
  // eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

/**
 * @param {*} value
 * @return {boolean}
 */
export function isNumeric(value) {
  return isEmpty(value) || (!isNaN(parseFloat(value)) && isFinite(value));
}

/**
 * @param {*} telNumber
 * @return {boolean}
 */
export function isPhoneNumber(telNumber) {
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  return isEmpty(telNumber) || regex.test(telNumber);
}

/**
 * @param {*} postCode
 * @return {boolean}
 */
export function isValidPostCode(postCode) {
  const regex = /^\s*([a-zA-Z]{1,2}\d{1,2}[a-zA-Z]?)\s*(\d[a-zA-Z]{2})\s*$/;
  return isEmpty(postCode) || regex.test(postCode);
}

/**
 * Does a value match another value defined in fields?
 *
 * @param {string} value
 * @param {string} fieldToMatch
 * @param {Object} fields
 * @return {boolean}
 */
export function doesMatch(value, fieldToMatch, fields) {
  const matchValue = fields[fieldToMatch];
  return value === matchValue;
}

/**
 * @param {string} value
 * @param {string} fieldToNotEqual
 * @param {Object} fields
 * @return {boolean}
 */
export function isDifferent(value, fieldToNotEqual, fields) {
  return !doesMatch(value, fieldToNotEqual, fields);
}

/**
 * @param {*} value
 * @param {number} minValue
 * @return {boolean}
 */
export function isAtLeast(value, minValue) {
  return isNumeric(value) && value >= minValue;
}

/**
 * @param {*} value
 * @param {number} maxValue
 * @return {boolean}
 */
export function isNoGreater(value, maxValue) {
  return isNumeric(value) && value <= maxValue;
}

/**
 * Calculate if a string has a length of at least min length.
 * Not required so return true if not set.
 *
 * @param {*} value
 * @param {number} minLength
 * @return {boolean}
 */
export function hasLengthAtLeast(value, minLength) {
  return value ? isAtLeast(value.toString().length, minLength) : true;
}

/**
 * Calculate if a string has a length of at least min length.
 * Not required so return true if not set.
 *
 * @param {*} value
 * @param {number} passwordMinLength
 * @return {boolean}
 */
export function isPasswordMinimumLength(value, passwordMinLength) {
  return value ? isAtLeast(value.toString().length, passwordMinLength) : true;
}

/**
 * Calculate if a string has a length no greater than max length.
 * Not required so return true if not set.
 *
 * @param {String} value
 * @param {number} maxLength
 * @return {boolean}
 */
export function hasLengthNoGreater(value, maxLength) {
  return value ? isNoGreater(value.toString().length, maxLength) : true;
}

/**
 * Check if an entry is a valid US State
 *
 * @param {String} state
 * @return {boolean}
 */
export const isUsState = state => state && validStates[state.toUpperCase()];

/**
 * Check if an entry is a valid Canadian Province
 *
 * @param {String} province
 * @return {boolean}
 */
export const isCaProvince = province =>
  province && validProvinces[province.toUpperCase()];

/**
 * Check if characters are ASCII compatible
 *
 * @param {String} string
 * @return {boolean}
 */
export const isASCII = string =>
  /(^[\x20-\x7FÄÁáąĆČčćçÉĘęêëèėěïîíłñńÖØóôøřŠšśŞşÜùÿ—Ħé]+$|^$)/u.test(string);

/**
 * @type {{email: isEmail, required: isRequired}}
 */
const validation = {
  differ: isDifferent,
  email: isEmail,
  match: doesMatch,
  tel: isPhoneNumber,
  postcode: isValidPostCode,
  min: isAtLeast,
  minLength: hasLengthAtLeast,
  max: isNoGreater,
  maxLength: hasLengthNoGreater,
  passwordMinLength: isPasswordMinimumLength,
  required: isRequired,
  usState: isUsState,
  caProvince: isCaProvince,
  ASCII: isASCII,
};

/**
 * @type {Object}
 */
export const messages = defineMessages({
  differ: { id: 'error.differ', defaultMessage: 'Must be different' },
  email: { id: 'error.email', defaultMessage: 'Invalid email' },
  usState: { id: 'error.us-state', defaultMessage: 'Invalid State' },
  caProvince: { id: 'error.ca-province', defaultMessage: 'Invalid Province' },
  match: { id: 'error.match', defaultMessage: 'Does not match' },
  tel: { id: 'error.tel', defaultMessage: 'Not a valid telephone number' },
  postcode: { id: 'error.postcode', defaultMessage: 'Not a valid UK postcode' },
  min: { id: 'error.below-minimum', defaultMessage: 'Value too small' },
  minLength: { id: 'error.min-length', defaultMessage: 'Value too short' },
  max: { id: 'error.above-maximum', defaultMessage: 'Value too large' },
  maxLength: { id: 'error.max-length', defaultMessage: 'Value too long' },
  passwordMinLength: {
    id: 'error.password-min-length',
    defaultMessage: 'Password should be a minimum of 10 characters',
  },
  required: { id: 'error.required', defaultMessage: 'Required field' },
  ASCII: {
    id: 'error.ASCII',
    defaultMessage: 'Please use letters (a-z) and numbers only',
  },
});

/**
 * Validate fields for given rules.
 *
 * Example rules definition:
 * {
 *   email: ['required', 'email'],
 *   firstName: ['required']
 *   password: ['required']
 *   passwordConfirm: ['required', { match: 'password' }]
 * }
 *
 * @param {Object} fields - an object containing the fields id and value
 * @param {Object} rules - see above for example
 * @param {function} formatMessage - localisation function
 * @throws Error if a validation rule doesn't exist.
 * @returns {{}}
 */
export function validate(fields, rules, formatMessage) {
  const errors = {};
  Object.keys(rules).forEach(fieldId => {
    const value = fields[fieldId];
    const fieldRules = rules[fieldId];

    // eslint-disable-next-line no-restricted-syntax
    for (const rule of fieldRules) {
      let validationRule = rule;
      const params = [value];

      if (isObject(rule)) {
        validationRule = Object.keys(rule)[0];
        params.push(rule[validationRule]);
      }

      params.push(fields);

      const isValid = validation[validationRule];

      if (isUndefined(isValid)) {
        throw new Error(`Validation rule: ${validationRule} does not exist`);
      }

      if (!isValid(...params)) {
        errors[fieldId] = formatMessage(messages[validationRule]);
        break;
      }
    }
  });

  return errors;
}

export default validate;
