// @flow
import { OPEN_OVERLAY, CLOSE_OVERLAY } from '../constants/overlay';

type OpenOverlay = {
  type: typeof OPEN_OVERLAY,
  payload: {
    message: string,
  },
};

type CloseOverlay = {
  type: typeof CLOSE_OVERLAY,
};

export type Actions = OpenOverlay | CloseOverlay;

/**
 * @param {string} message
 * @returns {{type: string}}
 */
export function openOverlay(message: string): OpenOverlay {
  return {
    type: OPEN_OVERLAY,
    payload: {
      message,
    },
  };
}

/**
 * @returns {{type: string}}
 */
export function closeOverlay(): CloseOverlay {
  return {
    type: CLOSE_OVERLAY,
  };
}
