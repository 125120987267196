/* eslint-env browser */
import { createStore, compose } from 'redux';
import { createResponsiveStoreEnhancer } from 'redux-responsive';
import reducers from './reducers';
import middleware from './middleware';

const composeEnhancers =
  (process.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export default state =>
  createStore(
    reducers,
    state,
    composeEnhancers(
      createResponsiveStoreEnhancer({ calculateInitialState: false }),
      middleware
    )
  );
