// @flow
import {
  RECEIVE_COUNTRIES,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_ERROR,
} from '../constants/countries';
import { CALL_API } from '../constants/api';
import type { Countries } from './system/types';

/**
 * @param {Array} countries
 * @returns {{type, payload: *}}
 */
export function receiveCountries(countries: Countries) {
  return {
    type: RECEIVE_COUNTRIES,
    payload: countries,
  };
}

/**
 * @returns {{type}}
 */
export function getCountriesRequest() {
  return {
    type: GET_COUNTRIES_REQUEST,
  };
}

/**
 * @returns {{type}}
 */
export function getCountriesError() {
  return {
    type: GET_COUNTRIES_ERROR,
  };
}

/**
 * @return {{actions: {success: receiveCountries}, type, url: string}}
 */
export function apiGetCountries() {
  return {
    actions: {
      start: getCountriesRequest,
      error: getCountriesError,
      success: receiveCountries,
    },
    type: CALL_API,
    url: 'countries',
  };
}
