const namespace = 'bravissimo/mega-menu';

// Navigation - items
export const RECEIVE_NAV_ITEMS = `${namespace}/RECEIVE_NAV_ITEMS`;
export const SET_ITEM_VISIBILITY = `${namespace}/SET_ITEM_VISIBILITY`;

// Navigation - search
export const PRODUCT_SUGGESTED_SEARCH_REQUEST = `${namespace}/PRODUCT_SUGGESTED_SEARCH_REQUEST`;
export const PRODUCT_SUGGESTED_SEARCH_SUCCESS = `${namespace}/PRODUCT_SUGGESTED_SEARCH_SUCCESS`;
export const PRODUCT_SUGGESTED_SEARCH_FAILURE = `${namespace}/PRODUCT_SUGGESTED_SEARCH_FAILURE`;
export const PRODUCT_SEARCH_REQUEST = `${namespace}/PRODUCT_SEARCH_REQUEST`;
export const PRODUCT_SEARCH_SUCCESS = `${namespace}/PRODUCT_SEARCH_SUCCESS`;
export const PRODUCT_SEARCH_FAILURE = `${namespace}/PRODUCT_SEARCH_FAILURE`;
export const PRODUCT_SEARCH_OPEN = `${namespace}/PRODUCT_SEARCH_OPEN`;
export const PRODUCT_SEARCH_CLOSE = `${namespace}/PRODUCT_SEARCH_CLOSE`;
export const CLEAR_SEARCH_REQUEST = `${namespace}/CLEAR_SEARCH_REQUEST`;
export const CLEAR_SEARCH_SUCCESS = `${namespace}/CLEAR_SEARCH_SUCCESS`;
export const CLEAR_SEARCH_FAILURE = `${namespace}/CLEAR_SEARCH_FAILURE`;

// Header
export const RECEIVE_TOOLS = `${namespace}/RECEIVE_TOOLS`;
export const RECEIVE_HEADER_LINKS = `${namespace}/RECEIVE_HEADER_LINKS`;
export const RECEIVE_TOP_BAR_TEXT = `${namespace}/RECEIVE_TOP_BAR_TEXT`;

// Drawer
export const OPEN_DRAWER = `${namespace}/OPEN_DRAWER`;
export const CLOSE_DRAWER = `${namespace}/CLOSE_DRAWER`;

// Popover Bag
export const OPEN_POPOVER_BAG = `${namespace}/OPEN_POPOVER_BAG`;
export const CLOSE_POPOVER_BAG = `${namespace}/CLOSE_POPOVER_BAG`;

// Mega menu
export const RECEIVE_MEGA_MENU_ITEMS = `${namespace}/RECEIVE_MEGA_MENU_ITEMS`;
export const TOGGLE_MEGA_MENU_NAVIGATION = `${namespace}/TOGGLE_MEGA_MENU_NAVIGATION`;
export const CLOSE_MEGA_MENU_NAVIGATION = `${namespace}/CLOSE_MEGA_MENU_NAVIGATION`;

export const RECEIVE_CONTAINERS = `${namespace}/RECEIVE_CONTAINERS`;
