// @flow
// TODO: Upgrade Flow to a version that supports React hooks
// $FlowFixMe
import React, { useEffect, useState } from 'react';
import Modal from 'brastrap/common/modal/Modal';
import Button from 'brastrap/common/button/Button';
import Icon from 'brastrap/common/icon/Icon';
import EditorialContainer from 'brastrap/editorial/editorial-container/EditorialContainer';

type Props = {
  backgroundColour?: string,
  collapsedBackgroundColour?: string,
  collapsedText?: string,
  collapsedTextColour?: string,
  containers: Array<Container>,
  isCollapsedInitially?: boolean,
  isOpen: boolean,
  modalId: string,
  onOpen: string => void,
  onClose: (string, number) => void,
  redisplayTime: number,
};

const STYLE_PREFIX = 'c-editorial-modal';

const EditorialModal = (props: Props) => {
  const {
    backgroundColour,
    collapsedBackgroundColour,
    collapsedText,
    collapsedTextColour,
    containers,
    isOpen,
    onOpen,
    onClose,
    modalId,
    isCollapsedInitially,
    redisplayTime,
  } = props;

  const iconDismiss = <Icon icon="dismiss" fillColour={collapsedTextColour} />;
  const [collapsed, setCollapsed] = useState(isCollapsedInitially);

  useEffect(() => {
    if (!isCollapsedInitially && !isOpen) {
      onOpen(modalId);
    }
  });

  if (collapsed) {
    return (
      <div
        className={`${STYLE_PREFIX}__container--collapsed`}
        style={{
          background: collapsedBackgroundColour,
          color: collapsedTextColour,
        }}
      >
        <Button
          modifiers={[
            'modal-open',
            'like-link',
            'inherit-case',
            'block',
            'center',
          ]}
          label={collapsedText}
          onClick={() => {
            setCollapsed(false);
            onOpen(modalId);
          }}
        />

        <Button
          modifiers={['modal-dismiss', 'like-link']}
          icon={iconDismiss}
          label=""
          onClick={() => {
            setCollapsed(false);
            onClose(modalId, redisplayTime);
          }}
        />
      </div>
    );
  }

  if (isOpen) {
    return (
      <div className={`${STYLE_PREFIX}__container`}>
        <Modal
          isOpen={isOpen}
          actions={{
            dismiss: {
              icon: {
                icon: 'dismiss',
              },
              onClick: () => onClose(modalId, redisplayTime),
            },
          }}
          backgroundColour={backgroundColour}
        >
          <EditorialContainer containers={containers} />
        </Modal>
      </div>
    );
  }

  return null;
};

EditorialModal.defaultProps = {
  backgroundColour: '#ffffff',
  collapsedBackgroundColour: '#f4436d',
  collapsedTextColour: '#ffffff',
  redisplayTime: 5,
  onOpen: () => {},
  onClose: () => {},
};

export default EditorialModal;
