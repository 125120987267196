// @flow
import React from 'react';
import UtilityNav from './UtilityNav';
import type { UtilityNav as Props } from './utility-nav.types.js';

type State = {
  open: boolean,
};

class UtilityNavCollapsible extends React.Component<Props, State> {
  static defaultProps = {
    open: false,
    modifiers: [],
  };

  /**
   * @param {Object} props
   */
  constructor(props: Props) {
    super(props);
    this.state = { open: !!props.openNav };
  }

  handleToggle = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  };

  /**
   * @returns {JSX}
   */
  render() {
    const { id, heading, links, ...rest } = this.props;
    const { open } = this.state;
    const modifiers = [...(this.props.modifiers || [])];
    modifiers.push('collapsible');

    if (open) {
      modifiers.push('open');
    }

    const newProps = {
      ...rest,
      heading,
      id,
      links,
      modifiers,
      onHeadingClick: this.handleToggle,
    };

    return <UtilityNav {...newProps} />;
  }
}

export default UtilityNavCollapsible;
