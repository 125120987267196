import { GDPR_CONTENT_SUCCESS, UPDATE_TERMS_DECISION } from '../constants/gdpr';

export const initialState = {
  consentMethod: null,
  content: {},
  isReady: false,
  terms: {
    acceptance: false,
  },
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {{consentMethod: null, content: {}, terms: {acceptance: boolean}}}
 */
export function gdpr(state = initialState, action = {}) {
  switch (action.type) {
    case GDPR_CONTENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isReady: true,
      };

    case UPDATE_TERMS_DECISION:
      return {
        ...state,

        terms: {
          ...state.terms,
          acceptance: action.payload,
        },
      };

    default:
      return state;
  }
}
