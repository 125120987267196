import { SET_SERVER_AND_PORT } from '../../constants/app';

const initialState = '';

/**
 * @param {String} state
 * @param {{ payload: string, type: String }} action
 * @returns {*}
 */
export function serverAndPort(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SERVER_AND_PORT:
      return action.payload;

    default:
      return state;
  }
}
