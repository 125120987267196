/* global window */
// @flow
import React, { Component } from 'react';
import { applyModifiers } from '../../utils';
import { Accordion } from './accordion.type';

const STYLE_PREFIX = 'c-accordion';
const ACTIVE_MODIFIER = 'active';
const MARGIN_BUFFER = 30;

type State = {
  maxHeight: number,
};

class Item extends Component<Accordion, State> {
  container: ?HTMLDivElement;
  interval: ?number;
  static defaultProps = {
    isActive: false,
    onClick: () => {},
  };
  state = { maxHeight: 0 };

  /**
   * Get full height of hidden content, and listen for changes in height
   */
  componentDidMount() {
    if (window) {
      window.addEventListener('resize', () => {
        this.resetHeight();
      });
    }
    setTimeout(() => this.resetHeight(), 0);
  }

  /**
   * Accept prop to notify that the content in the accordion has changed
   * and update container height when this prop changes
   * @param {object} nextProps
   */
  componentWillReceiveProps(nextProps: Accordion) {
    if (nextProps.needsResize !== this.props.needsResize) {
      // Reset the height, and poll to account for rendering time for new content
      this.interval = setInterval(() => {
        if (
          this.container &&
          this.container.scrollHeight + MARGIN_BUFFER !== this.state.maxHeight
        ) {
          this.resetHeight();
        } else {
          clearInterval(this.interval || 0);
        }
      }, 200);
    }
  }

  /**
   * Remove any timeouts hanging around
   */
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval || 0);
    }
  }

  /**
   * Utility function to get containers current height and set max height accordingly
   */
  resetHeight() {
    // scroll height does not account for margin and border, so adding that back in
    if (this.container) {
      this.setState({ maxHeight: this.container.scrollHeight + MARGIN_BUFFER });
    }
  }

  /**
   * Renders
   * @return {*}
   */
  render() {
    const { children, isActive, label, onClick, note, href } = this.props;
    const { maxHeight } = this.state;
    const styleProps = {};
    const anchorProps = {};
    if (isActive) styleProps.style = { maxHeight };
    if (href) anchorProps.href = href;
    return (
      <div
        className={applyModifiers(
          STYLE_PREFIX,
          isActive ? [ACTIVE_MODIFIER] : null
        )}
      >
        <a
          onClick={onClick}
          {...anchorProps}
          className={`${STYLE_PREFIX}__label`}
        >
          <span className={`${STYLE_PREFIX}__text`}>{label}</span>
          {note && (
            <span className={`${STYLE_PREFIX}__note`}>
              <br />
              {note}
            </span>
          )}
        </a>
        {children && (
          <div
            className={`${STYLE_PREFIX}__content`}
            {...styleProps}
            ref={el => {
              this.container = el;
            }}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default Item;
