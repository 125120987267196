import { CALL_API } from '../constants/api';
import {
  MASTECTOMY_VAT_EXEMPTION_REQUEST,
  MASTECTOMY_VAT_EXEMPTION_REQUEST_SUCCEEDED,
  MASTECTOMY_VAT_EXEMPTION_REQUEST_FAILED,
  CLOSE_MESSAGES,
} from '../constants/mastectomy-vat-exemption-request';

/**
 * @param {Object} payload - containing the details of the request
 * @returns {Object} An action containing all the necessary fields to make an API call
 */
export function mastectomyVatExemptionRequested(payload) {
  return {
    actions: {
      start: mastectomyVatExemptionRequestStart,
      success: mastectomyVatExemptionRequestSuccess,
      error: mastectomyVatExemptionRequestFailure,
    },
    method: 'post',
    params: payload,
    type: CALL_API,
    url: 'mastectomyVatExemptionRequest',
  };
}

/**
 * @returns {{type: String}}
 */
export function mastectomyVatExemptionRequestStart() {
  return {
    type: MASTECTOMY_VAT_EXEMPTION_REQUEST,
  };
}

/**
 * @param {object} details
 * @returns {{type, payload: object}}
 */
export function mastectomyVatExemptionRequestSuccess(details) {
  return {
    type: MASTECTOMY_VAT_EXEMPTION_REQUEST_SUCCEEDED,
    payload: details,
  };
}

/**
 * @param {object} details
 * @returns {{type, error: boolean, payload: object}}
 */
export function mastectomyVatExemptionRequestFailure(details) {
  return {
    type: MASTECTOMY_VAT_EXEMPTION_REQUEST_FAILED,
    error: true,
    payload: details,
  };
}

/**
 * @returns {{type}}
 */
export function closeMessages() {
  return {
    type: CLOSE_MESSAGES,
  };
}
