import isString from 'lodash/isString';

import { ADD, DISMISS, CLEAR_ALL } from '../constants/messages';

/**
 * The message component expects it to have a title and children.
 * If only a string is provided convert into an object.
 *
 * @param {String|Object} message
 * @return {Object}
 */
function messageToObject(message) {
  return isString(message) ? { children: message } : message;
}

/**
 * @param {Array} state
 * @param {Object} message
 * @param {null|string} target
 * @returns {Array}
 */
function addErrorMessage(state, message, target = null) {
  const errorMessage = {
    ...messageToObject(message),
    modifiers: ['warn', 'banner'],
    target,
    type: 'error',
  };
  return addMessage(state, errorMessage);
}

/**
 * @param {Array} state
 * @param {Object} message
 * @param {string|null} target
 * @returns {Array}
 */
function addConfirmMessage(state, message, target = null) {
  const confirmMessage = {
    ...messageToObject(message),
    modifiers: ['confirm', 'banner'],
    target,
  };
  return addMessage(state, confirmMessage);
}

/**
 * @param {Array} state
 * @param {Object} message
 * @param {string|null} target
 * @returns {Array}
 */
function addInfoMessage(state, message, target = null) {
  const infoMessage = {
    ...messageToObject(message),
    modifiers: ['inform', 'banner'],
    target,
  };
  return addMessage(state, infoMessage);
}

/**
 * @param {Array} state
 * @param {Object} message
 * @returns {Array}
 */
function addMessage(state, message) {
  const messageList = [...state];
  messageList.push(message);
  return messageList;
}

/**
 * @param {Array} state
 * @param {Number} index
 * @return {Array}
 */
function remove(state, index) {
  const messagesList = [...state];
  messagesList.splice(index, 1);
  return messagesList;
}

/**
 * Remove all messages for a particular target
 * If no target is specified then remove them all
 *
 * @param {Array} state
 * @param {String} target
 * @return {Array}
 */
function clear(state, target) {
  if (!target) {
    return [];
  }

  const messagesToRemove = state.filter(message => message.target === target);
  let messageList = [...state];

  messagesToRemove.forEach((message, index) => {
    messageList = remove(messageList, index);
  });

  return messageList;
}

export const initialState = [];

/**
 * @param {Array} state
 * @param {Object} action
 * @returns {Array}
 */
export function messages(state = initialState, action = {}) {
  switch (action.type) {
    case ADD: {
      const { isError, isInfo, message, target } = action.payload;
      if (isError) {
        return addErrorMessage(state, message, target);
      } else if (isInfo) {
        return addInfoMessage(state, message, target);
      }
      return addConfirmMessage(state, message, target);
    }

    case DISMISS: {
      const messageIndex = action.payload;
      return remove(state, messageIndex);
    }

    case CLEAR_ALL: {
      // Remove all messages for a particular target
      // If no target is specified then remove them all
      const target = action.payload;
      return clear(state, target);
    }

    default:
      return state;
  }
}
