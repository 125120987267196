// @flow

type ChangeLocation = 'bravissimo/app/routing/CHANGE_LOCATION';
type RoutingFunctionExecuted =
  'bravissimo/app/routing/ROUTING_FUNCTION_EXECUTED';
type Replace = 'bravissimo/app/routing/REPLACE';
type Push = 'bravissimo/app/routing/PUSH';
type SetBrowserId = 'bravissimo/app/SET_BROWSER_ID';
type SetServerAndPort = 'serverAndPort/SET_SERVER_AND_PORT';
type RedirectModalId = 'locale_modal';
type ConsentModalId = 'cookie_consent';
type RedirectLocaleStorageKey = 'APPLY_REDIRECT';
type PreferLocaleQueryParam = 'prefer-locale';
type LocaleChangeCancel = 'bravissimo/app/LOCALE_CHANGE_CANCEL';
type LocaleChangeSuccess = 'bravissimo/app/LOCALE_CHANGE_SUCCESS';
type LocaleChangeFailure = 'bravissimo/app/LOCALE_CHANGE_FAILURE';
type LocaleChangeRequest = 'bravissimo/app/LOCALE_CHANGE_REQUEST';
type ExplicitLocaleStorageKey = 'EXPLICIT_LOCALE';
type ExpectedLocaleStorageKey = 'EXPECTED_LOCALE';
type ReceiveLocales = 'bravissimo/app/RECEIVE_LOCALES';

// Content info - locales
export const RECEIVE_LOCALES: ReceiveLocales = 'bravissimo/app/RECEIVE_LOCALES';
export const EXPECTED_LOCALE_STORAGE_KEY: ExpectedLocaleStorageKey =
  'EXPECTED_LOCALE';
export const EXPLICIT_LOCALE_STORAGE_KEY: ExplicitLocaleStorageKey =
  'EXPLICIT_LOCALE';
export const LOCALE_CHANGE_REQUEST: LocaleChangeRequest =
  'bravissimo/app/LOCALE_CHANGE_REQUEST';
export const LOCALE_CHANGE_FAILURE: LocaleChangeFailure =
  'bravissimo/app/LOCALE_CHANGE_FAILURE';
export const LOCALE_CHANGE_SUCCESS: LocaleChangeSuccess =
  'bravissimo/app/LOCALE_CHANGE_SUCCESS';
export const LOCALE_CHANGE_CANCEL: LocaleChangeCancel =
  'bravissimo/app/LOCALE_CHANGE_CANCEL';
export const PREFER_LOCALE_QUERY_PARAM: PreferLocaleQueryParam =
  'prefer-locale';
export const REDIRECT_LOCALE_STORAGE_KEY: RedirectLocaleStorageKey =
  'APPLY_REDIRECT';
export const REDIRECT_MODAL_ID: RedirectModalId = 'locale_modal';
export const CONSENT_MODAL_ID: ConsentModalId = 'cookie_consent';

// Set server and port
export const SET_SERVER_AND_PORT: SetServerAndPort =
  'serverAndPort/SET_SERVER_AND_PORT';
export const SET_BROWSER_ID: SetBrowserId = 'bravissimo/app/SET_BROWSER_ID';

// Programatic navigation
export const PUSH: Push = 'bravissimo/app/routing/PUSH';
export const REPLACE: Replace = 'bravissimo/app/routing/REPLACE';
export const ROUTING_FUNCTION_EXECUTED: RoutingFunctionExecuted =
  'bravissimo/app/routing/ROUTING_FUNCTION_EXECUTED';
export const CHANGE_LOCATION: ChangeLocation =
  'bravissimo/app/routing/CHANGE_LOCATION';
