// @flow
import React from 'react';
import { Link as BaseLink, NavLink } from 'react-router-dom';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

export type Action = {
  functionName: string,
  name?: string,
  trigger: 'Click', // Add more triggers when needed.
};

export type Props = {
  action?: Action,
  activeClassName?: string,
  basePath?: string,
  children: any,
  hash?: string,
  href?: Url,
  onClick?: any => void,
  onlyActiveOnIndex?: boolean,
  isDummyButton?: boolean,
  query?: {},
  target?: string,
  to?: Url,
  analyticsRef?: string,
};

const DUMMY_DIV = 'dummyDiv';
const ANCHOR = 'anchor';
const LINK = 'routerLink';
const NAV = 'routerNavLink';

const additionalReactLinkProps = [
  'aria-controls',
  'className',
  'id',
  'role',
  'tabIndex',
  'data-ref',
  'rel',
];
const validLinkProps = [
  ...Object.keys(BaseLink.propTypes),
  ...additionalReactLinkProps,
];
const validNavLinkProps = [
  ...Object.keys(NavLink.propTypes),
  ...additionalReactLinkProps,
];
const invalidAProps = [
  '_type',
  'action',
  'basePath',
  'dispatch',
  'hash',
  'query',
  'to',
  'valid',
  'isDummyButton',
  'activeClassName',
  'analyticsRef',
  'match',
  'location',
  'history',
  'staticContext',
  'url',
];
const invalidDummyDivProps = [
  '_type',
  'action',
  'href',
  'onClick',
  'basePath',
  'dispatch',
  'isDummyButton',
  'analyticsRef',
  'data-ref',
  'rel',
  'match',
  'location',
  'history',
  'staticContext',
  'url',
];

/**
 * @param {string} url
 * @param {string} basePath
 * @returns {string}
 */
const formatUrl = (url: ?Url, basePath: ?string) => {
  // Match URL that begins with a single forward slash
  if (typeof url === 'string' && basePath && /^\/(?!\/)/.exec(url)) {
    return basePath + url;
  }
  return url;
};

/**
 * Ensure no invalid properties get added to the Link component.
 * Add the new url to the correct property.
 * Add the url to the correct property; either 'href' for <a/> or 'to' for <Link/>
 * If this is a dummy button, strip all <Link/> and <a/> props
 *
 * @param {Object} props
 * @param {String} type
 *
 * @return {Object}
 */
const formatProps = (props: Props, type: string) => {
  const propsWithUrl = {};

  if (type !== DUMMY_DIV) {
    const urlProp = type === ANCHOR ? 'href' : 'to';
    const url = formatUrl(props[urlProp], props.basePath);
    if (url) propsWithUrl[urlProp] = url;
  }

  // Filter out props that are null or undefined
  const allProps = pickBy({ ...props, ...propsWithUrl }, identity);

  if (props.analyticsRef) {
    allProps['data-ref'] = props.analyticsRef;
  }

  switch (type) {
    case ANCHOR:
      return omit(allProps, invalidAProps);

    case LINK:
      return pick(allProps, validLinkProps);

    case DUMMY_DIV:
      return omit(allProps, invalidDummyDivProps);

    case NAV:
      return pick(allProps, validNavLinkProps);

    default:
      return allProps;
  }
};

/**
 * This component should be used instead of <a/> or <Link/>.
 * It's used in conjunction with placeholder/link to add the basePath to all links on the page.
 * For example:
 *  - prefixing /us/ to all links.
 *
 * @param {Object} props
 * @return {XML}
 * @constructor
 */
const Link = (props: Props) => {
  const useAnchor = !props.to;
  const useNav = props.to && props.activeClassName;

  if (props.isDummyButton) {
    return <div {...formatProps(props, DUMMY_DIV)}>{props.children}</div>;
  } else if (useAnchor) {
    return <a {...formatProps(props, ANCHOR)}>{props.children}</a>;
  } else if (useNav) {
    return <NavLink {...formatProps(props, NAV)}>{props.children}</NavLink>;
  }
  return <BaseLink {...formatProps(props, LINK)}>{props.children}</BaseLink>;
};

export default Link;
