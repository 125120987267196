import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 16.3 18.1"
  >
    <g>
      <polygon points="0.7,17.5 15.7,17.5 14.2,4.8 2.2,4.8" />
      <path d="M5.1,6.2c0,0-0.9-5.6,3.1-5.6c4,0,3.4,5.6,3.4,5.6" />
    </g>
  </svg>
);

export default SvgIcon;
