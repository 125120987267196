import React, { useState } from 'react';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import ButtonLink from '../../common/button-link/ButtonLink';

type Props = {
  collapsedBackgroundColour?: string,
  collapsedTextColour?: string,
  modalId: string,
  onClose: (
    modalId: string,
    redisplayTime: number,
    action: 'granted' | 'denied'
  ) => void,
  redisplayTime: number,
  consentModalText: string,
  modalHeader: string,
  consentModalAcceptButtonText: string,
  consentModalRejectButtonText: string,
  isConsentCookiePresent: Boolean,
  containers: Array[],
};

const STYLE_PREFIX = 'c-consent-modal';

const ConsentModal = (props: Props) => {
  const [consentPreferences, changePreferences] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [consent, setConsent] = useState('granted');

  const handleClick = () => {
    setToggle(prevToggle => {
      if (!prevToggle) {
        setConsent('granted');
      } else {
        setConsent('denied');
      }
      return !prevToggle;
    });
  };

  const {
    collapsedBackgroundColour,
    collapsedTextColour,
    onClose,
    modalId,
    redisplayTime,
    modalHeader,
    consentModalText,
    consentModalAcceptButtonText,
    consentModalRejectButtonText,
    isConsentCookiePresent,
    containers,
  } = props;

  const { introduction, title } = containers[0];

  if (!isConsentCookiePresent) {
    if (!consentPreferences) {
      return (
        <div
          className={`${STYLE_PREFIX}__container`}
          style={{
            background: collapsedBackgroundColour,
            color: collapsedTextColour,
          }}
        >
          <div className={`${STYLE_PREFIX}__content-container`}>
            <p className={`${STYLE_PREFIX}__text-header`}>
              <b>{modalHeader}</b>
            </p>
            <p className={`${STYLE_PREFIX}__text`}>
              <MarkDown content={consentModalText} />
            </p>
            <div className={`${STYLE_PREFIX}__accept-button-container`}>
              <ButtonLink
                label={consentModalAcceptButtonText}
                modifiers={['major-filled', 'small']}
                onClick={() => {
                  onClose(modalId, redisplayTime, 'granted');
                }}
              />
            </div>
            <div className={`${STYLE_PREFIX}__reject-link-container`}>
              <ButtonLink
                label={consentModalRejectButtonText}
                modifiers={['underlined', 'minor-hollow', 'xsmall']}
                onClick={() => {
                  changePreferences(true);
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    if (consentPreferences) {
      return (
        <div
          className={`${STYLE_PREFIX}__container`}
          style={{
            background: collapsedBackgroundColour,
            color: collapsedTextColour,
          }}
        >
          <div className={`${STYLE_PREFIX}__consent-preference-container`}>
            <p className={`${STYLE_PREFIX}__text-header-consent-preference`}>
              <b>{title}</b>
            </p>
            <table className={`${STYLE_PREFIX}__consent-table`}>
              <tr>
                <td className={`${STYLE_PREFIX}__consent-table-item`}>
                  &#x2022;
                </td>
                <td className={`${STYLE_PREFIX}__consent-table-item`}>
                  Performance Cookies
                </td>
                <td className={`${STYLE_PREFIX}__consent-table-item`}>
                  <b>Always Active</b>
                </td>
              </tr>
              <tr>
                <td className={`${STYLE_PREFIX}__consent-table-item`}>
                  &#x2022;
                </td>
                <td className={`${STYLE_PREFIX}__consent-table-item`}>
                  Google Cookies
                </td>
                <td className={`${STYLE_PREFIX}__consent-table-item`}>
                  <label className={`${STYLE_PREFIX}__switch`}>
                    <input
                      type="checkbox"
                      onChange={handleClick}
                      checked={toggle}
                    />
                    <span className={`${STYLE_PREFIX}__slider round`} />
                  </label>
                </td>
              </tr>
            </table>
            <p className={`${STYLE_PREFIX}__consent-cookies-text`}>
              <MarkDown content={introduction} />
            </p>
            <div className={`${STYLE_PREFIX}__accept-button-container`}>
              <ButtonLink
                label="Confirm my Choice"
                modifiers={['major-filled', 'small']}
                onClick={() => {
                  onClose(modalId, redisplayTime, consent);
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }
  return null;
};

ConsentModal.defaultProps = {
  redisplayTime: 5,
  onClose: () => {},
};

export default ConsentModal;
