import {
  RECEIVE_LOCALES,
  LOCALE_CHANGE_SUCCESS,
  LOCALE_CHANGE_CANCEL,
} from '../../constants/app';

export const initialState = {
  available: [],
  expected: null,
  modalContent: null,
  selected: null,
  translations: [],
  url: null, // Temp state storage for the locale that we are about to switch to.
};

/**
 * @param {Object} state
 * @param {{ payload: *, type: String }} action
 * @returns {*}
 */
export function locale(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_LOCALES:
      return {
        ...state,
        ...action.payload,
      };

    case LOCALE_CHANGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case LOCALE_CHANGE_CANCEL:
      return {
        ...state,
        modalContent: null,
        url: null,
      };

    default:
      return state;
  }
}
