import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from 'shared/constants/password-reset-request';

import { NOTIFY_EXPIRED_TOKEN } from 'shared/constants/reset-password';

export const initialState = {
  isSaving: false,
  confirmed: false,
  message: null,
};

/**
 * Returns an appropriate error message, which is either the server's message or
 * a generic "something went wrong" message.
 * @param {Object|String} payload the failure action's payload
 * @returns {String} the error message.
 */
function processFailureMessage(payload) {
  if (payload && payload.message) {
    return payload.message;
  }

  /* TODO: i18n */
  return 'There was a problem sending your request. Please trying again later.';
}

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} state
 */
export function passwordResetRequest(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_PASSWORD:
      return {
        ...state,

        isSaving: true,
        message: null,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,

        isSaving: false,
        confirmed: true,
        message: null,
      };

    case RESET_PASSWORD_FAILURE:
    case NOTIFY_EXPIRED_TOKEN:
      return {
        ...state,

        isSaving: false,
        message: processFailureMessage(action.payload),
      };

    default:
      return state;
  }
}
