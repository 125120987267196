import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
    aria-labelledby="title"
  >
    <title>Tiktok</title>
    <path d="M 176.191406 0 L 23.808594 0 C 10.679688 0 0 10.679688 0 23.808594 L 0 176.191406 C 0 189.320312 10.679688 200 23.808594 200 L 176.191406 200 C 189.320312 200 200 189.320312 200 176.191406 L 200 23.808594 C 200 10.679688 189.320312 0 176.191406 0 M 157.171875 87.253906 C 156.078125 87.359375 154.984375 87.414062 153.886719 87.417969 C 141.851562 87.421875 130.625 81.355469 124.035156 71.285156 L 124.035156 126.222656 C 124.035156 148.648438 105.855469 166.828125 83.429688 166.828125 C 61.003906 166.828125 42.824219 148.648438 42.824219 126.222656 C 42.824219 103.796875 61.003906 85.617188 83.429688 85.617188 C 84.277344 85.617188 85.105469 85.695312 85.9375 85.746094 L 85.9375 105.757812 C 85.105469 105.65625 84.285156 105.503906 83.429688 105.503906 C 71.984375 105.503906 62.703125 114.78125 62.703125 126.230469 C 62.703125 137.675781 71.984375 146.953125 83.429688 146.953125 C 94.875 146.953125 104.984375 137.933594 104.984375 126.484375 L 105.1875 33.179688 L 124.328125 33.179688 C 126.132812 50.347656 139.976562 63.753906 157.191406 65.015625 L 157.191406 87.253906 " />
  </svg>
);

export default SvgIcon;
