import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  IS_EMAIL_REGISTERED_REQUEST,
  IS_EMAIL_REGISTERED_SUCCESS,
  IS_EMAIL_REGISTERED_FAILURE,
  NOT_AUTHORISED,
} from '../constants/auth';

import { UPDATE_PERSONAL_DETAILS_SUCCESS } from '../constants/personal-details';

import { UPDATE_SUCCESS as DELIVERY_ADDRESSES_UPDATED } from '../constants/delivery-addresses';

export const initialState = {
  isAuthenticated: false,
  isIdentified: false,
  isFetching: false,
  isSaving: false,
  token: null,
  user: null,
  error: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function auth(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,

        isAuthenticated: false,
        isFetching: true,
        isIdentified: false,
      };

    case UPDATE_PERSONAL_DETAILS_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,

        isAuthenticated: true,
        isIdentified: true,
        isFetching: false,
        user: action.payload.user,
      };

    case LOGIN_FAILURE:
      return {
        ...state,

        isAuthenticated: false,
        isIdentified: false,
        isFetching: false,
        message: action.payload.message,
      };

    case LOGOUT_REQUEST:
      return {
        isFetching: true,
      };

    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };

    case LOGOUT_FAILURE:
      return {
        ...state,

        isFetching: false,
      };

    case IS_EMAIL_REGISTERED_REQUEST:
      return {
        ...state,

        isFetching: true,
      };

    case IS_EMAIL_REGISTERED_SUCCESS:
      return {
        ...state,

        isFetching: false,
        user: action.payload.user,
      };

    case IS_EMAIL_REGISTERED_FAILURE:
      return {
        ...state,

        isFetching: false,
        error: action.error,
      };

    case DELIVERY_ADDRESSES_UPDATED:
      return {
        ...state,

        user: action.payload.user,
      };

    case NOT_AUTHORISED:
      // User is not authenticated. Hard refresh the page to reinitialise the state.
      // The user will then be treated to the login page for any page behind the account section.
      // If the user is on the checkout page it'll use the identified cookie to prompt the user to login again.
      // Do not use the logout api action as it'll remove the identified cookie.
      return window && window.location.reload(true); // eslint-disable-line no-undef

    default:
      return state;
  }
}
