import { applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import { CALCULATE_RESPONSIVE_STATE } from 'redux-responsive';
import { sendErrorMessage } from 'shared/utils/utils';

import apiMiddleware from './api';
import redirectMiddleware from './redirect';
import socketMiddleware from './socket.io';

const middlewares = [
  apiMiddleware(),
  redirectMiddleware(),
  socketMiddleware,
  thunk,
];

if (
  process.env.CLIENT_ERROR_LOG_LEVEL === '1' ||
  process.env.CLIENT_ERROR_LOG_LEVEL === '2'
) {
  const errorLogger = store => next => async action => {
    try {
      return await next(action);
    } catch (err) {
      sendErrorMessage(store.getState(), err, 'REDUX_MIDDLEWARE');
      return err;
    }
  };
  middlewares.unshift(errorLogger);
}

// Setting LOG_LEVEL to noisy will log all redux actions to the console, along with current state
// TODO: Use custom logging function (instead of console.log) to block this from logging
// to the server.
if (process.env.LOG_LEVEL === 'noisy') {
  const { createLogger } = require('redux-logger'); // eslint-disable-line global-require

  // Add action types here to prevent them from being logged
  const ignoredActions = [CALCULATE_RESPONSIVE_STATE];
  const isIgnored = action => ignoredActions.indexOf(action) !== -1;

  const logger = createLogger({
    // Only actions with errors will be initially expanded
    collapsed: (getState, action, logEntry) => !logEntry.error,
    predicate: (getState, action) => !isIgnored(action.type),
    diff: false,
  });
  middlewares.push(logger);
}

export default applyMiddleware(...middlewares);
