// @flow
import {
  PUSH,
  REPLACE,
  ROUTING_FUNCTION_EXECUTED,
  CHANGE_LOCATION,
} from '../../constants/app';

export type Push = {
  type: typeof PUSH,
  payload: {
    url: Url | UrlObject,
  },
};
/**
 * @param {String} url
 * @returns {Object}
 */
export function push(url: Url | UrlObject): Push {
  return {
    type: PUSH,
    payload: { url },
  };
}

export type Replace = {
  type: typeof REPLACE,
  payload: { url: Url },
};
/**
 * @param {String} url
 * @returns {Object}
 */
export function replace(url: Url): Replace {
  return {
    type: REPLACE,
    payload: { url },
  };
}

type RoutingFunctionExecuted = {
  type: typeof ROUTING_FUNCTION_EXECUTED,
};
/**
 * @returns {Object}
 */
export function routingFunctionExecuted(): RoutingFunctionExecuted {
  return {
    type: ROUTING_FUNCTION_EXECUTED,
  };
}

type ChangeLocation = {
  type: typeof CHANGE_LOCATION,
  payload: {
    location: RouterLocation,
  },
};

export const changeLocation = (location: RouterLocation): ChangeLocation => ({
  type: CHANGE_LOCATION,
  payload: { location },
});

export type Actions = Push | Replace | RoutingFunctionExecuted | ChangeLocation;
