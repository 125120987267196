import PropTypes from 'prop-types';
/*eslint spaced-comment: ["off"]*/
import { Children } from 'react';
import withSideEffect from 'react-side-effect';

const BodyNav = props => Children.only(props.children);

BodyNav.propTypes = {
  menuId: PropTypes.string,
};

const reducePropsToState = props => {
  const innermostProps = props[props.length - 1];
  return innermostProps ? innermostProps.menuId : null;
};

const handleStateChangeOnClient = menuId =>
  menuId
    ? document.body.setAttribute('data-menu-visible', menuId)
    : document.body.removeAttribute('data-menu-visible');

export default withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient
)(BodyNav);

export const UnwrappedBodyNav = BodyNav;
