import {
  RECEIVE_COUNTRIES,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_ERROR,
} from '../constants/countries';

export const initialState = {
  data: [],
  isFetching: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function countries(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_COUNTRIES:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };

    case GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case GET_COUNTRIES_ERROR:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
