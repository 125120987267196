import PropTypes from 'prop-types';
import React from 'react';
import MarkDown from 'brastrap/editorial/markdown/Markdown';

const STYLE_PREFIX = 'c-touchpoint';

const Touchpoint = props => {
  let className = STYLE_PREFIX;
  const displayImage = props.img && props.img.src;
  const imageAlt = props.img && props.img.alt ? props.img.alt : props.title;

  if (props.modifiers) {
    props.modifiers.forEach(modifier => {
      className += ` ${STYLE_PREFIX}--${modifier}`;
    });
  }

  return (
    <section className={className}>
      <header className="c-touchpoint__header">
        {displayImage && (
          <img
            className="c-touchpoint__img"
            src={props.img.src}
            alt={imageAlt}
            height="96"
          />
        )}
        <h1 className="c-touchpoint__title">{props.title}</h1>
      </header>
      <div className="c-touchpoint__main">
        {props.introduction && <MarkDown content={props.introduction} />}
      </div>
      <div className="c-touchpoint__actions">{props.children}</div>
    </section>
  );
};

Touchpoint.propTypes = {
  children: PropTypes.node,
  introduction: PropTypes.string,
  img: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
  title: PropTypes.string,
  modifiers: PropTypes.array,
};

Touchpoint.defaultProps = {
  modifiers: [],
};

export default Touchpoint;
