// @flow
import React from 'react';
import variants from './variants';

const STYLE_PREFIX = 'c-icon';

type Span = {
  className: string,
  ref?: RefCallback,
};

type Svg = {
  style: {
    fill?: string,
  },
  size: {
    width?: number,
    height?: number,
  },
  count?: number,
  countContext?: {
    one: string,
    other: string,
  },
};

const IconComponent = (props: Icon) => {
  let className = `${STYLE_PREFIX} ${STYLE_PREFIX}--${props.icon}`;
  const SVG = variants[props.icon];
  const labelText = props.label;
  const labelClass = props.labelled ? `${STYLE_PREFIX}__label` : 'u-hidden';

  if (props.labelled) {
    className += ` ${STYLE_PREFIX}--label-${props.labelled}`;
  }

  if (props.className) {
    className += ` ${props.className}`;
  }

  const spanProps: Span = { className };
  if (props.refCallback) {
    spanProps.ref = props.refCallback;
  }

  const svgProps: Svg = {
    style: {},
    size: {},
    count: props.count,
    countContext: props.countContext,
  };
  if (props.fillColour) {
    svgProps.style.fill = props.fillColour;
  }
  if (props.size && props.size.width) {
    svgProps.size.width = props.size.width;
  }
  if (props.size && props.size.height) {
    svgProps.size.height = props.size.height;
  }

  return (
    <span {...spanProps}>
      <SVG {...svgProps} />
      {labelText && <span className={labelClass}>{labelText}</span>}
    </span>
  );
};

IconComponent.defaultProps = {
  labelled: false,
};

export default IconComponent;
