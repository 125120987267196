// @flow
import React from 'react';
import type { Image, Source } from './image.types';

type Props = {
  img: Image,
  sources: Array<Source>,
  fetchPriority: string
};

const Picture = ({ img, sources, fetchPriority }: Props) => (
  <picture className="c-picture">
    {sources.map(source => (
      <source key={source.srcSet} {...source} />
    ))}
    <img fetchpriority={fetchPriority} alt={img.alt} {...img} />
  </picture>
);

export default Picture;
