import React from 'react';

const SvgIcon = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="25" height="25" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#616365" stroke="none">
      <path d="M1090 3549 c0 -728 0 -730 -22 -782 -94 -226 -337 -452 -632 -593
-104 -49 -321 -125 -401 -140 -19 -3 -35 -7 -35 -9 0 -9 201 -1073 205 -1082
3 -10 109 -13 481 -13 l477 0 87 -30 c343 -118 739 -56 1004 155 l70 55 236 0
236 0 70 -55 c265 -211 661 -273 1004 -155 l87 30 477 0 c372 0 478 3 481 12
4 10 205 1074 205 1083 0 2 -16 6 -35 9 -80 15 -297 91 -401 140 -295 141
-538 367 -632 593 -22 52 -22 54 -22 782 l0 731 -105 0 -104 0 -3 -742 c-3
-630 -5 -749 -18 -780 -44 -109 -135 -184 -405 -339 -99 -56 -218 -127 -263
-158 -144 -95 -347 -301 -502 -511 -34 -47 -66 -85 -70 -85 -4 0 -36 38 -70
85 -155 210 -358 416 -502 511 -45 31 -164 102 -263 158 -270 155 -361 230
-405 339 -13 31 -15 150 -18 780 l-3 742 -104 0 -105 0 0 -731z m265 -1149
c44 -32 169 -110 278 -172 109 -62 235 -141 280 -174 126 -95 300 -282 436
-470 l23 -32 -41 -78 c-103 -196 -281 -338 -496 -395 -94 -25 -301 -30 -400
-10 -192 39 -353 139 -471 292 -117 152 -213 429 -237 680 l-4 46 91 64 c111
79 264 230 331 327 l49 73 41 -45 c22 -26 76 -73 120 -106z m2630 65 c67 -95
211 -236 322 -314 l90 -64 -4 -46 c-24 -251 -120 -528 -237 -680 -118 -153
-279 -253 -471 -292 -99 -20 -306 -15 -400 10 -218 58 -393 197 -496 395 l-41
78 23 32 c136 188 310 375 436 470 45 33 171 112 280 174 212 121 354 220 404
284 18 21 35 36 39 31 3 -4 29 -39 55 -78z m-3461 -517 c3 -13 12 -66 22 -118
21 -119 72 -276 124 -387 41 -88 136 -237 170 -268 11 -10 20 -22 20 -27 0 -4
-108 -8 -239 -8 l-239 0 -6 28 c-7 36 -126 673 -129 691 -1 9 26 23 78 41 44
15 100 37 125 48 59 27 69 27 74 0z m4143 1 c26 -12 84 -34 128 -49 52 -18 79
-32 78 -41 -3 -18 -122 -655 -129 -691 l-6 -28 -239 0 c-131 0 -239 4 -239 8
0 5 9 17 20 27 34 31 129 180 170 268 52 111 103 268 124 387 25 138 26 140
35 140 5 0 31 -10 58 -21z m-2065 -579 l28 -50 -70 0 -70 0 28 50 c15 27 34
50 42 50 8 0 27 -23 42 -50z"/>
    </g>
  </svg>
);

export default SvgIcon;
