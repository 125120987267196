// @flow
import {
  RECEIVE_SHOP_REVIEWS,
  SHOP_REVIEWS_REQUEST,
  SHOP_REVIEWS_FAILURE,
  RECEIVE_SHOP_RATING,
} from '../constants/shop-reviews'

import { type Action } from '../actions/shop-reviews';

const initialState = {
  limit: 2,
  reviews: [],
  rating: { total: 0, value: 0 },
  showReadMore: true,
};

type State = {
  limit: number,
  reviews: Review[],
  rating: Rating,
  showReadMore: boolean,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function shopReviews(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case RECEIVE_SHOP_REVIEWS: {
      const reviews = action.payload.addToExisting
        ? [...state.reviews, ...action.payload.reviews]
        : action.payload.reviews;
      const { rating = { total: 0, value: 0 } } = state;
      return {
        ...state,
        reviews,
        rating,
        loading: false,
        showReadMore: reviews.length < (rating.total || 0),
      };
    }
    case SHOP_REVIEWS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case RECEIVE_SHOP_RATING: {
      return {
        ...state,
        rating: action.payload.rating,
      };
    }
    case SHOP_REVIEWS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
