import {
  SUBMIT_FAILURE,
  SUBMIT_REQUEST,
  SUBMIT_SUCCESS,
  PURCHASED_SIZE_SELECTED,
} from '../constants/review';
import { CALL_API } from '../constants/api';

/**
 * @param {string} size
 * @return {{payload: Object, type: String}}
 */
export function purchasedSizeSelected(size) {
  return {
    payload: size,
    type: PURCHASED_SIZE_SELECTED,
  };
}

/**
 * @param {Object} review
 * @return {{payload: Object, type: String}}
 */
export function submitReviewRequest(review) {
  return {
    payload: review,
    type: SUBMIT_REQUEST,
  };
}

/**
 * @param {String} message
 * @return {{payload: String, type: String}}
 */
export function submitReviewSuccess(message) {
  return {
    payload: message,
    type: SUBMIT_SUCCESS,
  };
}

/**
 * @param {String} message
 * @return {{payload: String, type: String}}
 */
export function submitReviewFailure(message) {
  return {
    payload: message,
    type: SUBMIT_FAILURE,
  };
}

/**
 * @param {Object} review
 * @param {String} url
 * @return {{
 *  actions: {
 *    error: submitReviewFailure,
 *    start: submitReviewRequest,
 *    success: submitReviewSuccess
 *  },
 *  params: *,
 *  method: string,
 *  type
 * }}
 */
export function apiSubmitReview(review, url) {
  return {
    actions: {
      error: submitReviewFailure,
      start: submitReviewRequest,
      success: submitReviewSuccess,
    },
    params: review,
    method: 'post',
    type: CALL_API,
    url,
  };
}
