export const RESET_ALL_CHANGES = 'RESET_ALL_CHANGES';
export const RESET_BACK_AND_CUP = 'RESET_BACK_AND_CUP';
export const RESET_CUP = 'RESET_CUP';
export const RESET_SIZE_AND_CACHES = 'RESET_SIZE_AND_CACHES';
export const SET_BACK_SIZE = 'SET_BACK_SIZE';
export const SET_BACK_SIZES = 'SET_BACK_SIZES';
export const SET_BACK_CHANGE = 'SET_BACK_CHANGE';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_CUP_SIZE = 'SET_CUP_SIZE';
export const SET_CUP_SIZES = 'SET_CUP_SIZES';
export const SET_CUP_CHANGE = 'SET_CUP_CHANGE';
export const SET_CACHED_BACK_SIZE = 'SET_CACHED_BACK_SIZE';
export const SET_CACHED_CUP_SIZE = 'SET_CACHED_CUP_SIZE';
export const SET_GO_TO_STAGE_EIGHT = 'SET_GO_TO_STAGE_EIGHT';
export const SET_NEXT_STAGE = 'SET_NEXT_STAGE';
export const SET_PART_2_SELECTED = 'SET_PART_2_SELECTED';
export const SET_PREV_STAGE = 'SET_PREV_STAGE';
export const SET_SELECTED = 'SET_SELECTED';
export const SET_TEMP_BACK_CACHE = 'SET_TEMP_BACK_CACHE';
export const SET_TEMP_CUP_CACHE = 'SET_TEMP_CUP_CACHE';
export const TOGGLE_QUIZ = 'TOGGLE_QUIZ';
export const TOGGLE_PART_TWO = 'TOGGLE_PART_TWO';
