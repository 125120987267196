import React from 'react';

const SvgIcon = () => (
  <svg
    className="c-icon__glyph"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 240 240"
    role="img"
  >
    <title>Add to favourites</title>
    <path
      transform="translate(20,20)"
      strokeWidth="20"
      d="M200 69c0-32-20-56-48-60-20-4-44 8-52 30C92 17 68 5 48 9c-28 4-48 28-48 60 0 56 100 124 100 124s100-68 100-124z"
    />
  </svg>
);

export default SvgIcon;
