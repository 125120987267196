import {
  RECEIVE_PAYMENT_METHODS,
  PAYMENT_METHODS_REQUEST,
  PAYMENT_METHODS_FAILURE,
} from '../../constants/account';

import { LOGOUT_SUCCESS } from '../../constants/auth';

import { ADD_PAYMENT_CARD_SUCCESS } from '../../constants/payment-card-details'; // TODO move

export const initialState = {
  isFetching: false,
  methods: [],
  toFetch: true,
};

/**
 * @param {Array} state
 * @param {Object} action
 * @returns {Array}
 */
export function payment(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_PAYMENT_METHODS:
    case ADD_PAYMENT_CARD_SUCCESS:
      return {
        isFetching: false,
        methods: action.payload,
        toFetch: false,
      };

    case PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
