// @flow
export type SetPoints = 'bravissimo/loyalty/SET_POINTS';
export const SET_POINTS: SetPoints = 'bravissimo/loyalty/SET_POINTS';

export type GetPointsRequest = 'bravissimo/loyalty/GET_POINTS_REQUEST';
export const GET_POINTS_REQUEST: GetPointsRequest =
  'bravissimo/loyalty/GET_POINTS_REQUEST';

export type GetPointsSuccess = 'bravissimo/loyalty/GET_POINTS_SUCCESS';
export const GET_POINTS_SUCCESS: GetPointsSuccess =
  'bravissimo/loyalty/GET_POINTS_SUCCESS';

export type GetPointsFailure = 'bravissimo/loyalty/GET_POINTS_FAILURE';
export const GET_POINTS_FAILURE: GetPointsFailure =
  'bravissimo/loyalty/GET_POINTS_FAILURE';
