// @flow
import React from 'react';
import isFunction from 'lodash/isFunction';
import Link from 'brastrap/containers/link/LinkContainer';
import type { UtilityNavLinks as Props } from './utility-nav.types.js';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-utility-nav__item';

const Item = ({ action, id, label, modifiers, onClick, url }: Props) => {
  const aProps = {};

  if (isFunction(onClick)) {
    aProps.onClick = onClick;
  }

  if (url) {
    aProps.href = url;
  }

  if (action) {
    aProps.action = action;
  }

  return (
    <li
      id={id}
      className={applyModifiers(STYLE_PREFIX, modifiers)}
      role="menuitem"
    >
      <Link className="c-utility-nav__label" {...aProps}>
        {label}
      </Link>
    </li>
  );
};

Item.defaultProps = {
  modifiers: [],
};

export default Item;
