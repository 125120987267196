import { OPEN_DRAWER, CLOSE_DRAWER } from '../../constants/masthead';

import { CLOSE_BACKDROP } from '../../constants/backdrop';

export const initialState = {
  navigation: {
    isVisible: false,
  },
  bag: {
    isVisible: false,
  },
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function drawer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_DRAWER: {
      const drawerName = action.payload;

      return {
        ...state,
        [drawerName]: {
          isVisible: true,
        },
      };
    }

    case CLOSE_DRAWER: {
      const drawerName = action.payload;
      const newState = { ...state };

      newState[drawerName] = {
        isVisible: false,
      };

      return newState;
    }

    case CLOSE_BACKDROP: {
      const newState = { ...state };
      Object.keys(newState).forEach(key => {
        newState[key].isVisible = false;
      });
      return newState;
    }

    default:
      return state;
  }
}
