import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
    aria-labelledby="title"
  >
    <title>YouTube</title>
    <path d="M190 40c6 7 10 20 10 60s-4 53-10 60c-10 10-32 10-90 10s-80 0-90-10c-6-7-10-20-10-60s4-53 10-60c10-10 32-10 90-10 58 0 80 0 90 10zM80 72v56l52-28-52-28z" />
  </svg>
);

export default SvgIcon;
