import {
  OPEN_STOCK_FINDER,
  CLOSE_STOCK_FINDER,
  FIND_STOCK_REQUEST,
  FIND_STOCK_SUCCESS,
  FIND_STOCK_FAILURE,
} from '../constants/stock-finder.js';
import { FIND_SHOPS_SUCCESS } from '../constants/shops';
import { PRODUCT_SELECT_SKU } from '../constants/product';

const initialState = {
  errorMessage: null,
  isFetching: false,
  isVisible: false,
  results: [],
};

/**
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export function stockFinder(state = initialState, { payload, type }) {
  switch (type) {
    case OPEN_STOCK_FINDER:
      return {
        ...state,
        isVisible: true,
      };

    case CLOSE_STOCK_FINDER:
      return {
        ...state,
        ...initialState,
      };

    case FIND_STOCK_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isFetching: true,
        results: [],
      };

    case FIND_STOCK_FAILURE:
      return {
        ...state,
        errorMessage: payload,
        isFetching: false,
      };

    case FIND_STOCK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: payload,
      };

    case FIND_SHOPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case PRODUCT_SELECT_SKU:
      return {
        ...state,
        results: [], // Previous results are invalid now we've changed sku.
      };

    default:
      return state;
  }
}
