// @flow
type AddPaymentCardRequest =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_REQUEST';
type AddPaymentCardFailure =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_FAILURE';
type AddPaymentCardSuccess =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_SUCCESS';
type AddPaymentCardCloseMessages =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_CLOSE_MESSAGES';
type AddPaymentCardFormOpen =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_FORM_OPEN';
type AddPaymentCardFormClosed =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_FORM_CLOSED';
type PaymentSessionScriptUrl =
  'bravissimo/payment-card-details/PAYMENT_SESSION_SCRIPT_URL';
type SetPaymentProviderIssue =
  'bravissimo/payment-card-details/SET_PAYMENT_PROVIDER_ISSUE';
type SetPaymentOption = 'bravissimo/payment-card-details/SET_PAYMENT_OPTION';
type PaypalClientTokenRequest =
  'bravissimo/payment-card-details/PAYPAL_CLIENT_TOKEN_REQUEST';
type PaypalClientTokenSuccess =
  'bravissimo/payment-card-details/PAYPAL_CLIENT_TOKEN_SUCCESS';
type PaypalClientTokenFailure =
  'bravissimo/payment-card-details/PAYPAL_CLIENT_TOKEN_FAILURE';

export const ADD_PAYMENT_CARD_REQUEST: AddPaymentCardRequest =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_REQUEST';
export const ADD_PAYMENT_CARD_FAILURE: AddPaymentCardFailure =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_FAILURE';
export const ADD_PAYMENT_CARD_SUCCESS: AddPaymentCardSuccess =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_SUCCESS';
export const ADD_PAYMENT_CARD_CLOSE_MESSAGES: AddPaymentCardCloseMessages =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_CLOSE_MESSAGES';
export const ADD_PAYMENT_CARD_FORM_OPEN: AddPaymentCardFormOpen =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_FORM_OPEN';
export const ADD_PAYMENT_CARD_FORM_CLOSED: AddPaymentCardFormClosed =
  'bravissimo/payment-card-details/ADD_PAYMENT_CARD_FORM_CLOSED';
export const PAYMENT_SESSION_SCRIPT_URL: PaymentSessionScriptUrl =
  'bravissimo/payment-card-details/PAYMENT_SESSION_SCRIPT_URL';
export const SET_PAYMENT_PROVIDER_ISSUE: SetPaymentProviderIssue =
  'bravissimo/payment-card-details/SET_PAYMENT_PROVIDER_ISSUE';
export const SET_PAYMENT_OPTION: SetPaymentOption =
  'bravissimo/payment-card-details/SET_PAYMENT_OPTION';
export const PAYPAL_CLIENT_TOKEN_REQUEST: PaypalClientTokenRequest =
  'bravissimo/payment-card-details/PAYPAL_CLIENT_TOKEN_REQUEST';
export const PAYPAL_CLIENT_TOKEN_SUCCESS: PaypalClientTokenSuccess =
  'bravissimo/payment-card-details/PAYPAL_CLIENT_TOKEN_SUCCESS';
export const PAYPAL_CLIENT_TOKEN_FAILURE: PaypalClientTokenFailure =
  'bravissimo/payment-card-details/PAYPAL_CLIENT_TOKEN_FAILURE';
