// @flow
import React from 'react';
import Image from 'brastrap/editorial/image/Image';
import Markdown from 'brastrap/editorial/markdown/Markdown';
import StyledText from 'brastrap/editorial/styled-text/StyledText';
import StyledTitleBlock from 'brastrap/editorial/styled-title-block/StyledTitleBlock';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import { applyModifiers } from '../../utils';
import type { Banner as Props } from './banner.types';

export const DEFAULT_BACKGROUND_COLOUR = '#f9f6f6';
export const DEFAULT_CHARACTERS_TO_SHOW = 33;

const STYLE_PREFIX = 'c-banner';

/**
 * @param {Object} props
 * @returns {Array}
 */
const getText = ({ isMobile, text }: Props): Array<StyledTextType> =>
  text && ((isMobile && text.mobile) || text.desktop || []);

const Banner = (props: Props) => {
  const {
    actions,
    backgroundColour,
    contentBlock,
    image,
    isDesktop,
    isMobile,
    modifiers,
  } = props;
  const className = applyModifiers(STYLE_PREFIX, modifiers);
  const text = getText(props);
  const backgroundStyle = backgroundColour
    ? { backgroundColor: backgroundColour }
    : {};
  const [headerText, ...bodyTexts] = text || [];
  const displayImage = image && image.src && image.width && isDesktop;

  const fadeStyle = {
    'background-image': `linear-gradient(to right, rgba(255, 255, 255, 0), ${backgroundColour} 85%)`,
  };

  let contentComponent;

  if (contentBlock) {
    const markDownProps = {
      ...contentBlock,
      isMobile,
    };

    if (!isMobile) {
      // Only show the read more if on a mobile device.
      // Read more will only be shown if charactersToShow is present.
      delete markDownProps.charactersToShow;
    }
    contentComponent = <Markdown {...markDownProps} />;
  }

  return (
    <div className={className} style={backgroundStyle}>
      {displayImage && (
        <div
          className="c-banner__image"
          style={{ width: image && image.width }}
        >
          <Image {...image} />
        </div>
      )}
      <div
        className="c-banner__text"
        style={displayImage ? { marginLeft: image && image.width } : {}}
      >
        {headerText && (
          <StyledTitleBlock
            key={`bannerText-${headerText.id}`}
            title={[headerText]}
          >
            {(bodyTexts || []).map(item => (
              <StyledText key={`bannerText-${item.id}`} {...item} />
            ))}
          </StyledTitleBlock>
        )}
        {contentComponent}
        {actions && (
          <div className="c-banner__actions-container">
            <div className="c-banner__actions">
              {actions.map(action => (
                <ButtonLink key={action.id} {...action} />
              ))}
            </div>
            {!isDesktop && <div className="c-banner__fade" style={fadeStyle} />}
          </div>
        )}
      </div>
    </div>
  );
};

Banner.defaultProps = {
  backgroundColour: 'transparent',
};

export default Banner;
