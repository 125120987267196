import {
  CONTACT_REQUEST,
  CONTACT_REQUEST_SUCCEEDED,
  CONTACT_REQUEST_FAILED,
  RECEIVE_ENQUIRY_TEMPLATES,
  SELECT_ENQUIRY_TEMPLATE,
} from '../constants/contact-request';

export const initialState = {
  isSending: false,
  isSent: false,
  enquiryTemplates: [],
  enquiryTemplate: null,
  failed: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} state
 */
export function contactRequest(state = initialState, action = {}) {
  switch (action.type) {
    case CONTACT_REQUEST:
      return {
        ...state,

        isSending: true,
        failed: false,
      };

    case CONTACT_REQUEST_SUCCEEDED:
      return {
        ...state,

        isSending: false,
        isSent: true,
        confirmed: true,
        failed: false,
      };

    case CONTACT_REQUEST_FAILED:
      return {
        ...state,

        isSaving: false,
        failed: true,
      };

    case RECEIVE_ENQUIRY_TEMPLATES:
      return {
        ...state,
        enquiryTemplates: action.payload,
      };

    case SELECT_ENQUIRY_TEMPLATE:
      return {
        ...state,
        enquiryTemplate: state.enquiryTemplates.find(
          template => template.subject === action.payload
        ),
      };

    default:
      return state;
  }
}
