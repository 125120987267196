import {
  ACCOUNT_SIGNUP,
  CLOSE_MESSAGES,
  SIGN_UP_FAILED,
} from 'shared/constants/account';

export const initialState = {
  isSaving: false,
  errorMessage: null,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} state
 */
export function accountSignUpForm(state = initialState, action = {}) {
  switch (action.type) {
    case ACCOUNT_SIGNUP:
      return {
        ...state,

        isSaving: true,
        errorMessage: null,
      };

    case SIGN_UP_FAILED:
      return {
        ...state,

        isSaving: false,
        errorMessage: action.payload.message,
      };

    case CLOSE_MESSAGES:
      return {
        ...state,

        errorMessage: null,
      };

    default:
      return state;
  }
}
