// @flow
import React, { type Node } from 'react';

type Props = {
  aspectRatio: number,
  children: Node,
};

/**
 * @param {number} aspectRatio - Should be the Width / Height
 * @returns {string} aspectRatio as a percentage for applying to padding-top.
 */
const applyAspectRatio = (aspectRatio: number): string =>
  `${(1 / aspectRatio) * 100}%`;

/*
 * This must be a class because it is used inside of a css TransitionGroup,
 * which applies a ref to it, and refs can only be used with classes.
 */
// eslint-disable-next-line react/prefer-stateless-function
class RatioBox extends React.Component<Props> {
  /**
   * @returns {JSX}
   */
  render() {
    const { aspectRatio, children } = this.props;
    return (
      <div className="c-ratio-box">
        <div
          className="c-ratio-box__before"
          style={{ paddingTop: applyAspectRatio(aspectRatio) }}
        />
        <div className="c-ratio-box__content">{children}</div>
      </div>
    );
  }
}

export default RatioBox;
