// @flow
import React from 'react';
import Embed from 'brastrap/editorial/embed/Embed';
import Image from 'brastrap/editorial/image/Image';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import type { Media as Props } from './media.types.js';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-media';

const Media = (props: Props) => {
  const background = props.backgroundColour
    ? { backgroundColor: props.backgroundColour }
    : {};
  const modifiers = props.embed ? ['no-background'] : [];
  let mediaItemClasse = 'c-media__image';
  if (props.mediaPosition) {
    mediaItemClasse += ` ${mediaItemClasse}--${props.mediaPosition}`;
  }
  return (
    <div className={applyModifiers(STYLE_PREFIX, modifiers)} style={background}>
      {props.image && (
        <div className={mediaItemClasse}>
          <Image {...props.image} />
        </div>
      )}
      {props.embed && (
        <div className="c-media__embed">
          <Embed {...props.embed} />
        </div>
      )}
      {props.content && (
        <div className="c-media__content-container">
          <div className="c-media__content">
            <div className="s-prose">
              <MarkDown content={props.content} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Media;
