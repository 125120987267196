import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 200 200"
  >
    <path d="M0 71c0-4 3-9 4-11l25-33c4-6 9.6-11 17-11h91c4 0 7 3 7 7v102c0 5-3 11-6 14L85 194c-2 2-4 3-8 2 0 0-9-2-13-3-4-1-5-4-5-9s15-42 15-42c1-4-2-8-6-8H18c-10 0-18-8-18-18V71zM200 120c0 9-7 16-16 16h-8c-9 0-16-7-16-16V32c0-9 7-16 16-16h8c9 0 16 7 16 16v88z" />
  </svg>
);

export default SvgIcon;
