import _ from 'lodash';
import axios from 'axios';
import uuid from 'uuid';
import crypto from 'crypto';
import { filestackServer } from 'config';

const USA_COUNTRY_CODE = 185;

/**
 * Upsert (insert or update) an object in an array
 *
 * @param {Array} array - array to modify
 * @param {Object} newObject - object to upsert
 * @param {String} key - object key to match on
 * @return {Array} updated array
 */
export function upsertObjectToArray(array, newObject, key) {
  const updatedArray = [...array];
  const existingItemIndex = _.findIndex(updatedArray, [key, newObject[key]]);
  if (existingItemIndex < 0) {
    updatedArray.push(newObject);
  } else {
    updatedArray.splice(existingItemIndex, 1, newObject);
  }
  return updatedArray;
}

/**
 * Remove object from an array by key prop
 *
 * @param {Array} array - array to modify
 * @param {Object} objectToRemove - object to remove
 * @param {String} key - object key to match on
 * @return {Array} updated array
 */
export function removeObjectFromArray(array, objectToRemove, key) {
  const updatedArray = [...array];
  const existingItemIndex = _.findIndex(updatedArray, [
    key,
    objectToRemove[key],
  ]);
  if (existingItemIndex >= 0) {
    updatedArray.splice(existingItemIndex, 1);
  }
  return updatedArray;
}

/**
 * Sets a value into session storage, if it is available in the current browser
 * @param {string} key
 * @param {*} value
 */
export function addToSessionStorage(key, value) {
  if (
    typeof window !== 'undefined' &&
    window.sessionStorage &&
    window.sessionStorage.setItem
  ) {
    window.sessionStorage.setItem(key, value);
  }
}

/**
 * Returns a key from session storage, if it exists
 * @param {string} key
 * @return{*} - the value, or null
 */
export function getFromSessionStorage(key) {
  if (
    typeof window !== 'undefined' &&
    window.sessionStorage &&
    window.sessionStorage.getItem
  ) {
    return window.sessionStorage.getItem(key);
  }
  return null;
}

/**
 * @param {string} key
 */
export function clearFromSessionStorage(key) {
  if (
    typeof window !== 'undefined' &&
    window.sessionStorage &&
    window.sessionStorage.removeItem
  ) {
    window.sessionStorage.removeItem(key);
  }
}

/**
 * Formats the address by joining its fields with newlines.
 *
 * @param {Object} address the object to format
 * @param {Object} state state object to access country data
 * @param {boolean} includeAddressee whether to include the addreee. Defaults to true
 * @returns {null|string} null if no address is supplied, otherwise a string
 */
export function getFormattedAddress(
  address,
  state = null,
  includeAddressee = true
) {
  if (!address) {
    return null;
  }

  let countryName;
  if (address.countryId && !address.country && state) {
    countryName = state.countries.data.find(
      c => c.id.toString() === address.countryId.toString()
    ).name;
  }

  let town;
  if (parseInt(address.countryId, 10) === USA_COUNTRY_CODE && address.region) {
    town = `${address.town}, ${address.region}`;
  } else {
    town = address.town;
  }

  return _.compact([
    includeAddressee ? address.addressee : null,
    address.houseName,
    address.address1,
    address.address2,
    address.address3,
    town,
    address.postCode,
    address.country || countryName,
  ]).join('\n');
}

/**
 * @param {object} fields
 * @returns {object}
 */
export function stripWhiteSpaceFromFields(fields) {
  const formattedFields = { ...fields };
  Object.keys(formattedFields).forEach(field => {
    if (typeof formattedFields[field] === 'string') {
      formattedFields[field] = formattedFields[field].trim();
    }
    if (_.isObject(formattedFields[field])) {
      formattedFields[field] = stripWhiteSpaceFromFields(
        formattedFields[field]
      );
    }
  });
  return formattedFields;
}

/**
 * @param {string | object} sku
 * @param {object} product
 * @returns {string | object}
 */
export function getSizeCodeFromSku(sku, product) {
  const {
    data: { sizes },
  } = product;

  const code =
    typeof sku === 'string' ? sku.slice(-4, 0) : sku.code.slice(0, 4);
  const metricOne = code.slice(0, 2);
  const metricTwo = code.slice(2, 4);

  // Match metricOne to the available product levelOne variants
  const levelOne = sizes.variants.filter(o => metricOne === o.value);
  if (levelOne.length && sizes.value !== 'size') {
    // Match cup size to the available product cup size variants
    let levelTwo = levelOne[0].sizes.variants.filter(
      t => metricTwo === t.value
    );

    if (!levelTwo.length) {
      levelTwo = levelOne[0].sizes.variants.filter(t => code === t.code);
    }

    if (levelTwo) {
      return levelTwo[0];
    }
  } else {
    return levelOne[0];
  }

  return null;
}

/**
 * Translates country isoCode from state to country data
 *
 * @param {object} state
 * @return {number} the country's numeric code
 */
export function getDefaultCountry(state) {
  const currentOutletIsoCode = state.app.config.outlet;
  const countries = state.countries.data;
  return countries.find(c => c.isoCode === currentOutletIsoCode);
}

/**
 * Format the countries into a simple value/label data structure formatted for select components.
 *
 * @param {Array} countries
 * @returns {Array}
 */
export const formatCountriesForSelect = countries =>
  countries.map(country => ({
    value: country.id,
    label: country.name,
    isoCode: country.isoCode,
  }));

/**
 * Sends runtime error reports to api
 * @param {Object} fullState
 * @param {Object} error
 * @param {String} sender
 */
export function sendErrorMessage(fullState, error, sender) {
  const state = _.cloneDeep(fullState);
  delete state.app.locale.translations;
  const errorMetaData = {
    errorId: uuid.v4().toString().toUpperCase(),
    timeStamp: new Date(),
  };
  try {
    axios.post('/api/error', {
      state,
      error: error.toString(),
      stack: error.stack,
      errorMetaData,
      sender,
    });
  } catch (err) {
    console.log(err);
  }
}

/**
 * Generates object with base64 encoded JSON object
 * and HMAC encrypted string
 * @param {String} jsonInput
 * @returns {Object}
 */
export function generateFilestackSecurity(jsonInput) {
  const jsonBuffer = Buffer.from(JSON.stringify(jsonInput), 'utf8');
  // Replace + with - and / with _
  const policy = jsonBuffer
    .toString('base64')
    .replace('+', '-')
    .replace('/', '_');
  const { secretKey } = filestackServer;

  const hmac = crypto.createHmac('sha256', secretKey);

  hmac.update(policy);
  return {
    policy,
    signature: hmac.digest('hex'),
  };
}

/**
 * Returns true or false based on display configuration for editorial properties
 * hideAtMobile, hideAtTablet, hideAtDesktop
 * @param {Object} deviceIs
 * @param {Object} editorialDisplayProps
 * @returns {boolean}
 */
export function shouldHideEditorialContent(deviceIs, editorialDisplayProps) {
  const { hideAtMobile, hideAtTablet, hideAtDesktop } = editorialDisplayProps;
  const hasHideFlag = hideAtMobile || hideAtTablet || hideAtDesktop;

  if (hasHideFlag && deviceIs) {
    switch (true) {
      case hideAtDesktop && deviceIs.desktop:
      case hideAtTablet && deviceIs.tablet:
      case hideAtMobile && deviceIs.mobile:
        return true;
      default:
        return false;
    }
  }

  return false;
}

/**
 * Sort settings by order number
 * @param {object} settings
 * @returns {object}
 */
export function getSettingsSortByOrderNumber(settings) {
  return Object.keys(settings)
    .sort(
      (setting1, setting2) =>
        settings[setting1].orderNumber - settings[setting2].orderNumber
    )
    .reduce(
      (acc, setting) => ({
        ...acc,
        [setting]: settings[setting],
      }),
      {}
    );
}

/**
 * Determines if the checkout button should be disabled
 *
 * @param {object} state
 * @return {boolean}
 */
export const disableCheckoutButton = state =>
  !state.auth.isAuthenticated &&
  (!state.gdpr.isReady ||
    (state.gdpr.consentMethod === 'terms' && !state.gdpr.terms.acceptance));

/**
 * Create an expiry date
 *
 * @param {Number} days
 * @returns {Date}
 */
export const getExpirationDate = days => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  return expirationDate;
};
/**
 * Hash data
 * @param {string} details
 * @returns {string} hashed data
 */
export function hashData(details) {
  // eslint-disable-next-line global-require
  return require('crypto').createHash('sha256').update(details).digest('hex');
}

/**
 * checks if a cookie exists
 *
 * @param {string} cookieName
 * @returns {boolean}
 */
export function doesCookieExist(cookieName) {
  if (typeof window !== 'undefined') {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
      const cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return true;
      }
    }
    return false;
  }
  return false;
}
