import generateAsyncRouteComponent from '../generateAsyncRouteComponent';

export const HOMEPAGE_LIVECHAT_ROUTE = 'live-chat';

export default [
  {
    path: '',
    exact: true,
    component: generateAsyncRouteComponent({
      loader: () => import('./containers/EditorialContentPageContainer'),
    }),
  },
  {
    path: HOMEPAGE_LIVECHAT_ROUTE,
    component: generateAsyncRouteComponent({
      loader: () => import('./containers/EditorialContentPageContainer'),
    }),
  },
];
