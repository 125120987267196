import {
  RECEIVE_NAV_ITEMS,
  SET_ITEM_VISIBILITY,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAILURE,
  PRODUCT_SUGGESTED_SEARCH_REQUEST,
  PRODUCT_SUGGESTED_SEARCH_SUCCESS,
  PRODUCT_SUGGESTED_SEARCH_FAILURE,
  PRODUCT_SEARCH_OPEN,
  PRODUCT_SEARCH_CLOSE,
  CLEAR_SEARCH_REQUEST,
  CLEAR_SEARCH_SUCCESS,
  CLEAR_SEARCH_FAILURE,
} from '../../constants/masthead';

import { CALL_API } from '../../constants/api';

import { push } from '../../actions/app/routing';

import { receiveProductListing } from '../product-listing';
import { redirect } from '../redirect';

/**
 * @returns {{type: string}}
 */
export function searchOpen() {
  return {
    type: PRODUCT_SEARCH_OPEN,
  };
}

/**
 * @returns {{type: string}}
 */
export function searchClose() {
  return {
    type: PRODUCT_SEARCH_CLOSE,
  };
}

/**
 * @param {array} items
 * @returns {{type: string, payload: array}}
 */
export function receiveItems(items) {
  return {
    type: RECEIVE_NAV_ITEMS,
    payload: items,
  };
}

/**
 * @param {*} key
 * @returns {{type: string, payload: number}}
 */
export function setItemVisibility(key) {
  return {
    type: SET_ITEM_VISIBILITY,
    payload: key,
  };
}

/**
 * @returns {{type: string}}
 */
export function searchRequest() {
  return {
    type: PRODUCT_SEARCH_REQUEST,
  };
}

/**
 * @param {Object} searchData - payload containing search suggestions and recent searches.
 * @returns {{type: string, payload: {suggestions: array, recentSearches: array}}}
 */
export function searchSuccess(searchData) {
  return {
    type: PRODUCT_SEARCH_SUCCESS,
    payload: searchData,
  };
}

/**
 * @param {string} errorMessage
 * @returns {{type: string, payload: string, error: boolean}}
 */
export function searchFailure(errorMessage) {
  return {
    type: PRODUCT_SEARCH_FAILURE,
    payload: errorMessage,
    error: true,
  };
}

/**
 * @returns {{type}}
 */
export function clearSearchRequest() {
  return {
    type: CLEAR_SEARCH_REQUEST,
  };
}

/**
 * @param {array} recentSearches
 * @returns {{type, payload: *}}
 */
export function clearSearchSuccess(recentSearches) {
  return {
    type: CLEAR_SEARCH_SUCCESS,
    payload: recentSearches,
  };
}

/**
 * @param {string} error
 * @returns {{type, payload: *, error: boolean}}
 */
export function clearSearchFailure(error) {
  return {
    type: CLEAR_SEARCH_FAILURE,
    payload: error,
    error: true,
  };
}

/**
 * @param {Object} searchData - payload containing search results
 * @param {string} fallbackResultsPath - page to redirect to
 * @returns {{type: string, payload: {results: array}}}
 */
export function productSearchSuccess(searchData, fallbackResultsPath) {
  return (dispatch, getState) => {
    const { basePath } = getState().app.config;
    const { exactMatch, resultsPath } = searchData;
    if (resultsPath) {
      dispatch(redirect(exactMatch ? `${resultsPath.slice(1)}` : resultsPath));
    } else {
      const path = resultsPath
        ? basePath + resultsPath.slice(1)
        : fallbackResultsPath;
      dispatch(receiveProductListing(searchData))
        .then(() => dispatch(push(path)))
        .then(() => dispatch(searchSuccess(searchData)))
        .then(() => dispatch(searchClose()));
    }
  };
}

/**
 * @param {string} searchPhrase
 * @param {string} resultsPath
 * @returns {{
 * actions: {
 *  error: searchFailure,
 *  start: searchRequest,
 *  success: searchSuccess
 * },
 * type,
 * url: string
 * }}
 */
export function apiProductSearch(searchPhrase, resultsPath) {
  return {
    actions: {
      error: searchFailure,
      start: searchRequest,
      success: searchData => productSearchSuccess(searchData, resultsPath),
    },
    type: CALL_API,
    url: `search/${searchPhrase}`,
  };
}

/**
 * @returns {{type: string}}
 */
export function suggestedSearchRequest() {
  return {
    type: PRODUCT_SUGGESTED_SEARCH_REQUEST,
  };
}

/**
 * @param {Object} searchData - payload containing search suggestions and recent searches.
 * @returns {{type: string, payload: {suggestions: array, recentSearches: array}}}
 */
export function suggestedSearchSuccess(searchData) {
  return {
    type: PRODUCT_SUGGESTED_SEARCH_SUCCESS,
    payload: searchData,
  };
}

/**
 * @param {string} errorMessage
 * @returns {{type: string, payload: string, error: boolean}}
 */
export function suggestedSearchFailure(errorMessage) {
  return {
    type: PRODUCT_SUGGESTED_SEARCH_FAILURE,
    payload: errorMessage,
    error: true,
  };
}

/**
 * @param {string} searchPhrase
 * @returns {{
 * actions: {
 *  error: searchFailure,
 *  start: searchRequest,
 *  success: searchSuccess
 * },
 * type,
 * url: string
 * }}
 */
export function apiSuggestedSearch(searchPhrase) {
  return {
    actions: {
      error: suggestedSearchFailure(),
      start: suggestedSearchRequest(),
      success: searchData => suggestedSearchSuccess(searchData),
    },
    params: {
      suggested: true,
    },
    type: CALL_API,
    url: `search/${searchPhrase}`,
  };
}

/**
 * @returns {{
 * actions: {
 *  error: clearSearchFailure,
 *  start: clearSearchRequest,
 *  success: clearSearchSuccess
 * },
 * method: string,
 * type,
 * url: string
 * }}
 */
export function apiClearSearchHistory() {
  return {
    actions: {
      error: clearSearchFailure,
      start: clearSearchRequest,
      success: clearSearchSuccess,
    },
    method: 'put',
    type: CALL_API,
    url: 'search/clear',
  };
}
