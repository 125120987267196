import PropTypes from 'prop-types';
// @flow
import React from 'react';
import Container from 'brastrap/common/container/Container';
import Grid from 'brastrap/helpers/l-grid/l-grid';

import EnhancedFacet from './ResultsFacetsEnhanced';

import type { Facet } from './facet-types';

type Props = {
  onChange: (boolean, string, string) => void,
  facets: Facet[],
};

const FacetContainer = ({ facets, onChange }: Props) => (
  <Container modifiers="facet-container">
    <Grid>
      {facets.map((facet, index) => (
        <Grid.Unit key={index}>
          <EnhancedFacet facet={facet} onChange={onChange} />
        </Grid.Unit>
      ))}
    </Grid>
  </Container>
);

FacetContainer.propTypes = {
  facets: PropTypes.array,
  onChange: PropTypes.func,
};

FacetContainer.defaultProps = {
  facets: [],
};

export default FacetContainer;
