// @flow
import type { Dispatch } from 'redux';

import { CALL_API } from '../constants/api';
import {
  ADD_PAYMENT_CARD_SUCCESS,
  ADD_PAYMENT_CARD_REQUEST,
  ADD_PAYMENT_CARD_FAILURE,
  ADD_PAYMENT_CARD_CLOSE_MESSAGES,
  ADD_PAYMENT_CARD_FORM_OPEN,
  ADD_PAYMENT_CARD_FORM_CLOSED,
  PAYMENT_SESSION_SCRIPT_URL,
  SET_PAYMENT_PROVIDER_ISSUE,
  SET_PAYMENT_OPTION,
  PAYPAL_CLIENT_TOKEN_REQUEST,
  PAYPAL_CLIENT_TOKEN_FAILURE,
  PAYPAL_CLIENT_TOKEN_SUCCESS,
} from '../constants/payment-card-details';

import { apiAddPayment } from '../actions/bag';
import { clearAllMessages } from './messages';

type AuthData = {
  card: Token,
};

export type ErrorPayload = {
  message: string,
};

type AddPaymentCardRequest = {
  type: typeof ADD_PAYMENT_CARD_REQUEST,
  payload: {
    details: TNSResponse,
  },
};

type AddPaymentCardSuccess = {
  type: typeof ADD_PAYMENT_CARD_SUCCESS,
  payload: AuthData,
};

type AddPaymentCardFailure = {
  type: typeof ADD_PAYMENT_CARD_FAILURE,
  payload: ErrorPayload,
  error: true,
};

type CloseMessages = {
  type: typeof ADD_PAYMENT_CARD_CLOSE_MESSAGES,
};

type ReceiverPaymentSessionScriptUrl = {
  type: typeof PAYMENT_SESSION_SCRIPT_URL,
  payload: Url,
};

type SetPaymentProviderIssue = {
  type: typeof SET_PAYMENT_PROVIDER_ISSUE,
  payload: {
    paymentProviderIssue: boolean,
  },
};

type SetPaymentOption = {
  type: typeof SET_PAYMENT_OPTION,
  payload: {
    selectedPaymentOption: string,
  },
};

type PaypalClientTokenRequest = {
  type: typeof PAYPAL_CLIENT_TOKEN_REQUEST,
};

type PaypalClientTokenFailure = {
  type: typeof PAYPAL_CLIENT_TOKEN_FAILURE,
};

type PaypalClientTokenSuccess = {
  type: typeof PAYPAL_CLIENT_TOKEN_SUCCESS,
  payload: {
    paypalClientToken: string,
  },
};

export type Actions =
  | AddPaymentCardRequest
  | AddPaymentCardSuccess
  | AddPaymentCardFailure
  | CloseMessages
  | ReceiverPaymentSessionScriptUrl
  | SetPaymentProviderIssue
  | SetPaymentOption
  | PaypalClientTokenSuccess
  | PaypalClientTokenFailure;

/**
 * @param {string} url
 * @returns {{type, payload: string}}
 */
export function receivePaymentSessionScriptUrl(
  url: Url
): ReceiverPaymentSessionScriptUrl {
  return {
    type: PAYMENT_SESSION_SCRIPT_URL,
    payload: url,
  };
}

/**
 * @param {Boolean} paymentProviderIssue
 * @returns {{type, payload: {paymentProviderIssue: Boolean}}}
 */
export function setPaymentProviderIssue(
  paymentProviderIssue: boolean
): SetPaymentProviderIssue {
  return {
    type: SET_PAYMENT_PROVIDER_ISSUE,
    payload: { paymentProviderIssue },
  };
}

/**
 * @param {object} authData authenticated data returned by api/customer.
 * @returns {{type, message}}
 */
export function addPaymentCardSuccess(
  authData: AuthData
): AddPaymentCardSuccess {
  return {
    type: ADD_PAYMENT_CARD_SUCCESS,
    payload: {
      card: authData.card,
    },
  };
}

// thunk to close modal on success
// Need to update the form state to success
// And then add the selected payment to the bag
const closeLayerOnPaymentCardSuccess = (authData: AuthData) => (
  dispatch: Dispatch<*>
): void => {
  // Store card in rethink
  dispatch(addPaymentCardSuccess(authData));
  dispatch(apiAddPayment(authData.card, 'card'));
  dispatch(close());
};

/**
 * @param {object} details user details
 * @returns {{type, payload: {code: string}}}
 */
export function addPaymentCardRequest(
  details: TNSResponse
): AddPaymentCardRequest {
  return {
    type: ADD_PAYMENT_CARD_REQUEST,
    payload: {
      details,
    },
  };
}

/**
 * @param {Object} payload
 * @returns {{type, payload: {message: string}}}
 */
export function addPaymentCardFailure(
  payload: ErrorPayload
): AddPaymentCardFailure {
  return {
    type: ADD_PAYMENT_CARD_FAILURE,
    error: true,
    payload,
  };
}

/**
 * @param {string} details - Payment card session details
 * @returns {Object}
 */
export function apiGetCardWithToken(details: TNSResponse) {
  return {
    actions: {
      error: addPaymentCardFailure,
      start: addPaymentCardRequest,
      success: closeLayerOnPaymentCardSuccess,
    },
    method: 'post',
    params: details,
    type: CALL_API,
    url: 'account/getCardWithToken',
  };
}

/**
 * @param {string} paypalClientToken user details
 * @returns {object}
 */
export function paypalClientTokenSuccess(
  paypalClientToken: string
): PaypalClientTokenSuccess {
  return {
    type: PAYPAL_CLIENT_TOKEN_SUCCESS,
    payload: {
      paypalClientToken,
    },
  };
}

/**
 * @returns {object}
 */
export function paypalClientTokenRequest(): PaypalClientTokenRequest {
  return {
    type: PAYPAL_CLIENT_TOKEN_REQUEST,
  };
}

/**
 * @param {Object} payload
 * @returns {object}
 */
export function paypalClientTokenFailure(
  payload: ErrorPayload
): PaypalClientTokenFailure {
  return {
    type: PAYPAL_CLIENT_TOKEN_FAILURE,
    error: true,
    payload,
  };
}

/**
 * @param {string} details - Payment card session details
 * @returns {Object}
 */
export function apiGetPaypalClientToken() {
  return {
    actions: {
      error: paypalClientTokenFailure,
      start: paypalClientTokenRequest,
      success: paypalClientTokenSuccess,
    },
    method: 'get',
    type: CALL_API,
    url: 'payment/braintree/token',
  };
}

/**
 * @returns {{type, payload: number}}
 */
export function closeMessages(): CloseMessages {
  return {
    type: ADD_PAYMENT_CARD_CLOSE_MESSAGES,
  };
}

/**
 * @returns {{type}}
 */
export function open() {
  return {
    type: ADD_PAYMENT_CARD_FORM_OPEN,
  };
}

/**
 * @returns {{type}}
 */
export function close() {
  return {
    type: ADD_PAYMENT_CARD_FORM_CLOSED,
  };
}

/**
 * @param {string} selectedPaymentOption
 * @return {{type, payload}}
 */
export const setPaymentOption = (selectedPaymentOption: string) => (
  dispatch: Dispatch<*>
): void => {
  dispatch(apiAddPayment(null, selectedPaymentOption));
  dispatch(clearAllMessages());
  dispatch({
    type: SET_PAYMENT_OPTION,
    payload: { selectedPaymentOption },
  });
};
