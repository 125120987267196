import generateAsyncRouteComponent from '../generateAsyncRouteComponent';
import ReturnsConfirmation from './routes/ReturnConfirmation';
import StartReturn from './routes/StartReturn';

export default {
  path: 'returns',

  routes: [StartReturn, ReturnsConfirmation],

  component: generateAsyncRouteComponent({
    loader: () => import('./containers/ReturnsPage'),
  }),
};
