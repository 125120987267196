import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
  >
    <path d="M0 0h200v56H0V0zm0 72h200v56H0V72zm0 72h200v56H0v-56z" />
  </svg>
);

export default SvgIcon;
