import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import Action from 'brastrap/common/action/Action';
import Markdown from 'brastrap/editorial/markdown/Markdown';

const STYLE_PREFIX = 'c-message';

const messages = defineMessages({
  close: { id: 'message.close', defaultMessage: 'Close' },
  errorTitle: { id: 'title.error', defaultMessage: 'Error' },
});

const Message = props => {
  let className = STYLE_PREFIX;

  props.modifiers.forEach(modifier => {
    className += ` ${STYLE_PREFIX}--${modifier}`;
  });

  if (props.popover) {
    className += ` c-popover c-popover--${props.popover}`;
  }

  let { title } = props;
  if (props.type === 'error' && !props.title) {
    title = props.intl.formatMessage(messages.errorTitle);
  }

  const closeText = props.intl.formatMessage(messages.close);

  return (
    <aside className={className} role="note">
      {
        /* CSS floats this to the right, so it must come first. */
        props.dismissable && (
          <Action
            onClick={props.onClose}
            modifiers={['dismiss']}
            icon={{ icon: 'dismiss', label: closeText, name: closeText }}
          />
        )
      }
      {title && <h1 className="c-message__title">{title}</h1>}
      <div className="c-message__main">
        {typeof props.children === 'string' ? (
          <Markdown content={props.children} />
        ) : (
          props.children
        )}
      </div>
    </aside>
  );
};

Message.propTypes = {
  children: PropTypes.node,
  modifiers: PropTypes.array,
  title: PropTypes.string,
  popover: PropTypes.string,
  dismissable: PropTypes.bool,
  onClose: PropTypes.func,
  intl: intlShape,
  type: PropTypes.string,
};

Message.defaultProps = {
  modifiers: [],
  dismissable: true,
  onClose: () => {},
};

export default injectIntl(Message);
