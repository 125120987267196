import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  defineMessages,
  intlShape,
  injectIntl,
  FormattedMessage,
} from 'react-intl';
import Select from 'brastrap/common/field-select/FieldSelect';
import MultiSelect from 'brastrap/common/field-brasize/FieldBrasize';
import SingleDimensionSizeGrid from 'brastrap/promotional/size-grid/SizeGridSingleDimension';

const messages = defineMessages({
  size: { id: 'sizes.placeholder', defaultMessage: 'Size…' },
});

  /**
* @param {Array} variants
* @param {boolean} respectStockLevels
* @returns {Array}
*/
const formatVariantsForSelect = (variants, respectStockLevels) =>
variants.map(sku => ({
  // Only display the stock level if told to.
  label: respectStockLevels ? `${sku.label} (${sku.stockStatus})` : sku.label,
  value: sku.skuCode,
  // If stock not available, do not allow it to be selected
  disabled: respectStockLevels && !sku.available,
}));

/**
* @param {Object} props
* @param {boolean} skipPlaceholder
* @returns {Object}
*/
export const getPropsForSelect = (props, skipPlaceholder = false) => {
const propsForSelect = {
  modifiers: props.modifiers,
  onChange: event => {
    const skuCode = event.currentTarget.value;
    const skuData = props.sizes.variants.find(sku => sku.skuCode === skuCode);
    props.onChange(skuData);
  },
  options: formatVariantsForSelect(
    props.sizes.variants,
    props.respectStockLevels
  ),
  value: props.value || '',
};
if (!skipPlaceholder) {
  propsForSelect.placeholder = props.intl.formatMessage(messages.size);
}
return propsForSelect;
};

const getPropsForSizeGrid = props => ({
  onChange: props.onChange,
  selectedSku: props.selectedSku,
  sizes: props.sizes,
  styleCode: props.styleCode,
  selectedSkuRef: props.selectedSkuRef,
});

/**
 * @param {Object} props
 * @returns {XML}
 * @constructor
 */
const Sizes = props => {
  const {
    sizes: { variants, value },
    isAccessoryContainer,
    isMobile,
    isFieldSizes,
    useSingleDropdown,
  } = props;
  const displayMultiSelect = value !== 'size' && variants && !!variants.length;
  const displaySingleDimensionSizeGrid =
    value === 'size' &&
    variants &&
    variants.length > 1 &&
    !isAccessoryContainer &&
    !isMobile &&
    !isFieldSizes;
  const displaySelect =
    value === 'size' &&
    variants &&
    variants.length > 1 &&
    !displaySingleDimensionSizeGrid;
  const displayOneOption =
    value === 'size' &&
    variants &&
    variants.length === 1 &&
    variants[0].skuCode;

  useEffect(() => {
    if (typeof window !== 'undefined' && props.selectedSkuRef && props.selectedSkuRef !== undefined) {
      const stringifiedObj = sessionStorage.getItem('selectedSkuDetails');
      const selectedSkuDetails = JSON.parse(stringifiedObj);

      const matchingValue = selectedSkuDetails && props.sizes.variants && props.sizes.variants.find(variant => variant.value === selectedSkuDetails.cupSize);

      if (selectedSkuDetails && selectedSkuDetails.styleCode === props.styleCode && matchingValue) {
        const skuData = props.sizes.variants.find(sku => sku.skuCode === matchingValue.skuCode);
        props.onChange(skuData);
      } else {
        props.selectedSkuRef.current = null;
      }
    }
  }, []);

  return (
    <fieldset id="sizes" className="c-sizes">
      <legend className="c-sizes__header">
        <span className="c-sizes__title u-hidden">
          <FormattedMessage id="sizes.title" defaultMessage="Choose a size" />
        </span>
      </legend>
      {displayMultiSelect && (
        <MultiSelect
          {...props}
          sizeType={value}
          useSingleDropdown={useSingleDropdown}
        />
      )}
      {displaySelect && <Select {...getPropsForSelect(props)} />}
      {displaySingleDimensionSizeGrid && (
        <SingleDimensionSizeGrid {...getPropsForSizeGrid(props)} />
      )}
      {displayOneOption && (
        <div className="u-hidden">{variants[0].skuCode}</div>
      )}
    </fieldset>
  );
};

Sizes.propTypes = {
  ...MultiSelect.propTypes,
  intl: intlShape,
  isAccessoryContainer: PropTypes.bool,
  isMobile: PropTypes.bool,
  isFieldSizes: PropTypes.bool,
  useSingleDropdown: PropTypes.bool,
};

Sizes.defaultProps = {
  respectStockLevels: true,
};

export default injectIntl(Sizes);
