import { setDataLayer } from '../utils/data-layer';
import { mapStateToDynamicRemarketingData } from '../utils/dynamic-remarketing';

const namespace = 'bravissimo/dynamic-remarketing';

/**
 * Updates the data layer when there's new data for dynamic remarketing
 *
 * @param {String} url
 *
 * @returns {*}
 */
export const setDynamicRemarketing = url => (dispatch, getState) => {
  const data = mapStateToDynamicRemarketingData(getState(), url);
  if (data) {
    setDataLayer(data, url);
    dispatch({ type: `${namespace}/UPDATE` });
  }
};
