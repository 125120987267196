import {
  UPDATE_COMMUNICATION_PREFS_REQUEST,
  UPDATE_COMMUNICATION_PREFS_FAILURE,
  UPDATE_COMMUNICATION_PREFS_SUCCESS,
  COMMUNICATION_PREFS_CLOSE_MESSAGES,
} from '../constants/communication-prefs';

import { GDPR_CONTENT_SUCCESS } from '../constants/gdpr';

export const initialState = {
  errorMessage: null,
  confirmationMessage: null,
  isSaving: false,
  needsGdpr: false,
  isReady: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function communicationPrefsForm(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_COMMUNICATION_PREFS_REQUEST:
      return {
        ...state,

        isSaving: true,
        errorMessage: null,
        confirmationMessage: null,
      };

    case UPDATE_COMMUNICATION_PREFS_FAILURE:
      return {
        ...state,

        isSaving: false,
        errorMessage: action.message,
        confirmationMessage: null,
      };

    case UPDATE_COMMUNICATION_PREFS_SUCCESS:
      return {
        ...state,

        isSaving: false,
        errorMessage: null,
        confirmationMessage: action.message,
      };

    case COMMUNICATION_PREFS_CLOSE_MESSAGES:
      return {
        ...state,

        confirmationMessage: null,
        errorMessage: null,
      };

    case GDPR_CONTENT_SUCCESS:
      return {
        ...state,
        isReady: true,
        needsGdpr: action.payload.consentMethod === 'explicit',
      };

    default:
      return state;
  }
}
