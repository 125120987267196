// @flow
import React from 'react';
import Link from 'brastrap/containers/link/LinkContainer';
import { createDataRef } from '../../utils';

export type CollectionType = {
  name: string,
  url: Url,
};

const Collection = ({ name, url }: CollectionType) => (
  <Link
    analyticsRef={createDataRef({ _type: 'mega-menu-collection', name })}
    className="c-collection"
    href={url}
    role="menuitem"
  >
    <span className="c-collection__name">{name}</span>
  </Link>
);

export default Collection;
