import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
    aria-labelledby="title"
  >
    <title>Pinterest</title>
    <path d="M61 192C25 177 0 141 0 100 0 45 45 0 100 0s100 45 100 100-45 100-100 100c-10 0-20-2-29-4 4-7 9-16 11-24l6-25s7 12 25 12c32 0 55-30 55-66s-29-62-66-62c-46 0-70 31-70 65 0 15 7 33 20 40 4 2 5-1 5-1l3-10c0-3 0-4-1-5-4-6-8-15-8-24 0-24 18-46 48-46C126 50 144 68 144 93c0 29-15 49-34 49-10 0-18-9-15-19 2-13 9-27 9-36 0-8-5-15-14-15-11 0-19 11-19 26 0 9 3 16 3 16l-13 53c-1 7-1 17-0 25z" />
  </svg>
);

export default SvgIcon;
