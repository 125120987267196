import React from 'react';

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 200 200"
    role="img"
  >
    <path d="M0 0h92v92H0V0zm108 0h92v92h-92V0zM0 108h92v92H0v-92zm108 0h92v92h-92v-92z" />
  </svg>
);

export default SvgIcon;
