// @flow
/* global document */
import React, { Component } from 'react';
import { omit } from 'lodash';
import Activity from 'brastrap/common/activity/Activity';
import { applyModifiers } from '../../utils';
import type { VideoContainer as Props } from './video-container.types.js';

type State = {
  displayOverlay: boolean,
  loaded: boolean,
  width: number,
};

const STYLE_PREFIX = 'c-video';

/**
 * Adds an id attribute to a video iframe to make selecting it easier
 * @param {object} iframe
 * @param {string} id
 * @returns {object} - iframe with id attribute added
 */
function addIdToIframe(
  iframe: { __html: string } = { __html: '' },
  id: string
) {
  const iframeMarkupArray = iframe.__html.split(' '); // eslint-disable-line no-underscore-dangle
  iframeMarkupArray.splice(1, 0, `id=${id}`);

  return {
    __html: iframeMarkupArray.join(' '),
  };
}

class VideoContainer extends Component<Props, State> {
  player: {
    play: () => void,
    pause: () => void,
    on: (string, () => void) => void,
  };

  static defaultProps = {
    aspectRatio: 1,
    modifiers: [],
    displayOverlay: true,
  };

  /**
   * Takes a set aspect ratio, and adjusts the video iFrame
   * height to maintain that ratio as width changes
   */
  state = {
    loaded: false,
    width: this.props.video.width || 0,
    displayOverlay: this.props.displayOverlay,
  };

  /**
   * Get access to player methods of video iframe
   */
  componentDidMount() {
    // Requiring here, because vimeo throws an error when window is undefined
    const Player = require('@vimeo/player'); // eslint-disable-line global-require

    const id = this.props.video.id;
    const iframe = document.getElementById(id);

    if (iframe) {
      this.player = new Player(iframe);
      this.player.on('loaded', this.handleLoaded);
      this.player.on('play', () => {
        this.setState({ displayOverlay: false });
      });
      this.player.on('pause', () => {
        this.setState({ displayOverlay: true });
      });
    }
  }

  /**
   * Autoplay, and autopause the video based on play prop
   * @param {object} nextProps
   */
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.play && !this.props.play) {
      try {
        this.player.play();
      } catch (e) {
        // Some mobile devices will not allow programmatic playing
        // ignoring these errors as they are inevitable
      }
    } else if (!nextProps.play && this.props.play) {
      this.player.pause();
    }

    const { width } = nextProps.video;
    if (this.state.width !== width) {
      this.setState({ width });
    }
  }

  /**
   */
  handleLoaded = () => {
    this.setState({ loaded: true });
  };

  /**
   */
  overlayClick = () => {
    this.player.play();
  };

  /**
   * Render a loader if one is required.
   *
   * @returns {XML|null}
   */
  renderLoader() {
    const { loader, video } = this.props;

    switch (loader) {
      case 'thumbnail':
        return (
          <img
            className={`${STYLE_PREFIX}__loading-image`}
            src={video.thumbnail}
            alt="loading"
          />
        );

      case 'activity':
        return <Activity />;

      default:
        return null;
    }
  }

  /**
   * @return {XML}
   */
  render() {
    const { modifiers, video } = this.props;
    const isIframe = video.iframe && video.iframe.__html; // eslint-disable-line no-underscore-dangle
    const isLoaded = this.state.loaded;
    const displayLoader = isIframe && !isLoaded && !!video.thumbnail;

    const containerProps = {
      className: applyModifiers(
        STYLE_PREFIX,
        isIframe && isLoaded ? [...modifiers, 'ready'] : modifiers
      ),
      height: video.height,
      width: video.width,
    };

    return (
      <div {...containerProps}>
        {displayLoader && this.renderLoader()}
        {isIframe ? (
          <div
            dangerouslySetInnerHTML={addIdToIframe(video.iframe, video.id)}
          />
        ) : (
          <iframe
            {...omit(video, 'iframe', 'type', 'url', 'thumbnail')}
            frameBorder={0}
          />
        )}
        {this.state.displayOverlay && (
          <div
            className={`${STYLE_PREFIX}__overlay`}
            onClick={this.overlayClick}
          />
        )}
      </div>
    );
  }
}

export default VideoContainer;
