import io from 'socket.io-client';
import isUndefined from 'lodash/isUndefined';
import {
  CONNECT,
  DISCONNECT,
  FAILURE,
  EMIT,
  SUBSCRIBE,
  SUCCESS,
  UNSUBSCRIBE,
} from '../constants/socket.io';

const sockets = [];
const validSocketTypes = [CONNECT, DISCONNECT, EMIT, SUBSCRIBE, UNSUBSCRIBE];
const requiredProps = ['event', 'type'];

/**
 * Actions are required, however they are omitted from the requiredProps above as sensible fallbacks are defined here.
 *
 * @type {{onError: (()=>{type}), onSuccess: (()=>{type})}}
 */
const defaultActions = {
  onError: payload => ({ payload, type: FAILURE }),
  onSuccess: payload => ({ payload, type: SUCCESS }),
};

/**
 * Are we running on the client or not?
 *
 * @return {boolean}
 */
const isClient = () => typeof window !== 'undefined';

/**
 * Ensures the action is correctly defined before attempting to process it.
 *
 * @param {Object} action
 * @return {boolean}
 */
const hasRequiredProps = action =>
  Object.keys(action).some(prop => requiredProps.includes(prop));

/**
 * Middleware that either subscribes or emits events to socket.io
 *
 * @param {Object} store
 * @returns {Function}
 */
export default store => next => action => {
  if (
    !hasRequiredProps(action) ||
    validSocketTypes.indexOf(action.type) === -1 ||
    !isClient()
  ) {
    return next(action);
  }

  const actions = { ...defaultActions, ...action.actions };
  const { onError, onSuccess } = actions;
  const { dispatch } = store;

  let socket = sockets[action.namespace];

  if (isUndefined(socket)) {
    if ([DISCONNECT, UNSUBSCRIBE].indexOf(action.type) > -1) {
      dispatch(
        onError(
          'Cannot disconnect or unsubscribe from an uninitialised socket.'
        )
      );
      return next(action);
    }

    // We don't have a connected socket yet.
    // Let's create one.
    const { serverAndPort } = store.getState().app;
    const namespacedConnection = action.namespace
      ? `${serverAndPort}/${action.namespace}`
      : serverAndPort;

    try {
      socket = sockets[action.namespace] = io(namespacedConnection);
    } catch (error) {
      return dispatch(onError(error));
    }
  }

  const isSubscribed = () => {
    const subscriptions = store.getState().socketIo;
    const { event, namespace } = action;

    return (subscriptions[namespace] || []).indexOf(event) > -1;
  };

  switch (action.type) {
    case SUBSCRIBE: {
      if (!isSubscribed(action)) {
        // If not already subscribed then subscribe to the event.
        socket.on(action.event, payload => {
          dispatch(onSuccess(payload));
        });
      }
      break;
    }

    case EMIT: {
      socket.emit(action.event, action.params);
      dispatch(onSuccess());
      break;
    }

    case UNSUBSCRIBE: {
      socket.off(action.event);
      dispatch(onSuccess());
      break;
    }

    case DISCONNECT: {
      socket.disconnect();
      dispatch(onSuccess());
      break;
    }

    // Connection is already done above. Just fire the success callback as if it's got this far, everything A'OK.
    case CONNECT:
    default:
      dispatch(onSuccess());
      break;
  }

  return next(action);
};
