// @flow
/**
 * TODO: Might be worth refactoring the product reducer into a smaller set of reducers including this one.
 *       Might be quite a lot of work so keeping it separate for the time being.
 */
import {
  FILTER_PRODUCT_REVIEWS,
  RECEIVE_REVIEWS,
  REVIEWS_FAILURE,
  REVIEWS_REQUEST,
} from '../constants/product-reviews';

import { type Action } from '../actions/product-reviews';

export type State = {
  limit: number,
  loading: boolean,
  sortBy: string,
  page: number,
  reviews: Review[],
  starRating: string,
};

const initialState = {
  limit: 8,
  loading: true,
  page: 1,
  reviews: [],
  sortBy: 'all',
  starRating: 'all',
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function productReviews(
  state: State = initialState,
  action: Action
): State {
  // If the style colour selection has changed, go back to page 1
  const updateDefaults = (prevState, act) =>
    act.payload && act.payload.sortBy ? { ...prevState, page: 1 } : prevState;

  switch (action.type) {
    case FILTER_PRODUCT_REVIEWS: {
      return {
        ...updateDefaults(state, action),
        ...action.payload,
      };
    }
    case RECEIVE_REVIEWS: {
      return {
        ...state,
        reviews: action.payload.reviews,
        loading: false,
      };
    }
    case REVIEWS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case REVIEWS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
