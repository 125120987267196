import PropTypes from 'prop-types';
/*eslint spaced-comment: ["off"]*/
import { Children } from 'react';
import uniq from 'lodash/uniq';
import withSideEffect from 'react-side-effect';

const BodyClass = props => Children.only(props.children);

BodyClass.propTypes = {
  className: PropTypes.string,
};

/**
 * Ensure all classes are unique.
 * @param {Array} propsList
 * @return {string} of class names separated by spaces.
 */
const reducePropsToState = propsList =>
  uniq(propsList.map(props => props.className)).join(' ');

/**
 * Update the client with the correct class name.
 * @param {string} classNames
 */
const handleStateChangeOnClient = classNames => {
  document.body.className = classNames || '';
};

export default withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient
)(BodyClass);

export const UnwrappedBodyClass = BodyClass;
