/**
 * @param {object} dataLayer
 */
export const setDataLayer = dataLayer => {
  if (typeof window !== 'undefined' && window.setDataLayer) {
    window.setDataLayer({ ...dataLayer });
  }
};

/**
 * @param {object} product
 * @param {string} code
 * @param {number} quantity
 * @returns {object}
 */
export const formatProductForDataLayer = (product, code, quantity) => ({
  name: `${product.styleName} ${product.colour}`,
  id: code,
  price: product.price,
  brand: product.brandName,
  category: product.category,
  colour: product.colour,
  quantity: quantity || product.quantity,
  styleName: product.styleName,
  styleColourCode: product.styleColourCode,
  size: product.size.size,
});

/**
 * @param {string} email
 * @returns {{type}}
 */
export const dataLayerSendEmail = email =>
  setDataLayer({ event: 'sendEmail', email });

/**
 * @returns {{type}}
 */
export const dataLayerRequestMagazine = () =>
  setDataLayer({ event: 'requestMagazine' });

/**
 * @param {string} type
 * @param {object} product
 * @param {string} currency
 * @param {object} bag
 * @returns {{type}}
 */
export const dataLayerBagChange = (type, product, currency, bag) =>
  setDataLayer({
    event: 'bagChange',
    bagChangeType: type,
    product,
    currency,
    bag,
  });

/**
 * @param {string} currency
 * @param {object} item
 */
export const dataLayerGA4AddToCart = (currency, item) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_id: item.styleColourCode,
          item_name: item.styleName,
          item_brand: item.brand,
          currency: currency,
          item_category: item.category,
          item_category2: item.styleName,
          item_variant: item.colour,
          item_variantID: item.id,
          price: item.price,
          size: item.size,
          quantity: item.quantity,
        },
      ],
    },
  });
};

/**
 * @param {string} currency
 * @param {object} bag
 */
export const dataLayerGA4Checkout = (currency, bag) => {
  setDataLayerForEventType(currency, bag, 'begin_checkout');
};

/**
 * @param {string} currency
 * @param {string} styleCode
 * @param {string} styleName
 */
export const dataLayerGA4SelectItem = (currency, styleCode, styleName) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_id: styleCode,
          item_name: styleName,
          currency: currency,
          index: 0,
        },
      ],
    },
  });
};

/**
 * @param {string} currency
 * @param {object} bag
 * @param {string} orderId
 */
export const dataLayerGA4Purchase = (currency, bag, orderId) => {
  const taxAmount = bag.subTotal * bag.taxRate;
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'purchase',
    ecommerce: {
      transaction_id: orderId,
      value: bag.subTotal,
      currency: currency,
      tax: taxAmount.toFixed(2),
      shipping: bag.delivery.postage,
      coupon: '',
      affiliation: 'Online Store',
      items: bag.items.map((item, index) => ({
        item_id: item.skuCode,
        item_name: item.styleName,
        currency: currency,
        index: index,
        item_brand: item.brandName,
        item_category: item.category,
        item_category2: item.styleName,
        item_variant: item.colour,
        price: item.price,
        quantity: item.quantity,
      })),
    },
  });
};

/**
 * @param {object} order
 */
export const dataLayerGA4Refund = order => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'refund',
    ecommerce: {
      currency: order.orderCurrency,
      transaction_id: order.orderId,
      value: order.orderValue,
      items: order.awaitingDespatch.map((item, index) => ({
        item_id: item.skuId,
        currency: item.currency,
        index: index,
        price: item.unitPrice,
        quantity: item.ordered,
      })),
    },
  });
};

/**
 * @param {object} item
 * @param {string} currency
 */
export const dataLayerGA4RemoveFromCart = (item, currency) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        {
          item_id: item.skuCode,
          item_name: item.styleName,
          currency: currency,
          item_brand: item.brandName,
          item_category: item.category,
          item_category2: item.styleName,
          item_variant: item.colour,
          price: item.price,
          quantity: item.quantity,
        },
      ],
    },
  });
};

/**
 * @param {object} items
 * @param {object} categories
 * @param {string} outlet
 * @param {number} total
 * @param {string} currency
 *
 */
export const dataLayerGA4ViewItemList = (
  items,
  categories,
  outlet,
  total,
  currency
) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'view_item_list',
    ecommerce: {
      items: items.map((item, index) => ({
        item_id: item.styleCode,
        item_name: item.styleName,
        currency: currency,
        index: index,
        item_category: categories,
        item_category2: item.styleName,
        location_id: outlet,
        quantity: total,
      })),
    },
  });
};

/**
 * @param {object} product
 * @param {string} currency
 */
export const dataLayerGA4ViewItem = (product, currency) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_id: product.styleColourCode,
          item_name: product.styleName,
          currency: currency,
          item_brand: product.brand,
          item_category: product.category,
          item_category2: product.styleName,
          item_variant: product.colour.colour,
          price: product.prices.price,
        },
      ],
    },
  });
};

/**
 * @param {object} product
 * @returns {{type}}
 */
export const dataLayerSetProduct = product =>
  setDataLayer({ event: 'setProduct', product });

/**
 * @param {string} action
 * @param {string} name
 * @returns {{type}}
 */
export const dataLayerEditorialModal = (action, name) =>
  setDataLayer({
    event: 'editorialModalAction',
    editorialModalActionType: `Popup: ${name} - ${
      action === 'open' ? 'Display' : 'Dismiss'
    }`,
  });

/**
 * @param {string} currency
 * @param {object} bag
 * @param {string} eventType
 */
function setDataLayerForEventType(currency, bag, eventType) {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: eventType,
    ecommerce: {
      items: bag.items.map((item, index) => ({
        item_id: item.skuCode,
        item_name: item.styleName,
        currency: currency,
        index: index,
        item_brand: item.brandName,
        item_category: item.category,
        item_category2: item.styleName,
        item_variant: item.colour,
        price: item.price,
        quantity: item.quantity,
      })),
    },
  });
}

/**
 * @param {string} stageNumber
 */
export const dataLayerGA4SizeQuizStageNumber = stageNumber => {
  setDataLayer({
    event: 'bra_fit_finder_stage_number',
    stage: `User is on stage number: ${stageNumber}`,
  });
};
/**
 * @param {string} stageNumber
 */
export const dataLayerGA4ChatToFitter = stageNumber => {
  setDataLayer({
    event: 'bra_fit_finder_chat_to_fitter',
    stage: `Chat to fitter CTA - stage:${stageNumber}`,
  });
};
/**
 * @param {string} stageNumber
 */
export const dataLayerGA4HyperlinksInText = stageNumber => {
  setDataLayer({
    event: 'bra_fit_finder_hyperlinks_in_text',
    stage: `Hyperlinks in text - stage:${stageNumber}`,
  });
};
/**
 * @param {string} stageNumber
 * @param {string} backSize
 * @param {string} cupSize
 */
export const dataLayerGA4UserSize = (stageNumber, backSize, cupSize) => {
  if (backSize && cupSize) {
    if (stageNumber === 1) {
      setDataLayer({
        event: 'bra_fit_finder_user_size',
        size: `User has selected - back size:${backSize}, cup size:${cupSize}`,
      });
    } else {
      setDataLayer({
        event: 'bra_fit_finder_user_size',
        size: `User's recommended size is - back size:${backSize}, cup size:${cupSize}`,
      });
    }
  }
};

/**
 * @param {string} stageNumber
 * @param {string} elapsedTime
 */
export const dataLayerGA4TimeOnQuizStage = (stageNumber, elapsedTime) => {
  const stage = stageNumber + 1;

  setDataLayer({
    event: 'bra_fit_finder_time_spent',
    time: `Time spent on stage ${stage}: ${elapsedTime} seconds`,
  });
};

/**
 * @param {string} styleName
 * @param {string} styleCode
 */
export const dataLayerGA4SelectedRailItem = (styleName, styleCode) => {
  setDataLayer({
    event: 'editorial_content',
    name: styleName,
    sku: styleCode,
  });
};

/**
 * @param {string} searchPhraseUrl
 */
export const dataLayerSliCollectionsSearch = searchPhraseUrl => {
  setDataLayer({
    event: 'collectionsSearch',
    searchPhraseUrl: searchPhraseUrl,
  });
};

/**
 * @param {string} selectedNavItem
 */
export const dataLayerNavItemSelected = selectedNavItem => {
  setDataLayer({
    event: 'nav_item_selected',
    selectedNavItem: selectedNavItem,
  });
};
