import { RECEIVE_PERSONAL_DETAILS } from 'shared/constants/account';

/**
 * @param {Object} customer
 * @returns {{type: string, payload: Object}}
 */
export function receivePersonalDetails(customer) {
  return {
    type: RECEIVE_PERSONAL_DETAILS,
    payload: customer,
  };
}

export default receivePersonalDetails;
