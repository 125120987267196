import {
  FIND_SHOPS_FAILURE,
  FIND_SHOPS_REQUEST,
  FIND_SHOPS_SUCCESS,
  GET_ALL_FAILURE,
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
} from '../constants/shops';

import { ADD_CLICK_AND_COLLECT_ADDRESS } from '../constants/checkout';

export const initialState = {
  errorMessage: null,
  isFetching: false,
  noResults: false,
  postCodes: [],
  shops: [],
};

/**
 * @param {Object} state
 * @param {Object} action
 * @return {{dataType: string, data: Array}}
 */
export function shopFinder(state = initialState, action = {}) {
  switch (action.type) {
    case FIND_SHOPS_SUCCESS:
      return {
        ...state,

        ...action.payload,

        errorMessage: null,
        isFetching: false,
      };

    case GET_ALL_SUCCESS:
      return {
        ...state,

        shops: action.payload,

        errorMessage: null,
        isFetching: false,
      };

    case GET_ALL_FAILURE:
    case FIND_SHOPS_FAILURE:
      return {
        ...state,

        errorMessage: action.payload,
        isFetching: false,
      };

    case GET_ALL_REQUEST:
    case FIND_SHOPS_REQUEST:
      return {
        ...state,

        isFetching: true,
        noResults: false,
      };

    case ADD_CLICK_AND_COLLECT_ADDRESS:
      // Revert to initial state when a click and collect address
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
