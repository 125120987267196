/* eslint-env browser */
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

/**
 * Convert hex to rgb
 *
 * @param {String} hex
 * @returns {Object}
 */
export function hexToRgb(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const newHex = hex.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const modifiersShape = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.string),
  PropTypes.string,
]);

/**
 * Generates a final className attribute value given a base class name
 * and any number of modifier values.
 * @param {string} baseClass a class that is always applied e.g. 'c-popover'
 * @param {string[]|string} modifiers modifiers to apply. A modifier of 'bag' results in
 *                          a class of 'c-popover--bag'. An array or a string can be passed.
 *                          A string parameters is split on whitespace and then treated the
 *                          same as if an array were passed.
 * @return {string} the final className value.
 */
export function applyModifiers(baseClass, modifiers = []) {
  if (typeof baseClass !== 'string') {
    throw new Error('baseClass must be a string');
  }

  if (baseClass.length === 0) {
    throw new Error('Must supply baseClass');
  }

  /* eslint-disable no-param-reassign */
  if (!modifiers) {
    modifiers = [];
  } else if (typeof modifiers === 'string') {
    modifiers = modifiers.length === 0 ? [] : modifiers.split(/\s+/);
  }
  /* eslint-enable no-param-reassign */

  return (
    baseClass +
    modifiers
      .filter(m => m)
      .map(m => ` ${baseClass}--${m}`)
      .join('')
  );
}

/**
 * Creates a string for the Google Tag Manager `data-ref` attribute
 * @param {Object} props
 * @returns {string}
 * */
export function createDataRef(props) {
  const { _type: contentType, title, name } = props;
  return `${startCase(contentType || '')}: ${startCase(
    (typeof title === 'string' && title) || name || ''
  )}`;
}

/**
 * Returns a total with specified number of decimal places
 * @param {string} total
 * @param {number} number
 * @returns {string}
 * */
export const formattedTotal = (total, number) =>
  Number(total).toFixed(number).toString();
