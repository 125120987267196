// @flow
import React from 'react';
import { reduxForm, reset } from 'redux-form';
import { injectIntl } from 'react-intl';

import Form from 'brastrap/editorial/editorial-form/EditorialForm';
import type { EditorialForm as Props } from 'brastrap/editorial/editorial-form/editorial-form.types.js';

import {
  apiSubmitEditorialForm,
  closeMessages,
  formValidation,
  setFormMetadata,
} from 'shared/actions/editorial-form';
import { validate } from 'shared/utils/validation';

const mapStateToProps = ({ editorialForm }, ownProps) => {
  const error = editorialForm.error === ownProps.formKey;
  const confirmed = editorialForm.confirmed === ownProps.formKey;
  const isSending = editorialForm.isSending === ownProps.formKey;
  return {
    confirmed,
    isSending,
    message: (confirmed || error) && editorialForm.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: values => {
    dispatch(
      apiSubmitEditorialForm({
        ...values,
        formKey: ownProps.formKey,
        subject: values.subject || ownProps.subject,
      })
    );
    dispatch(reset(ownProps.formConfig.form));
  },
  onCloseMessages: () => dispatch(closeMessages()),
  setFormMetadata: metadata => dispatch(setFormMetadata(metadata)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const validationRules = ownProps.formFields.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr.validation }),
    {}
  );
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    validate: (fields, { intl: { formatMessage } }) => {
      const result = validate(fields, validationRules, formatMessage);
      // Store whether the data entered by the customer is valid, so that other parts of
      // the website know if the form can be submitted
      dispatchProps.dispatch(formValidation(!Object.keys(result).length));
      return result;
    },
  };
};

const FormComponent = injectIntl(
  reduxForm(
    { form: '', fields: [] },
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(Form)
);

const EditorialFormContainer = (props: Props) => (
  <FormComponent
    fields={props.formConfig.fields}
    form={props.formConfig.form}
    validate={props.validate}
    {...props}
  />
);

export default EditorialFormContainer;
