// @flow
import React, { type Node } from 'react';
import { applyModifiers } from '../../utils';

type SharedProps = {|
  disabled?: boolean,
  modifiers?: Modifiers,
  // onClick has been made optional, this is because some components (possibly wrongly) have the onclick event on the
  // wrapping component.
  // * has been added instead of HTMLButtonElement. Adding HTMLButtonElement causes an issue in flow where it says that
  // HTMLAnchorElement is used instead. I have been through the entire codebase and it is definitely not being used.
  onClick?: (SyntheticEvent<*>) => void,
  submit?: boolean,
  id?: string,
  formNoValidate?: boolean,
|};

export type ButtonProps = {
  ...SharedProps,
  'aria-label'?: string,
};

export type Props = {
  ...SharedProps,
  ariaLabel?: string,
  label: Node,
  icon?: Node,
};

const Button = (props: Props) => {
  const modifiers = [];

  if (props.modifiers)
    modifiers.push(
      // Annoyingly modifiers is either an array or a string ¯\_(ツ)_/¯
      ...(Array.isArray(props.modifiers)
        ? props.modifiers
        : // For some insane reason modifiers can contain multiples the same string
          // https://media.giphy.com/media/zrdUjl6N99nLq/giphy.gif
          props.modifiers.split(' '))
    );

  if (props.icon) modifiers.push('with-icon');

  const buttonProps: ButtonProps = {
    className: applyModifiers('c-button', modifiers),
    type: props.submit ? 'submit' : 'button',
  };

  if (props.disabled) {
    buttonProps.disabled = true;
  }

  if (props.formNoValidate) {
    buttonProps.formNoValidate = true;
  }

  if (props.id) {
    buttonProps.id = props.id;
  }

  if (props.ariaLabel) {
    buttonProps['aria-label'] = props.ariaLabel;
  }

  if (props.onClick) {
    buttonProps.onClick = props.onClick;
  }

  return (
    <button {...buttonProps}>
      {props.icon}
      {props.label}
    </button>
  );
};

Button.defaultProps = {
  label: 'Label',
};

export default Button;
