import * as containers from './masthead/containers';
import * as header from './masthead/header';
import * as megaMenu from './masthead/mega-menu';
import * as navigation from './masthead/navigation';

export default {
  ...containers,
  ...header,
  ...megaMenu,
  ...navigation,
};

/**
 * @param {Number} key
 * @param {Boolean} isDesktop
 * @returns {Function}
 */
export const onNavPress = (key, isDesktop) => dispatch => {
  dispatch(header.closeDrawerAndLayer('navigation'));
  dispatch(navigation.setItemVisibility(key));
  dispatch(megaMenu.toggleMenuAndLayer(key, isDesktop));
};
