import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import SVG from './IconBagLarge';

const messages = defineMessages({
  toolbarCount: {
    id: 'toolbar.count',
    defaultMessage: `{count} {count, plural,
    one {{one}}
    other {{other}}
  }`,
  },
});

type Props = {
  count?: number,
  countContext: {
    one: string,
    other: string,
  },
};

const SvgIcon = ({ count, countContext }: Props) => (
  <span className="c-icon__counter-frame">
    <SVG />
    <data className="c-counter__value" value={count}>
      (
      <FormattedMessage
        {...messages.toolbarCount}
        values={{
          count: (count || 0).toString(),
          ...countContext,
        }}
      />
      )
    </data>
  </span>
);

export default SvgIcon;
