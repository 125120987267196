import { ADD, DISMISS, CLEAR_ALL } from '../constants/messages';

/**
 * @param {Number} messageIndex
 * @returns {{type: string, payload: number}}
 */
export function dismiss(messageIndex) {
  return {
    type: DISMISS,
    payload: messageIndex,
  };
}

/**
 * @param {string} target
 * @return {{type: string, payload: string}}
 */
export function clearAllMessages(target) {
  return {
    type: CLEAR_ALL,
    payload: target,
  };
}

/**
 * @param {Object} message
 * @param {string} target
 * @param {Boolean} isError
 * @param {Boolean} isInfo
 * @return {{type, payload: {message: Object, target: String, isError: Boolean, isInfo: Boolean}}}
 */
export function addMessage(message, target, isError = false, isInfo = false) {
  return {
    type: ADD,
    payload: { isError, isInfo, message, target },
  };
}
