import { SET_SCROLL_POINT, CLEAR_SCROLL_POINT } from '../constants/scroll';

/**
 * @param {string} scrollPoint
 * @returns {{type: string}}
 */
export function setScrollPoint(scrollPoint) {
  return {
    type: SET_SCROLL_POINT,
    payload: scrollPoint,
  };
}

/**
 * @returns {{type: string}}
 */
export function clearScrollPoint() {
  return {
    type: CLEAR_SCROLL_POINT,
  };
}
