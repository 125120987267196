// @flow
import React from 'react';
import Icon from 'brastrap/common/icon/Icon';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import StyledText, {
  getWrappingTag,
} from 'brastrap/editorial/styled-text/StyledText';
import type { Block as Props } from './block.types';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-block';

const Block = ({
  modifiers,
  actions = [],
  icon,
  title,
  copy,
  children,
  backgroundColour,
  styledTextTitle,
}: Props) => {
  const className = applyModifiers(STYLE_PREFIX, modifiers);
  let displayedTitle = title;
  let titleClassName = 'c-block__title';
  const sectionProps = {};

  if (backgroundColour) {
    sectionProps.style = { backgroundColor: backgroundColour };
  }

  if (styledTextTitle) {
    titleClassName += ` ${titleClassName}--styled-text`;
    displayedTitle = styledTextTitle.map(item => (
      <StyledText key={item.id} {...item} />
    ));
  }

  const TagName = getWrappingTag(styledTextTitle || [], 'h1');

  const displayActions = !!actions.length;

  return (
    <section className={className} {...sectionProps}>
      <header className="c-block__header">
        <TagName className={titleClassName}>
          {icon && <Icon {...icon} />}
          {displayedTitle}
        </TagName>
      </header>

      <div className="c-block__main">
        <div className="s-prose s-prose--small">
          {copy && <MarkDown content={copy} />}
          {children}
        </div>
      </div>

      {displayActions && (
        <div className="c-block__actions">
          {actions.map((action, key) => (
            <ButtonLink key={key} {...action} />
          ))}
        </div>
      )}
    </section>
  );
};

Block.defaultProps = {
  modifiers: [],
};

export default Block;
