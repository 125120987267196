import { receivePaymentMethods } from './account/payment';
import { receiveDeliveryAddresses } from './account/addresses';
import { receiveBag, updateTotalWithUSTax } from './bag';
import {
  GET_DELIVERY_METHODS,
  SET_ESTIMATED_DELIVERY_DATE,
  CONFIRM_ORDER_FAILURE,
  TOGGLE_DELIVERY_METHODS,
  ADD_DELIVERY_ADDRESS,
  SET_CONFIRMED_ORDER,
  SAVE_ORDER_NUMBER,
  MISSING_ORDER_NUMBER,
  IGNORE_LOGIN_PROMPT,
  SET_AUTH_PAYMENT_CARD_MODAL_HTML,
  ADD_CUSTOMER_DETAILS,
  TOGGLE_ADD_ADDRESS_FORM,
  SET_GUEST_EMAIL,
  CUSTOMER_CHECKOUT_DATA_REQUEST,
  CUSTOMER_CHECKOUT_DATA_FAILURE,
  CHECKOUT_DATA_REQUEST,
  CHECKOUT_DATA_FAILURE,
  NO_DELIVERY_METHODS,
  ORDER_SUBMITTED,
  SET_ADYEN,
  AUTH_3DS_FAILED,
  PROCESS_3D_AUTH,
  US_TAX_DATA_RECEIVED,
  US_TAX_DATA_FAILURE,
  US_TAX_DATA_FAILURE_ADDRESS,
  US_TAX_DATA_REQUEST,
  US_TAX_DATA_FAILURE_GENERAL_ERROR,
  DISPLAY_UK_PAYMENT,
  US_TAX_UNHANDLED_ERROR,
} from '../constants/checkout';

import { CALL_API } from '../constants/api';

import { closeOverlay } from '../actions/overlay';
import { setScrollPoint } from './scroll';
import { addMessage, clearAllMessages } from './messages';

/**
 * @param {Boolean} value
 * @returns {{type: String, payload: Boolean}}
 */
export function orderSubmitted(value) {
  return {
    type: ORDER_SUBMITTED,
    payload: value,
  };
}

/**
 * @param {Array} methods
 * @returns {{type: string, payload: Array}}
 */
export function receiveDeliveryMethods(methods) {
  return {
    type: GET_DELIVERY_METHODS,
    payload: methods,
  };
}

/**
 * Assumes the date is a string like: "2-4 days" or "next day" etc.
 * @param {string} date
 * @returns {{type, payload: string}}
 */
export function setEstimatedDeliveryDate(date) {
  return {
    type: SET_ESTIMATED_DELIVERY_DATE,
    payload: date,
  };
}

/**
 * @param {Object} payload
 * @returns {{type: string, payload: object}}
 */
export function setAuthPaymentCardModalHTML(payload) {
  return {
    type: SET_AUTH_PAYMENT_CARD_MODAL_HTML,
    payload,
  };
}

/**
 * @returns {{type: string}}
 */
export function addDeliveryAddress() {
  return {
    type: ADD_DELIVERY_ADDRESS,
  };
}

/**
 * @returns {{type}}
 */
export function toggleAddresses() {
  return {
    type: TOGGLE_DELIVERY_METHODS,
  };
}

/**
 * @param {object} payload details of the order
 * @returns {Promise}
 */
export const confirmOrderSuccess = payload => (dispatch, getState) => {
  const { basePath } = getState().app.config;
  // Using a location redirect so that populate store runs on the confirm page
  // eslint-disable-next-line no-undef
  window.location.href = `${basePath}confirm/${payload.order.id}`;
};

/**
 * @param {object} error - message and 'failure' boolean
 * @returns {{type}}
 */
export const confirmOrderFailure = error => (dispatch, getState) => {
  const state = getState();
  // Close the processing payment overlay, if it is showing
  if (state.overlay.isVisible) {
    dispatch(closeOverlay());
  }
  // Scroll to the error message
  dispatch(setScrollPoint('scrollPoint.ErrorMessage'));
  // Use the messages container to manage displayed error messages
  const message = { children: error.message };
  if (message.children) {
    dispatch(addMessage(message, 'checkoutMessages', true));
  }

  dispatch({
    type: CONFIRM_ORDER_FAILURE,
    payload: error.failure,
  });
};

/**
 * @param {object} order
 * @returns {{type, payload: {bag: object}}}
 */
export function confirmOrder(order) {
  return {
    actions: {
      success: confirmOrderSuccess,
      error: confirmOrderFailure,
    },
    method: 'post',
    params: order,
    type: CALL_API,
    url: 'order',
  };
}

/**
 * @param {string} orderId
 * @returns {Object}
 */
export function getOrderNumber(orderId) {
  return {
    actions: {
      success: saveOrderNumber,
      error: getOrderNumberFailure,
    },
    method: 'get',
    type: CALL_API,
    url: `order/${orderId}/orderNo`,
  };
}

/**
 * @param {object} payload
 * @returns {{type, payload: {order: object}}}
 */
export const setConfirmOrderSuccess = payload => dispatch => {
  dispatch(clearAllMessages());
  dispatch({
    type: SET_CONFIRMED_ORDER,
    payload,
  });
};

/**
 * @param {object} payload
 * @returns {{type, payload: {object}}}
 */
export function saveOrderNumber(payload) {
  return {
    type: SAVE_ORDER_NUMBER,
    payload,
  };
}

/**
 * @returns {{type, error: boolean}}
 */
export function getOrderNumberFailure() {
  return {
    type: MISSING_ORDER_NUMBER,
    error: true,
  };
}

/**
 * @param {Object} customer
 * @returns {{type, payload: *}}
 */
export function addCustomerDetails(customer) {
  return {
    type: ADD_CUSTOMER_DETAILS,
    payload: customer,
  };
}

/**
 * @returns {{type}}
 */
export function ignoreLoginPrompt() {
  return {
    type: IGNORE_LOGIN_PROMPT,
  };
}

/**
 * @param {Boolean} display
 * @return {{payload: boolean, type}}
 */
export function updateAddAddressFormVisibility(display = true) {
  return {
    payload: display,
    type: TOGGLE_ADD_ADDRESS_FORM,
  };
}

/**
 * @param {string} email
 * @returns {{type: *, payload: *}}
 */
export function setGuestEmail(email) {
  return {
    type: SET_GUEST_EMAIL,
    payload: email,
  };
}

export const receiveCustomerCheckoutData = ({
  addresses,
  bag,
  customer,
  paymentMethods,
  deliveryMethods,
}) => dispatch => {
  dispatch(receiveBag(bag));
  dispatch(receivePaymentMethods(paymentMethods));
  dispatch(receiveDeliveryAddresses(addresses));
  dispatch(addCustomerDetails(customer));

  if (deliveryMethods) {
    dispatch(receiveDeliveryMethods(deliveryMethods));
  } else {
    dispatch({ type: NO_DELIVERY_METHODS });
  }
};

/**
 * @returns {{type}}
 */
export function customerCheckoutDataRequest() {
  return {
    type: CUSTOMER_CHECKOUT_DATA_REQUEST,
  };
}

/**
 * @param {Object} err
 * @returns {{function}}
 */
export const customerCheckoutDataFailure = err => dispatch => {
  const message = { children: err };
  dispatch(addMessage(message, 'checkoutMessages', true));
  dispatch({
    type: CUSTOMER_CHECKOUT_DATA_FAILURE,
    payload: {
      message: err,
    },
  });
};

export const receiveCheckoutData = ({ bag, deliveryMethods }) => dispatch => {
  dispatch(receiveBag(bag));

  if (deliveryMethods) {
    dispatch(receiveDeliveryMethods(deliveryMethods));
  } else {
    dispatch({ type: NO_DELIVERY_METHODS });
  }
};

/**
 * @returns {{type}}
 */
export function checkoutDataRequest() {
  return {
    type: CHECKOUT_DATA_REQUEST,
  };
}

/**
 * @param {Object} err
 * @returns {{type}}
 */
export function checkoutDataFailure(err) {
  return {
    type: CHECKOUT_DATA_FAILURE,
    error: true,
    payload: {
      message: err,
    },
  };
}

/**
 * @param {String} customerId
 * @returns {Object}
 */
export function apiGetCheckoutDataForCustomer(customerId) {
  return {
    actions: {
      success: receiveCustomerCheckoutData,
      error: customerCheckoutDataFailure,
      start: customerCheckoutDataRequest,
    },
    method: 'get',
    type: CALL_API,
    url: `checkout/customerData/${customerId}`,
  };
}

/**
 * @param {Object} payload
 * @returns {{type: *, payload: *}}
 */
export const receiveUSTaxData = payload => dispatch => {
  dispatch(updateTotalWithUSTax(payload));
  dispatch({
    type: US_TAX_DATA_RECEIVED,
    payload,
  });
};

/**
 * @param {Object} payload
 * @returns {{type: *, payload: *}}
 */
export const recieveUSTaxDataFailure = payload => dispatch => {
  switch (payload.type) {
    case 'Address Validation':
      dispatch({
        type: US_TAX_DATA_FAILURE_ADDRESS,
        payload: {
          ...payload,
        },
      });
      break;
    case 'Invalid Parameters':
    case 'Invalid Tax Request':
    case 'Missing Parameter':
      dispatch({
        type: US_TAX_DATA_FAILURE_GENERAL_ERROR,
        payload: {
          ...payload,
        },
      });
      break;
    case 'Unhandled Error':
    case 'Not Found':
      dispatch({
        type: US_TAX_UNHANDLED_ERROR,
        payload: {
          ...payload,
        },
      });
      break;
    default:
      dispatch({
        type: US_TAX_DATA_FAILURE,
        payload: {
          ...payload,
        },
      });
  }
};

/**
 * @returns {{type}}
 */
export function recieveUSTaxDataRequest() {
  return {
    type: US_TAX_DATA_REQUEST,
  };
}

/**
 * @param {String} usData
 * @returns {Object}
 */
export function apiGetUSTaxAmount(usData) {
  return {
    actions: {
      success: receiveUSTaxData,
      error: recieveUSTaxDataFailure,
      start: recieveUSTaxDataRequest,
    },
    method: 'post',
    params: usData,
    type: CALL_API,
    url: `checkout/calculateUSTax`,
  };
}

/**
 * @param {Object} newAddress
 * @returns {Object}
 */
export function apiUpdateBagDeliveryAddressAndGetDeliveryMethods({
  id: addressId,
  postCode,
  countryId,
}) {
  return {
    actions: {
      success: receiveCheckoutData,
      error: checkoutDataFailure,
      start: checkoutDataRequest,
    },
    method: 'put',
    type: CALL_API,
    url: `bag/address/${addressId}/${postCode}/${countryId}`,
  };
}

/**
 * @param {Object} payload
 * @returns {{type: *, payload: *}}
 */
export function setAdyen(payload) {
  return {
    type: SET_ADYEN,
    payload,
  };
}

/**
 * @param {bool} payload
 * @returns {{type: *, payload: *}}
 */
export function setAuth3dsFailed(payload) {
  return {
    type: AUTH_3DS_FAILED,
    payload,
  };
}

/**
 * @param {Object} payload
 * @returns {Object}
 */
export function process3dAuth(payload) {
  return {
    type: PROCESS_3D_AUTH,
    payload,
  };
}

/**
 * @param {Object} payload
 * @returns {Object}
 */
export function displayUKPayment(payload) {
  return {
    type: DISPLAY_UK_PAYMENT,
    payload,
  };
}
