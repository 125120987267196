// @flow
import React from 'react';
import StyledText from 'brastrap/editorial/styled-text/StyledText';
import Link from 'brastrap/containers/link/LinkContainer';
import type { ImageCard as Props } from './image-card.types.js';
import ImageCardContent from './ImageCardPositionedContent';
import ImageCardOverlay from './ImageCardOverlay';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-image-card';
const PRODUCT_STYLE_PREFIX = 'c-product-summary';

const ImageCard = (props: Props) => {
  const {
    image,
    titleText,
    action,
    analyticsRef,
    video,
    onClick,
    onModalClose,
    isOpen,
    id,
  } = props;
  let text;
  if (titleText) {
    text = titleText.map((item, i) => (
      <StyledText key={`image-card-text-${i}`} {...item} />
    ));
  }
  const imageProps = {
    action,
    image,
    text,
  };
  const overlayProps = {
    analyticsRef,
    image,
    isOpen,
    onClick,
    onModalClose,
    text,
    video,
    id,
  };

  return (
    <section className={applyModifiers(PRODUCT_STYLE_PREFIX, ['block'])}>
      {video ? (
        <ImageCardOverlay {...overlayProps} />
      ) : (
        <Link
          analyticsRef={analyticsRef}
          href={action && action.link && action.link.url}
          className={`${STYLE_PREFIX}__link`}
        >
          <ImageCardContent {...imageProps} />
        </Link>
      )}
    </section>
  );
};

ImageCard.defaultProps = {
  image: {},
  action: {},
  titleText: [],
};

export default ImageCard;
