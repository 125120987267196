import {
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  MODAL_CLOSED,
  ADDRESS_ADDED,
  EDIT_STARTED,
  DELETE_STARTED,
} from 'shared/constants/delivery-addresses';

export const initialState = {
  confirmation: null,
  isSaving: false,
  modalOpen: false,
  isNewAddress: false,
  isEditing: false,
  deleteIndex: -1,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {*}
 */
export function deliveryAddressesForm(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_REQUEST:
      return {
        ...state,

        isSaving: true,
        errorMessage: null,
        confirmationMessage: null,
      };

    case UPDATE_FAILURE:
      return {
        ...state,

        isSaving: false,
        errorMessage: action.message,
        confirmationMessage: null,
      };

    case UPDATE_SUCCESS:
      return {
        ...state,

        isSaving: false,
        errorMessage: null,
        confirmationMessage: action.message,
      };

    case MODAL_CLOSED:
      return {
        ...state,

        modalOpen: false,
        isNewAddress: false,
        isEditing: false,
        deleteIndex: -1,
      };

    case ADDRESS_ADDED:
      return {
        ...state,
        ...action.payload,
      };

    case EDIT_STARTED:
      return {
        ...state,

        modalOpen: true,
        isEditing: true,
        deleteIndex: -1,
      };

    case DELETE_STARTED:
      return {
        ...state,

        modalOpen: true,
        isNewAddress: false,
        isEditing: false,
        deleteIndex: action.index,
      };

    default:
      return state;
  }
}
