const validCanadianProvinces = {
  AB: true,
  ALBERTA: true,
  BC: true,
  'BRITISH COLUMBIA': true,
  'COLOMBIE-BRITANNIQUE': true,
  MB: true,
  MANITOBA: true,
  NB: true,
  'NEW BRUNSWICK': true,
  'NOUVEAU-BRUNSWICK': true,
  NL: true,
  'NEWFOUNDLAND AND LABRADOR': true,
  'TERRE-NEUVE-ET-LABRADOR': true,
  NS: true,
  'NOVA SCOTIA': true,
  'NOUVELLE-ÉCOSSE': true,
  'NOUVELLE-ECOSSE': true,
  NT: true,
  'NORTHWEST TERRITORIES': true,
  'TERRITOIRES DU NORD-OUEST': true,
  NU: true,
  NUNAVUT: true,
  ON: true,
  ONTARIO: true,
  PE: true,
  'PRINCE EDWARD ISLAND': true,
  'ÎLE-DU-PRINCE-ÉDOUARD': true,
  'ILE-DU-PRINCE-EDOUARD': true,
  QC: true,
  QUEBEC: true,
  QUÉBEC: true, // eslint-disable-line quote-props
  SK: true,
  SASKATCHEWAN: true,
  YT: true,
  YUKON: true,
  'YUKON TERRITORY': true,
};

export default validCanadianProvinces;
