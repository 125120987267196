import { handleException } from '@bravissimolabs/utils';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isFunction from 'lodash/isFunction';
import Link from 'brastrap/common/link/Link';

const mapStateToProps = (
  {
    app: {
      config: { basePath },
    },
  },
  ownProps
) => ({
  ...ownProps,
  basePath: basePath && basePath.replace(/\/$/, ''), // Removes trailing slash.
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const dispatchProps = {};

  if (ownProps.action) {
    const { functionName, trigger } = ownProps.action;
    try {
      const [fileName, action] = functionName.split('.');
      // eslint-disable-next-line global-require, import/no-dynamic-require
      const file = require(`shared/actions/${fileName}`);
      if (file && isFunction(file[action])) {
        dispatchProps[`on${trigger}`] = e => dispatch(file[action](e));
      }
    } catch (error) {
      handleException(
        'Contentful trigger is pointing to file that does not exist',
        error
      );
    }
  }

  return dispatchProps;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Link));
