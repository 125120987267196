// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  size?: string,
  total: number,
  value: number,
  hideRatingTotalString: boolean,
};

const STYLE_PREFIX = 'c-rating';

const Rating = (props: Props) => {
  let className = STYLE_PREFIX;

  if (props.size) {
    className += ` ${STYLE_PREFIX}--${props.size}`;
  }

  return (
    <span
      className={className}
      data-value={props.value}
      data-total={props.total}
    >
      <span className={`${STYLE_PREFIX}__value`}>
        <span className={`${STYLE_PREFIX}__meter`}>
          <span
            className={`${STYLE_PREFIX}__meter-fill`}
            style={{ width: `${(props.value / 5) * 100}%` }}
          >
            <FormattedMessage
              id="rating.value"
              defaultMessage="{value} out of 5 stars"
              values={{
                value: props.value,
              }}
            />
          </span>
        </span>
      </span>
      {props.total && !props.hideRatingTotalString && (
        <data className={`${STYLE_PREFIX}__total`} value={props.total}>
          <FormattedMessage
            id="rating.total"
            defaultMessage="based on {total} reviews"
            values={{
              total: props.total,
            }}
          />
        </data>
      )}
    </span>
  );
};

export default Rating;
