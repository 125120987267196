import extend from 'lodash/extend';

import {
  ADD_ITEM_REQUEST,
  ADD_ITEM_FAILURE,
  REMOVE_ITEM_REQUEST,
  REMOVE_ITEM_FAILURE,
  RECEIVE_BAG,
  UPDATE_QUANTITY_REQUEST,
  UPDATE_QUANTITY_FAILURE,
  ADD_PAYMENT_REQUEST,
  ADD_PAYMENT_FAILURE,
  ADD_DELIVERY_METHOD_REQUEST,
  ADD_DELIVERY_METHOD_FAILURE,
  UPDATE_DELIVERY_METHOD_FIELD,
  ADD_DELIVERY_ADDRESS_REQUEST,
  ADD_DELIVERY_ADDRESS_FAILURE,
  VALIDATE_BAG_FAILURE,
  VALIDATE_BAG_REQUEST,
  UPDATE_TOTAL_WITH_US_TAX,
  HAS_US_DEFAULT_ADDRESS_FOR_UK_SITE,
} from '../constants/bag';

import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../constants/auth';

export const initialState = {
  delivery: {
    addressId: null,
    method: null,
    methodLabel: null,
    clickAndCollectAddress: null,
    outletId: null,
    postage: null,
    additionalFields: {},
    private: false,
    singleParcel: false,
    premium: false,
  },
  isFetching: false,
  fetchingSku: null,
  items: [],
  subTotal: 0,
  total: 0,
  itemCount: 0,
  payment: {
    card: null,
    giftVouchers: [],
  },
  doesUKSiteHaveUsAddress: false,
  usTaxAmount: null,
};

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
export function bag(state = initialState, action = {}) {
  switch (action.type) {
    case VALIDATE_BAG_REQUEST:
    case ADD_DELIVERY_ADDRESS_REQUEST:
    case ADD_DELIVERY_METHOD_REQUEST:
    case ADD_PAYMENT_REQUEST:
    case REMOVE_ITEM_REQUEST:
    case UPDATE_QUANTITY_REQUEST: {
      return {
        ...state,

        isFetching: true,
        fetchingSku: null,
      };
    }

    case ADD_ITEM_REQUEST: {
      const code = action.payload && (action.payload.code || null);
      return {
        ...state,

        isFetching: true,
        fetchingSku: code,
      };
    }

    case VALIDATE_BAG_FAILURE:
    case ADD_DELIVERY_ADDRESS_FAILURE:
    case ADD_DELIVERY_METHOD_FAILURE:
    case ADD_PAYMENT_FAILURE:
    case ADD_ITEM_FAILURE:
    case REMOVE_ITEM_FAILURE:
    case UPDATE_QUANTITY_FAILURE:
      return {
        ...state,

        isFetching: false,
        fetchingSku: null,
      };

    case RECEIVE_BAG: {
      const newState = extend({}, state, action.payload);

      return {
        ...newState,

        isFetching: false,
        fetchingSku: null,
      };
    }

    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };

    case LOGIN_SUCCESS: {
      // Upon successful login the customers bag will be attached to the payload object.
      return extend({}, state, action.payload.bag);
    }

    case UPDATE_DELIVERY_METHOD_FIELD:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          additionalFields: {
            ...state.delivery.additionalFields,
            [action.payload.field.name]: action.payload.value,
          },
        },
      };

    case HAS_US_DEFAULT_ADDRESS_FOR_UK_SITE:
      return {
        ...state,
        doesUKSiteHaveUsAddress: action.payload.doesUKSiteHaveUsAddress,
      };

    case UPDATE_TOTAL_WITH_US_TAX:
      return {
        ...state,
        usTaxAmount: action.payload.taxValue,
      };

    default:
      return state;
  }
}
